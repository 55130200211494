import React from 'react'
import PopUp from '../../../../commonComponent/PopUp/PopUp'
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';

function PopupExcelError({title, closeBtn, errorMessage}) {
    return (
        <PopUp
            title={title}
            width={`${formStyle.popupWidthM}`}
            closeBtn={closeBtn}
        >
            <div className={`textLeft ${formStyle.overlay_48}`}>
                <div className={`fontM bold red ${formStyle.cellPd}`}>Error with {errorMessage.length} rows as mentioned below:</div>
                {errorMessage.map((error, index)=>{
                    return(
                        <div className={`red ${formStyle.mrg_y_6}`} key={index}>
                            <span>{index+1} . </span> {error}
                        </div>
                    )
                })}

            </div>
        </PopUp>
    );
}

export default PopupExcelError