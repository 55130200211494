import React, { useEffect, useState } from 'react';
import style from '../css/KafkaSwitcher.module.css'
import EditIcon from '../../../../icons/edit.svg'
import { getKafkaSwitcherList } from '../../../../utils/api';
import CloseCircle from '../../../../icons/close-circel.svg';
import PopUpKafkaSwitcher from './PopUpKafkaSwitcher';

function KafkaListing(props) {
    const [itemList,setItemList] = useState([]);
    const [editedValue,setEditedValue] = useState('');
    const [showPopUp,setshowPopUp] = useState(false);
    const [title,setTitle] = useState('');

    const togglePopUp = () => {
        setshowPopUp(!showPopUp);
    }

    const updateListing = async () => {
        const res = await getKafkaSwitcherList();
        
        if(res.data.length){
            res.data.map((elem)=>{
                elem.isEdited = false;
            })
            setItemList(res.data);
        }
        else{
            setItemList([]);
        }
        console.log(res);
    }

    const makeEditTrue = (id,value) => {
        let list = Object.assign([],itemList);
        list.map((item,idx)=>{
            if(idx === id){
                item.isEdited=value;
            }
            else{
                item.isEdited=false;
            }
        })
        setItemList(list);
    }

    useEffect(()=>{
        updateListing();
    },[])
    return (
        <>
            <div className={`${style.container}`}>
                <div className={`flex ${style.thead}`}>
                    <div className={`${style.pd_bg} ${style.width33}`}>Title</div>
                    <div className={`${style.pd_bg} ${style.width33}`}>Value</div>
                    <div className={`${style.pd_bg} ${style.width33}`}>Action</div>
                </div>

                <div className={`${style.tbody}`}>
                    {
                        itemList.map((item,idx) => {
                        return (
                            <div className={` flex flex-space-between ${style.trow}`} key={idx}>
                                <div className={`${style.pd_bg} ${style.width33}`}>{item.title}</div>
                                {
                                    !item.isEdited && 
                                    <div className={`${style.pd_bg} ${style.width33}`}>{item.value}</div>
                                }
                                {
                                    item.isEdited && 
                                    <div className={`${style.pd_bg} ${style.width33}`}>
                                    <input type="text" className={`${style.conditionalInput}`} value={editedValue} onChange={(e)=>setEditedValue(e.target.value)}  />
                                    </div>
                                }
                                {
                                    !item.isEdited && 
                                    <div className={`${style.pd_bg} ${style.width33}`}>
                                        <div>
                                            <span className={`pointer ${style.EditIcon}`}
                                                onClick={()=>{
                                                        setEditedValue(item.value)
                                                        makeEditTrue(idx,true)
                                                }}
                                            ><img src={EditIcon}/></span>
                                        </div>
                                    </div>
                                }
                                { 
                                        item.isEdited && 
                                    <div className={`${style.pd_bg} ${style.width33}`}>
                                        <div>
                                            <button className={`${style.filledBtn}`}
                                                    onClick={()=>{
                                                        togglePopUp();
                                                        setTitle(item.title)
                                                    }}
                                            >Save</button>
                                            <img src={CloseCircle} 
                                                className={`${style.closeIcon} pointer`}
                                                onClick={()=>{
                                                    makeEditTrue(idx,false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                        })
                    }
                </div>

            </div>
            {
                showPopUp &&
                <PopUpKafkaSwitcher
                closeBtn={() => togglePopUp()}
                title = {title}
                value={editedValue}
                fetchData = {updateListing}
                />
            }
            
        </>
    );
}

export default KafkaListing;