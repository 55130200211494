import React, { useState } from 'react';
import Loader from '../../../../commonComponent/Loader/Loader';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { deepCopyObject, sendTestCommunication } from '../../../../utils/api';
import { ErrorHandling } from '../../../../State';

function PopupSendTest({closeBtn, campaignType, submitData, firstVar}) {

    const [userData, setUserData] = useState({
        consumer_id: "",
        phone: "",
        user_id: ""
    })
    const [isLoading, setLoader] = useState(false);

    const handleDetailChange = (key, value) => {
        let numberRegex = /^\d+$/;
        // Validate numbers

        if(numberRegex.test(value) || value == ""){
            let localUserData = Object.assign({}, userData);
            localUserData[key] = value
            setUserData(localUserData)
        }
    }

    const handleSubmit = async() => {
        let message = ""
        if(!userData.consumer_id && (!userData.phone)){
            message = "Enter Phone number OR Consumer ID"
        }
        else if(userData.phone && userData.phone.length !== 10){
            message = "Enter valid phone number"
        }

        if(!message){
            let data = Object.assign({},submitData)
            let localUserData = deepCopyObject(userData)
            if(submitData.csv_type === 1){
                localUserData.var_list = firstVar.var_list;
            }
            data.consumer_data = [localUserData]
            setLoader(true)
            let res = await sendTestCommunication(data)
            setLoader(false)
            if(res){
                closeBtn()
            }
        }
        else {
            ErrorHandling(message)
        }
    }

    return (
        <PopUp
            width={formStyle.popupWidthS} 
            title={`Send test ${campaignType.name}`} 
            closeBtn={() => {
                closeBtn()
            }}
        >
            <div className={`${formStyle.inputContainer}`}>
                <div> Enter Phone Number </div>
                <input 
                    value={userData.phone} 
                    placeholder='Phone Number' 
                    onChange={(e) =>  handleDetailChange('phone', e.target.value)}
                    disabled={userData.consumer_id}
                    maxLength={10}
                    className={`${formStyle.mrg_y_6} ${formStyle.width96} ${formStyle.inputField}`}
                />

                <div className={`relative`}>
                    <div className={`${formStyle.separator}`}></div>
                    <span className={`absolute bg_white ${formStyle.separatorText}`}>OR</span>
                </div>

                <div> Enter Customer ID </div> 
                <input 
                    value={userData.consumer_id} 
                    placeholder='Consumer ID' 
                    onChange={(e) =>  handleDetailChange('consumer_id', e.target.value)}
                    disabled={userData.phone}
                    className={`${formStyle.mrg_y_6} ${formStyle.width96} ${formStyle.inputField}`}
                />
                {
                    submitData.csv_type === 1 &&
                    <div className={`orange ${formStyle.mrg_y_6}`}>
                        <b >Variable List :</b> {firstVar.var_list.join(', ')}
                    </div>
                }  

            </div>
            <div className={`${formStyle.btnContainer}`}>
                <button className={`${formStyle.btn} ${formStyle.successBtn}`} onClick={handleSubmit}>Submit</button>
            </div>
            {
                isLoading && <Loader />
            }
        </PopUp>
    );
}

export default PopupSendTest;