import React, { useEffect } from 'react'
import style from '../css/CampaignList.module.css'
import deleteIcon from '../../../../icons/bin.svg';
import { imgUrl } from '../../../../utils/config';
import { removeSkusFromZones } from '../../../../utils/api';
import { useState } from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { ErrorHandling } from '../../../../State';

export default function ({ productInfo, campaign_id }) {
    const [zoneCount, setZoneCount] = useState(0);
    const [product, setProduct] = useState(productInfo);
    const [removeProdInfo, setRemoveProdInfo] = useState({});
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        let count = 0
        product.zones_info.map((item) => { if (!item.is_zone_remove) count++ });

        setZoneCount(count);
    }, [])

    useEffect(() => {
        if (product.is_product_removable) setZoneCount(0);
    }, [product.is_product_removable])


    const removeProduct = async (prod_id, zone_id) => {
        let res = await removeSkusFromZones(campaign_id, prod_id, zone_id)
        if (res.success) {
            let product_details = { ...product }
            if (zone_id === 0) {
                product_details.zones_info.map((zone) => {
                    zone.is_zone_remove = true;
                })
                product_details.is_product_removable = true;
            } else {
                let count = 0;
                product_details.zones_info.map((zone) => {
                    if (zone.id == zone_id) {
                        zone.is_zone_remove = true;
                    }

                    if (zone.is_zone_remove) count++;

                })

                if (count === product_details.zones_info.length) product_details.is_product_removable = true;
            }
            setProduct({ ...product_details })
            setPopup(false)
        } else { 
            ErrorHandling(res.err)
        }
    }

    return (
        <div>
            <div className={`${style.deal_container}`} key={product.prod_id}>
                <div className="image_container">
                    <img src={imgUrl + product.sku_img} width="150" height="100" alt="" />
                </div>
                <div className={`${style.detail_container_two}`}>
                    <div className={`${style.pack_container}`}>
                        <div className={`${style.pack_title}`}>Pack: </div>
                        <div className={`${style.pack_description}`}>{product.pack_qt}</div>
                    </div>
                    <div className={`${style.title_one}`}>
                        {product.prod_name} | [{product.prod_id}] |
                    </div>
                    <div className={`${style.title_two}`}>Deal Price: {product.deal_price} | Rank : {product.rank}</div>
                    <div className={`${style.cities_container}`}>
                        {Object.keys(product.stock_left).map((item) => {

                            return (
                                <div>
                                    {` ${item} : ${product.stock_left[item]} | `}
                                </div>
                            );
                        })}
                    </div>
                    <div className={`${style.table_container}`}>
                        <div className={`${style.table_title}`}>
                            <div className="zone_selected">Zone Selected</div>
                            <div className={`textCenter`}>Burn Diff</div>
                            {product.activated_at > 0 && <div className="textCenter">Action</div>}
                        </div>
                        <>
                            {product.zones_info.map((item, index) => {
                                return (
                                    <div className={`${style.ind_entry}`}>
                                        <div className={`${style.city}`}>{item.name}</div>
                                        <div className={`${style.burn} ${item.is_zone_remove ? 'is_removed_style' : ''}`}>{item.burn_difference}</div>
                                        {product.activated_at > 0 && (!item.is_zone_remove ? <div className='textCenter'><img className='textCenter' src={deleteIcon} alt={'delete'} onClick={() => { setRemoveProdInfo({ prod_id: product.prod_id, zone_id: item.id }); setPopup(true); }} /> </div> : <div className='textCenter'>Removed</div>)}
                                        {/* <br></br> */}
                                    </div>
                                );
                            })}
                        </>
                    </div>
                    <div style={{ marginTop: '15px' }}>*{product.text}</div>
                </div>
                {product.status !== 3 ?
                    (
                        <div className="remove_button_container">
                            {product.activated_at > 0 && zoneCount !== 0 ? (<button className={`${style.remove_button}`} onClick={() => { setRemoveProdInfo({ prod_id: product.prod_id, zone_id: 0 }); setPopup(true); }}>Remove All</button>) : (zoneCount === 0 || !product.is_product_removable) && product.activated_at > 0 ? (<button className={`${style.remove_button} ${style.disable_btn}`}>Removed</button>) : ''}
                        </div>
                    ) : ''}
            </div>

            {/* Confirmation Popup */}
            {popup && <div>
                <PopUp width={formStyle.popupWidthS} title={`Remove Product`} closeBtn={() => { setPopup(false) }} >
                    <div style={{ textAlign: 'center' }} className={`${formStyle.inputContainer} `}>
                        Are You Sure You Want To Remove This Product ?
                    </div>
                    <div className={`${formStyle.btnContainer}`}>
                        <button onClick={() => { removeProduct(removeProdInfo.prod_id,removeProdInfo.zone_id) }} className={`${formStyle.confirmBtn}`}>Submit</button>
                    </div>
                </PopUp>
            </div>}
        </div>
    );
}
