import React, { useEffect, useRef, useState } from 'react'
import style from '../css/Products.module.css'
import { useNavigate, useParams } from 'react-router-dom';
import LocationIcon from "../../../../icons/location.svg";
import { createProductWidget, editProductWidget, getProductWidgetDetail, getOrderBannerZonewiseList, searchSku, uploadBannerImage, uploadPdfFile, deepCopyObject, uploadSelImage } from '../../../../utils/api';
import { BaseImgUrl, PdfUrl, ProductWidgetImgUrl } from '../../../../utils/config';
import EditIcon from '../../../../icons/edit.svg';
import PopupAddSku from './PopupAddSku';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import Loader from '../../../../commonComponent/Loader/Loader'
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import UploadIcon from "../../../../icons/ico-upload.svg";
import UploadGreyIcon from "../../../../icons/ico-upload-grey.svg";
import CancelIcon from "../../../../icons/close-circel.svg";
import UploadPdf from '../../../../icons/upload-pdf.svg'
import {ReactComponent as UploadFileIcon} from '../../../../icons/upload.svg'
import DownloadPdf from '../../../../icons/download-pdf.svg'
import DeleteIcon from '../../../../icons/bin-grey.svg'
import DeleteRedIcon from '../../../../icons/bin.svg'
import PreviewIcon from '../../../../icons/preview.png'

// image_type: small
// Request URL: https://api.crofarm.com/spa/products/image/v1/


function AddProduct ({ }) {
    const { status, selZone, id } = useParams()
    const inputRef = useRef({});

    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [selectedProd, setSelectedProd] = useState({});
    const [zoneList, setZoneList] = useState([]);
    const [isAllZoneSelected, setIsAllZoneSelected] = useState(false);
    const [selectedZoneList, setSelectedZoneList] = useState([]);
    const [showPopup, setShowPopup] = useState({
        showAddSkuPopup: false,
        popup: false,
        deletePdf: false,
        showPreview: false,
    })
    const [errorList, setErrorList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    
    const [images, setImages] = useState({
        large_banner: '',
        medium_banner: '',
        square_banner: '',
    })
    const [Productdata, setProductdata] = useState({
        id : '',
        pricing_zone_ids: [],
        prod_id: '',
        is_active: true,
        quality_attributes : '',
        prod_image_info : {
            large_banner:{
                Image_id : '',
            	is_dark : 1 
            },
            medium_banner:{
                Image_id : '',
            	is_dark : 1 
            },
            square_banner:{
                Image_id : '',
            	is_dark : 1 
            }
        },
        report_file: "",
        pdf_images: [],
        lab_test_image: {
            image_id: "",
            aspect_ratio: 0.0,
        }, // green box
        chemical_free_text: {
            image_id: "",
            aspect_ratio: 0.0,
        }
    })
    const navigate = useNavigate()

    // select zone
    const selectZone = (zoneElement) => {
        console.log(zoneElement);
        var all_zone_data = [...zoneList]
        let selectedZones = [...selectedZoneList]
        if (zoneElement.target.checked) {
            const updatedZoneList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = true;
                    selectedZones.push(zone.id);
                }
                return zone;
            });
            if (selectedZones.length === zoneList.length) {
                setIsAllZoneSelected(true);
            }

            setSelectedZoneList(selectedZones)
            setZoneList(updatedZoneList)

        }

        else {
            const updatedList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = false;
                }
                return zone;
            });

            selectedZones = selectedZoneList.filter((zone) => zone !== parseInt(zoneElement.target.id))
            setSelectedZoneList(selectedZones)
            setZoneList(updatedList)
            setIsAllZoneSelected(false);
        }

        let obj = Productdata;
        obj.pricing_zone_ids = selectedZones;
        setProductdata({ ...obj })
    }

    // zone selection 'ALL'
    const selectAllZone = (event) => {
        let selected_zones = []
        if (event.target.checked) {
            const pricing_zones = zoneList.map(zoneElement => {
                zoneElement.is_selected = true;
                selected_zones.push(zoneElement.id)
                return zoneElement;

            })
            setSelectedZoneList(selected_zones);
            setIsAllZoneSelected(true);
            setZoneList(pricing_zones)

        } else {
            const pricing_zone = zoneList.map(zoneElement => {
                if(!zoneElement.is_disabled){
                    zoneElement.is_selected = false;
                }
                return zoneElement;

            })
            setSelectedZoneList([]);
            setIsAllZoneSelected(false);
            setZoneList(pricing_zone)

        }

        let obj = Productdata;
        obj.pricing_zone_ids = selected_zones;
        setProductdata({ ...obj })
    }

    // input change - quality attribute 
    const handleInputChange = (e) => {
        let obj = Productdata;
        obj[e.target.name] = e.target.value;
        setProductdata({ ...obj });
    }

    // 3 image bg color change
    const handleImageBgColor = (key, value) => {
        let obj = Productdata;
        obj.prod_image_info[key].bg_color = value;
        setProductdata({ ...obj });
    }

    // radio btn selection
    const handleRadioChange = (e) => {
        let obj = Productdata;
        //console.log('e', e.target.value, 'and', e.currentTarget.name)
        //debugger;
        obj.prod_image_info[e.target.name].is_dark = parseInt(e.target.value);

        //obj.prod_Image_info[e.target.value].is_dark = true;

        console.log('obj', obj);
        setProductdata({ ...obj });

        console.log('Productdata', Productdata);
    }

    // select sku - new / creation - popup
    const addSkuForDeal = (sku) => {
        setShowPopup({... showPopup, showAddSkuPopup: false})
        setSelectedProd(sku);
        let obj = Productdata;
        obj.prod_id = sku.prod_id;
        obj.prod_name = sku.prod_name;
    }

    // opening sku pop
    const openAddSkuPopup = () => {
        if (selectedZoneList.length === 0) {
            ErrorHandling("Please Select Zone First");
        } else {
            setShowPopup({... showPopup, showAddSkuPopup: true})
        }
    }

    // upload 3 image click 
    // slect image from system
    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    // upload 3 image 
    // via api call 
    const uploadImage = async (e) => {
        let image = e.target.files[0];

        var image_type = 'large';
        let Imgdata = new FormData();      

        let imgTypeExt = image.type.split('/')
        
        Imgdata.append('file', image);
        Imgdata.append('file_ext', '.' + imgTypeExt[1]);
        Imgdata.append('image_type', image_type)
        if (image === '') {
            ErrorHandling('Please select the image')
        } 
        else if(image.type !== 'image/png' && image.type !== 'image/jpg' && image.type !== 'image/jpeg' && image.type !== 'image/webp' ){
            ErrorHandling('Please select correct image format')
        } else {
            if (e.target.id === 'large_banner') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 872 && img.width === 656) {
                        
                        setLoading(true)
                        let res = await uploadBannerImage(Imgdata)
                        setLoading(false)
                        
                        if (res.success) {
                            let obj = Productdata;
                            obj.prod_image_info.large_banner.Image_id = res.image_id;
                            console.log(obj);
                            setProductdata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(image);
            } else if (e.target.id === 'medium_banner') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 326 && img.width === 520) {

                        setLoading(true)
                        let res = await uploadBannerImage(Imgdata)
                        setLoading(false)

                        if (res.success) {
                            let obj = Productdata;
                            obj.prod_image_info.medium_banner.Image_id = res.image_id;
                            console.log(obj);
                            setProductdata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(image);
            } else if (e.target.id === 'square_banner') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 440 && img.width === 520 ) {

                        setLoading(true)
                        let res = await uploadBannerImage(Imgdata)
                        setLoading(false)

                        if (res.success) {
                            let obj = Productdata;
                            obj.prod_image_info.square_banner.Image_id = res.image_id;
                            console.log(obj);
                            setProductdata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(image);
            }  else {

                setLoading(true)
                let res = await uploadBannerImage(Imgdata)
                setLoading(false)

                if (res.success) {
                    let obj = Productdata;
                    obj[e.target.id] = res.image_id;
                    console.log(obj);
                    setProductdata({ ...obj })
                } else {
                    ErrorHandling(res.error.message)
                }
            }
        }
    }

    // handle pdf upload click
    const handleFileClick = (id) => {
        inputRef.current[id].click()
    }

    // handle file / pdf upload
    const handleFileUpload = async (id) => {
        const path = "static/reports/"
        let file = inputRef.current[id].files[0]
        let fileName = file.name.split('.')
        
        let fd = new FormData();
        fd.append("value", file);
        fd.append("name", fileName[0]);
        fd.append("path", path);

        setLoading(true)
        let file_id = await uploadPdfFile(fd);
        setLoading(false);
        setProductdata({... Productdata, report_file: file_id})
    }

    // upload lab report image
    const handleImageUpload = async (id) => {
        var image_type = 'images';
        let Imgdata = new FormData();      

        let file = inputRef.current[id].files[0]
        let fileName = file.name.split('.')
        
        Imgdata.append('file', file);
        Imgdata.append('file_ext', '.' + fileName[1]);
        Imgdata.append('image_type', image_type);
        Imgdata.append('is_bigger_size_accepted', true);

        setLoading(true)
        let image_id = await uploadSelImage(Imgdata)
        setLoading(false);

        if(image_id){

            let img = new Image;

            img.onload = async function () {

                const height = img.height;
                const width = img.width;
                const aspectRatio = width / height ;
                console.log("height -->", height);
                console.log("width -->", width);

                let localProductData = deepCopyObject(Productdata);
                localProductData[id].image_id = image_id;
                localProductData[id].aspect_ratio = aspectRatio.toFixed(2);
    
                setProductdata(localProductData);

                inputRef.current[id].files = ""
            }

            img.src = img.src = URL.createObjectURL(file);

        }
    }

    // handle pdf image upload
    // TODO: HANDLE THIS
    const handlePdfImageUpload = async (id) => {

        var image_type = 'images';
        let Imgdata = new FormData();      

        let file = inputRef.current[id].files[0]
        let fileName = file.name.split('.')
        
        Imgdata.append('file', file);
        Imgdata.append('file_ext', '.' + fileName[1]);
        Imgdata.append('image_type', image_type);
        Imgdata.append('is_bigger_size_accepted', true);

        let img = new Image;
        img.onload = async function () {
            const height = img.height;
            const width = img.width;
            const aspectRatio = width / height ;
            console.log("height -->", height)
            console.log("width -->", width)

            // desired aspect ratio (e.g., 16:9 | 7:5 | 1:1)
            // const desiredAspectRatio = 7 / 5;

            // Check if the aspect ratio is within an acceptable range (e.g., +/- 5%)
            // const aspectRatioTolerance = 0.05;
            // const isAspectRatioValid =
            //     aspectRatio >= desiredAspectRatio - aspectRatioTolerance &&
            //     aspectRatio <= desiredAspectRatio + aspectRatioTolerance;

            const isUnderDimensions = height <= 1400 && width <= 1000

            if (isUnderDimensions) {
                setLoading(true)
                let res = await uploadBannerImage(Imgdata)
                setLoading(false);
        
                debugger;
                if(res.success){
                    let index = id.split('_')
                    index = index[2]
            
                    let localProductData = deepCopyObject(Productdata)
                    let imageObj = {
                        image_id : res.image_id,
                        aspect_ratio : aspectRatio.toFixed(2),
                    }
                    localProductData.pdf_images[index] = imageObj
            
                    setProductdata(localProductData)
                }
                else {
                    ErrorHandling(res.error.message)
                }
            }
            else{
                ErrorHandling("Please Upload image dimensions Under height 1000px & width 1400px !")
            }
            inputRef.current[id].files[0] = ""
        }
        img.src = img.src = URL.createObjectURL(file);

    }

    // delete soil report
    const deleteReport = () => {
        setProductdata({... Productdata, report_file: "", pdf_images: []})
    }

    // delete Pdf image 
    const deletePdfImage = (index) => {
        let localProductData = deepCopyObject(Productdata);
        localProductData.pdf_images.splice(index, 1)
        setProductdata(localProductData)
    }

    //delete lab report 
    const deleteLabTestImage = (key) =>{
        setProductdata({...Productdata, [key]: {image_id: "", aspect_ratio: ""}})
    }

    // handle delete product images (three images)
    const deleteProdImage = (key) =>{
        let localProductData = deepCopyObject(Productdata);
        localProductData.prod_image_info[key] = {};
        setProductdata(localProductData);
    }

    // file name
    const pdfFileName = (fileName) => {
        let fileSplit = fileName.split('/')
        return fileSplit[fileSplit.length - 1]
    }

    // on submit validation 
    const validationProduct = function () {
        setIsSubmitButtonClicked(true);
        let pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
        if(status == 'new'){
            if (Productdata.pricing_zone_ids.length == 0) {
                setErrorList([{ loc: ["", "Zones"], msg: 'Please Select Zone' }])
                setShowPopup({... showPopup, popup: true})
            } else if (!Productdata.prod_id || Productdata.prod_id == undefined) {
                setErrorList([{ loc: ["", "Product"], msg: 'Please Select Product' }])
                setShowPopup({... showPopup, popup: true})
            } 
            // background color check 
            else if(
                    Productdata.prod_image_info.large_banner.Image_id && 
                    (!Productdata.prod_image_info.large_banner.bg_color ||
                    !pattern.test(Productdata.prod_image_info.large_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Big Product Image"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.prod_image_info.medium_banner.Image_id && 
                (!Productdata.prod_image_info.medium_banner.bg_color ||
                !pattern.test(Productdata.prod_image_info.medium_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Product Carousel - Center"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.prod_image_info.square_banner.Image_id && 
                (!Productdata.prod_image_info.square_banner.bg_color ||
                !pattern.test(Productdata.prod_image_info.square_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Medium Product Carousel"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.report_file !== "" && !Productdata.pdf_images.length
            ){
                setErrorList([{ loc: ["", "Soil Test Report Images"], msg: 'Please Upload Soil Test Report Images' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.report_file !== "" && !Productdata.chemical_free_text.image_id
            ){
                setErrorList([{ loc: ["", "Lab Test PDP Image"], msg: 'Please Upload Lab Test PDP Image' }])
                setShowPopup({... showPopup, popup: true})
            }
            else {
                status !== 'new' ? editProductData() : createProductData()
            }
        }else{
            if(typeof(Productdata.pricing_zone_ids) !== 'object'){
                let zoneId = [Productdata.pricing_zone_ids];
                Productdata.pricing_zone_ids = zoneId;
            }

              // background color check 
            if(
                Productdata.prod_image_info.large_banner.Image_id && 
                (!Productdata.prod_image_info.large_banner.bg_color ||
                !pattern.test(Productdata.prod_image_info.large_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Big Product Image"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.prod_image_info.medium_banner.Image_id && 
                (!Productdata.prod_image_info.medium_banner.bg_color ||
                !pattern.test(Productdata.prod_image_info.medium_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Product Carousel - Center"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.prod_image_info.square_banner.Image_id && 
                (!Productdata.prod_image_info.square_banner.bg_color ||
                !pattern.test(Productdata.prod_image_info.square_banner.bg_color))
            ){
                setErrorList([{ loc: ["", "Medium Product Carousel"], msg: 'Please Enter valid Background color' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.report_file !== "" && !Productdata.pdf_images.length
            ){
                setErrorList([{ loc: ["", "Soil Test Report Images"], msg: 'Please Upload Soil Test Report Images' }])
                setShowPopup({... showPopup, popup: true})
            }
            else if(
                Productdata.report_file !== "" && !Productdata.chemical_free_text.image_id
            ){
                setErrorList([{ loc: ["", "Lab Test PDP Image"], msg: 'Please Upload Lab Test PDP Image' }])
                setShowPopup({... showPopup, popup: true})
            }
            else{
                editProductData()
            }
        }
    }

    const createProductData = async () => {
        let data = Productdata;
        if(Productdata.prod_image_info.large_banner.Image_id==''){
            data.prod_image_info.large_banner = {};
        }
        if(Productdata.prod_image_info.medium_banner.Image_id==''){
            data.prod_image_info.medium_banner = {};
        }
        if(Productdata.prod_image_info.square_banner.Image_id==''){
            data.prod_image_info.square_banner = {};
        }
        if(status != "edit"){
            delete data.id
        }
        console.log('data',data)

        setLoading(true)
        let res = await createProductWidget(data);
        setLoading(false)

        if (res.success) {
            SuccessHandling('Product created successfully');
            navigate('/product-widget')
        } else {
            if (res.error.status_code === 422) {
                setErrorList(res.detail)
            } else {
                setErrorList([{ loc: ["", "Error"], msg: res.error.message }])
            }
            setShowPopup({... showPopup, popup: true})
        }
    }

    const editProductData = async () => {

        localStorage.removeItem('productData')

        setLoading(true)
        let res = await editProductWidget(Productdata);
        setLoading(false)

        if (res.success) {
            SuccessHandling('Product updated successfully');
            navigate('/product-widget')
        } else {
            if (res.error.status_code === 422) {
                setErrorList(res.detail)
            } else {
                setErrorList([{ loc: ["", "Error"], msg: res.error.message }])
            }
            setShowPopup({... showPopup, popup: true})
        }
    }

    const fetchData = async () => {
        // for gettig the zone list
        setLoading(true)
        let resListing = await getOrderBannerZonewiseList();
        setLoading(false)
        
        if (status == "edit") {
            const res = await getProductWidgetDetail(id)
            let data = res.data[0];
            console.log('data', data);
            data.report_file = data.report_file ? data.report_file : ""
            
            let pricingZoneId = data.pricing_zone_ids;
            data.previous_pricing_zone_ids =  [data.pricing_zone_ids]
            data.pricing_zone_ids = [data.pricing_zone_ids]

            let selProdData = {
                id: data.id,
                prod_id: data.prod_id,
                prod_name: data.prod_name,
                image_id: data.small_image_id,
                pack_qt: data.pack_qt,
            }
            setSelectedProd(selProdData);

            setProductdata({ ...data });
            setSelectedZoneList(data.pricing_zone_ids);
            //delete data.prod_id
            console.log('Productdata redit',Productdata);
            let obj = {};
            obj.prod_image_info = data.prod_image_info;
            
        
            let selectedZones = [...resListing.data];
            selectedZones.forEach((zone) => {
                zone.is_selected = false;
                
                if(zone.id === pricingZoneId){
                    zone.is_selected = true;
                    zone.is_disabled = true;
                }
            })
            setZoneList(selectedZones)

        } else {

            if (localStorage.getItem('productData')) {
                let data = await JSON.parse(localStorage.getItem('productData'));
                if (data.pricing_zone_id.length > 0) {
                    let skuData = await searchSku(data.pricing_zone_id[0], data.prod_id);
                    setSelectedProd(skuData[0])
                }
                setSelectedZoneList(data.pricing_zone_id)
                let selectedZones = [...resListing.data];
                for (let i = 0; i < data.pricing_zone_id.length; i++) {
                    selectedZones.forEach((zone) => {
                        if (data.pricing_zone_id[i] === zone.id) {
                            zone.is_selected = true;
                        }
                    })
                }
                setZoneList(selectedZones)
                localStorage.removeItem('productData')
                setProductdata({ ...data });

            } else {
                setZoneList(resListing.data);
            }
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {/* header */}
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>Product Widget Images</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>{status !== "new" ? "Update" : "Create"} Add Product Image {status !== 'new' ? ` | ${selectedProd.prod_name}` : ''}</div>

                <div className={`${style.addContainer}`}>


                    {/* zone section */}
                    <div className={`${style.separator}`}>
                        {/* zone listing header*/}
                        <div className={`${style.mrg_top_3}`}>
                            <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                            <span className={`bold ${style.zone_heading}`}>Select Zones</span>
                        </div>

                        {/* zone listing check boxes */}
                        <div className={`flex flex-sace-between flex-wrap`}>

                            {/* zone check boxes */}
                            <div onClick={() => { }} className={`${style.list_item}`}>
                                <label className={`pointer`}>
                                    <input type="checkbox" className={`middleAlignIcon ${style.checkbox}`} checked={isAllZoneSelected} onChange={(e) => { selectAllZone(e) }} />
                                    <span className={`${style.checkmark}`}>All</span>
                                </label>
                            </div>

                            {
                                zoneList.map((city, index) => {
                                    return (
                                        <div key={city.id} onClick={() => { }} className={`${style.list_item}`}>
                                            <label className={`${city.is_disabled ? 'not_allowed' : 'pointer'}`}>
                                                <input 
                                                    type="checkbox" 
                                                    className={`middleAlignIcon ${style.checkbox}`} 
                                                    id={city.id} 
                                                    checked={city.is_selected} 
                                                    disabled={city.is_disabled}
                                                    onChange={(e) => { selectZone(e) }}
                                                />
                                                <span className={`${city.is_disabled ? 'labelGrey' : ''}`}>{city.name}</span>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {isSubmitButtonClicked && (Productdata.pricing_zone_ids.length === 0) && <div className={`errorMessage`}>* Please Select Zone(s)</div>}
                    </div> 


                    {/* Add product | quality attribute | chemical free text | lab test image | PDF upload */}
                    <div className={`${style.mrg_top_3} `}>
                        <div>
                            {!selectedProd.id &&
                                <>
                                    <div className={`${style.title}`}>Add Product (SKU)</div>
                                    <button className={`${style.filledBtn} `} onClick={() => { openAddSkuPopup() }}>Add Skus (+)</button>
                                </> ||

                                <li className={`${style.product_detail_wrapper} `}>
                                    {/* product */}
                                    <div className={`grid ${style.grid_template_sku} ${style.sku_row}`}>
                                        <div className={style.pd_bg} style={{ float: 'left' }}>
                                            <img className={`${style.sku_img}`} src={'https://img.crofarm.com/images/prodsmall/' + selectedProd.image_id} />
                                        </div>
                                        <div className={style.pd_bg}>
                                            <strong className={style.product_id}>[{selectedProd.prod_id}] </strong>
                                            <span className={style.prod_name}>{selectedProd.prod_name} </span>
                                            <div className={`fontS labelGrey`}>( Pack: {selectedProd.pack_qt} )</div>
                                        </div>
                                        {status === 'new' && 
                                            <span className={style.pd_bg}><img style={{ height: '20px' }} src={EditIcon} onClick={() => openAddSkuPopup()} /></span>
                                        }
                                    </div>

                                    {/* quality attribute */}
                                    <div className={`grid ${style.brdGrey} ${style.quality_attribute}`}>
                                        <div className={`bold labelGrey ${style.title}`}>Quality Attributes</div>

                                        <textarea 
                                            className={`${isSubmitButtonClicked && (Productdata.quality_attributes < 0 || Productdata.quality_attributes === '') ? 'outlineRed' : ''}  
                                                ${style.textarea_field}`}     
                                            name='quality_attributes' 
                                            type='text'
                                            placeholder='Quality Attributes' 
                                            value={Productdata.quality_attributes} 
                                            onChange={(e) => { handleInputChange(e) }} 
                                        ></textarea>
                                            
                                    </div>

                                    {/* chemical free text */}
                                    <div className={`grid ${style.brdGreen} ${style.quality_attribute}`}>
                                        <input 
                                            ref={el => inputRef.current['chemical_free_text'] = el}
                                            type='file' 
                                            onChange={() => handleImageUpload(`chemical_free_text`)}  
                                            className={`hide`} 
                                            accept='image/*'
                                        />

                                        {
                                            Productdata.chemical_free_text.image_id ? 
                                            <>
                                                <div className={`flex flex-space-between bold labelGrey ${style.title}`}>
                                                    LAB TEST PDP IMAGE                                                 
                                                    <img 
                                                        src={DeleteIcon}  
                                                        className={`pointer ${style.mrg_x_6}`}
                                                        onClick={ ()=> {if(Productdata.chemical_free_text.image_id){
                                                            deleteLabTestImage('chemical_free_text')
                                                            }
                                                        }
                                                    }
                                                    />
                                                </div>
                                                <img 
                                                    src={BaseImgUrl + Productdata.chemical_free_text.image_id}
                                                    onClick={() => handleFileClick(`chemical_free_text`)}
                                                    className={` pointer ${style.labImage}`} 
                                                />
                                            </> :
                                            <div 
                                                onClick={() => handleFileClick(`chemical_free_text`)}
                                                className={` pointer `} 
                                            >
                                                <div className={`textCenter`}>
                                                    <img src={UploadIcon} />
                                                    <div className={`green upperCase bold fontS`}>Upload Lab Test PDP Image</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* lab test image */}
                                    <div className={`grid ${style.brdGreen} ${style.quality_attribute}`}>
                                        <input 
                                            ref={el => inputRef.current['lab_test_image'] = el}
                                            type='file' 
                                            onChange={() => handleImageUpload(`lab_test_image`)}  
                                            className={`hide`} 
                                            accept='image/*'
                                        />

                                        {
                                            Productdata.lab_test_image.image_id ? 
                                            <>
                                                <div className={`flex flex-space-between bold labelGrey ${style.title}`}>
                                                    Lab Test Image
                                                    <img 
                                                        src={DeleteIcon}  
                                                        className={`pointer ${style.mrg_x_6}`}
                                                        onClick={ ()=> {if(Productdata.lab_test_image.image_id){
                                                            deleteLabTestImage('lab_test_image')
                                                            }
                                                        }
                                                    }
                                                    />
                                                </div>
                                                <img 
                                                    src={BaseImgUrl + Productdata.lab_test_image.image_id}
                                                    onClick={() => handleFileClick(`lab_test_image`)}
                                                    className={` pointer ${style.labImage}`} 
                                                />
                                            </> :
                                            <div 
                                                onClick={() => handleFileClick(`lab_test_image`)}
                                                className={` pointer `} 
                                            >
                                                <div className={`textCenter`}>
                                                    <img src={UploadIcon} />
                                                    <div className={`green upperCase bold fontS`}>Upload Lab Test Image</div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {/* PDF upload */}
                                    <div className={`grid ${style.brdGreen} ${style.quality_attribute}`}>

                                        <input 
                                            ref={el => inputRef.current['pdf'] = el}
                                            type='file' 
                                            onChange={() => handleFileUpload('pdf')}  
                                            className={`hide`} 
                                            accept='application/pdf'
                                        />

                                        {/* upload pdf  */}
                                        {
                                            Productdata.report_file ? 
                                            <>
                                                <div className={`bold green ${style.title}`}>Soil Test Report </div>
                                                <div className={`flex flex-space-between`}>
                                                    <div className={`grid ${style.file_grid}`}> 
                                                        <a href={PdfUrl + Productdata.report_file} target={'_blank'}> <span className={`pointer fontS textUnderline blue textEllipse`}><img src={DownloadPdf} alt={"pdf"}/> {pdfFileName(Productdata.report_file)}</span>  </a>
                                                        <img 
                                                            src={DeleteIcon} 
                                                            alt={"delete pdf"} 
                                                            className={`pointer ${style.mrg_x_6}`}
                                                            onClick={ () => {
                                                                if(Productdata.pdf_images.length){
                                                                    setShowPopup({... showPopup, deletePdf: true})
                                                                }
                                                                else{
                                                                    deleteReport()
                                                                }
                                                            }}
                                                        /> 
                                                    </div>
                                                    <UploadFileIcon 
                                                        className={` pointer ${style.btn_outlined}`} 
                                                        onClick={() => handleFileClick('pdf')}
                                                        fill={'#2a9d8b'}
                                                    />
                                                    {/* <button className={`fontS ${style.btn_outlined}`} onClick={() => handleFileClick('pdf')}>Choose file</button> */}
                                                </div>
                                            </> : 
                                            <div className={`pointer textCenter`} onClick={() => handleFileClick('pdf')}>
                                                <div><img src={UploadPdf} alt={"upload pdf"}/></div>
                                                <div className={`bold green fontS`}>UPLOAD SOIL TEST REPORT </div>
                                            </div>
                                        }
                                    </div>
                                </li>

                            }

                            {isSubmitButtonClicked && (Productdata.prod_id === '' || Productdata.prod_id === undefined) && <div className={`errorMessage`}>* Please Select a product</div>}
                        </div>
                    </div>

                    {/* three images for each respective product */}
                    {/* soil report image */}
                    <div className={`${style.mrg_top_3}`}>
                        {
                            !!(Productdata.report_file !== "" && Productdata.pdf_images.length) && 
                            <div className={`textRight  ${style.pd_b_15}`}>
                                <span 
                                    className={`fontS bold pointer`}
                                    onClick={() => setShowPopup({...showPopup, showPreview: !showPopup.showPreview})}>
                                        <img src={PreviewIcon} className={`icon24 `}/>{ showPopup.showPreview ? 'HIDE' : 'SHOW'} PREVIEW
                                </span>
                            </div>
                        }
                        <div className={`grid-start grid-justify-center ${style.grid_template_images}`}>

                            <div className={`${style.mrg_x_6}`}>
                                <div className={`bold ${style.title}`}>Big Product Image</div>
                                <input type="file" name="large_banner" id="large_banner" accept="image/*" style={{ display: "none" }} onChange={(e) => uploadImage(e)} />
                                {
                                    Productdata.prod_image_info.large_banner.Image_id ? 
                                    <span className={'relative'}>
                                        <img 
                                            src={Productdata.prod_image_info.large_banner.Image_id ? 
                                                    `${ProductWidgetImgUrl}${Productdata.prod_image_info.large_banner.Image_id}` : 
                                                    null} 
                                            className={`pointer ${style.greyBrd} ${style.imgContainer}`} 
                                            onClick={() => { uploadImg("large_banner"); }} 
                                        />
                                        <img 
                                            src={DeleteRedIcon} 
                                            alt={"delete Big prod"} 
                                            className={`pointer absolute ${style.greyBrd} ${style.imageDeleteIcon} ${style.mrg_lft}`}
                                            onClick={() => deleteProdImage('large_banner')}
                                        /> 
                                    </span> :
                                    <div 
                                        onClick={() => { uploadImg("large_banner"); }}   
                                        className={`flex flex-space-evenly pointer ${style.greyBrd} ${style.imgContainer} `} >
                                        <div className={`textCenter ${style.imgPlaceholder}`}>
                                            <img src={UploadIcon} />
                                            <div className={`green`}>Upload</div>
                                        </div>
                                    </div>
                                }
                                
                                <div className={`fonts ${style.mrg_y_6} ${Productdata.prod_image_info.large_banner.Image_id !== '' || images.large_banner ? 'green' : 'red'}`}>
                                {images.large_banner !== '' && <span>Selected Image : {images.large_banner  ? images.large_banner.name : 'please select the image'}</span>}

                                    {/* <span>Selected Image : </span>
                                    {images.large_banner  ? images.large_banner.name : 'please select the image'} */}
                                    
                                </div>
                                <div className={`fontS labelGrey`}> *  (Image size 656 x 872px) </div>
                                {isSubmitButtonClicked && (Productdata.prod_image_info.large_banner.Image_id === '' || Productdata.prod_image_info.large_banner.Image_id === undefined) && <div className={`errorMessage`}>* Please upload Big Banner Image</div>}

                                <div className={`${style.mrg_top_3}`}>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value = '0' name='large_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.large_banner.is_dark== 0}
                                        onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Light Theme</span>
                                    </div>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value= '1' name='large_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.large_banner.is_dark== 1} onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Dark Theme</span>
                                    </div>
                                </div>
                                <div className={`${style.mrg_top_2}`}>
                                    <label>
                                        <div className={`fontS labelGrey`}>BACKGROUND COLOR :</div>
                                        <input 
                                            value={Productdata.prod_image_info.large_banner.bg_color}
                                            onChange={(e) => handleImageBgColor('large_banner', e.target.value)}
                                            disabled={!Productdata.prod_image_info.large_banner.Image_id}
                                            className={`${style.input_field}`}
                                        />
                                    </label>
                                    {
                                        isSubmitButtonClicked &&
                                        (
                                            Productdata.prod_image_info.large_banner.Image_id && 
                                            !Productdata.prod_image_info.large_banner.bg_color
                                        ) &&
                                        <div className={`errorMessage`}>* Please Enter background color Image</div>
                                    }
                                </div>
                            </div>

                            <div className={`${style.mrg_x_6}`}>
                                <div className={`bold ${style.title}`}>Product Carousel - Meduim</div>
                                <input type="file" name="medium_banner" id="medium_banner" accept="image/*" style={{ display: "none" }} onChange={(e) => uploadImage(e)} />
                                {
                                    Productdata.prod_image_info.medium_banner.Image_id ?
                                    <span className={`relative`}>
                                        <img 
                                            src={Productdata.prod_image_info.medium_banner.Image_id ? `${ProductWidgetImgUrl}${Productdata.prod_image_info.medium_banner.Image_id}` : null} 
                                            className={`pointer ${style.greyBrd} ${style.imgContainer}`} 
                                            onClick={() => { uploadImg("medium_banner"); }} 
                                        /> 
                                        <img 
                                            src={DeleteRedIcon} 
                                            alt={"delete Big prod"} 
                                            className={`pointer absolute ${style.greyBrd} ${style.imageDeleteIcon} ${style.mrg_lft}`}
                                            onClick={() => deleteProdImage('medium_banner')}
                                        /> 
                                    </span> :
                                    <div 
                                        onClick={() => { uploadImg("medium_banner"); }} 
                                        className={`flex flex-space-evenly pointer ${style.greyBrd} ${style.imgContainer} `} >
                                        <div className={`textCenter ${style.imgPlaceholder}`}>
                                            <img src={UploadIcon} />
                                            <div className={`green`}>Upload</div>
                                        </div>
                                    </div>
                                }
                                <div className={`fonts ${style.mrg_y_6} ${Productdata.prod_image_info.medium_banner.Image_id !== '' || images.medium_banner ? 'green' : 'red'}`}>
                                {images.medium_banner !== '' && <span>Selected Image : {images.medium_banner  ? images.medium_banner.name : 'please select the image'}</span>}

                                </div>
                                <div className={`fontS labelGrey`}> * (Image size 520 x 326 px) </div>
                                {isSubmitButtonClicked && (Productdata.prod_image_info.medium_banner.Image_id === '' || Productdata.prod_image_info.medium_banner.Image_id === undefined) && <div className={`errorMessage`}>* Please upload Medium Banner Image</div>}

                                <div className={`${style.mrg_top_3}`}>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value='0' name='medium_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.medium_banner.is_dark== 0}
                                        onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Light Theme</span>
                                    </div>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value='1' name='medium_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.medium_banner.is_dark== 1} 
                                        onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Dark Theme</span>
                                    </div>
                                </div>
                                <div className={`${style.mrg_top_2}`}>
                                    <label>
                                        <div className={`fontS labelGrey`}>BACKGROUND COLOR :</div>
                                        <input 
                                            value={Productdata.prod_image_info.medium_banner.bg_color}
                                            onChange={(e) => handleImageBgColor('medium_banner', e.target.value)}
                                            disabled={!Productdata.prod_image_info.medium_banner.Image_id}
                                            className={`${style.input_field}`}
                                        />
                                    </label>
                                    {
                                        isSubmitButtonClicked &&
                                        (
                                            Productdata.prod_image_info.medium_banner.Image_id && 
                                            !Productdata.prod_image_info.medium_banner.bg_color
                                        ) &&
                                        <div className={`errorMessage`}>* Please Enter background color Image</div>
                                    }
                                </div>
                            </div>

                            <div className={`${style.mrg_x_6}`}>
                                <div className={`bold ${style.title}`}>Square Product Carousel </div>
                                {/* <input type="file" name="square_banner" id="square_banner" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} /> */}
                                <input type="file" name="square_banner" id="square_banner" accept="image/*" style={{ display: "none" }} onChange={(e) => uploadImage(e)} />
                                {
                                    Productdata.prod_image_info.square_banner.Image_id ? 
                                    <span className={`relative`}>
                                        <img 
                                            src={Productdata.prod_image_info.square_banner.Image_id ? `${ProductWidgetImgUrl}${Productdata.prod_image_info.square_banner.Image_id}` : null} 
                                            className={`pointer ${style.greyBrd} ${style.imgContainer}`} 
                                            onClick={() => { uploadImg("square_banner"); }} 
                                        /> 
                                         <img 
                                            src={DeleteRedIcon} 
                                            alt={"delete Big prod"} 
                                            className={`pointer absolute ${style.greyBrd} ${style.imageDeleteIcon} ${style.mrg_lft}`}
                                            onClick={() => deleteProdImage('square_banner')}
                                        /> 
                                    </span>
                                    : 
                                    <div 
                                        onClick={() => { uploadImg("square_banner"); }} 
                                        className={`flex flex-space-evenly pointer ${style.greyBrd} ${style.imgContainer} `} >
                                        <div className={`textCenter ${style.imgPlaceholder}`}>
                                            <img src={UploadIcon} />
                                            <div className={`green`}>Upload</div>
                                        </div>
                                    </div>
                                }
                                <div className={`fonts ${style.mrg_y_6} ${Productdata.prod_image_info.square_banner.Image_id !== '' || images.square_banner ? 'green' : 'red'}`}>
                                {images.square_banner !== '' && <span>Selected Image : {images.square_banner  ? images.square_banner.name : 'please select the image'}</span>}
                                </div>

                                <div className={`fontS labelGrey`}> * (Image size 520 x 440px) </div>
                                {isSubmitButtonClicked && (Productdata.prod_image_info.square_banner.Image_id === '' || Productdata.prod_image_info.square_banner.Image_id === undefined) && <div className={`errorMessage`}>* Please upload Square Banner Image</div>}

                                <div className={`${style.mrg_top_3}`}>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value='0' name='square_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.square_banner.is_dark== 0}
                                        onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Light Theme</span>
                                    </div>
                                    <div className={`flex ${style.pd_b_15}`}>                              
                                        <input type="radio" value='1' name='square_banner' className={`pointer ${style.customRadio}`} 
                                        checked={Productdata.prod_image_info.square_banner.is_dark== 1} 
                                        onChange={(e) => { handleRadioChange(e) }}/>
                                        <span className={`${style.checkbox_title}`}>Dark Theme</span>
                                    </div>
                                </div>
                                <div className={`${style.mrg_top_2}`}>
                                    <label>
                                        <div className={`fontS labelGrey`}>BACKGROUND COLOR :</div>
                                        <input 
                                            value={Productdata.prod_image_info.square_banner.bg_color}
                                            onChange={(e) => handleImageBgColor('square_banner', e.target.value)}
                                            disabled={!Productdata.prod_image_info.square_banner.Image_id}
                                            className={`${style.input_field}`}
                                        />
                                    </label>
                                    {
                                        isSubmitButtonClicked &&
                                        (
                                            Productdata.prod_image_info.square_banner.Image_id && 
                                            !Productdata.prod_image_info.square_banner.bg_color
                                        ) &&
                                        <div className={`errorMessage`}>* Please Enter background color Image</div>
                                    }
                                </div>
                            </div>

                            <div className={`${style.mrg_x_6} ${style.pdf_image_container}`}>

                                <div className={`${style.title} ${style.imageSeparator}`}>
                                    <div className={`bold`}>Soil Test Report Images {showPopup.showPreview && '(PREVIEW)'}</div>
                                    {/* <div className={`fontS labelGrey`} style={{textTransform: "initial"}}> 
                                        *  Image aspect ratio must be 7 : 5 (h : w)
                                    </div> */}
                                </div>
                                
                                {
                                    showPopup.showPreview ?
                                    <div className={`${style.preview_container}`}>
                                        {
                                            Productdata.pdf_images.map((imageDetail) => {
                                                return(
                                                    <img 
                                                        src={BaseImgUrl + imageDetail.image_id} 
                                                        className={`${style.imageSeparator} ${formStyle.fullwidth}`}
                                                    />
                                                )
                                            })
                                        }
                                    </div> :
                                    Array.from([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], (index) => {
                                        let canUpload = true;
                                        if(index === 0){
                                            canUpload = !!Productdata.report_file
                                        }
                                        else {
                                            canUpload = !!(Productdata.report_file && Productdata.pdf_images.length && Productdata.pdf_images[index-1])
                                        }
                                        return(
                                            <div key={index} className={`${style.mrg_btm}`}>
                                                <input 
                                                    ref={el => inputRef.current[`pdf_image_${index}`] = el}
                                                    type='file' 
                                                    onChange={() => handlePdfImageUpload(`pdf_image_${index}`)}  
                                                    className={`hide`} 
                                                    accept='image/*'
                                                />

                                                {
                                                    Productdata.pdf_images && Productdata.pdf_images[index] ? 
                                                    <div className={`flex`}>
                                                        <img 
                                                            src={BaseImgUrl + Productdata.pdf_images[index].image_id} 
                                                            className={`pointer ${style.brdGrey} ${style.pdf_image}`}
                                                            onClick={() => handleFileClick(`pdf_image_${index}`)}
                                                        /> 
                                                        <span className={`textUnderline green ${style.mrg_x_6}`}>
                                                            {Productdata.pdf_images[index].image_id}{" "}
                                                            <img 
                                                                src={DeleteRedIcon} 
                                                                alt={"delete pdf"} 
                                                                className={`pointer ${style.mrg_lft}`}
                                                                onClick={() => deletePdfImage(index)}
                                                            /> 
                                                        </span>
                                                    </div>:
                                                    <div 
                                                        className={`${canUpload ? 'pointer' : 'not_allowed'} ${style.pdf_image}`} 
                                                        onClick={() => {
                                                            if(canUpload) handleFileClick(`pdf_image_${index}`)}
                                                        }
                                                    >
                                                        <div className={`textCenter ${canUpload ? style.imgPlaceholder : style.imgPlaceholderDisabled}`}>
                                                            <img src={canUpload ? UploadIcon : UploadGreyIcon} />
                                                            <div className={`${canUpload ? 'green' : 'grey'}`}>Upload</div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => { localStorage.removeItem('productData'); navigate('/product-widget/') }} className={`${style.outlinedBtn}`}> 
                            Cancel <img src={CancelIcon} className={`${style.cancelIcon}`}/>
                        </button>
                        <button className={`${style.filledBtn} `} onClick={validationProduct}>
                            {status !== "new" ? "Update" : "Add"} Product
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>

            {showPopup.showAddSkuPopup && <PopupAddSku
                zoneList={zoneList}
                dealType={`Banner`}
                closeBtn={() => {setShowPopup({... showPopup, showAddSkuPopup: false}) }}
                addSkuForDeal={addSkuForDeal}
            />}

            {showPopup.popup && <div>
                <PopUp width={formStyle.popupWidthS} title={'Errors'} closeBtn={() => {setShowPopup({... showPopup, popup: false})}} >
                    <div className={`${formStyle.inputContainer}`}>
                        {errorList.map((err, idx) => {
                            return <div className={`${style.pd_bg1} red`}>{`${idx + 1}. ${err.loc[1]} : ${err.msg}`}</div>
                        })}
                    </div>
                </PopUp>
            </div>}

            {showPopup.deletePdf &&
                <PopUp 
                    width={formStyle.popupWidthS} 
                    title={'Delete Soil Test Report!'} 
                    closeBtn={() => {setShowPopup({... showPopup, deletePdf: false})}} 
                >
                     <div className={`${formStyle.inputContainer}`}>
                        Are you sure you want to delete <b>Soil test Report</b>? Deleting Report will also delete Soil Test Report Images!!
                     </div>
                     <div className={`${formStyle.btnContainerflex}`}>
                        <button 
                            className={`${formStyle.btn} ${formStyle.secondaryBtn}`}
                            onClick={() => setShowPopup({... showPopup, deletePdf: false})}
                        >
                            No
                        </button>
                        <button 
                            className={`${formStyle.btn} ${formStyle.successBtn}`}
                            onClick={ () => {
                                setShowPopup({... showPopup, deletePdf: false})
                                deleteReport()
                            }}
                            >
                            Yes
                        </button>
                     </div>
                </PopUp>
            }

            {
                isLoading && <Loader />
            }

        </>
    );
}


export default AddProduct;