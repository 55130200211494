import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFeedContentListing } from '../../../utils/api';
import Header from './components/Header';
import FeedContentListing from './components/FeedContentListing';

function Index(props) {

    const { slot } = useParams();

    const [selZone, setselZone] = useState({});
    const [contentType, setContentType] = useState({});
    const [searchQuery, setSearchQuery] = useState("");
    // const [nSlot, setNslot] = useState('');
    const [itemList, setItemList] = useState([]);

    const updateItemList = async () => {
        let res = await getFeedContentListing(selZone.id, contentType.value, slot);
        localStorage.setItem('zone', JSON.stringify(selZone))
        localStorage.setItem('type', JSON.stringify(contentType))
        localStorage.setItem('nslot', JSON.stringify(slot))
        if (res.data.length) {
            setItemList(res.data);
        }
        else
            setItemList([]);
    }

    return (

        <div>
            <Header
                selZone={selZone}
                setselZone={setselZone}
                contentType={contentType}
                setContentType={setContentType}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                itemList={itemList}
                setItemList={setItemList}
                updateItemList={updateItemList}

                slot={slot}
            />
            <FeedContentListing
                selZone={selZone}
                contentType={contentType}
                itemList={itemList}
                setItemList={setItemList}
                updateItemList={updateItemList}

                slot={slot}
            />
        </div>
    );
}

export default Index;