import React, { useEffect, useState } from 'react';
import Calender from '../../../../icons/calender.svg'
import style from '../css/SmsDashboard.module.css'
import DatePicker from "react-datepicker";
function Header({nslot,setNslot}) {

    const [date, setDate] = useState(new Date());

    const updateNslotid = (date) => {
        setDate(date);
        const today1 = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getYear().toString();
        const final = year.substring(1) + '' + month + '' + today1;
        setNslot(final);
    }


    useEffect(()=>{
        const today1 = date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate();
        const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        const year = date.getYear().toString();
        const final = year.substring(1) + '' + month + '' + today1;
        setNslot(final);
        setDate(date);
    })


    return (
        <>
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>SMS Dashboard</span></div>

               <div className='flex'>
                    <div className={`flex ${style.calender}`} >
                    <img className='' src={Calender} />
                    <DatePicker 
                        className={style.datepicker}
                        selected={date} 
                        value={date.toString().substring(4, 15)}
                        onChange={(date) => { updateNslotid(date) }}       
                    />
                </div>
               </div>
            </div> 
        </>
    );
}

export default Header;