import React, { useState } from 'react';
import Header from './components/Header';
// import BonusCommunication from './components/BonusCommunication';
import AddBonusCommunication from './components/AddBonusCommunication';
function Index(props) {

    const [csvList, setCsvList] = useState([]);
    const [varBulkUpload, setVarBulkUpload] = useState(false);
    
    return (

        <div>
            <Header
                csvList={csvList}
                setCsvList = {setCsvList}
                varBulkUpload={varBulkUpload}
            />
            <AddBonusCommunication
                csvList={csvList}
                setCsvList = {setCsvList}
                setVarBulkUpload={setVarBulkUpload}
            />
        </div>
    );
}

export default Index;