import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorHandling, SuccessHandling } from "../../../../State";
import { getBonusDetail, updateBonusCommunication, getBonusConstantList, uploadSelImage, uploadSelVideo, updateBukBonusCommunication, deepCopyObject } from "../../../../utils/api";
import { SmsImgUrl, SmsVideoImgUrl } from '../../../../utils/config';
import { comLastOrder, communicationSelectUser } from "../../../../utils/constData";
import { EpochTimeTOHumanFormat, addMinutes } from "../../../../utils/TimeManager";
import Dropdown from "../../../../commonComponent/Dropdown/Dropdown";
import Loader from "../../../../commonComponent/Loader/Loader";
import PopUp from "../../../../commonComponent/PopUp/PopUp";
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import PopupVariableCheckbox from "./PopupVariableCheckbox";
import PopupSendText from "./PopupSendTest";

import style from "../css/Bonus.module.css";
import UploadVideoIcon from '../../../../icons/upload-video.svg';
import UploadIcon from '../../../../icons/ico-upload.svg';
import BinIcon from '../../../../icons/bin.svg';
import { ReactComponent as AddIcon } from "../../../../icons/add-icon.svg";
import { ReactComponent as EditIcon } from "../../../../icons/edit-underlined.svg";
import PopupAddSegment from "../../../Coupon/OfferCreation/component/PopupAddSegment";
import PopupUpdateSegment from "../../../Coupon/OfferCreation/component/PopupUpdateSegment";
import PopupAddOffer from "./PopupAddOffer";

function AddBanner({csvList, setCsvList, setVarBulkUpload}) {
    const { campaignStatus, id } = useParams()
    const navigate = useNavigate()
    const [popup, setPopup] = useState({
        sendTestStatus: false, 
        campaignType: {},
        submitData: {},
        variableCheckbox: false,
        selectedSelUser: {},
        addProdPopup: false,
        addSkuType: "include",
        updateProdPopup: false,
        offerPopup: false,
    })
    const [constData, setConstData] = useState({
        campaign_status: [],
        campaign_type: [],
        message_type: [],
        variable_list: [],
        whatsapp_media_types: [],
    });
    const [showErrorPopup, setShowErrorPopup] = useState({
        status: false,
        message: []
    });

    const [communicationData, setCommunicationData] = useState({
        campaign_id: 0,
        schedule_time: '',
        campaign_type: {id: 0, name: "Select Campaign Type"},
        message_type: {id: 0, name: "Select Message Type"},
        communication_priority: {id: 2, name: "marketing"},
        
        amount_validity_days: -1,
        cash_amount:0,
        reward_amount:0,

        whatsapp_template_name: '',
        whatsapp_media_type: {id: 4, name: "NA"},
        media_link: '',

        sms_text: '',
        sms_provider: {id: 0, name: "Select SMS Provider"},

        pn_title: '',
        pn_text: '',
        pn_app_inbox: false,

        consumer_data : [],
        var_list: [],

        inc_exc_key : comLastOrder[0],
        inc_exc_days : 0, 
        csv_type: communicationSelectUser[0],

        sms_header: {id: "", name: "Select Header Type"},
        include_segment_list: [],
        offer_id: '',
        exclude_segment_list: [],
    })

    const [startDate, setStartDate] = useState(addMinutes(new Date(), 15));
    const [inputList, setInputList] = useState([
        { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
        { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
        { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
        { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
        { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
    ]);
    const [isLoading, setLoader] = useState(false);


    // handle varlist deletion
    const handleVarlistDeletion = (index) => {
        let localInputList = Object.assign([], inputList);
        localInputList.splice(index, 1);
        setInputList(localInputList);
    }

    // handle add btn disable
    const isAddBtnDisabled = () => {
        let flag = false;
        if(inputList.length){
            inputList.forEach((obj) => {
                if(obj.var_type == 0 || obj.value == 0){
                    flag = true;
                }
            })
        }
        return flag;
    }
    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // var list first dropdown
    const getVarType = (content_type, index) => {
        const list = [...inputList];
        // console.log('type', list);
        list[index].var_type = content_type.id;
        list[index].type_name = content_type.name;
        list[index].dropdown_data = content_type.dropdown_data;

        list[index].value = 0;
        list[index].value_name = "select value";
        list[index].additional_action_data = "";

        setInputList(list);
    }

    // var list second dropdown
    const getVarValue = (varriable, index) => {
        // const { value, value_name } = varriable;
        const list = [...inputList];
        list[index].value = varriable.id;
        list[index].value_name = varriable.name;
        list[index].additional_action_data = "";
        setInputList(list);
    }


    // handle all inputs chnage - text, number
    const handleInputChange1 = (e, index) => {
        let obj = communicationData;
        obj[e.target.name] = e.target.value;
        setCommunicationData({ ...obj });
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] }]);
    };


    // on upload click
    const hanldeUploadImgClick = (id) => {
        document.getElementById(id).click()
    }

    // handle upload image
    const handleUploadImage = async (id, type) => {
        let image = document.getElementById(id).files[0];
        console.log(image);

        let imgTypeExt = image.type.split('/')

        var image_type = 'communication';
        let data = new FormData();
        data.append('file', image);
        data.append('file_ext', '.' + imgTypeExt[1]);
        data.append('image_type', image_type)

        if (image.type !== 'image/png' &&
            image.type !== 'image/jpg' &&
            image.type !== 'image/jpeg' &&
            image.type !== 'image/webp') {
            ErrorHandling('Please select correct image format')
        }
        else {
            let img = new Image;
            img.onload = async function () {
                let res = await uploadSelImage(data);
                let localProdData = Object.assign({}, communicationData)
                localProdData.media_link = SmsImgUrl + res
                setCommunicationData(localProdData);
                console.log(img.height, img.width);
            }
            img.src = img.src = URL.createObjectURL(image);
        }
    }

    // handle upload video
    const handleUploadVideo = (id, type) => {
        var file = document.getElementById(id).files[0];

        console.log(file);

        let videoTypeExt = file.type.split('/')

        var video_type = 'communication/';
        let data = new FormData();

        data.append('file', file);
        data.append('file_name', videoTypeExt[0]);
        data.append('file_ext', '.' + videoTypeExt[1]);
        data.append('path', video_type)

        var video = document.createElement('video');
        video.preload = 'metadata';

        video.onloadedmetadata = async function () {

            window.URL.revokeObjectURL(video.src);

            if (video.duration < 1) {
                ErrorHandling("Invalid Video! video is less than 1 second");
                return;
            }
            console.log(video.duration);

            let res = await uploadSelVideo(data);
            if (type === 'main') {
                let localProdData = Object.assign({}, communicationData)
                localProdData.media_link = SmsVideoImgUrl + res
                localProdData.video_length = parseInt(video.duration);
                setCommunicationData(localProdData);
            }

        }

        video.src = URL.createObjectURL(file);

    }

    // handle validation before submit and data creation for submit
    const publishCampaign = (e, sendText) => {
        let message = "";

        const dateObj = new Date (startDate);
        dateObj.setHours(dateObj.getHours() + 5);
        dateObj.setMinutes(dateObj.getMinutes() + 30);
        const data = 
        {
            "campaign_id":communicationData.id ? communicationData.id : 0,
            "campaign_type":communicationData.campaign_type.id,
            "message_type":communicationData.message_type.id,
            "communication_priority":communicationData.communication_priority.id,
            "schedule_time":dateObj.toISOString(),

            "amount_validity_days": parseInt(communicationData.amount_validity_days),
            "cash_amount":parseInt(communicationData.cash_amount),
            "reward_amount": parseInt(communicationData.reward_amount),
            "csv_type": communicationData.csv_type.id,
            "include_segment_list": communicationData.include_segment_list,
            "exclude_segment_list": communicationData.exclude_segment_list,
            "offer_id": communicationData.offer_id,

            "whatsapp_template_name":communicationData.whatsapp_template_name,
            "whatsapp_media_type":communicationData.whatsapp_media_type.id ? communicationData.whatsapp_media_type.id : 4,
            "media_link":communicationData.media_link,

            "sms_text":communicationData.sms_text,
            "sms_provider":communicationData.sms_provider.id ? communicationData.sms_provider.id : 0,
            "sms_header":communicationData.sms_header.id ? communicationData.sms_header.id : 0,

            "pn_title":communicationData.pn_title,
            "pn_text":communicationData.pn_text,
            "pn_app_inbox":communicationData.pn_app_inbox,
            "var_list": communicationData.csv_type.id === 1? [] : inputList,
            "consumer_data": csvList,

            "inc_exc_key": communicationData.inc_exc_key.id,
            "inc_exc_days": communicationData.inc_exc_days ,
        }
        debugger;
        // TODO: handle nan for 3 fields
        if(!data.campaign_type){
            message = ("Please select Campaign Type")
        }
        else if(data.message_type == 1 && data.campaign_type == 4){
            message = ("'Only Communication' cannot be selected with campaing type 'none' ! ")
        }
        else if(!data.message_type){
            message = ("Please select Message Type")
        }
        else if(!data.communication_priority){
            message = ("Please select Communication Priority")
        }
        else if(!data.schedule_time){
            message = ("Please select Schedule Time")
        }
        else if(isNaN(data.amount_validity_days)){
            message = ("Please Enter Amount Validaity Period")
        }
        else if(isNaN(data.cash_amount)){
            message = ("Please Enter Promo Cash")
        }
        else if(isNaN(data.reward_amount)){
            message = ("Please Enter Reward Wallet Amount")
        }
        //  check csv data
        else if(
            ( !sendText && (data.csv_type==1 || data.csv_type==0) && (!data.consumer_data || !data.consumer_data.length) ) ||
            ( sendText && data.csv_type === 1 &&  (!data.consumer_data || !data.consumer_data.length) )
        ){
            message=("Please Upload CSV ")
        }
        // check segment data
        else if (data.csv_type === 2 && !data.include_segment_list.length){
            message=("Please Select Segment to be Included ")
        }
        else if (data.csv_type === 3 && !data.offer_id){
            message=("Please Select Offer!")
        }
        // for sms fields check
        else if(data.campaign_type == 1){
            if(!data.sms_provider){
                message = ("Please Select SMS Provider")
            }
            else if(!data.sms_text){
                message = ("Please Add SMS Text")
            }
            else if(!data.sms_header){
                message = "Please Select Header Type"
            }
        }
        // for Whatsapp fields check
        else if(data.campaign_type == 3){
            if(!data.whatsapp_template_name){
                message = ("Please Enter Whatsapp Template Name")
            }
            else if (!data.whatsapp_media_type){
                message = ("Please Select Media Type")
            }
            else if((data.whatsapp_media_type == 1 || data.whatsapp_media_type == 2) && !data.media_link){
                message = ("Please Upload Media")
            }
        }
        // for PN fields check
        else if(data.campaign_type == 2){
            if(!data.pn_title){
                message = ("Please Enter PN Title")
            }
            else if(!data.pn_text){
                message = ("Please Enter PN Text")
            }
        }

        if(data.var_list.length){
            data.var_list.forEach((obj) => {
                if(
                    obj.var_type == 1 && 
                    obj.value == 2 && 
                    !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gim.test(obj.additional_action_data) 
                ){
                    message = ("Please Input Value URL!");
                }
            })
        }

        if(data.inc_exc_days == null || data.inc_exc_days == undefined || data.inc_exc_days < 0 || data.inc_exc_days === '' ){
            message = ("Please Enter Valid Input for Days Since Last Order! ");

        }

        if(!message){
            e.preventDefault();
            console.log("our state:", communicationData, inputList,  )
            console.log("api payload:", data  )
            if(sendText){
                data.consumer_data = []
                setPopup({
                    ...popup,
                    sendTestStatus: true, 
                    campaignType: communicationData.campaign_type,
                    submitData: data,
                })
            }
            else 
                addBanner(data);
        }
        else ErrorHandling(message)

    }

    // handle submit api call
    const addBanner = async (data) => {
        setLoader(true)
        let res = {};

        if(communicationData.csv_type.id === 1)
            res = await updateBukBonusCommunication(data);
        else 
            res = await updateBonusCommunication(data);

        setLoader(false)
        if (res.success) {
            SuccessHandling(res.message);
            navigate(`/communication-dashboard/`)
        } else {
            if(res.error.status_code == 400){
                let errorMessage = JSON.parse(res.error.message)
                setShowErrorPopup({
                    status: true,
                    message: errorMessage
                })
            }
            else{
                ErrorHandling(res.error.message)
            }
        }
    }

    // handle campaign change
    const getChannelType = (campaign_type) => {
        let obj = communicationData;
        obj.campaign_type = campaign_type;

        // setting initial value on campaign type change
        obj.whatsapp_template_name =  '';
        obj.whatsapp_media_type = {id: 4, name: "NA"};
        obj.media_link =  '';

        obj.sms_text = '';
        obj.sms_provider = {id: 0, name: "Select SMS Provider"};
        obj.sms_header = {id: 0, name: "Select Header Type"};


        obj.pn_title =  '';
        obj.pn_text =  '';
        obj.pn_app_inbox =  false;

        // setInputList([{ var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] }])
        setCommunicationData({ ...obj });
    }

    // handle messgae type change
    const getMessageType = (message_type) => {
        let obj = Object.assign({}, communicationData);
        obj.message_type = message_type;

        // validation for none type campaign selected
        if(message_type.id == 1 && obj.campaign_type?.id == 4){
            ErrorHandling("'Only Communication' cannot be selected with campaing type 'none' ! ")
        }

        // setting value of promo cash and reward wallet
        // only communication
        if (message_type.id == 1 ){
            obj.reward_amount = 0;
            obj.cash_amount = 0;
            obj.amount_validity_days = 0;
            obj.inc_exc_key = comLastOrder[0]; 
            obj.inc_exc_days = 0; 
        }
        // cash wallet
        else if(message_type.id == 2 ){
            obj.reward_amount = 0;
        }
        // promo balance
        else if (message_type.id == 3){
            obj.cash_amount = 0;
        }

        setCommunicationData(obj);
    }

    // handle priority change
    const getPriority = (campaign_type) => {
        let obj = communicationData;
        obj.communication_priority = campaign_type;
        setCommunicationData({ ...obj });
    }

    // handle whatsapp media change
    const getWhatsappMediaType = (whatsapp_media_type) => {
        let obj = communicationData;
        obj.whatsapp_media_type = whatsapp_media_type;
        obj.media_link = "";
        setCommunicationData({ ...obj });
    }

    // handle sms proivder change
    const getSmsProvider = (key, provider) => {
        let obj = deepCopyObject(communicationData);
        obj[key] = provider;
        setCommunicationData({ ...obj });
    }

    // handle days include change
    const handleIncExcKey = (inc_exc_key) => {
        setCommunicationData({
            ...communicationData,
            inc_exc_key: inc_exc_key,
            inc_exc_days: inc_exc_key.id === 2 ? 0 : communicationData.inc_exc_days,
        });
    }

    //handle no of days 
    const handleIncExcDays = (inc_exc_days) => {
        setCommunicationData({...communicationData,inc_exc_days:inc_exc_days});
    }

    // handle checkbox 
    const handleCheckBoxChange = (e) => {

        if(e.target.name === 'csv_type'){
            let hasValue = false
            inputList.map((varData) => {
                if(varData.additional_action_data !== "" || varData.type_name !== "select type"){
                    hasValue = true;
                }
            })
            if(csvList.length || hasValue){
                setPopup({
                    ...popup,
                    variableCheckbox: true,
                })
                return;
            }
            else{
                setVarBulkUpload(e.target.checked);
            }
        }

        let obj = communicationData;
        obj[e.target.name] = e.target.checked;
        setCommunicationData({ ...obj });
    }

    // handle select user change
    const handleSelUserChange = (value) => {

        if(value.id === 1 || communicationData.csv_type.id === 1){
            let hasValue = false
            inputList.map((varData) => {
                if(varData.additional_action_data !== "" || varData.type_name !== "select type"){
                    hasValue = true;
                }
            })
            if(csvList.length || hasValue){
                setPopup({
                    ...popup,
                    selectedSelUser: value,
                    variableCheckbox: true,
                })
                return;
            }
            else{
                setVarBulkUpload(value.id);
            }
        }

        let obj = deepCopyObject(communicationData);
        obj.csv_type = value;
        obj.sms_header = {id: 0, name: "Select Header Type"}
        obj.include_segment_list = []
        obj.offer_id = ''
        obj.exclude_segment_list = []

        setVarBulkUpload(value.id);
        setCommunicationData({ ...obj });
        setCsvList([])

    }

    // segment add
    const addSkuOfferType = (sku) => {
        let localCommunicationData = deepCopyObject(communicationData);
    
        let listName = "include_segment_list";
        if (popup.addSkuType === "exclude") {
          listName = "exclude_segment_list";
        }

        let obj = {
            id: sku.id,
            name: sku.name,
            description: sku.description,
            status: sku.status,
        }
        localCommunicationData[listName].push(obj);
        setCommunicationData(localCommunicationData);
    };

    // offer add
    const addOfferType = (offer) => {
        let localCommunicationData = deepCopyObject(communicationData);
 
        localCommunicationData.offer_id = offer.id;
        localCommunicationData.offer_name = offer.offer_name;
        setCommunicationData(localCommunicationData);
        setPopup({
            ...popup,
            offerPopup: false,
        })
    };

    // remove segment 
    const onDeleteProd = (item_id) => {
        let localCommunicationData = deepCopyObject(communicationData);
    
        let listName = "include_segment_list";
        if (popup.addSkuType === "exclude") {
          listName = "exclude_segment_list";
        }
    
        let item = localCommunicationData[listName].filter((prod) => prod.id !== parseInt(item_id));
        localCommunicationData[listName] = item;
        if(!item.length){
            setPopup({
                ...popup,
                updateProdPopup: false,
            })
        }
        setCommunicationData({ ...localCommunicationData });
    };

    // handle listing, selected banner 
    const fetchData = async () => {
        // for gettig the zone list
        setLoader(true)
        let resListing = await getBonusConstantList();
        setLoader(false)

        if (campaignStatus === "edit-campaign" || campaignStatus === "clone-campaign") {
            let selBanner = await getBonusDetail(id);
            if(selBanner.success){
                let obj = Object.assign({}, selBanner);
                obj.schedule_time = EpochTimeTOHumanFormat(selBanner.schedule_time);
    
                let campaign_type = resListing.campaign_type.filter((item) => item.id == obj.campaign_type)
                obj.campaign_type = campaign_type[0]
    
                let communication_priority = resListing.communication_priority.filter((item) => item.id == obj.communication_priority)
                obj.communication_priority = communication_priority[0]
    
                let message_type = resListing.message_type.filter((item) => item.id == obj.message_type)
                obj.message_type = message_type[0]

                obj.csv_type = communicationSelectUser[selBanner.csv_type];
                setVarBulkUpload(selBanner.csv_type);
                // if camp type is sms
                if(obj.campaign_type.id == 1){
                    let sms_provider = resListing.sms_providers.filter((item) => item.id == obj.sms_provider)
                    obj.sms_provider = sms_provider[0]

                    let sms_header =  resListing.sms_headers.filter((item) => item.id == obj.sms_header)
                    obj.sms_header = sms_header[0]
                }
                else {
                    obj.sms_provider = {id: 0, name: "Select SMS Provider"}
                    obj.sms_header = {id: 0, name: "Select Header Type"};

                }

                // if camp type is Whatsapp
                if(obj.campaign_type.id == 3){
                    let whatsapp_media_type = resListing.whatsapp_media_types.filter((item) =>  item.id == obj.whatsapp_media_type)
                    obj.whatsapp_media_type = whatsapp_media_type[0]
                }
                else{
                    obj.whatsapp_media_type = {id: 4, name: "NA"}
                }
    
                // camp days include/exclude keys
                obj.inc_exc_key =  comLastOrder[selBanner.inc_exc_key];

                //camp days include/exclude days
                obj.inc_exc_days = selBanner.inc_exc_days;

                if(campaignStatus === "clone-campaign"){
                    delete obj.id
                    delete obj.schedule_time
                }
                else{
                    setStartDate(new Date(selBanner.schedule_time))
                }

                setCsvList(obj.consumer_data)
                setInputList(obj.var_list)
                setCommunicationData({ ...obj })
            }

        }
        setConstData(resListing)

    }

    // handle next seven days array
    const handleNextSevenDays = () => {
        let dateArray = [new Date()]
        for(let i = 1; i < 6; i++){
            const date = new Date();
            date.setDate(date.getDate() + i);
            dateArray.push(date)
        }
        return dateArray
    }

    useEffect(() => {
        fetchData()
    }, [])


    return (
        <>
            {/* header */}
            <div className={`formWrapper`}>
                {/* page heading */}
                <div className={`${style.createWidget}`}>Communication Campaign</div>
                <div className={`${style.addContainer}`}>

                    {/* first section - input and dropdowns*/}
                    <div className={`${style.separator}`}>
                        {/*3 dropdowns and date*/}
                        <div className={`grid  ${style.grid_template4}`}>
                            <div>
                                <div className={`${style.land_page}`}>
                                    <div className={`${style.title}`}>Campaign Type</div>
                                </div>
                                <Dropdown name={communicationData.campaign_type} list={constData.campaign_type} getDropDownValue={getChannelType} height={'10rem'} />
                            </div>
                            <div>
                                <div className={`${style.land_page}`}>
                                    <div className={`${style.title}`}>Message Type</div>
                                </div>
                                <Dropdown name={communicationData.message_type} list={constData.message_type} getDropDownValue={getMessageType} height={'10rem'} />
                            </div>
                            <div>
                                <div className={`${style.land_page}`}>
                                    <div className={`${style.title}`}>Communication Priority</div>
                                </div>
                                <Dropdown name={communicationData.communication_priority} list={constData.communication_priority} getDropDownValue={getPriority} height={'10rem'} />
                            </div>
                            <div className={`${style.land_page}`}>
                                <div className={`${style.title}`}>Date time</div>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    className={style.custome_date_time}
                                    includeDates={handleNextSevenDays()}
                                />
                            </div>
                        </div>

                        {/* inputs  */}
                        <div className={`grid  ${style.grid_template4} ${style.mr_t_30}`}>
                            <div className={`${style.land_page}`}>
                                <div className={`${style.title}`}>Amount Validity Period</div>
                                <input 
                                    disabled={communicationData.message_type.id == 1 }
                                    className={`${style.input_field}`} 
                                    name='amount_validity_days' 
                                    type='number' 
                                    onWheel={ event => event.currentTarget.blur() } 
                                    placeholder='Amount Validity Period' 
                                    value={communicationData.amount_validity_days} 
                                    onChange={(e) => { handleInputChange1(e) }} 
                                />
                            </div>
                            <div className={`${style.land_page}`}>
                                <div className={`${style.title}`}>Promo Cash</div>
                                <input 
                                    className={`${style.input_field}`} 
                                    name='cash_amount' 
                                    type='number' 
                                    onWheel={ event => event.currentTarget.blur() } 
                                    placeholder='Promo Cash' 
                                    value={communicationData.cash_amount} 
                                    disabled={communicationData.message_type.id !== 2 }
                                    onChange={(e) => { handleInputChange1(e) }} 
                                />
                            </div>
                            <div className={`${style.land_page}`}>
                                <div className={`${style.title}`}>Reward Wallet</div>
                                <input 
                                    className={`${style.input_field}`} 
                                    name='reward_amount' 
                                    type='number' 
                                    onWheel={ event => event.currentTarget.blur() } 
                                    placeholder='Reward Wallet' 
                                    value={communicationData.reward_amount} 
                                    disabled={communicationData.message_type.id !== 3 }
                                    onChange={(e) => { handleInputChange1(e) }} 
                                />
                            </div>
                            <div>
                                <div  className={` ${style.land_page}`}>
                                    <div className={`${style.title}`}>Day Since Last Order (optional)</div>
                                </div>
                                <div className="flex">
                                    <Dropdown 
                                        name={communicationData.inc_exc_key}
                                        list={comLastOrder}
                                        getDropDownValue={handleIncExcKey}
                                    />
                                    <input
                                        disabled={communicationData.inc_exc_key.id === 2}
                                        className={`${style.width35} ${style.input_field}`} 
                                        type="number" 
                                        name="include_exclude"
                                        placeholder="Days"
                                        min={0}
                                        value={communicationData.inc_exc_days}
                                        onChange={(e) => handleIncExcDays(e.target.value)}
                                        onWheel={(e) => e.target.blur()}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* dynamic inputs and variable list */}
                        <div className={`grid-start ${style.grid_templete2}`}>
                            {/* left section  */}
                            <div className={`${style.mrg_top_3}`}>

                                {/*  Variable Throught Bulk Upload --- checkbox */}
                                {/* <div className={`${style.mrg_btm_3}`}>
                                        <label className={`pointer`}>
                                            <input
                                                // disabled={communicationData.disableFields}
                                                checked={communicationData.csv_type.id === 1} 
                                                onChange={(e) => handleCheckBoxChange(e)} 
                                                type='checkbox' 
                                                name="csv_type"
                                            /> 
                                            <span className={`bold`}> Variable Through Bulk Upload</span>
                                        </label>
                                </div> */}


                                {/* condition for sms message */}
                                {communicationData.campaign_type.id == 1 &&
                                    <div>
                                        <div className={`${style.land_page}`}>
                                            <div className={`${style.title}`}>SMS Text</div>
                                            <textarea   
                                                rows="6" 
                                                cols="40"
                                                className={`noResize ${style.fullWidth} ${style.input_field}`} 
                                                name='sms_text' 
                                                type='text' 
                                                placeholder='SMS text' 
                                                value={communicationData.sms_text} 
                                                onChange={(e) => { handleInputChange1(e) }} >    
                                            </textarea>
                                        </div>
                                        
                                        <div className={`flex flex-space-between ${style.mrg_btm_3}`}>
                                            <div className={`${style.wd_50p} ${style.mr_t_30}`}>
                                                <div className={`${style.land_page}`}>
                                                    <div className={`${style.title}`}>Service Provider</div>
                                                </div>
                                                <Dropdown 
                                                    name={communicationData.sms_provider} 
                                                    list={constData.sms_providers} 
                                                    getDropDownValue={(elem) => 
                                                        getSmsProvider('sms_provider', elem)} 
                                                    height={'10rem'}
                                                />
                                            </div>

                                            <div className={`${style.wd_50p} ${style.mr_t_30} `}>
                                                <div className={`${style.land_page}`}>
                                                    <div className={`${style.title}`}>Header Type</div>
                                                </div>
                                                <Dropdown 
                                                    name={communicationData.sms_header} 
                                                    list={constData.sms_headers} 
                                                    getDropDownValue={(elem) => 
                                                        getSmsProvider('sms_header', elem)} 
                                                    height={'10rem'} 
                                                />
                                            </div>

                                        </div>
                                    </div>
                                }
                                {/* condition for whatsapp message */}
                                {communicationData.campaign_type.id == 3 &&
                                    <div>
                                        <div className={`${style.land_page}`}>
                                            <div className={`${style.title}`}>Whatsapp Template Name</div>
                                            <input className={`${style.input_field}`} name='whatsapp_template_name' type='text' placeholder='whatsapp template name' value={communicationData.whatsapp_template_name} onChange={(e) => { handleInputChange1(e) }} />
                                        </div>
                                        <div className={`${style.wd_50p} ${style.mr_t_30}`}>
                                            <span className={`${style.land_page}`}>Whatsapp Image/Video</span>
                                            <Dropdown name={communicationData.whatsapp_media_type} list={constData.whatsapp_media_types} getDropDownValue={getWhatsappMediaType} height={'10rem'} />
                                        </div>
                                        <div className={`${style.wd_50p} ${style.mrg_btm_3}`}>
                                            {/* main video */}
                                            {communicationData.whatsapp_media_type.id == 2 &&
                                                <div className={`${style.imgContainer}`}>
                                                    <div className={`${style.greyBrd}`}>
                                                        <input
                                                            id={`main-video`}
                                                            type='file'
                                                            onChange={() => handleUploadVideo('main-video', 'main')}
                                                            className={`hide`}
                                                            accept='video/mp4'
                                                        />

                                                        {
                                                            communicationData.media_link ?
                                                                <video controls width={'100%'} height="250" key={communicationData.media_link}>
                                                                    <source src={communicationData.media_link} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video> :
                                                                <div
                                                                    onClick={() => { hanldeUploadImgClick("main-video"); }}
                                                                    className={`flex flex-space-evenly pointer ${style.imgUpload} `} >
                                                                    <div className={`textCenter ${style.imgPlaceholder}`}>
                                                                        <img src={UploadVideoIcon} alt="" width={'30px'} />
                                                                        <div className={`bold green`}>Upload</div>
                                                                    </div>
                                                                </div>
                                                        }

                                                        {/* upload label */}
                                                        <div className={`flex flex-space-between ${style.imgLabel} ${style.pd_bg}`}>
                                                            <div>
                                                                <div className={`bold`}> Main video </div>
                                                                <div className={`fontS labelGrey`}> (Video ratio 16:9) </div>
                                                            </div>
                                                            {
                                                                communicationData.media_link ?
                                                                    <div>
                                                                        <div className={`pointer ${style.uploadVideo}`} onClick={() => { hanldeUploadImgClick("main-video") }} >
                                                                            <img src={UploadVideoIcon} alt="upload video" width={'25px'} />
                                                                        </div>
                                                                    </div> : null
                                                            }
                                                        </div>

                                                    </div>

                                                    {/* error handling */}
                                                    {
                                                        communicationData.btnClicked && !communicationData.media_link &&
                                                        <div className={`errorMessage`}> *Please Upload Main Video</div>
                                                    }
                                                </div>
                                            }

                                            {/* Thumbnail image */}
                                            {communicationData.whatsapp_media_type.id == 1 &&
                                                <div className={`${style.imgContainer}`}>
                                                    <div className={` ${style.greyBrd}`}>
                                                        <input
                                                            id={`thumbnail-image`}
                                                            type='file'
                                                            onChange={() => handleUploadImage('thumbnail-image', 'thumbnail')}
                                                            className={`hide`}
                                                            accept='image/*'
                                                        />
                                                        {
                                                            communicationData.media_link ?
                                                                <img
                                                                    className={`pointer ${style.imgUpload}`}
                                                                    src={communicationData.media_link}
                                                                    onClick={() => { hanldeUploadImgClick("thumbnail-image"); }}
                                                                /> :
                                                                <div
                                                                    onClick={() => { hanldeUploadImgClick("thumbnail-image"); }}
                                                                    className={`flex flex-space-evenly pointer ${style.imgUpload} `} >
                                                                    <div className={`textCenter ${style.imgPlaceholder}`}>
                                                                        <img src={UploadIcon} alt="" width={'30px'} />
                                                                        <div className={`bold green`}>Upload</div>
                                                                    </div>
                                                                </div>
                                                        }

                                                        {/* upload label */}
                                                        <div className={`${style.imgLabel} ${style.pd_bg}`}>
                                                            <div className={`bold`}> Thumbnail image </div>
                                                            <div className={`fontS labelGrey`}> (Video ratio 16:9) </div>
                                                        </div>
                                                    </div>
                                                    {/* error handling */}
                                                    {
                                                        communicationData.btnClicked && !communicationData.media_link &&
                                                        <div className={`errorMessage`}> *Please Upload Thumbnail Image</div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {/* condition for PN type message */}
                                {communicationData.campaign_type.id == 2 &&
                                <>
                                    <div className={`bold green ${style.mrg_btm_3}`}>
                                        <div className={`${style.title}`}>PN Title</div>
                                        <input style={{width: '49%'}} className={`${style.width53} ${style.input_field}`} name='pn_title' type='text' placeholder='PN title' value={communicationData.pn_title} onChange={(e) => { handleInputChange1(e) }} />
                                    </div>
                                    <div className={`${style.mrg_btm_3}`}>
                                        <div className={`${style.land_page}`}>
                                            <div className={`${style.title}`}>PN Text</div>
                                            <textarea   
                                                rows="6" 
                                                cols="40"
                                                className={`noResize ${style.fullWidth} ${style.input_field}`} 
                                                name='pn_text' 
                                                type='text' 
                                                placeholder='PN text' 
                                                value={communicationData.pn_text} 
                                                onChange={(e) => { handleInputChange1(e) }} >
                                            </textarea>
                                        </div>
                                    </div>
                                    <div className={`${style.isActiveCheckbox} ${style.width_50} ${style.mrg_btm_3}`}>
                                        <input type="checkbox" name="pn_app_inbox" checked={communicationData.pn_app_inbox} onChange={(e) => { handleCheckBoxChange(e) }} />
                                        <span>PN in App-Inbox</span>
                                    </div>
                                </>
                                }

                                {/* SELECT USER */}
                                <div className={`${style.wd_50p} `}>
                                    <div className={`${style.land_page}`}>
                                        <div className={`${style.title}`}>Select User</div>
                                    </div>
                                    <Dropdown 
                                        name={communicationData.csv_type} 
                                        list={communicationSelectUser} 
                                        getDropDownValue={handleSelUserChange} 
                                        height={'10rem'}
                                    />
                                </div>

                                {/* add segment selection | offer selection */}
                                {
                                    communicationData.csv_type.id === 2 && 
                                    <div className={`flex flex-space-between ${style.mrg_btm_3}`}>

                                        <div className={`${style.wd_50p} ${style.mr_t_30} `}>
                                            <div className={`${style.land_page}`}>
                                                <div className={`${style.title}`}>Include Segment</div>
                                            </div>
                                            <div className={`flex`}>
                                                <button className={`pageBtn filledBtn bold flex`}
                                                    onClick={() => {
                                                        setPopup({
                                                        ...popup,
                                                        addProdPopup: true,
                                                        addSkuType: "include",
                                                        });
                                                    }}
                                                >   ADD
                                                    <AddIcon
                                                        width="18px"
                                                        height="18px"
                                                        className={`${style.mrg_x_6}`}
                                                    />
                                                </button>
                                                {
                                                    !!communicationData.include_segment_list.length &&
                                                    <button className={`pageBtn outlinedBtn bold flex`}
                                                        onClick={() =>
                                                            setPopup({
                                                                ...popup,
                                                                updateProdPopup: true,
                                                                addSkuType: "include",
                                                            })
                                                        }
                                                    >
                                                        UPDATE ({communicationData.include_segment_list.length})
                                                        <EditIcon
                                                            width="18px"
                                                            height="18px"
                                                            className={`${style.mrg_x_6}`}
                                                        />
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                        <div className={`${style.wd_50p} ${style.mr_t_30} `}>
                                            <div className={`${style.land_page}`}>
                                                <div className={`${style.title}`}>Exclude Segment</div>
                                            </div>
                                            <div className={`flex`}>
                                                <button className={`pageBtn filledBtn bold flex`}
                                                    onClick={() => {
                                                        setPopup({
                                                        ...popup,
                                                        addProdPopup: true,
                                                        addSkuType: "exclude",
                                                        });
                                                    }}
                                                > ADD
                                                <AddIcon
                                                    width="18px"
                                                    height="18px"
                                                    className={`${style.mrg_x_6}`}
                                                />
                                                </button>

                                                {
                                                !!communicationData.exclude_segment_list.length &&
                                                <button className={`pageBtn outlinedBtn bold flex`}
                                                    onClick={() =>
                                                    setPopup({
                                                        ...popup,
                                                        updateProdPopup: true,
                                                        addSkuType: "exclude",
                                                    })
                                                    }
                                                >
                                                    UPDATE ({communicationData.exclude_segment_list.length})
                                                    <EditIcon
                                                        width="18px"
                                                        height="18px"
                                                        className={`${style.mrg_x_6}`}
                                                    />
                                                </button>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                }

                                {/* offer selection */}
                                {
                                    communicationData.csv_type.id === 3 &&
                                    <div className={`${style.wd_50p} ${style.mr_t_30} `}>
                                        <div className={`${style.land_page}`}>
                                            <div className={`${style.title}`}>Select Offer</div>
                                        </div>
                                        {
                                            communicationData.offer_id ?
                                            <button 
                                                className={`pageBtn outlinedBtn bold pointer`}
                                                onClick={() => {
                                                    setPopup({ ...popup, offerPopup: true, });
                                                }}
                                            >[{communicationData.offer_id}] {communicationData.offer_name}</button> :
                                            <button className={`pageBtn filledBtn bold flex`}
                                                onClick={() => {
                                                    setPopup({ ...popup, offerPopup: true, });
                                                }}
                                            >   ADD
                                                <AddIcon
                                                    width="18px"
                                                    height="18px"
                                                    className={`${style.mrg_x_6}`}
                                                />
                                            </button>
                                        }

                                        
                                    </div> 
                                }


                            </div>
                            {/* End left section */}
                            {/* Right Section Variable list */}
                            {
                                communicationData.csv_type.id !== 1 && 
                                <div className={`${style.variable_wrapper}`}>
                                    <div className={`${style.variable_title} ${style.title}`}>Variable List</div>
                                    {inputList.map((x, index) => {
                                        return (
                                            <div key={`${index}-${x.type_name}-${x.value_name}`}>
                                                <div className={`grid ${style.variable_list}`}>
                                                    <div className={`${style.mrg_x_6} ${style.varNum}`}>Var {index + 1}</div>
                                                    <div>
                                                        <Dropdown nokey={true} name={x.type_name} type={index} list={constData.variable_list} getDropDownValue={(item, type) => getVarType(item, type)} height={'10rem'} minWidth={'150px'} width={'170px'}/>
                                                    </div>
                                                    <div>
                                                        <Dropdown nokey={true} name={x.value_name} type={index} list={x.dropdown_data} getDropDownValue={(item, type) => getVarValue(item, type)} height={'10rem'} minWidth={'150px'} width={'170px'}/>
                                                    </div>
                                                    <div>
                                                        {
                                                            x.var_type == 2 ? 
                                                            <input 
                                                                disabled={x.var_type == 2 && (x.value == 1 || x.value == 4 || x.value == 5 || x.value == 6)}
                                                                className={`${style.mrg_x_6} ${style.width80} ${style.input_field}`} 
                                                                name='additional_action_data' 
                                                                type={'number'} 
                                                                placeholder={`${x.var_type == 2 && (x.value == 1 || x.value == 4 || x.value == 5) ? x.value_name : 'Add prod id'}`}
                                                                value={x.additional_action_data} 
                                                                onChange={(e) => { handleInputChange(e, index) }} 
                                                            />
                                                            :
                                                            <input 
                                                                className={`${style.mrg_x_6} ${style.width80} ${style.input_field}`} 
                                                                name='additional_action_data' 
                                                                type={'text'} 
                                                                placeholder={`${x.value == 2 ? "Add url" : "Add text"} `} 
                                                                value={x.additional_action_data} 
                                                                onChange={(e) => { handleInputChange(e, index) }} 
                                                            />
                                                        }
                                                    </div>
                                                    <div>
                                                        <img src={BinIcon} onClick={() => handleVarlistDeletion(index)} className={`pointer`}/>
                                                    </div>
                                                </div>
                                                <div className={`${style.mr_t_30}`}></div>
                                            </div>
                                        );
                                    })}
                                    <div>
                                        <button 
                                            disabled={isAddBtnDisabled()} 
                                            className={`${style.filledBtn} ${style.pull_right} `} 
                                            onClick={handleAddClick}>
                                                Add variable +
                                        </button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => navigate('/communication-dashboard/')} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={(e) => { publishCampaign(e) }}>
                            {campaignStatus === "edit-campaign" ? "Update" : "Submit"} Communication Campaign
                        </button>
                        <button 
                            className={`${style.filledBtn} `} 
                            // disabled={communicationData.message_type.id == 2 || communicationData.message_type.id == 3} 
                            onClick={(e) => {
                                publishCampaign(e, true)
                            }}>
                            Send Test Message
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>

           {/* Confirmation Popup */}
           {showErrorPopup.status && 
                <PopUp 
                    width={formStyle.popupWidthM} 
                    title={`Error Message`} 
                    closeBtn={() => {
                        setShowErrorPopup({
                            status: false,
                            message: []
                        })
                    }}
                >
                    <div className={`${formStyle.inputContainer} `}>
                        {showErrorPopup.message.map((message, index) => {
                            return(
                                <div key={index} style={{marginBottom: "4px"}} className={`red`}>{index+1}. {message}</div>
                            )
                        })}
                    </div>
                </PopUp>
            }
            {
                popup.sendTestStatus &&
                <PopupSendText
                    closeBtn={() => {
                        setPopup({
                            ...popup,
                            sendTestStatus: false, 
                            campaignType: {},
                        })
                    }}
                    campaignType={popup.campaignType}
                    submitData = {popup.submitData}
                    firstVar={csvList[0]}
                />
            }

            {
                popup.variableCheckbox &&
                <PopupVariableCheckbox
                    closeBtn={() => {
                        setPopup({
                            ...popup,
                            variableCheckbox: false, 
                        })
                    }}
                    selectedSelUser={popup.selectedSelUser}
                    communicationData={communicationData}
                    setCommunicationData={setCommunicationData}
                    setVarBulkUpload={setVarBulkUpload}
                    setCsvList={setCsvList}
                    setInputList={setInputList}

                />
            }

            {
                popup.addProdPopup && 
                <PopupAddSegment
                    dealType={"Offer"}
                    closeBtn={() => {
                        setPopup({
                        ...popup,
                        addProdPopup: false,
                        });
                    }}
                    list={
                        popup.addSkuType === "include"
                        ? communicationData.include_segment_list
                        : communicationData.exclude_segment_list
                    }
                    addSkuForDeal={addSkuOfferType}
                    removeSkuForDeal={onDeleteProd}
                />
            }

            {
                popup.updateProdPopup && 
                <PopupUpdateSegment
                    dealType={"Offer"}
                    closeBtn={() => {
                        setPopup({
                        ...popup,
                        updateProdPopup: false,
                        });
                    }}
                    list={
                        popup.addSkuType === "include"
                        ? communicationData.include_segment_list
                        : communicationData.exclude_segment_list
                    }
                    removeSkuForDeal={onDeleteProd}
                />
            }

            {
                popup.offerPopup &&
                <PopupAddOffer
                    dealType={"Communication"}
                    closeBtn={() => {
                        setPopup({
                        ...popup,
                        offerPopup: false,
                        });
                    }}
                    list={[]}
                    addSkuForDeal={addOfferType}
                    removeSkuForDeal={() => {}}
                />
            }
            {isLoading && <Loader />}
        </>
    );
}

export default AddBanner;
