import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorHandling } from '../../../../State';
import { getAllOffertype, updateOfferType, uploadBannerImage } from '../../../../utils/api';
import { SubscriptionImgUrl } from '../../../../utils/config';
import style from '../css/Subscription.module.css'

export default function EditOffertType() {
    const { id, status } = useParams();
    const [imageId, setImageId] = useState();
    const [type, setType] = useState();
    const [data, setData] = useState();

    const navigate = useNavigate()

    const fetchData = async () => {
        let res = await getAllOffertype();
        let obj = res.data.data.filter((type) => type.id === parseInt(id))[0];
        let editData = {
            heading_1: obj.heading_1,
            heading_2: obj.heading_2,
            id: obj.id,
            bg_image: obj.bg_image
        }
        setData(editData)
        setType(obj);


    }

    const handleInputChange = (e) => {
        let obj = data;
        obj[e.target.name] = e.target.value;
        setData({ ...obj });
    }

    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    const saveFile = (e) => {
        setImageId(e.target.files[0]);

    }

    const uploadImage = async () => {
        var image_type = 'subscription';
        let Imgdata = new FormData();
        Imgdata.append('file', imageId);
        Imgdata.append('image_type', image_type)
        if (type.banner_required) {
            const img = new Image;
            img.onload = async function () {
                if (img.height === 150 && img.width === 750) {
                    let res = await uploadBannerImage(Imgdata)
                    if (res.success) {
                        let obj = data;
                        obj.bg_image = res.image_id;
                        console.log(obj);
                        setData({ ...obj })
                    } else {
                        ErrorHandling(res.error.message);
                    }
                } else {
                    ErrorHandling('The Aspect ratio is not matched ')
                }
            }
            img.src = URL.createObjectURL(imageId);
        } else {
            ErrorHandling('Can Not Upload Banner For This Image')
        }
    }

    const updateOffer = async () => {
        if (type.banner_required && imageId === '') {
            ErrorHandling('Please Upload Banner for this offer type')
        } else if (!type.banner_required && imageId !== '') {
            ErrorHandling('Can not upload banner for this type');
        } else {
            let res = await updateOfferType(data);
            if (res.success) {
                navigate(`/${status}/${localStorage.getItem('id')}/subscription/`)
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        data && <>
            {/* header */}
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>Promotional Banner Strip</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>Promotional Banner Strip</div>

                <div className={`${style.addContainer}`}>

                    {/* first section - input and checkboxes */}
                    <div className={`${style.separator}`}>
                        {/* is active, campaign name, tag and rank */}
                        {type && <div className={`textCenter fontL bold upperCase`}>
                            {type.name} | {type.title}
                        </div>}

                    </div>

                    {/* dropdowns - content type, landing screen */}
                    <div className={`flex ${style.mrg_top_3} ${style.separator}`}>
                        <div className={`${style.mrg_x_4}`} style={{ flex: 2 }}>
                            <div>
                                <div className={`${style.title}`}>Heading 1 | Max. 34 character</div>
                                <input
                                    className={`${style.input_field_border} ${style.width_80}`}
                                    name='heading_1'
                                    type='text'
                                    value={data.heading_1}
                                    placeholder='Write here'
                                    onChange={(e) => {
                                        if (e.target.value.length < 34) {
                                            handleInputChange(e)
                                        } else {
                                            ErrorHandling('Max Character limit reached')
                                        }
                                    }} />
                            </div>

                            <div className={`${style.mrg_top_3} `}>
                                <div className={`${style.title}`}>Heading 2 | Max. 20 character</div>
                                <input
                                    className={`${style.input_field_border} ${style.width_80}`}
                                    name='heading_2'
                                    type='text'
                                    value={data.heading_2}
                                    placeholder='Write here'
                                    onChange={(e) => {
                                        if (e.target.value.length < 20) {
                                            handleInputChange(e)
                                        } else {
                                            ErrorHandling('Max Character limit reached')
                                        }
                                    }} />
                            </div>
                        </div>

                        <div className={`textCenter`} style={{ flex: 1 }}>

                            <div className={`${style.mrg_x_6}`}>
                                <div className={`${style.title}`}>Upload Banner</div>
                                <input type="file" name="bg_image" id="firstImg" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                <div className={`${style.banner_wrapper}`}>
                                    <img src={data.bg_image ? SubscriptionImgUrl + data.bg_image : null} className={`${style.offer_type_img}`} onClick={() => { uploadImg("firstImg"); }} />
                                    <div className={`${style.heading_1}`}>
                                        {data.heading_1}
                                    </div>
                                    <div className={`${style.heading_2}`}>
                                        {data.heading_2}
                                    </div>
                                </div>
                                <div>
                                    <div className={`fonts ${style.mrg_y_6} ${imageId !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{imageId || data.bg_image ? (imageId && imageId.name ? imageId.name : data.bg_image) : 'please select the image'}</div>
                                    <span className={`fontS green ${style.mrg_x_6}`}> ASPECT RATIOS SHOULD BE (750 x 150)px </span>
                                    <button className={`${style.uploadBtn}`} onClick={() => { uploadImage() }}>Upload</button>
                                </div>
                            </div>



                        </div>


                    </div>



                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => navigate(`/${status}/${localStorage.getItem('id')}/subscription/`)} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={() => { updateOffer() }}>
                            Save
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>



        </>
    );
}
