import React, { useEffect, useState } from 'react';
import style from '../css/dashboard.module.css'
import { getCampaignList, getZoneData } from '../../../../utils/api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../../../../icons/calender.svg'
import { WeekDays } from '../../../../utils/constData';
function CampaignListing(props) {
    const [zones, setZones] = useState([]);
    const [is_today_active_btn, setTodayActiveBtn] = useState(true)
    const [is_tomorrow_active_btn, setTommorowActiveBtn] = useState(false)
    const [DayName, setWeekDay] = useState('')
    const [curr_date, setDate] = useState(new Date())
    const [dashboardData, setdashboardData] = useState({});
    const updateZoneList = async () => {
        let res = await getZoneData();
        setZones(res.data);
    }

    const updateCampaignList = async (slot_id) => {
        let res = await getCampaignList(slot_id);
        console.log(res.data);
        setdashboardData(res.data)

    }

    const selDate = (e) => {
        let day_name = WeekDays[e.getDay()]
        setWeekDay(day_name)
        let year = String(e.getFullYear()).split('')[2] + '' + String(e.getFullYear()).split('')[3]
        let dat_value = e.getDate() >= 10 ? e.getDate() : '0' + String(e.getDate())
        let month = e.getMonth() + 1 >= 10 ? parseInt(e.getMonth() + 1) : '0' + parseInt(e.getMonth() + 1)
        let today = year + '' + month + '' + dat_value;
        console.log("today", typeof (today))
        setDate(e)
        // setSlotId(today)
        updateCampaignList(today);
        // getCampaignList(today);
        setTodayActiveBtn(false)
        setTommorowActiveBtn(false)
    }

    const todayDateselected = () => {
        let today = new Date()
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let dat_value = today.getDate() > 10 ? today.getDate() : '0' + String(today.getDate())
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let today1 = year + '' + month + '' + dat_value;
        console.log("today", typeof (today), today1)
        setDate(today)
        updateCampaignList(today1);
        setTodayActiveBtn(true)
        setTommorowActiveBtn(false)
        let day_name = WeekDays[today.getDay()]
        setWeekDay(day_name)
    }

    const tomorrowDateselected = () => {
        var today = new Date();
        today.setDate(today.getDate() + 1);
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let dat_value = today.getDate() > 10 ? today.getDate() : '0' + String(today.getDate())
        let today1 = year + '' + month + '' + dat_value;
        console.log("today", typeof (today), today1)
        setDate(today)
        updateCampaignList(today1);
        setTodayActiveBtn(false)
        setTommorowActiveBtn(true)
        let day_name = WeekDays[today.getDay()]
        setWeekDay(day_name)

    }

    useEffect(() => {
        updateZoneList();
        let today = new Date();
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let dat_value = today.getDate() >= 10 ? today.getDate() : '0' + String(today.getDate())
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let slot_id = year + '' + month + '' + dat_value;
        console.log("slot_id", dat_value, today.getDate())
        let day_name = WeekDays[today.getDay()]
        setWeekDay(day_name)
        updateCampaignList(slot_id);
        // setSlotId(slot_id);
    }, [])

    return (
        <>
            <div className={`${style.dashboardContainer}`}>
                <div className={`${style.campaign_container} flex`}>

                    <div className={`${style.leftContainer} flex`}>
                        <div className={`${style.leftHeading}`}>
                            <div className={`${style.heading}`}>Campaign Dashboard</div>
                            <div className={`${style.sub_heading}`}>Data for <span style={{ fontSize: "12px", color: "black", fontWeight: "600" }}>assigned, partially assigned and empty </span>campaign allottments.</div>
                        </div>
                        <div className={`${style.leftCalender} flex`}>
                            <button onClick={todayDateselected} className={`${style.outlinedBtn} ${style.mrg_10} ${is_today_active_btn ? style.date_btn_active : ''}`}>Today</button>
                            <button onClick={tomorrowDateselected} className={`${style.outlinedBtn} ${style.mrg_10} ${is_tomorrow_active_btn ? style.date_btn_active : ''}`}>Tomorrow</button>
                            <div className={style.calendr_img}>
                                <img src={Calendar} />
                            </div>
                            <div className={style.calendr_img}>
                                {DayName},
                            </div>
                            <DatePicker onChange={(e) => selDate(e)} selected={curr_date}
                                className={style.custom_date_picker}
                                dateFormat="dd MMMM yyyy"
                            //    minDate = {new Date()}
                            />
                        </div>
                    </div>

                    <div className={`${style.rightContainer} flex`}>
                        <div className={`${style.leftInnerBox} flex flex-space-around`}>
                            <div className={`${style.first_itemBox}`}>
                                <div className={`${style.innerItem1}`}>Existing Users</div>
                                <div className={`${style.innerItem2}`}>Campaigns</div>
                                <div className={`${style.innerItem3}`} style={{ "color": "rgb(0,150,136)" }}>{dashboardData.existing_user_campaigns_count}</div>
                            </div>
                            <div className={`${style.first_itemBox}`}>
                                <div className={`${style.innerItem1}`}>New Users</div>
                                <div className={`${style.innerItem2}`}>Campaigns</div>
                                <div className={`${style.innerItem3}`} style={{ "color": "rgb(234, 180, 42)" }}>{dashboardData.new_user_campaigns_count}</div>
                            </div>
                        </div>
                        <div className={`${style.rightInnerBox} flex`}>
                            <div className={`${style.firstLine} flex`}>
                                <div className={`${style.darkgreen}`}></div>
                                <div className={`${style.orange}`}></div>
                                <div className={`${style.campaign_title}`}>Campaigns alloted</div>
                            </div>
                            <div className={`${style.secondLine} flex`}>
                                <div className={`${style.aqua}`}></div>
                                <div className={`${style.yellow}`}></div>
                                <div className={`${style.campaign_title}`}>Campaigns partially alloted</div>
                            </div>
                            <div className={`${style.thirdLine} flex`}>
                                <div className={`${style.white1}`}></div>
                                <div className={`${style.white2}`}></div>
                                <div className={`${style.campaign_title}`}>No Campaigns alloted</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={`${style.greyBottomBorder}`}></div> */}

                <table className={`${style.tableContainer}`}>
                    <thead>
                        <tr>
                            <th style={{ width: "10%" }}></th>
                            {
                                zones.map((item, idx) => {
                                    return (
                                        <th style={{ width: "10%", padding: "30px" }}>{item.name}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dashboardData.campaign_info &&
                            dashboardData.campaign_info.map((info) => {

                                return (
                                    <tr className={`${style.listRow}`}>
                                        <td style={{ textAlign: "center", padding: "17px" }}>{info.timings}</td>
                                        {
                                            info.data.map((item) => {
                                                let existingUser = '#fff'
                                                let newUser = '#fff'
                                                console.log(item)
                                                if (item.existing_user_partial) existingUser = "rgb(211, 240, 237)"
                                                else if (item.existing_user_campaign) existingUser = "rgb(0, 150, 136)"

                                                if (item.new_user_partial) newUser = "rgb(255, 231, 148)"
                                                else if (item.new_user_campaign) newUser = "rgb(234, 180, 42)"
                                                return (
                                                    <td style={{ textAlign: "center" }}>
                                                        {/* existing customers */}
                                                        <span className={`${style.colorBox1}`}
                                                            style={{ backgroundColor: existingUser }}>
                                                        </span>

                                                        {/* new customers */}
                                                        <span className={`${style.colorBox2}`}
                                                            style={{ backgroundColor: newUser }}
                                                        ></span>
                                                    </td>
                                                )

                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CampaignListing;