import { MonthNames } from "./constData"


export function UTCTimeFormat(date_time) {
    let year = String(date_time.getUTCFullYear())
    let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
    let setMinutes = date_time.getUTCMinutes() >= 10 ? String(date_time.getUTCMinutes()) : String('0' + date_time.getUTCMinutes())
    let hours = parseInt(date_time.getUTCHours()) >= 10 ? String(date_time.getUTCHours()) : String('0' + date_time.getUTCHours())
    let month = date_time.getMonth() + 1 > 9 ? date_time.getMonth() + 1 : '0' + (date_time.getMonth() + 1);
    let today1 = year + '-' + month + '-' + dat_value + 'T' + hours + ':' + setMinutes;
    return today1
}

export function EpochTimeTOHumanFormat(epoch_time) {
    let date_time = new Date(parseInt(epoch_time + '000'))
    let year = String(date_time.getFullYear())
    let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
    let setMinutes = date_time.getMinutes() >= 10 ? String(date_time.getMinutes()) : String('0' + date_time.getMinutes())
    let hours = parseInt(date_time.getHours()) >= 10 ? String(date_time.getHours()) : String('0' + date_time.getHours())
    let month = date_time.getMonth() + 1 > 9 ? date_time.getMonth() + 1 : '0' + (date_time.getMonth() + 1);
    let today1 = year + '-' + month + '-' + dat_value + 'T' + hours + ':' + setMinutes;
    return today1
}

export function EpochTimeToHumanFormatTime(epoch_time) {
    let date_time = new Date(parseInt(epoch_time + '000'))
    let month = MonthNames[date_time.getMonth()]
    let year = String(date_time.getFullYear())
    let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
    let setMinutes = date_time.getUTCMinutes() >= 10 ? String(date_time.getUTCMinutes()) : String('0' + date_time.getUTCMinutes())
    let hours = parseInt(date_time.getUTCHours()) >= 10 ? String(date_time.getUTCHours()) : String('0' + date_time.getUTCHours())
    let am_or_pm = date_time.getUTCHours() >= 12 ? 'PM' : 'AM'
    let today1 = month.substring(0, 3) + ' ' + dat_value + ', ' + year + ' ' + hours + ':' + setMinutes + ':00 ' + am_or_pm
    return today1
}


export function EpochTimetoDatePickerFormat(epoch_time) {
    let date_time = new Date(parseInt(epoch_time + '000'))
    let year = String(date_time.getFullYear())
    let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
    let setMinutes = date_time.getUTCMinutes() >= 10 ? String(date_time.getUTCMinutes()) : String('0' + date_time.getUTCMinutes())
    let hours = parseInt(date_time.getUTCHours()) >= 10 ? String(date_time.getUTCHours()) : String('0' + date_time.getUTCHours())
    // let am_or_pm = date_time.getUTCHours() >= 12 ? 'PM' : 'AM'
    let month = date_time.getMonth() + 1 > 9 ? date_time.getMonth() + 1 : '0' + (date_time.getMonth() + 1);
    let today1 = year + '-' + month + '-' + dat_value + 'T' + hours + ':' + setMinutes;
    return today1
}

export function EpochTimeInHumanLan(epoch_time, hrs_12_format,noZero) {
    let date_time;
    if(!noZero){
        date_time = new Date(parseInt(epoch_time + '000'));
    }else{
        date_time = new Date(parseInt(epoch_time))
    }
    
    let month = MonthNames[date_time.getMonth()]
    let year = String(date_time.getFullYear())
    let dat_value = date_time.getDate() >= 10 ? String(date_time.getDate()) : '0' + String(date_time.getDate())
    let setMinutes = date_time.getMinutes() >= 10 ? String(date_time.getMinutes()) : String('0' + date_time.getMinutes())
    let hours = parseInt(date_time.getHours()) >= 10 ? String(date_time.getHours()) : String('0' + date_time.getHours())

    if(hrs_12_format){
        hours = hours > 12 ? hours - 12 : hours;
    }
    
    let am_or_pm = hrs_12_format? date_time.getHours() >= 12 ? 'PM' : 'AM' : '';
    let today1 = month.substring(0, 3) + ' ' + dat_value + ', ' + year + ' ' + hours + ':' + setMinutes + ':00 ' + am_or_pm
    return today1
}

// tomorrow date with time
export function getTodayTomDate(){

    const today = new Date()
    // to return the date number(1-31) for the specified date
    // console.log("today => ",today)

    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1)
    //returns the tomorrow date
    // console.log("tomorrow => ",tomorrow)
    return {today: today, tomorrow: tomorrow};
}

export function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}

export function getTodaySlot(selDate){
    let today = new Date();
    if(selDate){
        today = new Date(selDate)
    }

    let year = today.getFullYear() - 2000;
    let month = (parseInt(today.getMonth() + 1) < 10 ? `0${parseInt(today.getMonth()+1)}` : parseInt(today.getMonth()+1));
    let date = (today.getDate() > 9 ? today.getDate() : '0' + today.getDate());

    return `${date}${month}${year}`;
}

export function getTodayNSlot(selDate){
    let today = new Date();
    if(selDate){
        today = new Date(selDate)
    }

    let year = today.getFullYear() - 2000;
    let month = (parseInt(today.getMonth() + 1) < 10 ? `0${parseInt(today.getMonth()+1)}` : parseInt(today.getMonth()+1));
    let date = (today.getDate() > 9 ? today.getDate() : '0' + today.getDate());

    return `${year}${month}${date}`;
}

export function epochIstToInputFormat(epoch){
    let date = new Date(parseInt(epoch + '000'));

    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2,'0');
    let todayDate = date.getDate().toString().padStart(2,'0');
    
    let hours = date.getHours().toString().padStart(2,'0');
    let minutes = date.getMinutes().toString().padStart(2,'0');

    return `${year}-${month}-${todayDate}T${hours}:${minutes}`;

}

// input to epoch convertion 
export function convertTimeToEpochSecond(date){
    let epoch = new Date(date).getTime()
    return epoch/1000;
}