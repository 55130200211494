// api endpoints here

// commom api end point
export const getExcelFileInMailEndpoint = 'bulk/download/v1/'

// BRANDING - app feed
export const getZoneListEndPoint = 'zone/v1/';
export const getAppItemListEndpoint = 'feed/v1/';
export const getNewAppFeedDraftVersionEndpoint = 'feed/draft/v1/';
export const publishAppFeedEndpoint = 'feed/publish/v1/';
export const getWidgetListEndpoint = 'feed/widget/types/v1/';

export const getSubCatListEndpoint = 'product/category/subcategory/download/';
export const getSubCatListZoneWiseEndpoint = 'product/category/sub_cat/zone_wise/list/?';

export const getCatListEndpoint = 'product/category/download/';
export const getCatListZoneWiseEndpoint = 'product/category/zone_wise/list/';

export const getDcListEndPoint = 'users/distributors/v1/';
export const uploadImageEndPoint = 'products/image/v1/';
export const saveAppFeedEndPoint = 'feed/create-update/v1/';
export const inactiveAppFeedEndPoint = 'feed/inactive/feeds/v1/'
export const moveInactiveToDraftEndPoint = 'feed/move/to/draft/v1/'
export const inactiveFeedDetailEndPoint = 'feed/details/v1/'

export const fetchConstantListEnpoint = 'feed/constants/v1/'

export const historyToDraftNewAppFeedEndpoint = "feed/inactive/to/draft/v1/"

//BRANDING - Feed Content
export const getFeedContentListEndPoint = `banner/products/v1/`;
export const uploadBannerImageEndPoint = `spa/products/image/v1/`;
export const addBannerEndPoint = `banner/create-update/v1/`;
export const uploadBannerExcelEndpoint = 'banner/upload/v1/';
export const downloadBannerExcelEndpoin = `banner/download/v1/`;
export const searchBannerEndpoint = `banner/search/v1/`;

//CUT OFF TIME
export const cutOffZoneListEndpoint = 'distributor/zonecutoff/time/update/v1/'

// BRANDING - order success banner
export const bannerListingEndpoint = 'products/erp/order/success/banners/v1/';
export const bannerTypeEndpoint = 'products/erp/order/success/constants/v1/';

// COUPON - coupon details
export const couponDetailEndpoint = 'cons/payment/btl/coupon/v1/'

// COUPON - coupon list
export const couponListEndpoint = 'otipycoupons/erp/new/coupon/v1/';

// MARKETING - burn mapping listing ,user segment listing
export const burnMappingEndpoint = 'campaigns/burn/mapping/v1/'
export const userSegmentEndpoint = 'campaigns/user/segments/v1/'

// MARKETING - campaign listing
export const campaignListingEndpoint = 'campaigns/dashboard/v1/'
export const searchSkuEndPoint = 'otipy/products/erp/v1/'
export const getInventryInfoCampaignSkuEndPoint = 'campaigns/product/inventory/info/v1/'
export const getMaxMinSkuPriceEndPoint = 'campaigns/search/products/v1/'
export const refreshminmaxSkuDataEndPoint = 'zone/refresh/product/price/v1/'
export const getUserSegmentInfoEndPoint = 'campaigns/user/segments/v1/'
export const createNewCampaignEndPoint = 'campaigns/create-update/v1/'
export const getcampaignListEndpoint = 'campaigns/list/v1/'
export const getcampaignDetailEndpoint = 'campaigns/detail/v1/'
export const removeSkuFromZoneEndpoint = 'campaigns/deal/remove/v1/'
export const activateCampaingEndpoint = 'campaigns/deactivate/v1/'
export const userSegmentListEndpoint = 'campaigns/user/segement/two/v1/'

// MARKETING - create / clone / edit campaign
export const cloneCampaignEndPoint = 'campaigns/v1/'
export const cancelCampaignEndPoint = 'campaigns/delete/v1/'
export const getProdCsvDetailEndPoint = 'campaigns/upload/deals/v1/'

// MARKETING - create / clone / edit membership campaign
export const getMembershipPlanListEndpoint = 'myorders/membership/config/v1/'
export const submitMembershipDataEndpoint = 'campaigns/create-update/membership/v1/'
export const getMembershipCampListEndpoint = 'campaigns/membership/listing/v1/'
export const getMembershipCampDetailEndpoint = 'campaigns/membership/info/v1/'


//Marketing Bonus Management

export const getWalletListEndPoint = 'otipysegments/wallet/dashboard/v1/';
export const resetSegmentListEndPoint = 'otipysegments/wallet/restore_default/v1/';
export const addSegemntEndPoint = 'otipysegments/wallet/dashboard/v1/';


// COUPON LIST - products list
export const productListEndpoint = 'search/products/v3/311222/search/'
// COUPON LIST - category list
export const categoryListEndpoint = 'product/category/download/'
// COUPON LIST - sub-category list
export const subCategoryListEndpoint = 'product/category/subcategory/download/'

// ADD COUPON - submit
export const submitCouponEndpoint = 'otipycoupons/erp/new/coupon/v1/'

// COMMUNICATION - SMS DASHBOARD 
export const SmsDashboardListEndpoint = 'cons/consumer/promo/sms_details/v1/'

// COMMUNICATION - KAFKA SWITCHER 
export const kafkaSwitcherListEndpoint = 'cons/consumer/promo/kafka_switch/v1/'


export const pushNotificationListEndpoint = 'campaigns/pntext/list/v1/'


//Subscription - subscription product
export const subscriptionProductEndpoint = `subscription/products/v1/`
export const getOfferTypeEndpoint = `subscription/all/offer/types/v1/`
export const updateOffertypeEndpoint = `subscription/update/offer/type/v1/`;
export const getProdDetailsZoneWise = `subscription/active/zone/products/v1/`

//Subscription - subscription top banner
export const getSubscriptionBannerEndPoint = `subscription/subs/banners/v1/`;
export const createSubscriptionBannerEndpoint = `subscription/create/subs/banners/v1/`;
export const updateSubscriptionBannerEndpoint = `subscription/update/subs/banners/v1/`;
export const getProdDetailsEndpoint = `subscription/product/v1/`;


// Product widget - add update images
export const getProductWidgetEndpoint = `zone/sku_image/v1/`
export const getProductDetailEndpoint = `zone/sku_image/id/v1/`
export const createProductWidgetEndpoint = `zone/sku_image/v1/`
export const updateProductWidgetEndpoint = `zone/sku_image/v1/`;
export const uploadProductImageEndPoint = `spa/products/image/v1/`;
export const deleteProductWidgetEndpoint = `zone/sku_image/delete/v1/`
export const uploadPdfFileEndpoint = `spa/products/pdffile/upload/v1/`



// Header Banner - add update
export const getHeaderBannerListEndpoint = `zone/feed_usp/v1/`;
export const createHeaderBannerEndpoint = `zone/feed_usp/v1/`;
export const getHeaderBannerEndpoint = `zone/feed_usp/v1/`;
export const updateHeaderBannerEndpoint = `zone/feed_usp/v1/`;
export const deleteHeaderBannerEndpoint = `zone/feed_usp/delete/v1/`

// MINI FEED 

export const getMiniFeedDetailEndPoint = "feed/get/mini_feed/v1/";
export const saveMiniFeedEndPoint = "feed/create-update/mini_feed/v1/";
export const updateMiniFeedEndPoint = "feed/change/status/mini_feed/v1/"

//BONUS MANAGEMENT

export const getBonusConstantEndPoint = "otipysegments/communication/constants/v1/";
export const addNewCommunication = "otipysegments/communication/create_edit/v1/";
export const addNewBulkCommunication = "otipysegments/communication/create_edit/bulk_upload/v1/";
export const getCommunicationListingEndpoint ="otipysegments/communication/list/v1/";
export const getCommunicationEditEndpoint ="otipysegments/communication/edit/v1/";

export const deleteSmsCampaignEndpoint = 'otipysegments/communication/delete/v1/'
export const sendTestCommunicationEndpoint = 'otipysegments/communication/send_test/v1/';
export const downloadUserListEnpoint = 'otipysegments/communication/download/consumer_list/v1/'



// PRODUCT DETAIL VIDEO

export const uploadVideoEndPoint = 'products/file/upload/v1/';
export const createUpdateProductDetailEndpoint = 'distributor/zone/product/create-update/video/v1/';
export const getProductVideoDetailEndpoint = 'distributor/zone/product/video/v1/';
export const activeInactiveDetailEnpoint = "distributor/zone/product/video/deactivate/v1/";
export const fetchProductInfoEndpoint = 'distributor/zone/product/video/info/v1/';



// PDP - sub cat
export const fetchCategoryListEndpoint = 'product/category/download/'
export const fetchSubCategoryListEndpoint = 'product/category/subcategory/list/'

// PDP - product detail keys
export const prodDetailKeyListEndpoint = 'product/product/details/keys/v1/'
export const listingSectionEndpoint = 'pdp/sections/v1/'

// PDP - bulk upload
export const prodDetailCsvUploadEndpoint = 'product/bulk/upload/details/v1/'
export const prodDetailZipUploadEndpoint = 'product/upload/images/v1/' 



//BRANDING - Widget Listing

export const getWidgetListingEndpoint=`feed/widget/v1/`;
export const getVersionsListingEndpoint = `feed/versions/v1/`;

// ADD EDIT WIDGET

export const fetchWidgetDetailsEndpoint = 'feed/widget/search/v1/';


// BRANDING - New Feed Content Listing
export const getNewFeedContentListEndPoint = `banner/v1/`;
export const searchNewBannerEndpoint = `banner/search/v1/`;
export const uploadNewBannerExcelEndpoint = `banner/products/excel/upload/v1/`;

//BRANDING - New Mini Feed Listing
export const getNewMiniFeedListEndPoint = `feed/minifeed/v1/`;
export const searchMiniFeedEndPoint = `feed/minifeed/search/v1/`;
export const bulkUploadWidgetsInMiniFeedEndPoint = `feed/minifeed/bulk/upload/v1/`;


// USER SEGMENT 
export const fetchSegmentListEndPoint = 'segments/view/v1/';
export const fetchSegmentUserListEndpoint = 'segments/download/v1/'
export const createUserSegmentEndpoint = 'segments/create/v1/'
export const updateUserSegmentEndpoint = 'segments/update/v1/'
export const searchUserSegmentEndpoint = "segments/search/v1/"
export const deleteSegmentEndpoint = 'segments/delete/v1/'
export const activateUserSegmentEndpoint= 'segments/activate/v1/'


// ONDC 
export const fetchOndcCityListEndpoint = 'serviceable/city/list/v1/';
export const fetchCitywiseProviderEndpoint = 'provider/list/v1/';
// export const fetchOndcCategoryListEndpoint = 'provider/category/list/v1/';
export const fetchOndcCategoryListEndpoint = 'store/details/v1/';
export const fetchOndcStoreProductsEndpoint = 'item/catalogue/v1/';


// ONDC FEED
export const getOndcZoneListEndPoint = 'feed/zone/list/v1/';
export const getOndcWidgetListingEndpoint = 'feed/widget/v1/';
export const fetchOndcWidgetDetailsEndpoint = 'feed/widget/search/v1/';
export const fetchOndcFeedDetailsEndpoint = 'feed/v1/';
export const fetchOndcFeedSearchEndpoint = 'feed/search/v1/';
export const ondcFeedNewVersionEndpoint = 'feed/draft/v1/';
export const ondcHistoryToDraftFeedEndpoint = 'feed/inactive/draft/v1/';
export const ondcPublishFeedEndpoint = 'feed/publish/draft/v1/';
export const getOndcWidgetTypeEndpoint ='feed/widget/types/v1/';
export const getOndcCatListEndpoint = 'feed/category/list/v1/'
export const getOndcStoreProdListEndpoint = 'feed/store/list/v1/'



// OFFERS 
export const getOfferListingEndPoint = 'offers/v1/'
export const SearchOfferEndPoint = 'offers/search/v1/'
export const getOfferDetailsEndPoint = 'offers/details/v1/'
export const ChangeOfferStatusEndPoint = 'offers/status/change/v1/'
export const getTagListsEndPoint = 'offer/tags/v1/'


// Bulk Update 
export const uploadProdDetails = 'bulk/upload/v1/'