import React,{useState,useEffect} from 'react';
import { getUserSegmentList } from '../../../../utils/api';
import style from '../css/BurnMapping.module.css';
import EditIcon from '../../../../icons/edit.svg'
import UserSegmentPopUp from './UserSegmentPopUp';
function UserSegmentList({showPopUp, setshowPopUp}) {

    const [itemList,setItemList] = useState([]);
    const [segment,setSegment] = useState({});

    const updateItemList = async () => {
        let res = await getUserSegmentList();
        console.log(res);
        if(res.data.length){
         setItemList(res.data);
        }
        else {
         setItemList([]);
        }
     }

     const togglePopUp = (clearSegment) => {
        setshowPopUp(!showPopUp);
        if(clearSegment) setSegment({})
     }

     useEffect(()=>{
         updateItemList();
     },[]);

    return (
        <>
             <div className={`${style.container}`}>
              <div className={`flex ${style.thead}`}>
                <div className={`${style.pd_bg} ${style.width25}`}>Segment ID</div>
                <div className={`${style.pd_bg} ${style.width25}`}>User Segment</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Description</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Mark Down %</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Action</div>
              </div>

              <div className={`${style.tbody}`}>
            {
                 itemList.map((item,idx) => {
                   return (
                    <div className={` flex flex-space-around ${style.trow}`} key={idx}>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.id}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.segment_name}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.description}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.mark_down}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>
                        <div>
                            <span className={`pointer ${style.EditIcon}`}
                            onClick={()=>{
                                setSegment(item);
                                togglePopUp();
                            }}
                            >
                            <img src={EditIcon}/></span>
                        </div>
                        </div>
                    </div>
                   )
                })
            }
              </div>
           </div>
           {
            showPopUp ? 
            <UserSegmentPopUp
                closeBtn={() => togglePopUp(true)} 
                fetchData={updateItemList}
                id={segment.id}
                userSegment={segment.segment_name}
                description={segment.description}
                mark_down_percent={segment.mark_down}
            /> : null
           }
        </>
    );
}

export default UserSegmentList;