import React from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../icons/arrowleftgrey.svg';
import style from './Header.module.css'

function Header({ title, color, path, children }) {

    const navigate = useNavigate();

    return (
        <div className={`flex flex-space-between ${style.header}`}>
            <div className={`${style.header_title}`}>
                {
                    path ?
                    <BackIcon 
                        className={`alignIcon pointer ${style.mrgRgh}`} 
                        height={'22px'}
                        width={'22px'}
                        onClick={() => navigate(path)}
                    /> : ""

                }
                Crofarm ERP | <span className={`bold ${ color ? color : 'green'}`}>{title}</span>
            </div>
            {children}
        </div>
    );
}

export default Header;