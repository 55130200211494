import React, { useState } from 'react'
import { deepCopyObject, getZoneNames, searchOndcWidget, searchWidget } from '../../utils/api'
import PopUp from '../PopUp/PopUp'
import ToolTip from '../ToolTip/ToolTip'
import Loader from '../Loader/Loader'
import formStyle from '../PopUp/Form.module.css'
import { ReactComponent as InfoIcon } from '../../icons/Exclamation Mark.svg'
import { searchMiniFeedWidgetList } from '../../utils/constData'

function PopUpAddWidget({ title, closeBtn, handleAddWidget, isSearchMiniFeed, showAllWidgets, hasCondition, checkEligibilty, ondcFeed, ondcZonesList }) {

    const [isLoading, setLoader] = useState(false);
    const [showList, setShowList] = useState([]);
    const [debounceTimer, setDebounceTimer] = useState(0);
    const [selectedList, setSelectedList] = useState([]);

    // searching in widget
    const handleSearch = async (searchQuery) => {
        if (searchQuery.length > 2) {
            if(ondcFeed){
                setLoader(true)
                let res = await searchOndcWidget(searchQuery, showAllWidgets ? 1 : 0);
                res.map((ele) => {
                    ele.checked = false;
                })
                setLoader(false)
                setShowList(res); 
            }
            else {
                setLoader(true)
                let res = await searchWidget(searchQuery, showAllWidgets ? 1 : 0);
                res.map((ele) => {
                    ele.checked = false;
                })
                setLoader(false)
                setShowList(res);
            }
        }
        else {
            setShowList([]);
        }
    }

    // constant debounce 
    const handleDebounceSearch = (searchQuery) => {
        let timer = setTimeout(() =>
            handleSearch(searchQuery)
            , 500);
        clearTimeout(debounceTimer);
        setDebounceTimer(timer);
    }

    //adding widget in feeddata widget list
    const AddWidget = (widget, checked, index) => {
        
        if(hasCondition){
            let isEligible = checkEligibilty(widget)
            if(!isEligible){
                return;
            }
        }

        if (checked === true) {
            let list = deepCopyObject(selectedList);
            list.push(widget);

            setSelectedList(list);
            
        } else {
            let list = selectedList.filter(item => item.id !== widget.id)
            setSelectedList([...list]);
        }
        let localShowList = deepCopyObject(showList);
        localShowList[index].checked = checked;
        setShowList(localShowList);
        
    }

    //submit button in popup
    const handleSubmitData = () => {
        let localWidgetList = deepCopyObject(selectedList);
        handleAddWidget(localWidgetList);
        closeBtn();
    }


    return (
        <PopUp
            width={formStyle.popupWidthM}
            title={title}
            closeBtn={closeBtn}>
            <div className={`${formStyle.inputContainer}`}>

                <div className={`${formStyle.pd_cell} flex flex-wrap`}>
                    {
                        !!selectedList.length &&
                        <div className={`bold`}>Selected Widget Ids are:</div>
                    }
                    {
                        selectedList.map((ele) => {
                            return (
                                <div className={`${formStyle.mrg_lt}`}>{ele.id}{", "}</div>
                            )
                        })
                    }

                </div>

                {/* search */}
                <div className={`${formStyle.textLeft}`}>
                    <input
                        className={`${formStyle.searchBar} ${formStyle.fullwidth}`}
                        id={'searchInput'}
                        type={'text'}
                        onChange={(e) => handleDebounceSearch(e.target.value)}
                        placeholder={'Search Widget ID / Tag'}
                    />
                </div>

                {/* widget list */}
                <div className={`${formStyle.overlay_36} ${formStyle.custom_scrollbar}`}>
                    {
                        showList.map((widget, index) => {
                            return (
                                <div className={`${formStyle.popupList} ${
                                        (!isSearchMiniFeed && searchMiniFeedWidgetList.includes(widget.widget_type)) || !widget.is_active ?  
                                        formStyle.redBackgroundColor : ""
                                    }`} key={widget.id}>
                                    <div className={`grid  ${formStyle.popupGrid}`}>
                                        <div className={`textCenter`}>

                                            <input
                                                className={`pointer`}
                                                type='checkbox'
                                                checked={widget.checked}
                                                onChange={(e) => AddWidget(widget, e.target.checked, index)}
                                                disabled={!isSearchMiniFeed && searchMiniFeedWidgetList.includes(widget.widget_type)? true : false}
                                            />
                                            {!widget.is_active && <div className={`fontS bold red`}>INACTIVE</div>}
                                        </div>


                                        <div>
                                            <div>
                                                <span className={`bold fontS labelGrey`}>ID: </span>{widget.id}
                                            </div>
                                            <div>
                                                <span className={`bold fontS labelGrey`}>TAG: </span>{widget.widget_tag}

                                            </div>
                                        </div>
                                        <div>
                                            <div>
                                                <span className={`bold fontS labelGrey`}>TYPE: </span>{widget.widget_type}

                                            </div>
                                            <div className={`${ondcFeed ? 'flex' : ''}`}>
                                                <span className={`bold fontS labelGrey`}>ZONES: </span>
                                                {
                                                    ondcFeed ? 
                                                    <span className={`flex`}> 
                                                        {widget.zone_list.length}  Zone(s) 
                                                        <ToolTip
                                                            icon={<InfoIcon className={`pointer ${formStyle.mrg_lt}`} />}
                                                            black={true}
                                                            infoText={getZoneNames(widget.zone_list, ondcZonesList)}
                                                        />
                                                    </span>:
                                                    widget.zone_list.join(",")
                                                    // getZoneNames(widget.zone_list, ondcZonesList)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !isSearchMiniFeed && searchMiniFeedWidgetList.includes(widget.widget_type) && 
                                        <div className={`fontXS red textCenter`} style={{padding: '0.5rem 1.2rem'}}>* {widget.widget_type} can only be applied for Search Mini feed !</div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>

                {/* cancel and submit button */}
                <div className={`flex flex-space-between`} style={{ margin: '1rem' }}>
                    <button className={`bold ${formStyle.btn} ${formStyle.secondaryBtn}`} style={{ padding: '0.6rem 2rem' }} onClick={closeBtn}>Cancel</button>
                    <button className={`bold ${formStyle.btn} ${formStyle.successBtn}`} onClick={handleSubmitData} style={{ padding: '0.6rem 2rem' }}>Confirm</button>
                </div>
            </div>
            {isLoading && <Loader />}
        </PopUp>
    )
}

export default PopUpAddWidget