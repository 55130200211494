import React,{useState,useEffect} from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { ErrorHandling } from '../../../../State';
import { updateCutOffTime } from '../../../../utils/api';

function PopUpEditCutOff(props) {
    const [time, setTime] = useState(props.time);
    const [timeMessage,setTimeMessage] = useState(props.msg);


    const handleSubmit = async () => {
        if (!time || time===undefined) {
            ErrorHandling('Please fill the cut off time')
        } else if(!timeMessage || timeMessage.trim()===''){
            ErrorHandling('Please fill cut off time message')
        } 
        else{
            let data = {             
               cut_off_message_time: timeMessage,
               cut_off_time: time,
               pricing_zone_id: props.id
            }
            console.log(data);
            await updateCutOffTime(data);
            await props.fetchData();
            props.closeBtn();
    }
   }



    return (
        <PopUp
        title={`Edit Cut-Off Time | ${props.zone}`}
        width={`${formStyle.popupWidthS}`}
        closeBtn={() => props.closeBtn(false)}
    >
        <div className={`${formStyle.inputContainer}`}>
            <label>
                <div className={`labelGrey fontS`} style={{marginBottom:"8px"}}>TIME*</div>
                <input type="number" id="hhmm" value={time} className={`${formStyle.width100} ${formStyle.inputField}`} onChange={(e)=>setTime(e.target.value)}/>
            </label>
            <label>
                <div className={`labelGrey fontS`} style={{marginTop:"20px"}}>CUT OFF TIME MESSAGE*</div>
                <input type="text" id="cutOffMsg" value={timeMessage} className={`${formStyle.width100} ${formStyle.inputField}`} onChange={(e)=>setTimeMessage(e.target.value)}/>
            </label>
        </div>
        <div className={`${formStyle.btnContainer}`}>
            <button onClick={handleSubmit} className={`${formStyle.confirmBtn}`}>Update</button>
        </div>

    </PopUp>
    )
}


export default PopUpEditCutOff;