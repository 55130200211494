import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';   
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css'
import { ReactComponent as UploadCsvIcon} from '../../../../icons/upload.svg';
import { getProdCsvDetail } from '../../../../utils/api';
import { ErrorHandling } from '../../../../State';
import { acceptedExcelTypes } from '../../../../utils/constData';


function PopupCsvUpload({closeBtn, dealType, zoneList, burnSkuList, setBurnSkuDeal, nonBurnSkuList, setNonBurnSkuDeal, campaignData, setCampaignData, userSegment, burnSegment, getDefaultSegmentInfoValue, setLoader}) {

    const [isAppendProd, setAppendProd] = useState(0)
    const inputRef = useRef(null);

    // upload btn click
    const handleUpload = (appendProd) => {
        setAppendProd(appendProd)
        inputRef.current.click();
    }

    // create object for products in burn / non burn deal
    const handleAddApendCsvData = async (csvData) => {
        let prodId =  csvData.map((data) => data['Prod ID'])
        let localCampaignData = Object.assign({}, campaignData);

        // checking for duplicate products
        let burnProdList = burnSkuList.map((prod) => prod.prod_id)
        let nonBurnProdList = nonBurnSkuList.map((prod) => prod.prod_id)
        let duplicateBurnProdIds = burnProdList.filter((id) => prodId.includes(id))
        let duplicateNonBurnProdIds = nonBurnProdList.filter((id) => prodId.includes(id))

        // for append data
        if(isAppendProd && (duplicateBurnProdIds.length || duplicateNonBurnProdIds.length)){
            ErrorHandling(`Product IDs already Exists: ${duplicateBurnProdIds}, ${duplicateNonBurnProdIds}`)
            return;
        }
        else if(!isAppendProd && dealType == "burn" ){
            // if there are duplicates
            if(duplicateNonBurnProdIds.length){
                ErrorHandling(`Product IDs already Exists in Non burn Deal: ${duplicateNonBurnProdIds}`)
                return;
            }
            // in case there are no duplicates
            // we are resetting the prods here, include only non burn prod ids from 'updated_img_prod_list'
            else{
                localCampaignData.updated_img_prod_list = localCampaignData.updated_img_prod_list.filter((id) => duplicateNonBurnProdIds.includes(id))
            }
        }
        else if(!isAppendProd && !dealType == "burn" && duplicateBurnProdIds.length){
            // if there are duplicates
            if(duplicateBurnProdIds.length){
                ErrorHandling(`Product IDs already Exists in burn Deal: ${duplicateBurnProdIds}`)
                return;
            }
            // in case there are no duplicates
            // we are resetting the prods here, include only burn prod ids from 'updated_img_prod_list'
            else {
                localCampaignData.updated_img_prod_list = localCampaignData.updated_img_prod_list.filter((id) => duplicateBurnProdIds.includes(id))
            }
        }   

        console.log("updated updated_img_prod_list --> ",localCampaignData.updated_img_prod_list)

        let zoneId = zoneList.filter((zone) => zone.is_selected)
        zoneId = zoneId.map((zone) => zone.id)
        let body = {
            prod_ids: prodId,
            pricing_zone_ids: zoneId,
        }

        setLoader(true)
        let res = await getProdCsvDetail(body)
        setLoader(false)
        if(res.success){
            const {data} = res;
            let csvProdList = []
            
            // creating product data
            prodId.forEach((id, index) => {
                // TODO: separate the csv data and api data for better readability
                let sku = {}
    
                sku.discount = 0;
                sku.prod_name = data[id].prod_name;
                sku.pack_qt = data[id].pack_qt;
                sku.image_id = data[id].small_image_id;
                sku.dc_stock_left_list = data[id].stock_info;
                sku.max_zone_id=  data[id].max_zone_id;
                sku.max_zone_name= data[id].max_zone_name;
                sku.min_product_price=  data[id].min_price;
                sku.min_zone_id= data[id].min_zone_id;
                sku.min_zone_name=  data[id].min_zone_name;
                sku.price=  data[id].price;
                sku.pricing_zone_id=  data[id].pricing_zone_id;
                sku.banner_image_id = data[id].banner_image_id;
                sku.vertical_banner_image_id = data[id].vertical_banner_image_id;

                sku.prod_id = csvData[index]['Prod ID'];
                sku.associated_prod_id = csvData[index]['Associate Prod ID'] ? csvData[index]['Associate Prod ID'] : 0;
                sku.is_pn = csvData[index]['PN (0 /1)'] ? true : false;
                sku.rank = csvData[index]['Rank'] ? csvData[index]['Rank'] : 0;

                sku.discount_amount = "";
                sku.deal_price = "";    
    
                if(csvData[index]['Discount Amount'] && !isNaN(csvData[index]['Discount Amount'])){
                    sku.discount_amount = csvData[index]['Discount Amount'];
                }
                else if(csvData[index]['Price'] && !isNaN(csvData[index]['Price'])){
                    sku.deal_price = csvData[index]['Price'];
                }

                if(dealType == "burn"){
                    sku.is_burn_deal = true
                    sku.segment_info = getDefaultSegmentInfoValue(userSegment, burnSegment, 'burn')

                    if(csvData[index]['User Segment Include (0/1)'] && csvData[index]['User Segment Include (0/1)'] == "0"){
                        sku.segment_info.is_include_user_segment = {id: 0, name: "Exclude"}
                    }

                    if(csvData[index]['Non Burn Segment Include (0/1)'] && csvData[index]['Non Burn Segment Include (0/1)'] == "0"){
                        sku.segment_info.burn_segment_list = {id: 0, name: "Exclude"}
                    }

                    if(csvData[index]['User Segments']){
                        let str =  csvData[index]['User Segments']

                        let list = []
                        try{
                            list = str.split('|')
                        }
                        catch{
                            list = [str.toString()]
                        }  

                        sku.segment_info.user_segment_list.forEach((item, index) => {
                            if(list.includes(item.id.toString()) && item.id){
                                sku.segment_info.user_segment_list[index].is_selected = true;
                            }
                            else sku.segment_info.user_segment_list[index].is_selected = false;
                        })

                        // for all selection
                        let selUserCount = sku.segment_info.user_segment_list.filter((item => item.is_selected))
                        // sku.segment_info.user_segment_list[0].is_selected = selUserCount.length == sku.segment_info.user_segment_list - 1? true : false

                        if(selUserCount.length >= sku.segment_info.user_segment_list.length - 1){
                            sku.segment_info.user_segment_list[0].is_selected = true
                        }
                        else {
                            sku.segment_info.user_segment_list[0].is_selected = false
                        }
                    }

                    if(csvData[index]['Non Burn Segments']){
                        let str = csvData[index]['Non Burn Segments']

                        let list = []
                        try{
                            list = str.split('|')
                        }
                        catch{
                            list = [str.toString()]
                        }

                        sku.segment_info.burn_segment_list.forEach((item, index) => {
                            if(list.includes(item.id.toString()) && item.id){
                                sku.segment_info.burn_segment_list[index].is_selected = true;
                            }
                            else sku.segment_info.burn_segment_list[index].is_selected = false;
                        })

                        // for all selection
                        let selBurnCount = sku.segment_info.burn_segment_list.filter((item => item.is_selected))
                        // selBurnCount = selBurnCount.length
                        // sku.segment_info.burn_segment_list[0].is_selected = selBurnCount.length == sku.segment_info.burn_segment_list.length -1 ? true : false

                        if(selBurnCount.length >= sku.segment_info.burn_segment_list.length -1){
                            sku.segment_info.burn_segment_list[0].is_selected = true 
                        }
                        else {
                            sku.segment_info.burn_segment_list[0].is_selected = false
                        }
                    }
                        
                    sku.segment_info.segment_price_7 = csvData[index]['Segment 7 Price'] ? csvData[index]['Segment 7 Price'] : ""
                }
                else{
                    sku.is_burn_deal = false
                    sku.segment_info = getDefaultSegmentInfoValue(userSegment, burnSegment, 'non-burn')
                }
                
                sku.is_burn_deal = dealType == "burn" ? true : false
                sku.is_banner_image = false;

                csvProdList.push(sku)
            });

            console.log(csvProdList)

            if(dealType == "burn"){
                if(isAppendProd){
                    csvProdList = [...burnSkuList, ...csvProdList]
                }
                setBurnSkuDeal(csvProdList)
            }
            else {
                if(isAppendProd){
                    csvProdList = [...nonBurnSkuList, ...csvProdList]
                }
                setNonBurnSkuDeal(csvProdList)
            }
            setCampaignData(localCampaignData)
        }
        closeBtn()
    }

    // checking the csv data for further calculation
    const handleCsvDataValidation = (csvRowsData) => {
        let flag = true;
        let regex =  /^[0-9|]+$/;
        let prodNameIndex = csvRowsData[0].indexOf('Prod Name')
        if(!csvRowsData[0].includes('Prod ID')){
            ErrorHandling("No Product id found");
            return false
        }

        else {
            csvRowsData.splice(0, 1)
            csvRowsData.forEach((row) => {
                row.forEach((item, index) => {
                    if(prodNameIndex !== index && item && !regex.test(item)){
                        flag = false;
                    }
                })
            })
            if(!flag){
                ErrorHandling("Please Input Valid Values in CSV!")
            }
        }

        return flag;        
    }

    // read csv
    const handleFileChange = (e) => {
        e.preventDefault();

        var files = e.target.files,
            f = files[0];
        inputRef.current.value = null;
        if(!acceptedExcelTypes.includes(f.type)){
            ErrorHandling("Unsupported File type!")
            return;
        }
        var newData ;
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: "binary" });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            // for proper json response use dataParse2
            const dataParse2 = XLSX.utils.sheet_to_json(ws);
            newData = Object.assign({}, dataParse2);
            console.log("Parsed Data:", dataParse);
            console.log("Parsed Data 2:", dataParse2);

            let isValid = handleCsvDataValidation(dataParse);
            if(isValid){
                handleAddApendCsvData(dataParse2);
            }

        };
        reader.readAsBinaryString(f);
    };
    return (
        <PopUp
            title={`CSV Upload for ${dealType} Deal`}
            width={`${formStyle.popupWidthM}`}
            closeBtn={closeBtn}
        >
            <div className={` ${formStyle.inputContainer}`}>
                {/* heading */}
                <div  className={`red`} style={{marginBottom: "18px"}}>
                    <div className={` bold`}>Instruction: </div>
                    <div><b>1. </b> All the values in the columns must be a Number</div>
                    <div><b>2. </b> Product Ids must be numbers only</div>
                    <div><b>3. </b> User Segment ids and Burn segment ids must be separated by <b>'|' (pipe symbol) ONLY</b></div>
                    <div><b>4. </b> Incase Price and Discount Amount both are filled, then discount amount will be given priority over price</div>
                </div>

                <div className={`textCenter`}>
                    <input
                        style={{display: 'none'}}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                        accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />  
                    <div className={`flex flex-space-between`} style={{marginTop: "2.6rem"}}>
                        <button 
                            className={`${formStyle.btn} ${formStyle.btnL} ${formStyle.successBtn}`} 
                            onClick={() => handleUpload(1)}>
                                Append Product <UploadCsvIcon className={`whiteIcon`}/>
                        </button>
                        <button 
                            className={`${formStyle.btn} ${formStyle.btnL} ${formStyle.successBtn}`}
                            onClick={() => handleUpload(0)}>
                            Reset and Add Product <UploadCsvIcon className={`whiteIcon`}/>
                        </button>
                    </div>

                </div>
            </div>
        </PopUp>
    );
}

export default PopupCsvUpload;
