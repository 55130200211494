import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../css/Couponlist.module.css'
import DatePicker from 'react-datepicker';
import LocationIcon from '../../../../icons/location.svg'
import 'react-datepicker/dist/react-datepicker.css';
import ArrowIcon from '../../../../icons/down-arrow.png'
import { checkboxes } from '../../../../utils/constData';
import { getCategoryItems, getProductItems, getSubCategoryItems, getZoneData, submitCoupon, toggle } from '../../../../utils/api';
import { OneTimeUseDropdown } from '../../../../utils/constData';
import SearchIcon from '../../../../icons/ic_grey_search.svg'
import DeleteIcon from "../../../../icons/bin.svg"
import { UTCTimeFormat } from '../../../../utils/TimeManager';
import { ErrorHandling } from '../../../../State';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';

function AddCoupon(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [isSelected,setSelected] = useState(1);
    const [dropdownText,setDropdownText] = useState(OneTimeUseDropdown[0]);
    const [searchQuery, setSearchQuery] = useState("");
    const [checkboxlist,setCheckboxlist] = useState(checkboxes);
    const [productList,setProductList] = useState([]);
    const [categoryList,setCategoryList] = useState([]);
    const [zoneList,setZoneList] = useState([]);
    const [subCategoryList,setSubCategoryList] = useState([]);
    const [showList,setShowList] = useState(false);
    const [outerListItem,setOuterListItem] = useState([]);
    const [showOuterList,setShowOuterList] = useState(false);

    const [formData,setFormData] = useState({
        agent_id:"",
        agent_id_list:"",
        aov_coupon:0,
        coupon_code:"",
        id:32,
        coupon_type:0,
        description:"",
        is_active:false,
        is_private:false,
        rank:0,
        consumer_ids:"",
        saving_text:"",
        title:"",
        order_count:0,
        success_text:"",
        expiry_time:"",
        tnc:"",
        mapped_agent_id:0,
        product_min_order_value:0,
        product_max_discount:0,
        product_absolute_discount:0,
        product_discount_per:0
    })

    const navigate = useNavigate();

    const getBackListing = () => {
        navigate('/coupon-list/');
    }
    const toggleList = () => {
        setShowList(!showList);
    }
    const makeCheckFalse = (id) => {
        const checkboxes = Object.assign([],checkboxlist);

        checkboxes.map((item,idx)=>{
            if(item.id === id){
                item.isChecked = true;
            }
            else{
                item.isChecked = false;
            }
        })
            setCheckboxlist(checkboxes);
    }

    const removeList = (id) => {
        const newList = outerListItem.filter((elem)=> elem.id !== id);
        setOuterListItem(newList);
    }
    const getPlaceHolder =() => {
        if(isSelected===1) return "Search SKU";
        else if(isSelected===2) return "Search Category";
        else if(isSelected===3) return "Search Sub Category";
    }

    const updateProductList = async () => {
        let res = await getProductItems(searchQuery);
        if(res.products.length){
            setProductList(res.products);
        }
        else{
            setProductList([]);
        }
    }

    const updateCategoryList = async () => {
        let res = await getCategoryItems();
        console.log(res);
        if(res.data.length){
            setCategoryList(res.data);
        }
        else{
            setCategoryList([]);
        }
    }

    const updateSubCategoryList = async () => {
        let res = await getSubCategoryItems();
        console.log(res);
        if(res.data.length){
            setSubCategoryList(res.data);
        }
        else{
            setSubCategoryList([]);
        }
    }


    const handleSearchOnKeyPress = (e) => {
        if(isSelected===2 && searchQuery.length >2 ) {
            updateCategoryList();
            setShowList(true);
            
        }
        else if(isSelected===3 && searchQuery.length >2){
            updateSubCategoryList();
            setShowList(true);
        }
        else if(isSelected===1 && searchQuery.length >2){
            updateProductList();
            setShowList(true);
        }
    }

    const handleFormData = (key,value) => {
        let data = Object.assign({}, formData);
        data[key] = value;
        console.log(key,value)
        setFormData(data);
    }

    const getZoneList = async () => {
        let resList = await getZoneData();
        resList.data.forEach((zone,index)=>{
            resList.data[index].is_selected = false;
        })
        setZoneList(resList.data);
    }

    const handleSelZone = (key,value) => {
        let list = Object.assign([], zoneList);
        list[key].is_selected = value;
        setZoneList(list);
        console.log(list);
    }

    const handleSubmitData = async () => {
        let list = Object.assign({},formData);

        if(list.expiry_time!==null)
        list.expiry_time = UTCTimeFormat(startDate);
        else{
         ErrorHandling('Please enter correct date')
        }

        let selZone = zoneList.filter((item) => item.is_selected);


        if(!list.rank){
                ErrorHandling('Please enter Rank');
        }   
        else if(!list.coupon_code){
            ErrorHandling('Please enter coupon code')
        }
        else if(!list.saving_text){
            ErrorHandling('Please enter saving text')
        }
        else if(!list.description){
            ErrorHandling('Please enter description')
        }
        else if(!list.title){
            ErrorHandling('Please enter coupon title')
        }
        else if(!list.order_count){
            ErrorHandling('Please enter order count')
        }
        else if(!list.coupon_type){
            ErrorHandling('Please enter coupon type')
        }
        else if(!list.success_text){
            ErrorHandling('Please enter success text')
        }
        else if(!selZone.length){
            ErrorHandling('Please select any zone')
        }
        else if(!list.tnc){
            ErrorHandling('Please enter terms and conditions')
        }
        else if(!list.expiry_time){
            ErrorHandling('Please enter expiry time')
        }
        else{ 
                if(isSelected===1){
                    let prod_ids = [];
                    outerListItem.map((product)=> prod_ids.push(product.prod_id));           
                    list.is_product = true;
                    list.is_cat = false;
                    list.is_sub_cat = false;
                    list.product = {
                        "data_list" : prod_ids,
                        "absolute_discount" : formData.product_absolute_discount ? formData.product_absolute_discount : 0,
                        "discount_per" : formData.product_discount_per ? formData.product_discount_per : 0,
                        "max_discount" : formData.product_max_discount ? formData.product_max_discount : 0,
                        "min_order_value" : formData.product_min_order_value ? formData.product_min_order_value : 0
                    }
                }
                else if(isSelected===2){
                    let cat_ids = [];
                    outerListItem.map((category)=> cat_ids.push(category.id));
                    list.is_product = false;
                    list.is_cat = true;
                    list.is_sub_cat = false;
                    list.cat = {
                        "data_list" : cat_ids,
                        "absolute_discount" : formData.product_absolute_discount ? formData.product_absolute_discount : 0,
                        "discount_per" : formData.product_discount_per ? formData.product_discount_per : 0,
                        "max_discount" : formData.product_max_discount ? formData.product_max_discount : 0,
                        "min_order_value" : formData.product_min_order_value ? formData.product_min_order_value : 0
                    }
                }
                else if(isSelected===3){
                    let sub_cat_ids = [];
                    outerListItem.map((subcat)=> sub_cat_ids.push(subcat.id))
                    list.is_product = false;
                    list.is_cat = false;
                    list.is_sub_cat = true;
                    list.sub_cat = {
                        "data_list" : sub_cat_ids,
                        "absolute_discount" : formData.product_absolute_discount ? formData.product_absolute_discount : 0,
                        "discount_per" : formData.product_discount_per ? formData.product_discount_per : 0,
                        "max_discount" : formData.product_max_discount ? formData.product_max_discount : 0,
                        "min_order_value" : formData.product_min_order_value ? formData.product_min_order_value : 0
                    }
                }
                let sel_zone = [];
                const order_count = parseInt(list.order_count);
                list.order_count = order_count;
                const coupon_type = parseInt(list.coupon_type);
                list.coupon_type = coupon_type;
                zoneList.map((city)=>{
                    if(city.is_selected){
                        sel_zone.push(city.id);
                    }
                })
                list.pricing_zone = sel_zone;

                list.one_time_use = dropdownText.id;
                let response =  await submitCoupon(list);
        }
        console.log(list)
    }

    useEffect(()=>{
        getZoneList();
    },[])

    return (
        <>
        
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Coupon List</span></div>
            </div> 

            <div className={`${style.addCouponContainer}`}>
            <div className={`${style.addCouponForm}`}>
                   <div className={`${style.couponHeader} flex flex-space-between`}>
                    <div className={`${style.heading}`}>Create Coupon</div>
                    <button onClick={getBackListing} className={`${style.filledBtn}`}>Cancel</button>
                   </div>

             <div className={`${style.firstSection} ${style.section_seperator}`}>
                 
                <div className={`${style.firstRow} flex flex-space-between`}>
                   <div className={`flex`}>
                        <div className={`${style.isActiveCheckbox}`}>
                            <input 
                            type="checkbox" 
                            checked={formData.is_active}
                            onChange={(e) => handleFormData('is_active',!formData.is_active)}
                            />
                            <span>Is Active</span>
                         </div>
                   
                        <div className={`${style.isActiveCheckbox}`}>
                            <input 
                            type="checkbox" 
                            checked={formData.is_private}
                            onChange={(e) => handleFormData('is_private',!formData.is_private)}
                            />
                            <span>Is Private</span>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>Rank</div>
                            <input className={`${style.inputField}`} type='text' value={formData.rank} onChange={(e) => handleFormData('rank',e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className={`${style.secondRow} flex`}>
                     <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>Coupon Code</div>
                            <input className={`${style.inputField} ${style.width50}`} value={formData.coupon_code} onChange={(e) => handleFormData('coupon_code',e.target.value)} placeholder="Coupon Code" type='text'/>
                    </div>
                    <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>Saving Text</div>
                            <input className={`${style.inputField} ${style.width100}`} value={formData.saving_text} onChange={(e) => handleFormData('saving_text',e.target.value)} placeholder="Saving Text" type='text'/>
                    </div>
                </div>

                <div className={`${style.thirdRow}`}>
                    <div className={`${style.width100}`}>
                            <div className={`${style.title}`}>Description</div>
                            <input className={`${style.inputField} ${style.width100}`} value={formData.description} onChange={(e) => handleFormData('description',e.target.value)} placeholder="Description" type='text'/>
                    </div> 
                </div>
                <div className={`${style.fourthRow} flex`}>
                    <div className={`${style.width_30}`}>
                            <div className={`${style.title}`}>Coupon Title</div>
                            <input className={`${style.inputField}`} type='text' value={formData.title} onChange={(e) => handleFormData('title',e.target.value)} placeholder='Coupon Title'/>
                    </div>
                    <div className={`${style.width_30}`}>
                            <div className={`${style.title}`}>Order Count</div>
                            <input className={`${style.inputField}`} type='number' value={formData.order_count} onChange={(e) => handleFormData('order_count',e.target.value)} />
                    </div>
                    <div className={`${style.width_30}`}>
                            <div className={`${style.title}`}>Coupon Type</div>
                            <input className={`${style.inputField}`} type='number' value={formData.coupon_type} onChange={(e) => handleFormData('coupon_type',e.target.value)} />
                    </div>
                    <div className={`${style.width_30}`}>
                            <div className={`${style.title}`}>Success Text</div>
                            <input className={`${style.inputField} ${style.width100}`} value={formData.success_text} onChange={(e) => handleFormData('success_text',e.target.value)} type='text' placeholder='Success Text'/>
                    </div>
                </div>
                <div className={`${style.fifthRow}`}>
                    <div className={`${style.width100}`}>
                            <div className={`${style.title}`}>Term and Condition Text</div>
                            <input className={`${style.inputField} ${style.width100}`} value={formData.tnc} onChange={(e) => handleFormData('tnc',e.target.value)} placeholder="Term and Condition Text" type='text'/>
                    </div> 
                </div>
            </div>
            <div className={`${style.secondSection} ${style.section_seperator}`}>
                    <div className={`${style.mappedAgentDetails} flex flex-space-between`}>
                        <div className={`${style.width_30}`}>
                                <div className={`${style.title}`}>Mapped Agent ID</div>
                                <input className={`${style.inputField}`} type='number' value={formData.mapped_agent_id} onChange={(e) => handleFormData('mapped_agent_id',e.target.value)} />
                        </div>
                        <div className={`${style.width_30}`}>
                                <div className={`${style.title}`}>Mapped Delivery Agent ID</div>
                                <input className={`${style.inputField}`} type='text' value={formData.agent_id} onChange={(e) => handleFormData('agent_id',e.target.value)} placeholder='Delivery Agent ID'/>
                        </div>
                        <div className={`${style.width_30}`}>
                                <div className={`${style.title}`}>Consumer ID</div>
                                <input className={`${style.inputField} ${style.width90}`} value={formData.consumer_ids} type='text' onChange={(e) => handleFormData('consumer_ids',e.target.value)} placeholder='Consumer ID'/>
                        </div>           
                    </div>
                    <div className={`${style.newUserCheck} flex`}>
                            <div className={`${style.width_30}`}>
                                <div className={`${style.title}`}>Only for new user</div>
                                <div className='flex flex-space-between'>
                                    <div className='flex'>                              
                                        <input type="radio" name='truefalse' className={`${style.checkbox}`} defaultChecked/>
                                        <span className={`${style.checkbox_title}`}>True</span>
                                    </div>
                                    <div className='flex'>                              
                                        <input type="radio" name='truefalse' className={`${style.checkbox}`}/>
                                        <span className={`${style.checkbox_title}`}>False</span>
                                    </div>
                                </div>
                            </div>

                            <div className={`${style.width50}`} style={{marginLeft:"5rem"}}>
                                <div className={`${style.title}`}>One time use</div>
                                
                                <Dropdown 
                                    header_width={'12rem'}
                                    list={OneTimeUseDropdown}
                                    name={dropdownText}
                                    getDropDownValue={(elem)=>{
                                        setDropdownText(elem);
                                    }}/>
                            </div>
                            <div className={`${style.width50}`}>
                                    <div className={`${style.title}`}>Expiry Time</div>
                                    <div className={`${style.calendar}`}>
                                    <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            className={style.custom_date_picker}
                                    />
                                    </div>
                            </div>
                    </div>
            </div>
            <div className={`${style.thirdSection} ${style.section_seperator}`}>
                    <div className={`${style.order_banner_select_zone_heading}`}>
                            <img
                            width="24px"
                            height="24px"
                            src={LocationIcon}
                            style={{ marginBottom: "-9px", marginRight: "7px" }}
                            />
                            <span className={`${style.zone_heading}`}>Select Zones</span>
                    </div>
                    <div className={`${style.zone_listing} flex flex-wrap`}>
                           {
                                zoneList.map((elem,index)=>{
                                    return(
                                        <div key={elem.id} className={`${style.width_25} ${style.checkboxDiv}`}>
                                            <input
                                             type="checkbox"
                                             className={`pointer ${style.checkbox}`}
                                             onChange={() => {handleSelZone(index,!elem.is_selected)}}
                                             checked={elem.is_selected}
                                             />
                                            <span>{elem.name}</span>
                                        </div>
                                    );
                                })
                           }
                    </div>
            </div>
            <div className={`${style.fourthSection}`}>
                    <div className={`flex flex-space-between`} style={{width:"70%"}}>
                        {
                            checkboxlist.map((elem,idx)=>{
                                return(
                            <div key={idx} className={`${style.isActiveCheckbox}`}>
                                    <input 
                                    type="checkbox" 
                                    checked = {elem.isChecked}
                                    onChange={()=>{
                                        setSelected(elem.id);
                                        makeCheckFalse(elem.id);
                                        setSearchQuery("");
                                        setShowList(false);
                                        setShowOuterList(false);
                                        setOuterListItem([]);
                                        if(elem.id===2){
                                            setProductList([]);
                                            setSubCategoryList([]);
                                        }
                                        else if(elem.id===1){
                                            setCategoryList([]);
                                            setSubCategoryList([]);
                                        }
                                        else if(elem.id===3){
                                            setProductList([]);
                                            setCategoryList([]);
                                        }
                                    }}
                                    />
                                    <span>{elem.name}</span>
                            </div>
                                );
                            })
                        }
                    </div>
                    <div className={`${style.conditionalRow} flex`}>
                                <div className={`${style.width_30}`}>
                                    <div className={`${style.title}`}>Minimum Order Value</div>
                                    <input className={`${style.inputField}`} type='number' value={formData.product_min_order_value} onChange={(e) => handleFormData('product_min_order_value',e.target.value)} placeholder='Minimum Order Value'/>
                                </div>
                                <div className={`${style.width_30}`}>
                                    <div className={`${style.title}`}>Max Discount</div>
                                    <input className={`${style.inputField}`} type='number' value={formData.product_max_discount} onChange={(e) => handleFormData('product_max_discount',e.target.value)} placeholder='Max Discount'/>
                                </div>
                                <div className={`${style.width_30}`}>
                                    <div className={`${style.title}`}>Absolute Discount Amount</div>
                                    <input className={`${style.inputField}`} type='number' value={formData.product_absolute_discount} onChange={(e) => handleFormData('product_absolute_discount',e.target.value)} placeholder='Absolute Discount Amount' />
                                </div>
                                <div className={`${style.width_30}`}>
                                    <div className={`${style.title}`}>Discount Percentage</div>
                                    <input className={`${style.inputField} ${style.width100}`} value={formData.product_discount_per} onChange={(e) => handleFormData('product_discount_per',e.target.value)} type='number' placeholder='Discount Percentage'/>
                                </div>
                            </div>
                    
                        <div className={`${style.searchContainer}`}>                           
                            <div className={`${style.conditionalTitle}`}>
                                {isSelected===1 ? 'Product ' : null}
                                {isSelected===2 ? 'Category ' : null} 
                                {isSelected===3 ? 'Sub Category ' : null}List
                            </div>
                            <div className={`${style.mrg_x_6}`}>
                                 <div className={`flex`} style={{marginLeft:"0.8rem"}}>
                                    <img className={`${style.searchIcon}`} src={SearchIcon} />
                                    <input
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className={`${style.searchInput}`}
                                        placeholder={getPlaceHolder()}
                                        onKeyUp={(e) => handleSearchOnKeyPress(e)}
                                    />
                                </div>
                                {
                                    showList && isSelected===1 &&
                                    <ul className={`${style.searchList}`}>
                                        {
                                            productList.filter((elem)=>{
                                                if(searchQuery===""){
                                                    return elem;
                                                }
                                                 if(elem.name.toLowerCase().includes(searchQuery.toLowerCase())){
                                                    return elem;
                                                }
                                            }).map((elem,idx)=>{
                                                return <li key={idx}
                                                onClick={()=>{
                                                    toggleList();
                                                    let list = Object.assign([],outerListItem);
                                                    list.push(elem);
                                                    console.log(list);
                                                    setOuterListItem(list);
                                                    setShowOuterList(true);
                                                }}
                                                >
                                                    [{elem.id}] {elem.name} 
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                                {
                                    showList && isSelected===2 && 
                                    <ul className={`${style.searchList}`}>
                                        {
                                            categoryList.filter((elem)=>{
                                                if(searchQuery===""){
                                                    return elem;
                                                }
                                                else if(elem.name.toLowerCase().includes(searchQuery.toLowerCase())){
                                                    return elem;
                                                }
                                            }).map((elem,idx)=>{
                                                return <li key={idx} 
                                                onClick={()=>{
                                                    toggleList();
                                                    let list = Object.assign([],outerListItem);
                                                    list.push(elem);
                                                    console.log(list);
                                                    setOuterListItem(list);
                                                    setShowOuterList(true);
                                                }}
                                                >
                                                [{elem.id}] {elem.name}
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                                {
                                    showList && isSelected===3 && 
                                    <ul className={`${style.searchList}`}>
                                        {
                                            subCategoryList.filter((elem)=>{
                                                if(searchQuery===""){
                                                    return elem;
                                                }
                                                else if(elem.name.toLowerCase().includes(searchQuery.toLowerCase())){
                                                    return elem;
                                                }
                                            }).map((elem,idx)=>{
                                                return <li key={idx} 
                                                onClick={()=>{
                                                    toggleList();
                                                    let list = Object.assign([],outerListItem);
                                                    list.push(elem);
                                                    console.log(list);
                                                    setOuterListItem(list);
                                                    setShowOuterList(true);
                                                }}
                                                >
                                                [{elem.id}] {elem.name}
                                                </li>
                                            })
                                        }
                                    </ul>
                                }
                            </div>
                            {
                                showOuterList &&
                                <ul className={`${style.outerList}`}>
                                    {
                                        outerListItem.map((item,idx)=>{
                                            return(
                                                <li key={idx} className={`${style.outerItemList}`}>
                                                    
                                                    <div><span style={{paddingRight:"2.4rem"}}>{idx+1}</span> [{item.id}] {item.name}</div>
                                                    <div onClick={()=>removeList(item.id)}><img src={DeleteIcon}/></div>
                                                </li>
                                            );
                                        })
                                    }
                                </ul>
                            }

                        </div>
                    <div className={`${style.submit}`}>
                           <button className={`${style.filledSubmitBtn}`} onClick={handleSubmitData}>Submit</button>
                    </div>
                    
            </div>
            </div>
            </div>
        
        </>
    );
}

export default AddCoupon;