import React from 'react';
import CutOffList from './components/CutOffList';
import Header from './components/Header';

function index(props) {
    return (
        <div>
            <Header/>
            <CutOffList/>
        </div>
    );
}

export default index;