import React, { useState } from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { ErrorHandling } from '../../../../State';
import { updateUserSegmentList } from '../../../../utils/api';

function UserSegmentPopUp(props) {
    const [segName,setsegName]=useState(props.userSegment);
    const [description,setDescription]=useState(props.description);
    const [markDownPercent,setMarkDownPercent]=useState(props.mark_down_percent);

    const handleSubmit = async () => {
        if (!segName || segName.trim()==="") {
            ErrorHandling('Please enter the segment name')
        } 
        else if(!description || description.trim()===""){
            ErrorHandling('Please enter the segment description')
        }
        else if(markDownPercent==="" || markDownPercent===undefined || markDownPercent < 0){
            ErrorHandling('Please enter a valid markdown')
        }
        else{
            var data = {
                segment_name : segName,
                description : description,
                mark_down : markDownPercent         
            } 
            if (props.id) {
                data.id = props.id
            }
            console.log(data);
            await updateUserSegmentList(data);
            await props.fetchData();
            props.closeBtn(false);
        }
    }

    return (
        <PopUp
        title={
            props.userSegment ? 'Update Segment' : 'Add Segment'
        }
        width={`${formStyle.popupWidthXL}`}
        closeBtn={() => props.closeBtn(false)}
    >
        <div className={`${formStyle.inputContainer}`}>
            <div className={`${formStyle.popup_title}`}>
                 <div className={`${formStyle.width_30}`}>User Segment</div>
                 <div className={`${formStyle.width_40}`}>Description</div>
                 <div className={`${formStyle.width_30}`}>Mark Down %</div>
            </div>
            <div className={`${formStyle.popup_body}`}>
                 <div className={`${formStyle.width_30}`}>
                  <input type="text" value={segName} placeholder="User Segment Name" onChange={(e)=>setsegName(e.target.value)} className={`${formStyle.inputField}`}/>
                 </div>
                 <div className={`${formStyle.width_40}`}>
                 <textarea type="text" cols="30" rows="1" placeholder="Description" onChange={(e)=>setDescription(e.target.value)}>{description}</textarea>
                 </div>
                 <div className={`${formStyle.width_30}`}>
                    <input type="number" value={markDownPercent} placeholder="Mark down price" onChange={(e)=>setMarkDownPercent(e.target.value)} className={`${formStyle.inputField}`}/>
                 </div>
                 
            </div>
        </div>
        <div className={`${formStyle.btnContainer}`}>
            <button className={`${formStyle.confirmBtn}`} onClick={handleSubmit}>
                {
                    props.userSegment ? 'Update' : 'Add'
                }
            </button>
        </div>

    </PopUp>
    );
}

export default UserSegmentPopUp;