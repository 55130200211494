import React, { useEffect, useState } from "react";
import { getSearchedOffer } from "../../../../utils/api";
import PopUp from "../../../../commonComponent/PopUp/PopUp";
import formStyle from "../../../../commonComponent/PopUp/Form.module.css";
import popupStyle from "../../../../commonComponent/PopUp/Popup.module.css";

function PopupAddOffer(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [list, setList] = useState([]);
  const [idList, setIdList] = useState([]);

  const searchOffer = async () => {
    let list = await getSearchedOffer(searchQuery);
    setList(list);
  };

  const AddSku = (sku, isChecked) => {
    if (isChecked === true) {
      let list = idList;
      let obj = { is_checked: isChecked, id: sku.id };
      list.push(obj);
      setIdList([...list]);

      props.addSkuForDeal(sku);
    } else {
      let list = idList.filter((item) => item.id !== sku.id);
      setIdList([...list]);
      props.removeSkuForDeal(sku.id);
    }
  };

  useEffect(() => {

    searchOffer();
    setIdList([...props.list]);

    document.getElementById("searchInput").focus();
  }, [searchQuery]);

  const checkCheckedBox = (id) => {
    let filterList =
      props.list.filter((item) => {
        return item.id === id;
      }) || [];

    return filterList?.length > 0;
  };

  return (
    <PopUp
      title={`Add Offer for ${props.dealType}`}
      width={`${formStyle.popupWidthM}`}
      closeBtn={props.closeBtn}
    >
      {idList.length > 0 && (
        <div className={`${popupStyle.heading} textLeft`}>
          {idList.map((sku) => {
            return <span>{sku.id}, </span>;
          })}
        </div>
      )}
      <div className={`${formStyle.inputContainer}`}>
        {/* input */}
        <div>
          <input
            className={`${formStyle.searchBar} ${formStyle.fullwidth}`}
            id={"searchInput"}
            type={"text"}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={"Search segment"}
          />
        </div>
        {/* list */}
        <div
          className={`${formStyle.overlay_36} ${formStyle.custom_scrollbar}`}
        >
          {list?.map((sku) => {
            return (
              <div
                className={` ${
                  sku.status == 1
                    ? formStyle.greenBackgroundColor
                    : formStyle.redBackgroundColor
                }  ${formStyle.sku_list}`}
                key={sku.id}
              >
                <div
                  className={`${formStyle.flex_row} ${formStyle.justify_center}`}
                >
                  <div className={`${formStyle.flex_row}  ${formStyle.flex2}`}>
                    <div className={formStyle.flex_col}>
                      <input
                        className="pointer"
                        type="checkbox"
                        checked={checkCheckedBox(sku.id)}
                        onChange={(e) => AddSku(sku, e.target.checked)}
                        disabled={sku.status !== 1}
                      />
                    </div>
                    <div className={`${formStyle.flex_col}`}>
                      <strong>id: {sku.id}</strong>
                      <strong>Name: {sku.offer_name}</strong>
                    </div>
                  </div>
                  <div className={`${formStyle.flex1} `}>
                    <strong>tag: {sku.offer_tag}</strong>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PopUp>
  );
}

export default PopupAddOffer;
