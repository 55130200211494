import React from 'react';
import BurnMappingList from './components/BurnMappingList';
import Header from './components/Header';
import UserSegmentList from './components/UserSegmentList';
function index(props) {
    return (
        <div>
            <Header/>
            {/* <BurnMappingList/>
            <UserSegmentList/> */}
        </div>
    );
}

export default index;