import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import style from '../css/Subscription.module.css'
import LocationIcon from "../../../../icons/location.svg";
import { createSubscriptionBanner, getOrderBannerZonewiseList, getSubscriptionTopBanner, updateSubscriptionBanner, uploadBannerImage } from '../../../../utils/api';
import { SubscriptionImgUrl } from '../../../../utils/config';
import { ErrorHandling, SuccessHandling } from '../../../../State';

export default function AddTopBanner() {
    const { status, zoneid, id } = useParams()
    const navigate = useNavigate()
    const [zoneList, setZoneList] = useState([]);
    const [isAllZoneSelected, setIsAllZoneSelected] = useState(false);
    const [selectedZoneList, setSelectedZoneList] = useState([]);
    const [images, setImages] = useState({ image_id: '' })
    const [data, setData] = useState({
        image_id: '',
        status: 1,
        pricing_zone_list: []
    })

    const selectZone = (zoneElement) => {
        console.log(zoneElement);
        var all_zone_data = [...zoneList]
        let selectedZones = [...selectedZoneList]
        if (zoneElement.target.checked) {
            const updatedZoneList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = true;
                    selectedZones.push(zone.id);
                }
                return zone;
            });
            if (selectedZones.length === zoneList.length) {
                setIsAllZoneSelected(true);
            }

            setSelectedZoneList(selectedZones)
            setZoneList(updatedZoneList)

        }

        else {
            const updatedList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = false;
                }
                return zone;
            });

            selectedZones = selectedZoneList.filter((zone) => zone !== parseInt(zoneElement.target.id))
            setSelectedZoneList(selectedZones)
            setZoneList(updatedList)
            setIsAllZoneSelected(false);
        }

        let obj = data;
        obj.pricing_zone_list = selectedZones;
        setData({ ...obj })
    }

    const selectAllZone = (event) => {
        let selected_zones = []
        if (event.target.checked) {
            const pricing_zones = zoneList.map(zoneElement => {
                zoneElement.is_selected = true;
                selected_zones.push(zoneElement.id)
                return zoneElement;

            })
            setSelectedZoneList(selected_zones);
            setIsAllZoneSelected(true);
            setZoneList(pricing_zones)

        } else {
            const pricing_zone = zoneList.map(zoneElement => {
                zoneElement.is_selected = false;
                return zoneElement;

            })
            setSelectedZoneList([]);
            setIsAllZoneSelected(false);
            setZoneList(pricing_zone)

        }

        let obj = data;
        obj.pricing_zone_list = selected_zones;
        setData({ ...obj })
    }

    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    const saveFile = (e) => {
        let obj = images;
        obj[e.target.name] = e.target.files[0];
        setImages({ ...obj });
    }

    const validateBanner = async () => {
        if (data.pricing_zone_list.lenght < 0) {
            ErrorHandling('Please Select The Zone');
        } else if (data.image_id === '') {
            ErrorHandling('please upload the image');
        } else {
            if (status === 'edit') {
                let res = await updateSubscriptionBanner(data);
                if (res.success) {
                    SuccessHandling('Subscription top banner updated successfully')
                    navigate(`/subscription-top-banner/`)
                } else {
                    ErrorHandling(res.error.message);
                }
            } else {
                let res = await createSubscriptionBanner(data);
                if (res.success) {
                    SuccessHandling('Subscription top banner created successfully')
                    navigate(`/subscription-top-banner/`)
                } else {
                    ErrorHandling(res.error.message);
                }
            }
        }
    }

    const uploadImage = async (e) => {
        console.log(e.target.id);
        var image_type = 'subscription';
        let Imgdata = new FormData();
        Imgdata.append('file', images[e.target.id]);
        Imgdata.append('image_type', image_type)
        if (images.image_id === '') {
            ErrorHandling('please select the image first');
        } else { 
            let res = await uploadBannerImage(Imgdata)
            let obj = data;
            obj[e.target.id] = res.image_id;
            console.log(obj);
            setData({ ...obj })
        }
    }

    const fetchData = async () => {
        // for gettig the zone list
        let resListing = await getOrderBannerZonewiseList();


        if (status === "edit") {
            let obj = {};
            obj.image_id = localStorage.getItem('image_id');
            obj.id = id;
            obj.pricing_zone_list = [parseInt(zoneid)];
            obj.status = 1;
            setData({ ...obj })
            setImages({ image_id: data.image_id });
            resListing.data.map((zone) => {
                if (zone.id === parseInt(zoneid)) {
                    zone.is_selected = true;
                }
            })
            console.log(resListing);
            setZoneList(resListing.data);

        }
        else {
            let res = await getSubscriptionTopBanner();
            resListing.data.forEach((zone) => {
                res.data.map(item => { 
                    if (zone.id === item.pricing_zone_id) { 
                        zone.is_already_active = true;
                    }
                })
            })
            console.log(resListing.data);
            setZoneList(resListing.data);

        }
    }



    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div>
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>Subscription Top Banner</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>Subscription Top Banner</div>

                <div className={`${style.addContainer}`}>


                    {/* dropdowns - content type, landing screen */}
                    <div className={`flex flex-space-around`} style={{flexWrap:'wrap'}}>
                        {<div className={``}>
                            {/* zone listing header*/}
                            <div className={`${style.mrg_top_3}`}>
                                <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                                <span className={`${style.zone_heading}`}>Select Zones</span>
                            </div>

                            {/* zone listing check boxes */}
                            <div className={`${style.zone_container}`}>

                                {/* zone check boxes */}
                                <label onClick={() => { }} className={`${style.list_item}`}>
                                    <input type="checkbox" disabled={status === 'edit'} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.checkbox}`} checked={isAllZoneSelected} onChange={(e) => { selectAllZone(e) }} />
                                    <span className={`${style.checkmark}`}>All</span>
                                </label>

                                {
                                    zoneList.map((city, index) => {
                                        return (
                                            <div key={city.id} onClick={() => { }} className={`${style.list_item}`}>
                                                <input type="checkbox" disabled={status === 'edit'} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.checkbox}`} id={city.id} checked={city.is_selected} onChange={(e) => { selectZone(e) }} />
                                                <span className={`${city.is_already_active && city.is_selected ? 'red' : ''} ${style.checkmark}`}>{city.name}</span>
                                                <span className={`${style.mrg_y_6} red`}>{city.is_already_active && city.is_selected && ` - Banner is already active for this zone `}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>}


                        <div className={`textCenter`}>
                            <div className={`${style.mrg_x_6}`}>
                                <div className={`${style.title}`}>Upload Banner</div>
                                <input type="file" name="image_id" id="image_id" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                <img src={data.image_id ? SubscriptionImgUrl + data.image_id : null} className={`${style.offer_type_img}`} onClick={() => { uploadImg("image_id"); }} />
                                <div>
                                    <div className={`fonts ${style.mrg_y_6} ${images.image_id !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{images.image_id ? images.image_id.name : 'please select the image'}</div>
                                    <span className={`fontS green ${style.mrg_x_6}`}> ASPECT RATIOS SHOULD BE (750 x 150)px </span>
                                    <button id="image_id" className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                </div>
                            </div>



                        </div>


                    </div>



                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => navigate(`/subscription-top-banner/`)} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={() => { validateBanner() }}>
                            {status === 'edit' ? 'Update' : 'Save'}
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>

        </div>
    )
}
