import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css'

function PopupZoneBurnError({closeBtn, burnErrorList}) {
    return (
        <PopUp
            title={`Errors`}
            width={`${formStyle.popupWidthM}`}
            closeBtn={closeBtn}
        >
            <div className={` textLeft ${formStyle.inputContainer}`}>
                <div className={`grid ${formStyle.feed_detail_grid} ${formStyle.thead}`}>
                    <div className={`${formStyle.pd_cell}`}>Zone Name</div>
                    <div className={`${formStyle.pd_cell}`}>Zone Burn</div>
                    <div className={`${formStyle.pd_cell}`}>Max Burn User</div>
                    <div className={`${formStyle.pd_cell}`}>Mkt Cost Diff</div>
                </div>
                <div className={`${formStyle.overlayDropdown_12}`}>
                    {
                        burnErrorList.map((zoneDetail) => {
                            return(
                                <div className={`grid ${formStyle.feed_detail_grid} ${formStyle.brdBottom}`} key={zoneDetail.zone_id}>
                                    <div className={`${formStyle.pd_cell}`}>({zoneDetail.zone_id}) {zoneDetail.zone_name}</div>
                                    <div className={`${formStyle.pd_cell}`}>{zoneDetail.sum_mkt_cost}</div>
                                    <div className={`${formStyle.pd_cell}`}>{zoneDetail.max_burn_user}</div>
                                    <div className={`${formStyle.pd_cell}`}>{zoneDetail.mkt_cost_diff}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </PopUp>
    );
}

export default PopupZoneBurnError;