import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addNewOrderBanner, getCatList, getOrderBannerListing, getOrderBannerTypeList, getOrderBannerZonewiseList, toggle, updateOrderBanner, uploadSelImage } from "../../../../utils/api";
import Dropdown from "../../../../commonComponent/Dropdown/Dropdown";
import Loader from "../../../../commonComponent/Loader/Loader";
import { landingPageList } from "../../../../utils/constData";
import style from "../css/OrderSuccess.module.css";
import LocationIcon from "../../../../icons/location.svg";
import { BaseImgUrl } from "../../../../utils/config";
import { ErrorHandling } from "../../../../State";
import Header from "../../../../commonComponent/Header/Header";

function OrderBanner(props) {
  const {status, banner_id, selZone} = useParams()
  const navigate = useNavigate()

  const [screen, setScreen] = useState({
    selZone: landingPageList[0],
    optField: {
      show: false,
      type: null,
      list: []
    }
  });
  const [zoneList, setZoneList] = useState([]);
  const [bannerTypeList, setBannerTypeList] = useState([]);
  const [file, setFile] = useState("");
  const [secondFile, setSecondFile] = useState("");
  const [selectedBanner, setSelectedBanner] = useState({
    action_data: "",
    additional_action_data: "0",
    banner_id: 0,
    banner_type: 0,
    banner_text: "Select Banner Type",
    image: "",
    is_active: false,
    is_all_selected: false,
    pricing_zone_id: "",
    small_image: "",
    selAdditonalDropDown: {} 
  })
  const [isLoading, setLoader] = useState(false);

  // handle submit data
  const handleSubmit = async() => {

    // edit order banner checks
    if(status == "edit") {

      if(
        (screen.selZone.value === "PRODUCT" || screen.selZone.value === "DEAL")
        && selectedBanner.additional_action_data == 0 || selectedBanner.additional_action_data === ""
      ){
        ErrorHandling("please enter a valid prod id")
      }
      else{

        // data creation
        let contendId = '0';
        if(screen.selZone.value === "PRODUCT" || screen.selZone.value === "DEAL"){
          contendId = selectedBanner.additional_action_data;
        }
        else if(screen.selZone.value === "CATEGORY"){
          contendId = selectedBanner.selAdditonalDropDown.id;
        }
        let data = {
          action_id: screen.selZone.value,
          additional_action_data: contendId,
          banner_id: selectedBanner.banner_id,
          image: selectedBanner.image,
          is_active: selectedBanner.is_active,
          pricing_zone_id: selectedBanner.pricing_zone_id,
          small_image: selectedBanner.small_image,
          banner_type: selectedBanner.banner_type,
        }
        console.log(data)
        setLoader(true)
        await updateOrderBanner(data)
        setLoader(false)

        navigate('/order-success-banner/');
      }
    }
    // new order banner checks
    else {
      let selZoneList = zoneList.filter(item => item.is_selected === true);
      console.log(selZoneList)
      if(
        (screen.selZone.value === "PRODUCT" || screen.selZone.value === "DEAL")
        && selectedBanner.additional_action_data == 0 || selectedBanner.additional_action_data === ""
      ){
        ErrorHandling("please enter a valid prod id")
      }
      else if (selZoneList.length === 0){
        ErrorHandling("Please Select zones")
      }
      else if( !selectedBanner.small_image || !selectedBanner.image){
        ErrorHandling("Please upload images");
      }
      else{
        selZoneList.map( async(zone) =>{
          // data creation
          let contendId = '0';
          if(screen.selZone.value === "PRODUCT" || screen.selZone.value === "DEAL"){
            contendId = selectedBanner.additional_action_data;
          }
          else if(screen.selZone.value === "CATEGORY"){
            contendId = selectedBanner.selAdditonalDropDown.id;
          }
          let data = {
            action_id: screen.selZone.value,
            additional_action_data: contendId,
            image: selectedBanner.image,
            is_active: selectedBanner.is_active,
            pricing_zone_id: zone.id,
            small_image: selectedBanner.small_image,
            banner_type: selectedBanner.banner_type,
          }
          
          setLoader(true)
          await addNewOrderBanner(data)
          setLoader(false)

          navigate('/order-success-banner/');
        })
      }
    }

  }

  // handle upload image
  const handleUploadImage = async(id) => {
    let image = document.getElementById(id).files[0];
    console.log(image);

    var image_type = 'images';
    let data = new FormData();
    data.append('file', image);
    data.append('image_type', image_type)

    setLoader(true)
    let res = await uploadSelImage(data);
    setLoader(false)

    if(res && id === "firstImg"){
        let selBanner = Object.assign({}, selectedBanner)
        selBanner.image = res
        setFile(BaseImgUrl+res);
        setSelectedBanner(selBanner)
    }
    else if(res && id === "secondImg"){
        let selBanner = Object.assign({}, selectedBanner)
        selBanner.small_image = res
        setSecondFile(BaseImgUrl+res);
        setSelectedBanner(selBanner)
    }
  }

  // handle zone listigng data
  const handleSelZone = (key, value) => {
    // debugger;
    if(key === "is_all_selected"){
      // for 'all' zone
      let selBanner = Object.assign({}, selectedBanner)
      selBanner.is_all_selected = value;

      // if 'is_all_selected is set to be true
      // setting all zones to be selected

      let list = Object.assign([], zoneList)
      list.forEach((elem, index) => 
        list[index].is_selected = value
      )
      setZoneList(list);

      setSelectedBanner(selBanner);
    }
    else{
      // for individual selection of zones 
      let selBanner = Object.assign({}, selectedBanner)

      // if unselecting a zone and is all is selected
      if(!value) {
        selBanner.is_all_selected = false;
      }

      // editing the 'is_selected' for zone listing
      let list = Object.assign([], zoneList)
      list[key].is_selected = value;

      // checking weather all zones are selected
      let selZones = list.filter(elem => elem.is_selected === true);
      if(selZones.length === list.length){
        selBanner.is_all_selected = true;
      }

      setZoneList(list);
      setSelectedBanner(selBanner);
    }
  }

  const handleChange = (e) => {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange2 = (e) => {
    console.log(e.target.files);
    setSecondFile(URL.createObjectURL(e.target.files[0]));
  };

  const uploadImg = (id) => {
    let img = document.getElementById(id);
    img.click();
  };

  // handle edit / add data
  const handleBannerChange = (key, value) => {
    let localBanner = Object.assign({},selectedBanner);

    if(key === "banner_type"){
      localBanner.banner_type = value.id;
      localBanner.banner_text = value.name;
      setSelectedBanner(localBanner);
      return;
    }

    localBanner[key] = value;
    console.log(key, value);

    if(key !== "landing_page"){
      setSelectedBanner(localBanner)
    }
    else{
      handleOptField(localBanner, value)
    }

  }

  // handle listing, selected banner 
  const fetchData = async() => {
    // for gettig the zone list
    setLoader(true)
    let resListing = await getOrderBannerZonewiseList();
    let bannerListing =  await getOrderBannerTypeList()
    setLoader(false)

    setBannerTypeList(bannerListing);

    if(status === "edit"){
      // for fetching the selected banner 
      const type = JSON.parse(localStorage.getItem('bannerType') )

      setLoader(true)
      const res = await getOrderBannerListing(selZone, type.id);
      setLoader(false)
      
      let selBanner = res.data.filter((banner) => banner.banner_id == banner_id)
      selBanner = selBanner[0];

      // setting selected zones isSelected true for 'zoneList'
      resListing.data.forEach((elem, index) => {
        resListing.data[index].is_selected = false;
        if(elem.id == selBanner.pricing_zone_id){
          resListing.data[index].is_selected = true;
        }
      });
      selBanner.is_all_selected = false;
      setZoneList(resListing.data)

  
      // set image links - big image 
      setFile(BaseImgUrl+selBanner.image)
  
      // set image links- width greater image
      setSecondFile(BaseImgUrl+selBanner.small_image)
      
      // getting the selected landing page
      let selLandingPage = landingPageList.filter(elem => elem.value === selBanner.action_data)
  
      // optional field funX
      handleOptField(selBanner, selLandingPage[0])
    }
    else {
      // as no selected banner is present, so new templete for new banner
      resListing.data.map((elem, index) => {
        resListing.data[index].is_selected = false;
      })
      setZoneList(resListing.data)

      let selBanner = Object.assign({}, selectedBanner);

      // setting banner type
      selBanner.banner_text = bannerListing[0].name;
      selBanner.banner_type = bannerListing[0].id;

      handleOptField(selBanner, landingPageList[0])
    }
  }

  // handle optional field data
  const handleOptField = async (selBanner, selLandingPage) => {
    let optionalfield =  {
      selZone: selLandingPage,
    };
    if(selLandingPage.value === "PRODUCT" || selLandingPage.value ==="DEAL" ){
      optionalfield.optField = {
        show: true,
        type: 'number',
        list: [],
      }
    }
    else if(selLandingPage.value === "CATEGORY" ){

      setLoader(true)
      let list = await getCatList()
      setLoader(false)

      optionalfield.optField = {
        show: true,
        type: 'dropdown',
        list: list,
      }
      let selCat = list.filter((item) => item.id == selBanner.additional_action_data)
      if(selCat.length){
        selBanner.selAdditonalDropDown =  selCat[0];
      }
      else {
        selBanner.selAdditonalDropDown =  list[0];
      }
    }
    else{
      optionalfield.optField = {
        show: false,
        type: null,
        list: [],
      }
    }
    setScreen(optionalfield)
    setSelectedBanner(selBanner);
  }

  useEffect(() =>{
    fetchData()
  }, [])

  return (
    <>
      {/* header */}
      <Header title={`${status === 'new' ? "Add" : "Edit"} Order Success Banner`}/>

      {/* sub heading */}
      <div className={`${style.subHeading}`}>{status === 'new' ? "Add" : "Edit"} Order Success Banner</div>

      {/* listing */}
      <div className={`${style.whiteContainer}`}>

        {/* input | image | zones | dropdowns */}
        <div className={`flex flex-space-between`}>

          {/* left section - input section*/}
          <div className={`${style.width40}`}>

            {/* isActive check | banner type*/}

            <div className={`flex-end flex-space-between`}>
              
              {/* isActive check */}
              <label className={`pointer`}>
                <div className={`${style.isActiveCheckbox}`}>
                  <input 
                    type="checkbox" 
                    width="20px" 
                    height="20px" 
                    checked={selectedBanner.is_active} 
                    onChange={(e) => {handleBannerChange('is_active', !selectedBanner.is_active)}}
                  />
                  <span>Is Active</span>
                </div>
              </label>

              {/* banner type*/}
              <div className={`${style.dropdown} ${style.dropdown_menu} ${style.mrg_x_6}`}>
                <span className={`${style.land_page}`}>Banner Type</span>
                <Dropdown 
                  height={'8rem'}
                  list={bannerTypeList}
                  disabled={status !== 'new'}
                  name={{id: selectedBanner.banner_type, name: selectedBanner.banner_text}}
                  getDropDownValue={(elem)=>{
                    handleBannerChange('banner_type', elem)
                  }}
                />
              </div>

            </div>

            {/* zone listing */}
            <div className={`${style.mrg_tp_3}`}>
              <img
                width="24px"
                height="24px"
                src={LocationIcon}
                style={{ marginBottom: "-9px", marginRight: "7px" }}
              />
              <span className={`${style.zone_heading}`}>Select Zones</span>
            </div>
            <div className={`${style.order_banner_zone_list}`}>
            
            {/* zone check boxes */}
            <label onClick={() => {}} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.list_item}`}>
              <input 
                type="checkbox" 
                onChange={() => {handleSelZone("is_all_selected", !selectedBanner.is_all_selected)}}
                className={` ${style.checkbox}`} 
                checked={selectedBanner.is_all_selected}
                disabled={selectedBanner.banner_id}
              />
              <span className={`${style.checkmark}`}>All</span>
            </label>
              {
                zoneList.map((city, index) => {
                  return(
                    <label key={city.id} onClick={() => {}} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.list_item}`}>
                      <input 
                        type="checkbox" 
                        onChange={() => {handleSelZone(index, !city.is_selected)}}
                        className={` ${style.checkbox}`} 
                        checked={city.is_selected}
                        disabled={selectedBanner.banner_id}
                      />
                      <span className={`${style.checkmark}`}>{city.name}</span>
                    </label>
                  )
                })
              }
            </div>

            {/* landing page */}
            <div className={`flex-end flex-space-between`}>
              <div
                className={`${style.dropdown} ${style.dropdown_menu} ${style.mrg_x_6}`}
              >
                <span className={`${style.land_page}`}>Landing Page</span>
                <Dropdown 
                  height={'8rem'}
                  list={landingPageList.filter((landingPage) => landingPage.value !== 'WEBV')}
                  name={screen.selZone}
                  getDropDownValue={(elem)=>{
                    handleBannerChange('landing_page', elem)
                  }}/>
              </div>
              {
                screen.optField.show && screen.optField.type === "number" ?
                  <div style={{width: "200px"}}>
                    <span className={`${style.land_page}`}>Please Enter {screen.selZone.value === "PRODUCT" ? 'Prod id' : 'Banner Id'} </span>
                    <input 
                      type="number" 
                      value={selectedBanner.additional_action_data} 
                      onChange={(e) => handleBannerChange('additional_action_data' ,e.target.value)}
                      className={`${style.inputField}`}
                    /> 
                  </div>
                : null
              }
              {
                screen.optField.show && screen.optField.type === "dropdown" ?
                  <div
                    className={`${style.dropdown} ${style.dropdown_menu} ${style.mrg_x_6}`}
                  >
                    <span className={`${style.land_page}`}>Select category</span>
                    <Dropdown 
                      height={'8rem'}
                      list={screen.optField.list}
                      name={selectedBanner.selAdditonalDropDown}
                      getDropDownValue={(elem)=>{
                        handleBannerChange('selAdditonalDropDown', elem)
                      }}/> 
                  </div>
                : null
              }
            </div>
          </div>

          {/* Right section - image section */}
          <div className={`${style.width50}`}>
            <div className={`${style.uploadImg}`}>
              <div className={`bold ${style.title_banner}`}>
                Aspect ratio should be (632 x 300)px
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                name="first"
                id="firstImg"
                accept="image/*"
                onChange={handleChange}
              />
              <img
                src={file}
                className={`pointer ${style.imgContainer}`}
                onClick={() => {
                  uploadImg("firstImg");
                }}
              />
              <button onClick={() => handleUploadImage('firstImg')} className={`${style.uploadBtn}`}>Upload</button>
            </div>
            <div className={`${style.uploadImg}`}>
              <div className={`bold ${style.title_banner}`}>
                Aspect ratio should be (632 x 120)px
              </div>
              <input
                type="file"
                style={{ display: "none" }}
                name="first"
                id="secondImg"
                accept="image/*"
                onChange={handleChange2}
              />
              <img
                src={secondFile}
                className={`pointer ${style.imgContainer}`}
                onClick={() => {
                  uploadImg("secondImg");
                }}
              />
              <button onClick={() => handleUploadImage('secondImg')} className={`${style.uploadBtn}`}>Upload</button>
            </div>
          </div>

        </div>

        {/* button */}
        <div className={`textCenter ${style.mrg_tp_6}`}>
          <button onClick={() =>  navigate('/order-success-banner/')} className={`${style.outlinedBtn}`}> Cancel</button>
          <button onClick={handleSubmit} className={`${style.filledBtn} `}>
            {status === "edit" ? "Update" : "Publish"} Banner
          </button>
        </div>
        
      </div>


      {
        isLoading && <Loader />
      }
    </>
  );
}

export default OrderBanner;
