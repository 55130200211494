import React from 'react';
import {  Outlet } from 'react-router-dom';
// import { Navigate } from 'react-router-dom';
import { ErrorHandling } from '../State';

const checkAuth = () => {
    const user = {loggedIn: false};
    const token = localStorage.getItem('token');
    if(token){
        user.loggedIn = true;
    }
    return user && user.loggedIn;
}
const ProtectedRoute = () => {
    const auth = checkAuth();
    if(!auth)
    ErrorHandling("Your session has expired, please login again !")
    // return auth ? (<Outlet />) :<Navigate to="/login" />
    return <Outlet />
}


export default ProtectedRoute;