import React, { useState } from 'react';
import CampaignListing from './components/CampaignListing';
import Header from './components/Header';
import SubHeader from './components/SubHeader';

function Index(props) {
    const [Nslot,setNslot] = useState('');
    const [filter,setFilter] = useState(0);
    const [zoneList,setZoneList] = useState([]);
    
    return (
        <div style={{backgroundColor:"#f4fff9",minHeight:"100vh"}}>
            <Header 
                filter={filter}
                setFilter={setFilter}
                zoneList={zoneList}
                setZoneList={setZoneList}
            />
            <SubHeader 
                Nslot={Nslot} 
                setNslot={setNslot}
                filter={filter}
                setFilter={setFilter}
                zoneList={zoneList}
                setZoneList={setZoneList}
            />
            <CampaignListing 
                Nslot={Nslot} 
                setNslot={setNslot}
                filter={filter}
                setFilter={setFilter}
                zoneList={zoneList}
                setZoneList={setZoneList}
            />
        </div>
    );
}

export default Index;