import React, { useEffect, useState } from 'react';
import style from '../css/CampaignList.module.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../../../../icons/calender.svg'
import { WeekDays } from '../../../../utils/constData';

function SubHeader({ setNslot , filter , setFilter }) {
    const [is_today_active_btn, setTodayActiveBtn] = useState(true)
    const [is_yesterday_active_btn, setYesterdayActiveBtn] = useState(false);
    const [is_tomorrow_active_btn, setTommorowActiveBtn] = useState(false);
    const [curr_date, setDate] = useState(new Date());

    const selDate = (e) => {
        let year = String(e.getFullYear()).split('')[2] + '' + String(e.getFullYear()).split('')[3]
        let dat_value = e.getDate() >= 10 ? e.getDate() : '0' + String(e.getDate())
        let month = e.getMonth() + 1 >= 10 ? parseInt(e.getMonth() + 1) : '0' + parseInt(e.getMonth() + 1)
        let today = year + '' + month + '' + dat_value;
        console.log("today", typeof (today))
        setDate(e)
        setNslot(today);
        // setSlotId(today)
        // updateCampaignList(today);
        // getCampaignList(today);
        setTodayActiveBtn(false)
        setTommorowActiveBtn(false)
        setYesterdayActiveBtn(false)
    }

    const todayDateselected = () => {
        let today = new Date()
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let dat_value = today.getDate() >= 10 ? today.getDate() : '0' + String(today.getDate())
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let today1 = year + '' + month + '' + dat_value;
        setDate(today)
        setNslot(today1)
        setTodayActiveBtn(true)
        setTommorowActiveBtn(false)
        setYesterdayActiveBtn(false)

    }

    const yesterdayDateselected = () => {
        let today = new Date()
        today.setDate(today.getDate() - 1)
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let dat_value = today.getDate() >= 10 ? today.getDate() : '0' + String(today.getDate())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '' + month + '' + dat_value;
        setDate(today)
        setNslot(today1)
        setTodayActiveBtn(false)
        setTommorowActiveBtn(false)
        setYesterdayActiveBtn(true)
        // setSlotId(today1)
        // getCampaignList(today1,status, selPricingzone);
        // let dateInfo = {'day_name':day_name, 'date':today.getDate(),'currentMonth':currentMonth, 'year':today.getFullYear()}
        // setDateInfo(dateInfo)
    }
    const tomorrowDateselected = () => {
        var today = new Date();
        today.setDate(today.getDate() + 1);
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let dat_value = today.getDate() > 9 ? today.getDate() : '0' + String(today.getDate())
        let today1 = year + '' + month + '' + dat_value;
        console.log("today", typeof (today), today1)
        setDate(today)
        setNslot(today1)
        // updateCampaignList(today1);
        setTodayActiveBtn(false)
        setTommorowActiveBtn(true)
        setYesterdayActiveBtn(false)
    }

    const getStatusName = () => {
        if(filter==0) return "All"
        else if(filter==1) return "Active"
        else if(filter==3) return "Planned"
        else if(filter==2) return "Completed"
        else if(filter==4) return "Master"
        else if(filter==5) return "Cancelled"
    }
    useEffect(() => {
        let today = new Date();
        let year = String(today.getFullYear()).split('')[2] + '' + String(today.getFullYear()).split('')[3]
        let dat_value = today.getDate() >= 10 ? today.getDate() : '0' + String(today.getDate())
        let month = today.getMonth() + 1 >= 10 ? parseInt(today.getMonth() + 1) : '0' + parseInt(today.getMonth() + 1)
        let slot_id = year + '' + month + '' + dat_value;
        setNslot(slot_id)
        console.log("=====>>>", slot_id)
        // console.log("slot_id", slot_id ,dat_value, today.getDate())
    }, [])
    return (
        <>
            <div>
                <div className={`${style.SubHeaderContainer} flex flex-space-between`}>
                    <div className={`${style.leftContainer} flex`}>
                        <div className={`${style.heading}`}>Campaign Listing(
                            {getStatusName()}
                            )</div>
                        <div className={`${style.sub_heading}`}>List Of Completed Campaigns</div>
                        <div className={`${style.calendar}`}>
                            <button onClick={yesterdayDateselected} className={`${style.outlinedBtn} ${style.mrg_10} ${is_yesterday_active_btn ? style.date_btn_active : ''}`}>Yesterday</button>
                            <button onClick={todayDateselected} className={`${style.outlinedBtn} ${style.mrg_10} ${is_today_active_btn ? style.date_btn_active : ''}`}>Today</button>
                            <button onClick={tomorrowDateselected} className={`${style.outlinedBtn} ${style.mrg_10} ${is_tomorrow_active_btn ? style.date_btn_active : ''}`}>Tomorrow</button>
                            <div className={style.calendr_img}>
                                <img src={Calendar} />
                            </div>
                            <div className={style.calendr_img}></div>
                            <DatePicker onChange={(e) => selDate(e)} selected={curr_date}
                                className={style.custom_date_picker}
                                dateFormat="dd MMMM yyyy"
                            //    minDate = {new Date()}
                            />
                        </div>
                    </div>
                    <div className={`${style.rightInnerBox}`}>
                        <div className={`bold ${style.legend}`}>
                            Campaign Types
                        </div>
                        <div className={`${style.legend} flex`}>
                            <div className={`${style.legendBox} ${style.yellow}`}></div>
                            <div >New users</div>
                        </div>
                        <div className={`${style.legend} flex`}>
                            <div className={`${style.legendBox} ${style.darkgreen}`}></div>
                            <div >Existing users</div>
                        </div>
                    </div>
                </div>
                <div className={`${style.tabContainer}`}>
                    <button className={`${style.tabButtons} ${filter==0 ? style.active : ''}`} onClick={()=>setFilter(0)}>All</button>
                    <button className={`${style.tabButtons} ${filter==1 ? style.active : ''}`} onClick={()=>setFilter(1)}>Active</button>
                    <button className={`${style.tabButtons} ${filter==3 ? style.active : ''}`} onClick={()=>setFilter(3)}>Planned</button>
                    <button className={`${style.tabButtons} ${filter==2 ? style.active : ''}`} onClick={()=>setFilter(2)}>Completed</button>
                    <button className={`${style.tabButtons} ${filter==5 ? style.active : ''}`} onClick={()=>setFilter(5)}>Cancelled</button>
                </div>

            </div>

        </>
    );
}

export default SubHeader;