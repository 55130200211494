// import Vehicle from "../../icons/vehicle.png";
// import login from "../../icons/home-icon.png";
import Home from "../../icons/home-icon.png";
import Branding from "../../icons/branding.svg";
import Communication from "../../icons/communication.svg";
import Coupon from "../../icons/coupon.svg";
import Marketing from "../../icons/marketing.svg";
import CutOffTime from "../../icons/time.svg";
import VideoIcon from "../../icons/video-icon.svg";
import Subscription from "../../icons/subscription.svg";
import PDP from "../../icons/edit-list-icon.svg";
import MultiUser from "../../icons/multi-user.svg";
// import MultiUser from "../../icons/user.svg";
import Ondc from "../../icons/ondc.png";
import Upload from "../../icons/upload-icon.svg"

export const SidebarData = [
  {
    title: "HOME PAGE",
    path: "/",
    hasSlot: false,
    subListClass: "hide",
    image: Home,
    subList: []
  },
  {
    title: "BRANDING",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Branding,
    subList: [
      {
        title: "App Feed",
        icon: "",
        path: "/app-feed/",
        hasSlot: false,

      },
      {
        title: "Feed Content",
        icon: "",
        path: "/feed-content/",
        hasSlot: true,
      },
      {
        title: "Order Success Banner",
        icon: "",
        path: "/order-success-banner/"
      },
      // {
      //   title: "Web Feed",
      //   icon: "",
      //   path: "/web-feed/"
      // },
      {
        title: "Product List",
        icon: "",
        path: "/product-widget/",
        hasSlot: false,
      },
      {
        title: "USP Banner",
        icon: "",
        path: "/header-banner/",
        hasSlot: false,
      },
      // {
      //   title: "Mini Feed",
      //   icon: "",
      //   path: "/mini-feed/",
      //   hasSlot: false,
      // },
      {
        title: "Widget Listing",
        icon: "",
        path: "/widget-listing/",
        hasSlot: false,
      },
      {
        title: "New Feed Content",
        icon: "",
        path: "/new-feed-content/",
        hasSlot: true,
      },
      {
        title: "New Mini Feed",
        icon: "",
        path: "/new-mini-feed/",
        hasSlot: false,
      },
      {
        title: "New App Feed",
        icon: "",
        path: "/new-app-feed/",
        hasSlot: false,
      }
    ]
  },
  {
    title: "COMMUNICATION",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Communication,
    subList: [
      {
        title: "WhatsApp",
        icon: "",
        path: "",
        hasSlot: false,
      },
      {
        title: "SMS DashBoard",
        icon: "",
        path: "/sms-dashboard/",
        hasSlot: false,
      },
      {
        title: "Kafka Switcher",
        icon: "",
        path: "/kafka-switcher/",
        hasSlot: false,
      },
      {
        title: "Communication Dashboard",
        icon: "",
        path: "/communication-dashboard/",
        hasSlot: false,
      },

    ]
  },
  {
    title: "COUPONS",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Coupon,
    subList: [
      {
        title: "Coupon Deal",
        icon: "",
        path: "/coupon-detail/",
        hasSlot: false,
      },
      {
        title: "Coupon List",
        icon: "",
        path: "/coupon-list/",
        hasSlot: false,
      },
      {
        title: "Offer List",
        icon: "",
        path: "/offer-list/",
        hasSlot: false,
      }
    ]
  },
  {
    title: "CUTOFF TIME",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: CutOffTime,
    subList: [
      {
        title: "CutOff time",
        icon: "",
        path: "/cut-off-time",
        hasSlot: false,
      },
    ]
  },
  {
    title: "MARKETING",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Marketing,
    subList: [
      {
        title: "Burn Mapping",
        icon: "",
        path: "/burn-mapping",
        hasSlot: false,
      },
      {
        title: "Bonus Management",
        icon: "",
        path: "/bonus-management",
        hasSlot: false,
      },
      {
        title: "Campaign list",
        icon: "",
        path: "/campaign-list",
        hasSlot: false,
      },
      {
        title: "Create Campaign",
        icon: "",
        path: "/create-campaign/0/",
        hasSlot: false,
      },
      {
        title: "Dashboard",
        icon: "",
        path: "/dashboard",
        hasSlot: false,
      },
      {
        title: "Membership Campaign list",
        icon: "",
        path: "/membership-product/",
        hasSlot: false,
      },
    ]
  },
  {
    title: "SUBSCRIPTION",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Subscription,
    subList: [
      {
        title: "Subscription Product",
        icon: "",
        path: "/subscription",
        hasSlot: false,
      },
      {
        title: "Subscription Top Banner",
        icon: "",
        path: "/subscription-top-banner",
        hasSlot: false,
      },
    ]
  },
  {
    title: "PRODUCT DETAIL VIDEO",
    path: "/product-detail-video/",
    hasSlot: false,
    subListClass: "hide",
    image: VideoIcon,
    subList: []
  },
  {
    title: "PDP",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: PDP,
    subList: [
    //   {
    //     title: "Sub Category",
    //     icon: "",
    //     path: "/sub-category/",
    //     hasSlot: false,
    //   },
      {
        title: "Product Details Keys",
        icon: "",
        path: "/product-details-keys/",
        hasSlot: false,
      },
      // {
      //   title: "Listing Order",
      //   icon: "",
      //   path: "/",
      //   hasSlot: false,
      // },
      {
        title: "Bulk Upload",
        icon: "",
        path: "/bulk-upload/",
        hasSlot: false,
      }
    ]
  },
  {
    title: "BULK UPLOAD",
    path: "bulk-upload-data/",
    hasSlot: false,
    subListClass: "hide",
    image: Upload,
    subList: []
  },
  {
    title: "USER SEGMENT",
    path: "/user-segment/",
    hasSlot: false,
    subListClass: "hide",
    image: MultiUser,
    subList: []
  },
  {
    title: "ONDC",
    path: "",
    hasSlot: false,
    subListClass: "hide",
    image: Ondc,
    subList: [
      {
        title: "Stores Details",
        icon: "",
        path: "/ondc-provider/",
        hasSlot: false,
      },
      {
        title: "Widget Listing",
        icon: "",
        path: "/ondc-widget-listing/",
        hasSlot: false,
      },
      {
        title: "Feed",
        icon: "",
        path: "/ondc-feed/",
        hasSlot: false,
      },
    ]
  },
];
