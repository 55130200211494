import React, { useEffect, useState } from 'react';
import style from '../css/SmsDashboard.module.css'
import { getSmsDashboardList } from '../../../../utils/api';
function Listing({nslot,setNslot}) {
    const [list,setList] = useState([]);
    const [curr_date, setDate] = useState(new Date());

    const updateListItems = async () => {
        let res = await getSmsDashboardList(nslot);
        console.log(res);
        if(res.data.length){
            setList(res.data);
        }
        else {
            setList([]);
        }
    }

    useEffect(()=>{
        if(nslot)
        updateListItems();
    },[nslot])

    return (
        <>
            <div className={`${style.container}`}>
                <div className={` ${style.thead}`}>
                                <div className={`${style.pd_bg}`}>Date</div>
                                <div className={`${style.pd_bg}`}>Template Message</div>
                                <div className={`${style.pd_bg}`}>Service Provider</div>
                                <div className={`${style.pd_bg}`}>Username</div>
                                <div className={`${style.pd_bg}`}>Is Reward</div>
                                <div className={`${style.pd_bg}`}>Users selected for recharge</div>
                                <div className={`${style.pd_bg}`}>Users eligible for recharge</div>
                                <div className={`${style.pd_bg}`}>User sms success count</div>
                                <div className={`${style.pd_bg}`}>User recharge success count</div>
                                <div className={`${style.pd_bg}`}>Order Count</div>
                                <div className={`${style.pd_bg}`}>Order Condition</div>
                                <div className={`${style.pd_bg}`}>Wallet Condition</div>
                </div>

                <div className={`${style.tbody}`}>
                    {
                        list.map((item,idx) => {
                        return (
                            <div className={`${style.trow}`} key={idx}>
                                <div className={`${style.pd_bg}`}>{`${item.created_at.substr(0, 10).split('-')[2]}-${item.created_at.substr(0, 10).split('-')[1]}-${item.created_at.substr(0, 10).split('-')[0]}`}</div>
                                <div className={`${style.pd_bg}`}>{item.message}</div>
                                <div className={`${style.pd_bg}`}>{item.sms_provider}</div>
                                <div className={`${style.pd_bg}`}>{item.erp_user}</div>
                                <div className={`${style.pd_bg}`}>{item.is_reward ? 'True' : 'False'}</div>
                                <div className={`${style.pd_bg}`}>{item.users_selected_for_recharge}</div>
                                <div className={`${style.pd_bg}`}>{item.users_eligible_for_recharge}</div>
                                <div className={`${style.pd_bg}`}>{item.user_sms_success_count}</div>
                                <div className={`${style.pd_bg}`}>{item.user_recharge_success_count}</div>
                                <div className={`${style.pd_bg}`}>{item.order_conversion ? item.order_conversion : 'To be updated at mid night'}</div>
                                <div className={`${style.pd_bg}`}>{item.users_removed_due_to_order_condition}</div>
                                <div className={`${style.pd_bg}`}>{item.users_removed_due_to_order_condition}</div>                          
                            </div>
                        )
                        })
                    }
              </div>
            </div>
        </>
    );
}

export default Listing;