import React from 'react'
import PopUp from '../../../../commonComponent/PopUp/PopUp'
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';

function PopupVariableCheckbox({closeBtn, communicationData, setCommunicationData, setVarBulkUpload, setCsvList, setInputList, selectedSelUser}) {

    const handleConfirm = () => {
        setCommunicationData({
            ...communicationData,
            csv_type: selectedSelUser,

            sms_header: {id: 0, name: "Select Header Type"},
            include_segment_list: [],
            offer_id: '',
            exclude_segment_list: [],
        })
        setVarBulkUpload(selectedSelUser.id);
        setCsvList([])
        setInputList([
            { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
            { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
            { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
            { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
            { var_type: 0, type_name: "select type", value: 0, value_name: "select value", additional_action_data: "", dropdown_data: [] },
        ]);
        closeBtn()
    }

    return (
        <PopUp
            title={'Confirm!'}
            width={`${formStyle.popupWidthS}`}
            closeBtn={closeBtn}
        >
            <div className={`textLeft`}>
                <div className={` ${formStyle.cellPd}`}>
                    <div className={`${formStyle.mrg_y_6}`}> Are you sure you want to <b>{communicationData.csv_type === 1 ? 'Un select' : 'Select'} </b> the <b>Variable Through Bulk Upload</b> checkbox ? </div>
                    <div className={`red ${formStyle.mrg_y_6}`}> This will reset <b>Variable List</b> and <b>Selected User base</b>. </div>
                </div>
                <div className={`flex flex-space-between`}>
                    <button className={`${formStyle.btn} ${formStyle.secondaryBtn}`} onClick={closeBtn}>Cancel</button>
                    <button className={`${formStyle.btn} ${formStyle.successBtn}`} onClick={handleConfirm}>Confirm</button>
                </div>
            </div>
        </PopUp>
    );
}

export default PopupVariableCheckbox