import React from 'react';
import Header from './components/Header';
import KafkaListing from './components/KafkaListing';
function Index(props) {
    return (
        <div>
            <Header/>
            <KafkaListing/>
        </div>
    );
}

export default Index;