import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { widgetImgUrl } from '../../../../utils/config';
import { getCatListZoneWise, getItemsList, getSubCatList, getWebItemsList, getWidgetList, saveAppFeed, toggle, uploadSelImage, validateData } from '../../../../utils/api';
import style from "../css/AppFeed.module.css";
import ImgIcon from '../../../../icons/picture-icon.svg';
import ArrowIcon from '../../../../icons/down-arrow.png';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';


function WidgetScreen(props) {
    const { status, id, selZone } = useParams()
    const navigate = useNavigate()
    const [file, setFile] = useState("");
    const [secondFile, setSecondFile] = useState("");
    const [widgetData, setWidgetData] = useState({
        background_color: "",
        background_image: "",
        category_id: 0,
        content_id: 0,
        data: {},
        id: 0,
        no_of_children: 0,
        rank: "",
        sub_title: "",
        title: "",
        widget_bg_color: "",
        widget_icon: "",
        widget_type: "banners_list_wd",
        selSubCat: {}
    })
    const [listing, setListing] = useState({
        widgetType: [],
        optionalField: {
            show: false,
            inputType: null,
            list: []
        },
        bannerList: [],
        feedId: "",
        feed_tag: ""
    })

    const handleFeedTag = (value) => {
        setListing({...listing, feed_tag: value})
    }

    // submit data
    const handleSubmit = async () => {

        if(listing.feed_tag == undefined || !listing.feed_tag.length){
            ErrorHandling("Feed Tag cannot be empty")
            return;
        }

        const bannerObj = Object.assign({}, widgetData);
        const localListing = Object.assign({}, listing);
        // debugger;
        let bannerListing = localListing.bannerList
        let res = validateData(bannerObj);
        if (res) {
            let index = bannerListing.length;
            bannerListing.forEach((obj, idx) => {
                if (obj.id == bannerObj.id)
                    index = idx;
            });
            bannerListing[index] = bannerObj;
            console.log(bannerListing)
            let data = {
                feed_id: listing.feedId,
                feed_type: "web",
                feed_tag: listing.feed_tag,
                pricing_zone_id: selZone,
                widget_list: bannerListing
            }
            // check weather content_id id array
            if (!data.widget_list[index].content_id.length) {
                data.widget_list[index].content_id = [data.widget_list[index].content_id]
            }
            let response = await saveAppFeed(data)
            if (response) {
                navigate('/web-feed/')
            }

        }
    }

    // handle upload image
    const handleUploadImage = async (id) => {
        let image = document.getElementById(id).files[0];
        console.log(image);

        var image_type = 'widget';
        let data = new FormData();
        data.append('file', image);
        data.append('image_type', image_type)

        let res = await uploadSelImage(data);
        if (res && id === "firstImg") {
            let widget = Object.assign({}, widgetData)
            widget.background_image = res
            setFile(widgetImgUrl + res);
            setWidgetData(widget)
        }
        else if (res && id === "secondImg") {
            let widget = Object.assign({}, widgetData)
            widget.widget_icon = res
            setSecondFile(widgetImgUrl + res);
            setWidgetData(widget)
        }
    }

    // uploaded img preview url 
    const handleChange = (e) => {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    // uploaded img preview url 
    const handleChange2 = (e) => {
        console.log(e.target.files);
        setSecondFile(URL.createObjectURL(e.target.files[0]));
    }

    // upload image
    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    }

    // input field change
    const handleWidgetDataChange = (key, value) => {

        let data = Object.assign({}, widgetData);
        data[key] = value;
        if (key === "widget_type") {
            let obj = Object.assign({}, listing);
            setOptionalField(data, obj)
        }
        else {
            setWidgetData(data);
        }
    }

    // setOptional field Data 
    // use this when the widget type is changed as well
    const setOptionalField = async (selItem, obj) => {
        console.log(selItem);
        console.log(obj);

        // to handle new widget 
        if (!selItem) {
            console.log("add new widget")
        }
        // hanlding the optional field data 
        else if (selItem.widget_type === "subcat_prod_wd") {
            let list = await getSubCatList();
            obj.optionalField = {
                show: true,
                inputType: 'dropdown',
                list: list
            }
            let selSubCat = list.filter((item) => item.id == selItem.content_id[0]);
            if (selSubCat.length)
                selItem.selSubCat = selSubCat[0];
            else
                selItem.selSubCat = list[0];
        }
        else if (selItem.widget_type === "cat_prod_wd") {
            let list = await getCatListZoneWise(selZone)
            obj.optionalField = {
                show: true,
                inputType: 'dropdown',
                list: list
            }

            let selSubCat = list.filter((item) => item.id == selItem.content_id[0]);
            if (selSubCat.length)
                selItem.selSubCat = selSubCat[0];
            else
                selItem.selSubCat = list[0];

        }
        else if (
            selItem.widget_type === "virtual_cat_wd" ||
            selItem.widget_type === "subscription_wd" ||
            selItem.widget_type === "image_list_wd"
        ) {
            obj.optionalField = {
                show: true,
                inputType: 'number',
                list: []
            }
        }
        else {
            obj.optionalField = {
                show: false,
                inputType: null,
                list: []
            }
        }
        setListing(obj)
        setWidgetData(selItem)

    }

    // fetch data const
    const fetchData = async () => {
        // get banner list 
        let res = await getWebItemsList(selZone, 2);
        let bannerList = res.data.feed.widget_list
        if (!bannerList)
            bannerList = []
        // if(!bannerList)
        //     navigate('/web-feed/')

        // variables for edit banner 
        let selFeed, widData;

        if (status === "edit") {
            // sort the selected banner item
            selFeed = bannerList.filter(item => item.id == id)
            widData = selFeed[0];

            // set imag url
            if (widData.background_image)
                setFile(widgetImgUrl + widData.background_image)
            if (widData.widget_icon)
                setSecondFile(widgetImgUrl + widData.widget_icon)
        }

        // get widget list for dropdown
        let list = await getWidgetList();
        let obj = Object.assign({}, listing);
        obj.widgetType = list;
        obj.bannerList = bannerList;
        obj.feedId = res.data.feed.feed_id;
        obj.feed_tag = res.data.feed.feed_tag;

        // set addition field data
        if (status === "edit")
            setOptionalField(selFeed[0], obj)
        else
            setOptionalField(widgetData, obj)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {/* header */}
            <div className={`${style.header} flex flex-space-between`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Web Feed</span></div>
            </div>

            <div className={`${style.mrgL}`}>
                <div className={`${style.createWidget}`}>Edit Widget</div>

                <div className={`${style.listingContainer}`}>
                    <div className={`${style.widget_container}`}>
                        {/* input fields container */}
                        <div className={`${style.firstBox}`}>
                        <div className={`${style.row}`}>
                                    <div className={`${style.width_50}`}>
                                        <div className={`${style.title}`}>Feed Tag</div>
                                        <input className={`${style.inputField}`} value={listing.feed_tag} onChange={(e) => handleFeedTag(e.target.value)} type='text' placeholder='Title'/>
                                    </div>
                                </div>


                            <div className={`${style.row}`}>
                                <div className={`${style.width_50}`}>
                                    <div className={`${style.title}`}>Title</div>
                                    <input className={`${style.inputField}`} value={widgetData.title} onChange={(e) => handleWidgetDataChange('title', e.target.value)} type='text' placeholder='Title' />
                                </div>
                                <div className={`${style.width_50}`}>
                                    <div className={`${style.title}`}>Sub Title</div>
                                    <input className={`${style.inputField}`} value={widgetData.sub_title} onChange={(e) => handleWidgetDataChange('sub_title', e.target.value)} type='text' placeholder='Sub Title' />
                                </div>
                            </div>

                            <div className={`${style.row}`}>
                                <div className={`${style.width_33}`}>
                                    <div className={`${style.title}`}>Rank</div>
                                    <input className={`${style.inputField}`} value={widgetData.rank} onChange={(e) => handleWidgetDataChange('rank', e.target.value)} type='number' placeholder='Rank' />
                                </div>
                                <div className={`${style.width_33}`}>
                                    <div className={`${style.title}`}>Background Color</div>
                                    <input className={`${style.inputField}`} value={widgetData.background_color} onChange={(e) => handleWidgetDataChange('background_color', e.target.value)} type='text' placeholder='Background Color' />
                                </div>
                                <div className={`${style.width_33}`}>
                                    <div className={`${style.title}`}>Icon Color</div>
                                    <input className={`${style.inputField}`} value={widgetData.widget_bg_color} onChange={(e) => handleWidgetDataChange('widget_bg_color', e.target.value)} type='text' placeholder='Icon Color' />
                                </div>
                            </div>

                            <div className={`${style.row}`}>
                                {/* widget type */}
                                <div className={`${style.width_50}`}>
                                    <div className={`${style.title}`}>Widget Type</div>
                                    <div className={`${style.dropdown} ${style.width100}`}>
                                        <button onClick={() => toggle('widget_type')} className={`flex flex-space-between ${style.dropdownMenu}`}>{widgetData.widget_type} <img src={ArrowIcon} /></button>

                                        <ul className={`${style.dropdownList} ${style.overlay12} hide`} id={'child_widget_type'}>
                                            {
                                                listing.widgetType.map((elem, index) => {
                                                    return (
                                                        <li key={index} onClick={() => {
                                                            // setBannerType(elem);
                                                            handleWidgetDataChange('widget_type', elem.type)
                                                            toggle('widget_type')
                                                        }}>{elem.type}</li>
                                                    );
                                                })
                                            }
                                        </ul>

                                    </div>
                                    {/* <input value={widgetData.widget_type} onChange={(e) => handleWidgetDataChange('widget_type',e.target.value)} type='text' placeholder='Title'/> */}
                                </div>

                                {/* conditional field */}
                                {
                                    listing.optionalField.show && listing.optionalField.inputType === 'number' ?
                                        <div className={`${style.width_50}`}>
                                            <div className={`${style.title}`}>Sub Category List</div>
                                            <input className={`${style.inputField}`} type='number' value={widgetData.content_id} onChange={(e) => handleWidgetDataChange('content_id', e.target.value)} placeholder='Sub Title' />
                                        </div>
                                        : null
                                }
                                {
                                    listing.optionalField.show && listing.optionalField.inputType === 'dropdown' ?
                                        <div className={`${style.width_50}`}>
                                            <div className={`${style.title}`}>Sub Category List</div>
                                            <Dropdown
                                                height={'8rem'}
                                                list={listing.optionalField.list}
                                                name={widgetData.selSubCat}
                                                getDropDownValue={(elem) => {
                                                    handleWidgetDataChange('selSubCat', elem)
                                                }} />

                                            {/* <input type='text' placeholder='Sub Title'/> */}
                                        </div>
                                        : null
                                }
                            </div>
                        </div>

                        {/* image upload container */}
                        <div className={`${style.secondBox}`}>
                            <div className={`${style.uploadImg}`}>
                                <div className={`${style.title}`}>Background Image</div>
                                <input type='file' style={{ display: "none" }} name='first' id='firstImg' accept='image/*' onChange={handleChange} />
                                <img src={file} className={`${style.imgContainer}`} onClick={() => {
                                    uploadImg('firstImg');
                                }} />
                                <button onClick={() => handleUploadImage('firstImg')} className={`${style.uploadBtn}`}>Upload</button>
                            </div>
                            <div className={`${style.uploadImg}`}>
                                <div className={`${style.title}`}>Widget Icon</div>
                                <input type='file' style={{ display: "none" }} name='first' id='secondImg' accept='image/*' onChange={handleChange2} />
                                <img src={secondFile} className={`${style.imgContainer}`} onClick={() => {
                                    uploadImg('secondImg')
                                }} />
                                <button onClick={() => handleUploadImage('secondImg')} className={`${style.uploadBtn}`}>Upload</button>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.textCenter}`}>
                        <button onClick={handleSubmit} className={`${style.submitBtn}`}>SAVE CHANGES</button>
                    </div>
                </div>
            </div>
            {/* title  */}

        </>
    );
}

export default WidgetScreen;
