import React, { useEffect, useState } from 'react'
import style from '../css/HeaderBanner.module.css'
import { usptype } from '../../../../utils/constData';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import LocationIcon from "../../../../icons/location.svg";
import { createHeaderBanner, editHeaderBanner, getHeaderBannerDetail, getOrderBannerZonewiseList, searchSku, uploadBannerImage } from '../../../../utils/api';
import { ProductWidgetImgUrl } from '../../../../utils/config';
import EditIcon from '../../../../icons/edit.svg';
import UploadIcon from "../../../../icons/ico-upload.svg";
import { ErrorHandling, SuccessHandling } from '../../../../State';
//import PopupErrorList from './PopupErrorList';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import PopUp from '../../../../commonComponent/PopUp/PopUp';


// image_type: small
// Request URL: https://api.crofarm.com/spa/products/image/v1/


export default function ({ }) {
    const { status, uspid, selZone } = useParams()
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [error504, setError504] = useState(false);
    const [selectedProd, setSelectedProd] = useState({});
    const [zoneList, setZoneList] = useState([]);
    const [isAllZoneSelected, setIsAllZoneSelected] = useState(false);
    const [selectedZoneList, setSelectedZoneList] = useState([]);
    const [selectedType, setSelectedType] = useState({})
    const [selectContentid, setContentid] = useState({ name: 'Select Category' })

    const [errorList, setErrorList] = useState([]);
    const [popup, setPopup] = useState(false);
    const [images, setImages] = useState({
    })

    const [Productdata, setProductdata] = useState({
        id: '',
        pricing_zone_ids: [],
        usp_name: '',
        min_lifetime_orders: 0,
        max_lifetime_orders: 0,
        content_type: { name: 'Content Type' }, // this will used as usp_type
        usp_data: '',
        url: "",
        is_web_view: false,
    })
    const navigate = useNavigate();

    const getContentType = (content_type) => {
        let obj = Productdata;
        obj.usp_data = '';
        obj.content_type = content_type;
        setProductdata({ ...obj });
    }

    const selectZone = (zoneElement) => {
        console.log(zoneElement);
        var all_zone_data = [...zoneList]
        let selectedZones = [...selectedZoneList]
        if (zoneElement.target.checked) {
            const updatedZoneList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = true;
                    selectedZones.push(zone.id);
                }
                return zone;
            });
            if (selectedZones.length === zoneList.length) {
                setIsAllZoneSelected(true);
            }

            setSelectedZoneList(selectedZones)
            setZoneList(updatedZoneList)

        }

        else {
            const updatedList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = false;
                }
                return zone;
            });

            selectedZones = selectedZoneList.filter((zone) => zone !== parseInt(zoneElement.target.id))
            setSelectedZoneList(selectedZones)
            setZoneList(updatedList)
            setIsAllZoneSelected(false);
        }

        let obj = Productdata;
        obj.pricing_zone_ids = selectedZones;
        setProductdata({ ...obj })
    }

    const selectAllZone = (event) => {
        let selected_zones = []
        if (event.target.checked) {
            const pricing_zones = zoneList.map(zoneElement => {
                zoneElement.is_selected = true;
                selected_zones.push(zoneElement.id)
                return zoneElement;

            })
            setSelectedZoneList(selected_zones);
            setIsAllZoneSelected(true);
            setZoneList(pricing_zones)

        } else {
            const pricing_zone = zoneList.map(zoneElement => {
                zoneElement.is_selected = false;
                return zoneElement;

            })
            setSelectedZoneList([]);
            setIsAllZoneSelected(false);
            setZoneList(pricing_zone)

        }

        let obj = Productdata;
        obj.pricing_zone_ids = selected_zones;
        setProductdata({ ...obj })
    }
    const handleInputChange = (e) => {
        let obj = Productdata;
        obj[e.target.name] = e.target.value;
        setProductdata({ ...obj });
    }

    const handleRadioChange = (e, value) => {
        let obj = Productdata;
        obj[e.target.name] = value;
        setProductdata({ ...obj });
    }

    const handleCheckBoxChange = (e) => {
        let obj = Productdata;
        obj[e.target.name] = e.target.value;
        setProductdata({ ...obj });
    }

    const selectedZoneName = () => {
        let zoneObj = JSON.parse( localStorage.getItem('zone') );
        return zoneObj.name
    }

    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    const saveFile = (e) => {
        let obj = images;
        console.log('image obj',obj,'file', e.target.files[0].type)
        obj[e.target.name] = e.target.files[0];
        obj.type = e.target.files[0].type;
        setImages({ ...obj });

        

    }
    const validationProduct = function () {
        console.log('Productdata', Productdata);
        let regexJsonFile = new RegExp(".json$", "i");
        let httppattern = new RegExp("/(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g");
        console.log('httppattern.test(Productdata.url)', httppattern.test(Productdata.url));
        setIsSubmitButtonClicked(true);
        console.log('httppattern.test(Productdata.url', httppattern.test(Productdata.url), 'and',Productdata.url);
        // need to add detail of edit and new product check 
        console.log('errorlist', errorList);
        if (Productdata.pricing_zone_ids.length == 0) {
            setErrorList([{ loc: ["", "Zones"], msg: 'Please Select Zone' }])
            setPopup(true);
        } else if (Productdata.usp_name === "" || Productdata.usp_name === undefined ) {
            setErrorList([{ loc: ["", "Name"], msg: 'Please add usp name' }])
            setPopup(true);
        } else if (Productdata.content_type.id === "" || Productdata.content_type.id === undefined ) {
            setErrorList([{ loc: ["", "Content type "], msg: 'Please select content type' }])
            setPopup(true);
        } else if (Productdata.content_type.id==2 && Productdata.usp_data === "") {
            setErrorList([{ loc: ["", "Image "], msg: 'Please upload Image' }])
            setPopup(true);
        }  else if (Productdata.is_web_view && !httppattern.test(Productdata.url)) {
            setErrorList([{ loc: ["", "URL "], msg: 'Please add valid web url' }])
            setPopup(true);
            
        } else if (Productdata.content_type.id==1 && !httppattern.test(Productdata.usp_data)) {
            setErrorList([{ loc: ["", "URL "], msg: 'Please add valid video url' }])
            setPopup(true);
            
        } else if (Productdata.content_type.id==4 && !regexJsonFile.test(Productdata.usp_data)) {
            setErrorList([{ loc: ["", "JSON "], msg: 'Please add valid json file' }])
            setPopup(true);
            
        } else if (Productdata.min_lifetime_orders === "" || Productdata.min_lifetime_orders < 0) {
            setErrorList([{ loc: ["", "Min order count"], msg: 'Please Enter valid Min order count' }])
            setPopup(true);
        } else if (Productdata.max_lifetime_orders === "" || Productdata.max_lifetime_orders < 0) {
            setErrorList([{ loc: ["", "Max order count"], msg: 'Please Enter valid Max order count' }])
            // setErrorList([{ msg: 'Please Enter valid Max order count' }])
            setPopup(true);
        } else if (Productdata.min_lifetime_orders > Productdata.max_lifetime_orders) {
            setErrorList([{ loc: ["", "Min Max order count"], msg: 'Min order cannot be greater than max order count' }])
            // setErrorList([{ msg: 'Please Enter valid Max order count' }])
            setPopup(true);
        }
        else {
            status !== 'new' ? editProductData() : createProductData()
        }

        //status !== 'new' ? editProductData() : createProductData()

    }


    const createProductData = async () => {
        // let data = Productdata;
        let data = {
            pricing_zone_ids: Productdata.pricing_zone_ids,
            usp_name: Productdata.usp_name,
            min_lifetime_orders: Productdata.min_lifetime_orders,
            max_lifetime_orders: Productdata.max_lifetime_orders,
            usp_data: Productdata.usp_data,
            usp_type: Productdata.content_type.id,
            url: Productdata.url,
            is_web_view: Productdata.is_web_view,
        }

        if (status != "edit") {
            delete data.id
        }
        // data.usp_type= Productdata.content_type.id;
        console.log('data', data)
        let res = await createHeaderBanner(data);

        if (res.success) {
            SuccessHandling('Product created successfully');
            navigate('/header-banner')
        } else {
            if (res.error.status_code === 422) {
                ErrorHandling(res.error.message)
                
            } else if (res.error.status_code === 504){
                setError504 (true);
                setErrorList(JSON.parse(res.error.message));
                setPopup(true);
            }
            // else {
            //     //ErrorHandling(res.error.message)
            //     //json.parse(res.error.message);

            //     setErrorList(JSON.parse(res.error.message));
            // }
            setPopup(true);
        }
    }

    const editProductData = async () => {
        let data = {
            id : Productdata.id, 
            pricing_zone_ids: [parseInt(selZone)],
            usp_name: Productdata.usp_name,
            min_lifetime_orders: Productdata.min_lifetime_orders,
            max_lifetime_orders: Productdata.max_lifetime_orders,
            usp_data: Productdata.usp_data,
            usp_type: Productdata.content_type.id,
            url: Productdata.url,
            is_web_view: Productdata.is_web_view,
        }

        localStorage.removeItem('productData')
        let res = await editHeaderBanner(data);
        if (res.success) {
            SuccessHandling('USP Banner updated successfully');
            navigate('/header-banner')
        } else {
            if (res.error.status_code === 422) {
                setErrorList(res.detail)
            } else {
                const rmsg = JSON.parse(res.error.message)
                setErrorList([{ loc: ["", "Error"], msg: res.error.message }])
                // setErrorList([{msg: rmsg }])
            }
            setPopup(true);
        }
    }


    const uploadImage = async (e) => {
        var image_type = 'large';
        if(e.target.id === 'usp_data')
            image_type='json'
        let Imgdata = new FormData();

        let imgTypeExt = images[e.target.id].type.split('/')

        Imgdata.append('file', images[e.target.id]);
        Imgdata.append('file_ext', '.' + imgTypeExt[1]);
        Imgdata.append('image_type', image_type);
        // file_ext
        if (images[e.target.id] === '') {
            ErrorHandling('Please select the image')
        }else if(images.type !== 'image/png' && images.type !== 'image/jpg' && images.type !== 'image/jpeg' && images.type !== 'image/webp' ){
            ErrorHandling('Please select correct image format')
        }else {
            if (e.target.id === 'large_banner') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 80 && img.width === 343) {
                        let res = await uploadBannerImage(Imgdata)
                        if (res.success) {
                            let obj = Productdata;
                            obj.usp_data = res.image_id;
                            console.log(obj);
                            setProductdata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(images[e.target.id]);
            } else {
                let res = await uploadBannerImage(Imgdata)
                if (res.success) {
                    let obj = Productdata;
                    obj[e.target.id] = res.image_id;
                    console.log(obj);
                    setProductdata({ ...obj })
                } else {
                    ErrorHandling(res.error.message)
                }
            }
        }
        // if (images[e.target.id] === '') {
        //     if(images.type != ('image/png' || 'image/jpg' || 'image/jpeg' || 'image/webp' )){
        //         ErrorHandling('Please select correct image format')
        //     }else{
        //         ErrorHandling('Please select the image')
        //     }
            
        // } else {
        //     if (e.target.id === 'large_banner') {
        //         let img = new Image;
        //         img.onload = async function () {
        //             if (img.height === 80 && img.width === 343) {
        //                 let res = await uploadBannerImage(Imgdata)
        //                 if (res.success) {
        //                     let obj = Productdata;
        //                     obj.usp_data = res.image_id;
        //                     console.log(obj);
        //                     setProductdata({ ...obj })
        //                 } else {
        //                     ErrorHandling(res.error.message)
        //                 }
        //             } else {
        //                 ErrorHandling(`Aspect ratio doesn't match`)
        //             }
        //         }
        //         img.src = img.src = URL.createObjectURL(images[e.target.id]);
        //     } else {
        //         let res = await uploadBannerImage(Imgdata)
        //         if (res.success) {
        //             let obj = Productdata;
        //             obj[e.target.id] = res.image_id;
        //             console.log(obj);
        //             setProductdata({ ...obj })
        //         } else {
        //             ErrorHandling(res.error.message)
        //         }
        //     }
        // }
    } 

    const fetchData = async () => {
        // for gettig the zone list
        let resListing = await getOrderBannerZonewiseList();
        // let id = 3;
        // let testData = Productdata;
        // testData.content_type = usptype.filter(obj => obj.id == id);
        // testData.content_type = testData.content_type[0];
        //console.log('testData', testData.content_type);
        //setProductdata({...testData});

        if (status === "edit") {
            const res = await getHeaderBannerDetail(selZone, uspid)
            let data = res.data[0];
            data.content_type = usptype.filter(obj => obj.id == data.usp_type);
            data.content_type = data.content_type[0];
            console.log('edit data', data);

            if(!data.is_web_view) 
                data.is_web_view = false;
            if(!data.url)
                data.url = '';

            // data.usp_type= Productdata.content_type.id;

            // data.content_type = usptype.filter(obj => obj.id == data.usp_type[0])

            setSelectedProd(data);
            setProductdata({ ...data });
            setSelectedZoneList(data.pricing_zone_id);
            delete data.prod_id

            let obj = {};
            obj.prod_image_info = data.prod_image_info;
            //setImages({ ...obj })
            let selectedZones = [...resListing.data];
            for (let i = 0; i < data.pricing_zone_ids.length; i++) {
                selectedZones.forEach((zone) => {
                    if (data.pricing_zone_ids[i] === zone.id) {
                        zone.is_selected = true;
                    }
                })
            }
            setZoneList(selectedZones)

            if (data.pricing_zone_id.length === resListing.data.length) {
                setIsAllZoneSelected(true);
            }

        } else {

            if (localStorage.getItem('productData')) {
                let data = await JSON.parse(localStorage.getItem('productData'));
                if (data.pricing_zone_ids.length > 0) {
                    let skuData = await searchSku(data.pricing_zone_ids[0], data.prod_id);
                    setSelectedProd(skuData[0])
                }
                setSelectedZoneList(data.pricing_zone_ids)
                let selectedZones = [...resListing.data];
                for (let i = 0; i < data.pricing_zone_ids.length; i++) {
                    selectedZones.forEach((zone) => {
                        if (data.pricing_zone_ids[i] === zone.id) {
                            zone.is_selected = true;
                        }
                    })
                }
                setZoneList(selectedZones)
                localStorage.removeItem('productData')
                setProductdata({ ...data });

            } else {
                setZoneList(resListing.data);
            }
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {/* header */}
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>Feed USP Banner</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>{status !== "new" ? "Update" : "Create"} USP Banner {status !== 'new' ? ` | ${selectedProd.usp_name}` : ''}</div>

                <div className={`${style.addContainer}`}>


                    {/* zone section */}
                    {status !== 'edit' ?
                    <div className={`${style.separator}`}>
                        {/* zone listing header*/}
                        <div className={`${style.mrg_top_3}`}>
                            <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                            <span className={`${style.zone_heading}`}>Select Zones</span>
                        </div>

                        {/* zone listing check boxes */}
                        <div className={`flex flex-sace-between flex-wrap`}>

                            {/* zone check boxes */}
                            <label onClick={() => { }} className={`${style.list_item}`}>
                                <input type="checkbox" className={`${style.checkbox}`} checked={isAllZoneSelected} onChange={(e) => { selectAllZone(e) }} />
                                <span className={`${style.checkmark}`}>All</span>
                            </label>

                            {
                                zoneList.map((city, index) => {
                                    return (
                                        <label key={city.id} onClick={() => { }} className={`${style.list_item}`}>
                                            <input type="checkbox" className={`${style.checkbox}`} id={city.id} checked={city.is_selected} onChange={(e) => { selectZone(e) }} />
                                            <span className={`${status === 'edit-all' && !city.is_selected ? 'red' : ''} ${style.checkmark}`}>{city.name}</span>
                                        </label>
                                    )
                                })
                            }
                        </div>
                        {isSubmitButtonClicked && (Productdata.pricing_zone_ids.length === 0) && <div className={`errorMessage`}>* Please Select Zone(s)</div>}
                    </div> :
                    <div className={`${style.separator}`}>
                        <div className={`${style.mrg_top_3}`}>
                            <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                            <span className={`${style.zone_heading}`}>Selected Zone - </span><span className={`bold green`}>{selectedZoneName()}</span>
                        </div>
                    </div>
                    }

                    <div className={`${style.mrg_top_3} ${style.grid_template_3col}`}>
                        <div className={`${style.margin_1rem} `}>
                            <div className={`bold ${style.title}`}>Name</div>
                            <input className={`${style.input_field}`} name='usp_name' type='text' placeholder='Name' min={0} value={Productdata.usp_name} onChange={(e) => { handleInputChange(e) }} />
                            {isSubmitButtonClicked && (Productdata.name === '') && <div className={`errorMessage`}>* Please Enter name</div>}
                        </div>
                        <div className={`${style.margin_1rem} `}>
                            <div className={`bold ${style.title}`}>Min lifetime orders</div>
                            <input className={`${style.input_field}`} name='min_lifetime_orders' type='number' placeholder='Min lifetime orders' min={0}  onWheel={(e) => e.target.blur()} value={Productdata.min_lifetime_orders} onChange={(e) => { handleInputChange(e) }} />
                            {isSubmitButtonClicked && (Productdata.min_lifetime_orders === '') && <div className={`errorMessage`}>* Please Enter Valid value</div>}
                        </div>
                        <div className={`${style.margin_1rem} `}>
                            <div className={`bold ${style.title}`}>Max lifetime orders</div>
                            <input className={`${style.input_field}`} name='max_lifetime_orders' type='number' placeholder='Max lifetime orders' min={0}  onWheel={(e) => e.target.blur()} value={Productdata.max_lifetime_orders} onChange={(e) => { handleInputChange(e) }} />
                            {isSubmitButtonClicked && (Productdata.max_lifetime_orders === '') && <div className={`errorMessage`}>* Please Enter Valid value</div>}
                        </div>
                    </div>

                    {/* Header Banner type selection  */}
                    <div className={`${style.mrg_top_3} ${style.grid_template_3col}`}>
                        
                        <div className={`${style.width_50} ${style.pd_t_30}`}>
                            <div className={`bold ${style.title}`}>Content type</div>
                            <Dropdown
                                name={Productdata.content_type}
                                list={usptype}
                                getDropDownValue={getContentType}
                                height={'10rem'} />
                        </div>


                        {/* conditional rendering of input field and image uploader  */}
                        {(Productdata.content_type.name === "VIDEO" || Productdata.content_type.name === "GIF") && <div>
                            <div className={`bold ${style.title}`}>Please Add URL</div>
                            <input className={`${style.input_field}`}
                                name='usp_data' type='text'
                                placeholder='http://URL/' value={Productdata.usp_data} onChange={(e) => { handleCheckBoxChange(e) }} />
                        </div>}

                        {/* Image uploader  */}
                        {(Productdata.content_type.name === "IMAGE") &&
                            <div className={`${style.pd_t_30}`}>
                                <div className={`grid grid-align-start ${style.width_70}`}>
                                    <div className={`${style.mrg_x_6} ${style.margin_1rem}`}>
                                        <input type="file" name="large_banner" id="large_banner" accept="image/*" 
                                        style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                        {
                                        Productdata.usp_data ? 
                                        <img 
                                            src={Productdata.usp_data ? 
                                                    `${ProductWidgetImgUrl}${Productdata.usp_data}` : 
                                                    null} 
                                            className={`${style.imgContainer}`} 
                                            onClick={() => { uploadImg("large_banner"); }} 
                                        />:
                                        <div 
                                            onClick={() => { uploadImg("large_banner"); }}   
                                            className={`flex flex-space-evenly pointer ${style.greyBrd} ${style.imgContainer} `} >
                                            <div className={`textCenter ${style.imgPlaceholder}`}>
                                                <img src={UploadIcon} />
                                                <div className={`green`}>Upload</div>
                                            </div>
                                        </div>
                                    }
                                        
                                        
                                        <div className={`fonts ${style.mrg_y_6} ${images.usp_data !== '' ? 'green' : 'red'}`}><span>
                                            Selected Image : </span>{images.usp_data ? images.usp_data : 'please select the image'}</div>
                                        <div className={`${style.title}`}>Feed header</div>
                                        <div className={`fontS gray`}> *  (Image size 343 x 80px) </div>
                                        <button id='large_banner' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                        {isSubmitButtonClicked && (Productdata.usp_data === '' || Productdata.usp_data === undefined) && 
                                        <div className={`errorMessage`}>* Please upload Header Banner Image</div>}
                                    </div>
                                </div>
                            </div>
                        }

                        {/* conditional rendering of input field and image uploader  */}
                        {(Productdata.content_type.name === "LOTTIE") && 
                        <div>
                            <div className={`bold ${style.title}`}>Please Add JSON</div>

                            {/* <input type="file" name="usp_data" id="usp_data" accept="application/JSON" 
                            onChange={(e) => saveFile(e)} />

                            <button id='usp_data' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button> */}
                            
                            <input className={`${style.input_field}`}
                                name="usp_data" type="text"
                                value={Productdata.usp_data} onChange={(e) => { handleCheckBoxChange(e) }} 
                            />
                        </div>}

                        {/* is web view */}
                        {
                            Productdata.content_type.name !== 'Content Type' &&
                            <div>
                                {/* radio btn */}
                                <div className={`bold ${style.pd_b_15} ${style.title}`}>SHOW WEB VIEW:</div>
                                <div className={`flex flex-space-between ${style.width_50}`}>
                                    <div className={`flex ${style.pd_b_15}`}>
                                        <input 
                                            type="radio" name='is_web_view' checked={Productdata.is_web_view}
                                            className={`${style.customRadio}`} onChange={(e) => { handleRadioChange(e, true) }}
                                        />
                                        <span> Yes </span>
                                    </div>
                                    <div className={`flex ${style.pd_b_15}`}>
                                        <input 
                                            type="radio" name='is_web_view' checked={!Productdata.is_web_view}
                                            className={`${style.customRadio}`} onChange={(e) => { handleRadioChange(e, false) }}
                                        />
                                        <span> No </span>
                                    </div>
                                </div>
                                
                                {
                                    Productdata.is_web_view &&
                                    <div className={`${style.mrg_top_6} `}>
                                        <div className={`bold ${style.title}`}>Web view URL</div>
                                        <input 
                                            value={Productdata.url}
                                            className={`${style.input_field}`} 
                                            name="url" placeholder={`http://url.com/home`}
                                            onChange={(e) => { handleInputChange(e) }}
                                        />
                                        {isSubmitButtonClicked && (Productdata.url === '') && <div className={`errorMessage`}>* Please Enter URL</div>}
                                    </div>
                                }
                            </div>
                        }

                    </div>


                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_6}`}>
                        <button onClick={() => { localStorage.removeItem('productData'); navigate('/header-banner/') }} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={validationProduct}>
                            {status !== "new" ? "Update" : "Add"} USP Banner
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>
            {popup && <div>
                <PopUp width={formStyle.popupWidthS} title={'Errors'} closeBtn={() => { setPopup(false) }} >
                    <div className={`${formStyle.inputContainer}`}>
                        {error504 && <div>
                            {errorList.map((err, idx) => {
                                return <div className={`${style.pd_bg1} red`}>{`${idx + 1}.${err}`}</div>
                            })} </div>
                        }

                       {!error504 && <div>{errorList.map((err, idx) => {
                            return <div className={`${style.pd_bg1} red`}>{`${idx + 1}. ${err.loc[1]} : ${err.msg}`}</div>
                        })} </div>
                        }
                    </div>
                </PopUp>
            </div>}
        </>
    );
}
