import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';


function PopupConfirmation({closeBtn, selZoneList, onSubmit}) {
    return (
        <PopUp
            title={`Update Subscription Product`}
            width={`${formStyle.popupWidthM}`}
            closeBtn={closeBtn}
        >
            <div className={`${formStyle.inputContainer}`}>
                Are you sure you want to update for zones:
                {
                    selZoneList.map((city, index) => {
                        return(
                            <div className={`bold`}>{index + 1}. {city.name}</div>
                        )
                    })
                }
            </div>
            <div className={`flex flex-space-between`} style={{marginTop: "1.2vh"}}>
                <button className={`${formStyle.btn} ${formStyle.secondaryBtn}`} onClick={closeBtn}>
                    No
                </button>
                <button className={`${formStyle.btn} ${formStyle.successBtn}`} onClick={onSubmit}>
                    Yes
                </button>
            </div>
            
        </PopUp>
    );
}

export default PopupConfirmation;