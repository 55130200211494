import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css'

function PopupOverlapingCampaign({closeBtn, overlapCampDetail, onSubmit}) {
    
    const showZoneList = () => {
        return overlapCampDetail.zone_list.join(', ')
    }
    return (
        <PopUp
            title={`Conflicting Campaigns: ${overlapCampDetail.id}`}
            width={`${formStyle.popupWidthS}`}
            closeBtn={closeBtn}
        >
            <div style={{lineHeight: "23px"}} className={` textLeft ${formStyle.inputContainer}`}>
                <div style={{marginBottom: "10px"}}> Zone Ids: <b>{showZoneList()}</b></div>
                <div style={{marginBottom: "14px"}}> Active From: <b>{overlapCampDetail.start_time}</b> to <b>{overlapCampDetail.end_time}</b> </div>
                <div style={{marginBottom: "30px"}}> {overlapCampDetail.msg}</div>
                <div className={`flex flex-space-between`}>
                    <button className={`${formStyle.btn} ${formStyle.secondaryBtn}`} onClick={closeBtn}>
                        No
                    </button>
                    <button className={`${formStyle.btn} ${formStyle.successBtn}`} onClick={onSubmit}>
                        Yes
                    </button>
                </div>
            </div>
        </PopUp>
    );
}

export default PopupOverlapingCampaign;