import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { addSegmentItem } from '../../../../utils/api';
import style from "../css/BonusManagement.module.css"

export default function AddSegmentForm(props) {
  const { wallet_id } = useParams()
  const [segmentData, setSegmentData] = useState({ is_reward: parseInt(wallet_id), is_active: false, pn_text: '', sms_text: '', schedule_time: '', pn_head: '', whatsapp_template: '', whatsapp_image_link: '', vars_list: '[]' });
  const navigate = useNavigate();

  const setValues = (e) => {
    let obj = {}
    obj[e.target.name] = parseInt(e.target.value);
    setSegmentData({ ...segmentData, ...obj });
  }

  useEffect(() => {
    let obj = segmentData;
    obj.is_reward = parseInt(wallet_id);
    setSegmentData({ ...obj });
  }, [])


  return (
    <div>
      <div className={`${style.header} flex flex-space-between`}>
        <div className={`${style.header_title}`}>
          Crofarm ERP | <span className={`bold green`}>Bonus Management</span>
        </div>
      </div>
      <div className={`${style.addCouponContainer}`}>
        <div className={`${style.addCouponForm}`}>
          <div className={`${style.couponHeader} flex flex-space-between`}>
            <div className={`${style.heading} bold green`}>Add Segment</div>
            <button className={`${style.filledBtn}`} onClick={() => navigate('/bonus-management/')}>Cancel</button>
          </div>
          <div className={`${style.firstRow} flex flex-space-between`}>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Min Order Count</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='min_order_count' onChange={(e) => { setValues(e) }} placeholder="Min Order Count" type='text' />
            </div>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Max Order Count</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='max_order_count' onChange={(e) => { setValues(e) }} placeholder="Max Order Count" type='text' />
            </div>
          </div>
          <div className={`${style.secondRow} flex flex-space-between`}>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Segment Periodicity</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='segment_periodicity' onChange={(e) => { setValues(e) }} placeholder="Segment Periodicity" type='text' />
            </div>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Last Order Days Start</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='min_last_order_day' onChange={(e) => { setValues(e) }} placeholder="Last Order Days Start" type='text' />
            </div>
          </div>
          <div className={`${style.secondRow} flex flex-space-between`}>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Last Order Days End</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='max_last_order_day' onChange={(e) => { setValues(e) }} placeholder="Last Order Days End" type='text' />
            </div>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Target Balance</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='target_reward_balance' onChange={(e) => { setValues(e) }} placeholder="Target Balance" type='text' />
            </div>
          </div>
          <div className={`${style.secondRow} flex flex-space-between`}>
            <div className={`${style.width_50}`}>
              <div className={`${style.title}`}>Recharge Amount</div>
              <input className={`${style.inputSegmentField} ${style.width75}`} name='recharge_amount' onChange={(e) => { setValues(e) }} placeholder="Recharge Amount" type='text' />
            </div>
          </div>
          <div className={`${style.submit}`}>
            <button className={`${style.filledSubmitBtn}`} onClick={() => { addSegmentItem([segmentData]); navigate('/bonus-management/') }}>Submit</button>
          </div>
        </div>
      </div>
      {/* <div className='Add_Segement_form_container'>


        <div className='Add_Segement_input_upper_container'>
          <div>
            <label className='Add_segment_text_label'>Recharge Amount</label>
          </div>
          <div className='Add_segemnt_input_container'>
            <input className='Add_campaign_input_item' name='recharge_amount' type='text' placeholder='Recharge Amount' onChange={(e) => { setValues(e) }} />
          </div>
        </div>
        <div className=""><button className={`create-campaign-btn btn-color`} onClick={() => { addSegmentItem(segmentData); navigate('/bonus-management/') }}>Submit</button></div>
      </div> */}
    </div>
  )
}