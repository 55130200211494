import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { updateKafkaSwitcherList } from '../../../../utils/api';
function PopUpKafkaSwitcher(props) {

    const handleSubmit = async () => {
      
            let data = {             
               key : props.title ,
               value : props.value
            }
            console.log(data);
            await updateKafkaSwitcherList(data);
            await props.fetchData();
            props.closeBtn();
    }
   
    return (
        <PopUp
        title={`Update Value | ${props.title}`}
        width={`${formStyle.popupWidthS}`}
        closeBtn={() => props.closeBtn(false)}
    >
        <div className={`${formStyle.inputContainer}`}>
            <div>Do you want to change the value for {props.title} </div>
        </div>
        <div className={`${formStyle.btnContainer} flex`}>
            <button onClick={handleSubmit} className={`${formStyle.confirmBtn}`} style={{marginRight:"20px"}}>Yes</button>
            <button onClick={props.closeBtn} className={`${formStyle.outlinedBtn}`}>No</button>
        </div>

    </PopUp>
    );
}

export default PopUpKafkaSwitcher;