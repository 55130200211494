import React, { useState } from 'react';
import style from '../css/BurnMapping.module.css';
import BurnMappingList from './BurnMappingList';
import UserSegmentList from './UserSegmentList';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
function Header(props) {
    const data = [
        {
            "id":1,
            "name":"Burn Mapping"
        },
        {
            "id":2,
            "name":"User Segment"
        }
    ]
    const[filter,setFilter] = useState(data[0]);
    const [showPopUp,setshowPopUp] = useState(false);

    const togglePopUp = () => {
        setshowPopUp(!showPopUp);
     }
    return (
        <>
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Burn Mapping</span></div>

               <div className={`flex flex-space-between`}>
                    <Dropdown 
                        list={data}
                        name={filter}
                        getDropDownValue={(elem) => {
                            setFilter(elem);
                        }}/>

                    <div>
                        <button 
                        className={`${style.btnM} ${style.filledBtn}`}
                        onClick={()=>{
                            togglePopUp();
                        }}
                        >
                        {`Add ${filter.name}`}
                        </button>
                    </div>
                </div>
            </div> 
            {
                filter.id==1 && <BurnMappingList showPopUp={showPopUp} setshowPopUp={setshowPopUp}/>
            }
            {
                filter.id==2 && <UserSegmentList showPopUp={showPopUp} setshowPopUp={setshowPopUp}/>
            }

        </>
    );
}

export default Header;