import React, { Component } from 'react'
import "./Dropdown.css"
import DropdownIcon from '../../icons/down-arrow.png';
import CloseImg from '../../icons/close-black.png'

export default class Dropdown extends Component {

    constructor(props) {
        super(props);
        console.log('dropdown',props);
        this.state = {
            isDropdownVisible: false,
            isSelected: "",
            searchQuery: ""
        }
    }

    hideDropdownMenu = () => { 
        if(this.state.isDropdownVisible) this.setState({ isDropdownVisible: false });
    }


    render() {
        return (
        <>
            {
                this.state.isDropdownVisible && 
                <div className={`dropdown_dummy_div`} onClick={() => this.setState({ isDropdownVisible: false }) }></div>
            }
            <div className='dropdown_container' >
            {   
                (this.state.isDropdownVisible && this.props.showSearch) ?
                // search input
                <div 
                    className={`dropdown_header ${this.props.ondcBlue ? 'ondcOutline': 'greenOutline' }`}
                    style={{ width: this.props.header_width ? this.props.header_width :''}}>
                    <div className={`flex flex-space-between align-items-center`}>
                        <input 
                            type="text"
                            placeholder={this.props.placeholder}
                            className={`seachInp`}
                            value={this.state.searchQuery}
                            onChange={(e) => this.setState({ searchQuery: e.target.value })}
                            autoFocus={true}
                        />
                        <img 
                            height={'16px'}
                            width={'16px'}
                            src={CloseImg} 
                            className={`pointer`} 
                            onClick={() => this.setState({ isDropdownVisible: false, searchQuery: "" })}
                        />  
                    </div>
                </div> :
                <div
                    className={`dropdown_header  ${this.props.ondcBlue ? 'ondcOutline': 'greenOutline' } ${this.props.disabled ? 'not_allowed dropdown_header_disabled' : 'pointer' }`}
                    style={{ width: `${this.props.header_width}` ? `${this.props.header_width}` :'', minWidth: this.props.minWidth }}
                    onClick={() => {
                        if(!this.props.disabled) this.setState({ isDropdownVisible: !this.state.isDropdownVisible })
                    }}>
                    <div className={`flex flex-space-between`}>
                        <div className='text_container'>
                            {
                                this.props.NoKey ? 
                                <>{this.props.name || this.props.name === '0' ? this.props.name : this.props.placeholder}</> : 
                                this.props.nokey ?
                                this.props.name :
                                <>
                                    {`${this.props.name.name} ${this.props.name.title ? `|  ${this.props.name.title}` : ''}`} 
                                </>
                            }
                            
                        </div>
                        {/* { !this.props.disabled && <img src={DropdownIcon} /> } */}
                        <img src={DropdownIcon} />
                    </div>
                </div>
            }
                <div style={{ zIndex: "1" }}>
                    {(this.state.isDropdownVisible) &&
                        <ul className='list'
                        style={{
                            // width: `${this.props.width}` ? `${this.props.width}` :'220px',
                            maxWidth: `250px`,
                            maxHeight: `${this.props.height}` ? `${this.props.height}` : 'auto' ,
                        }}
                        >
                        {this.props.list
                            .filter((elem) => {
                                // NoKey is true
                                if(this.props.NoKey){
                                    elem = elem.toString()
                                }

                                // search conditions
                                if(this.state.searchQuery === ""){
                                    return elem
                                }
                                else if(this.props.NoKey && elem.toLowerCase().includes(this.state.searchQuery.toLowerCase())){
                                    return elem
                                } 
                                else if(!this.props.NoKey && elem.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())){
                                    return elem
                                }
                            }).map((item, itemIdx) => (
                            <li 
                                className={`option ${this.props.checkboxList ? 'textleft' : ''}`}
                                key={item.id ? item.id : item} 
                                onClick={() => { 
                                    if(!this.props.checkboxList)
                                        this.setState({ isDropdownVisible: false }); 
                                    this.props.getDropDownValue(item, this.props.type, itemIdx); }
                                }>
                                    <label>
                                        { this.props.checkboxList && <input type={"checkbox"} checked={item.is_selected} /> }{" "}
                                        { this.props.NoKey ? <>{item}</> : <>{item.name}</> }
                                    </label>

                            </li>
                        )
                        )}
                    </ul>}
                </div>
            </div>
        </>
        )
    }
}
