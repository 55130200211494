import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css'

function PopupUserSegment(props) {
    return (
        <PopUp
            title={`User Segment info`}
            width={`${formStyle.popupWidthM}`}
            closeBtn={props.closeBtn}
        >
            <div className={` textLeft ${formStyle.inputContaijner} ${formStyle.overlayDropdown_12}`}>
                {/* heading */}
                <div className={`grid black ${formStyle.user_segment_grid} ${formStyle.brd_btm}`}>
                    <div className={`${formStyle.pd_cell}`}>Segment Id</div>
                    <div className={`${formStyle.pd_cell}`}>User Segment</div>
                    <div className={`${formStyle.pd_cell}`}>Description</div>
                    <div className={`${formStyle.pd_cell}`}>Mark Down %</div>
                </div>
                {
                    props.data.map((segment) => {
                        return(
                            <div className={`grid ${formStyle.user_segment_grid} ${formStyle.brd_btm}`}>
                                <div className={`${formStyle.pd_cell}`}>{segment.id}</div>
                                <div className={`${formStyle.pd_cell}`}>{segment.segment_name}</div>
                                <div className={`${formStyle.pd_cell}`}>{segment.description}</div>
                                <div className={`${formStyle.pd_cell}`}>{segment.mark_down}</div>
                            </div>
                        )
                    })
                }
            </div>
        </PopUp>
    );
}

export default PopupUserSegment;