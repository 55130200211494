import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../css/CampaignList.module.css'
function Header() {
    const navigate = useNavigate();

    const CreateCampaign = () => {
        navigate(`/communication/create-campaign/0/`);
    }

    return (
        <>
            <div className={`${style.header} flex flex-space-between`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Communication Campaign Dashboard</span> </div>
                <div>
                    <button className={`${style.btnM} ${style.filledBtn}`} onClick={() => { CreateCampaign() }} >Create Campaign</button>
                </div>
            </div>
        </>

    );
}

export default Header;