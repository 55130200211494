import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie-player'
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import Loader from '../../../../commonComponent/Loader/Loader';
import { deepCopyObject, getCatListZoneWise, getMiniFeedDetail, getSubCatListZoneWise, getWidgetList, saveMiniFeed, toggle, uploadSelImage, validateData, uploadSelVideo } from '../../../../utils/api';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import PopupErrorList from './PopupErrorList';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import style from "../css/MiniFeed.module.css";
import BinGreyIcon from '../../../../icons/bin-grey.svg';
import ArrowIcon from '../../../../icons/down-arrow.png';
import UploadVideoIcon from '../../../../icons/upload-video.svg';
import AddIcon from '../../../../icons/add-icon-green.svg';
import { ReactComponent as UploadIcon } from '../../../../icons/ico-upload.svg';
import { ReactComponent as BackIcon } from '../../../../icons/arrowleftgrey.svg';
import { ReactComponent as PlusIcon} from '../../../../icons/plus.svg';
import { ReactComponent as PlayIcon} from '../../../../icons/play.svg';
import { LottieFileUrl, ProductWidgetImgUrl } from '../../../../utils/config';
import { headerType, screenType, visualType,aligmentType,media, seeMoreWidgets } from '../../../../utils/constData'
import { ErrorHandling } from '../../../../State';
import PopupShowVideo from '../../AppFeed/components/PopupShowVideo';


function MiniFeedWidget(props) {
    const {status, id, selZone, selFeedId } = useParams()
    const navigate = useNavigate() 
    const [isLoading, setLoader] = useState(false);
    const [showPopup,setPopup] = useState({
        status: false,
        message: [],
        showVideoPopup: false, 
        videoUrl: "",
    });
    const [errorList, setErrorList] = useState({
        status: false,
        message: []
    });
    const [widgetData, setWidgetData] = useState({
        background_color: "",
        background_image: "",
        category_id: 0,
        content_id: 0,
        data: {},
        id: 0,
        no_of_children: 13,
        rank: "",
        sub_title: "",
        title: "",
        widget_bg_color: "",
        widget_icon: "",
        widget_type: "banners_list_wd", 
        selSubCat: {},
        zone_name: "",
        show_subs_btn : false,
        show_see_more: true,
        show_quality_attributes: false,
        bg_color_1: "",
		bg_color_2: "",
        text_color: "",
        title_text_color: "",
        title_background_color: "",
        title_type: {name: "Select Header Type" , id: 0},
        visual_type: {name: "Select Visual Type", id: 0},
        widget_tag: "",

        bottom_padding: 16,
        is_carousal_auto: false,
        alignment: aligmentType[0],
        content_height: 0,
        content_width: 0,
        show_video_timeline: false,
        play_video_sound: false,
        show_sound_control: false,
    })
    const [listing, setListing] = useState({
        widgetType : [],
        optionalField :{
            show: false,
            inputType: null,
            list : []
        }, 
        bannerList: [], 
        catList : [],
        subCatlist : [],

        name: "",
        footer_image: "",
        header_image: "",
        status : 0,
        show_search: 0,
        show_share: 0,
        feed_type: 0,

        feedId: selFeedId,
        feed_tag: "",
        zone_name: "",
        feed_type: 0,
        pricing_zone_id: selZone,

    })
    const [contentData, setContentData] = useState([{
        screenType : {name: "Select Screen"},
        image: "",
        media: "",
        media_type: media[0],
        landingScreen: "",
        landingScreenDropDown: {name: "Select Landing Screen"},
    }])

    // handle new widget type
    const handleAddNewWidgetType = () => {
        // let obj = {  	
        //     name : "",
        //     content_type : "",
        //     content_id : "",
        //     image: "",
        //     type_name: "",
        // }
        let obj = {
            media_type: widgetData.widget_type === "video_carousal_wd" ? media[1] : media[0],
            media: "",
            screenType : {name: "Select Screen"},
            image: "",
            landingScreen: "",
            landingScreenDropDown: {name: "Select Landing Screen"}
        }
        
        setContentData([... contentData, obj])
    }

    // handle lisiting value changes
    const handleScreenTypeChange = (index, value, key) => {
        let localContentData =  Object.assign([], contentData)
        localContentData[index][key] = value;

        // On 'screenType' change -> set 'landingScreen', 'landingScreenDropDown' to its default values
        if(key === 'screenType'){
            localContentData[index].landingScreen = "";
            localContentData[index].landingScreenDropDown = {name: "Select Landing Screen"};
        }

        // On 'media_type' change -> set 'image', 'media' to its default values 
        if(key === 'media_type'){
            localContentData[index].image = "";
            localContentData[index].media = "";
        }

        // On 'landingScreen' change for 'screenType' "Subscription screen" -> check for number input only
        if(key === 'landingScreen' && localContentData[index].screenType.name === 'Subscription screen'){
            if(isNaN(value)) {
                ErrorHandling("Please input Prod ID as number!");
                return;
            }
        }

        setContentData(localContentData)
    }

    // handle mix widget prod list
    const handleDeleteSku = (index) => {
        let localContentData = deepCopyObject(contentData);
        localContentData.splice(index, 1);

        if(!localContentData.length){
            let localWidgetData = deepCopyObject(widgetData);
            localWidgetData.content_height = 0;
            localWidgetData.content_width = 0
            setWidgetData(localWidgetData)
        }

        setContentData(localContentData)
    }

    // submit data
    const handleSubmit = async() => {

        if(listing.feed_tag == undefined || !listing.feed_tag.length){
            ErrorHandling("Feed Tag cannot be empty")
            return;
        }

        let bannerObj = deepCopyObject(widgetData);
        let localListing = deepCopyObject(listing);
        // debugger;
        let bannerListing = deepCopyObject(localListing.bannerList)
        let res = validateData(bannerObj, true);
        
        let validContentData = true;
        if(listing.optionalField.inputType === 'list'){
            if(!contentData.length){
                ErrorHandling("Please Add Atleast One (1) Content Data!")
                validContentData = false;
                return;
            }
            contentData.forEach((widget) => {
                if(!widget.screenType.id){
                    ErrorHandling("Please select Screen Type")
                    validContentData = false;
                    return;
                }
                if( (widget.screenType.id == 4 || widget.screenType.id == 5 )
                    && !widget.landingScreenDropDown.id) {
                    ErrorHandling("Please select Landing Screen")
                    validContentData = false;
                    return;
                }
                if( !(widget.screenType.id == 4 || widget.screenType.id == 5 ) && 
                     widget.screenType.has_landing_screen &&
                    !widget.landingScreen ){
                    ErrorHandling("Please select Landing Screen")
                    validContentData = false;
                    return;
                }
                if(!widget.image){
                    ErrorHandling("Please Upload Image")
                    validContentData = false;
                    return;
                }
                if(!widget.name){
                    ErrorHandling("Please Added Name")
                    validContentData = false;
                    return;
                }

                let media_type = widget.media_type.id ? widget.media_type.id : widget.media_type;
                if((media_type == 1 || media_type == 2|| widgetData.widget_type === "video_carousal_wd") && !widget.media){
                    ErrorHandling(`Please upload ${media_type == 1 ? 'video' : 'lottie'}  `)
                    validContentData = false;
                    return;
                }
            })
        }

        console.log(res && validContentData);
        if(res && validContentData){
            let index = bannerListing.length;
            bannerListing.forEach((obj, idx) => {
                if(obj.id == bannerObj.id)
                    index = idx;
            });

            // creating data accordingly
            bannerObj.content_height = bannerObj.content_height ? parseInt(bannerObj.content_height) : 0
            bannerObj.content_width = bannerObj.content_width ? parseInt(bannerObj.content_width) : 0
            bannerObj.bottom_padding = parseInt(bannerObj.bottom_padding)
            bannerObj.rank = parseInt(bannerObj.rank)
            bannerObj.show_see_more = seeMoreWidgets.includes(bannerObj.widget_type) ? bannerObj.show_see_more : false

            bannerObj.title_type = bannerObj.title_type.id;
            bannerObj.visual_type = 
                (bannerObj.title_type == 3 || bannerObj.title_type == 4) ?  
                bannerObj.visual_type.id : 0;

            // adding content data only for selec ted widget types
            if(listing.optionalField.inputType === 'list'){
                let content_data = []
                contentData.forEach((widget) => {
                    let obj = {
                        name: widget.name ? widget.name : "",
                        content_type: widget.screenType.id,
                        image: widget.image,
                        type_name: widget.screenType.name,
                        media: widget.media,
                        media_type: widget.media_type.name ? widget.media_type.id : widget.media_type,
                        
                    }

                    if(obj.content_type == 4 || obj.content_type == 5 ) {
                        obj.content_id = widget.landingScreenDropDown.id;
                    }
                    else{
                        obj.content_id = widget.landingScreen
                    }

                    obj.category_id = obj.content_type == 4 ?  widget.landingScreenDropDown.cat_id : 0;
                    
                    content_data.push(obj);
                })
                bannerObj.content_data = content_data;
            }
            else{
                bannerObj.content_data = []
            }

            bannerListing[index] = deepCopyObject(bannerObj);
            
            // debugger;
            // if(typeof bannerListing[index].content_id !== 'object'){
            //     bannerListing[index].content_id = [bannerObj.content_id];
            // }
            if(listing.optionalField.inputType === 'number' )  {
                if(typeof(widgetData.content_id) !== 'object')
                bannerListing[index].content_id = [widgetData.content_id];
            }
            else if(widgetData.widget_type === "cat_prod_wd") {
                    bannerListing[index].content_id = [widgetData.selSubCat.id];
            }
            else if(widgetData.widget_type === "subcat_prod_wd") {
                // if(!widgetData.content_id.length){
                    bannerListing[index].content_id = [widgetData.selSubCat.id];
                // }
                bannerListing[index].category_id = widgetData.selSubCat.cat_id
            }
            else {
                bannerListing[index].content_id = [0];
            }

            // bannerListing[index].content_id = widgetData.selSubCat?.id ? [widgetData.selSubCat.id] : [0];
            console.log(bannerListing)
            let data = {
                feed_type: 0,
                feed_tag: listing.feed_tag ,
                pricing_zone_id: selZone,
                widget_list: bannerListing, 

                footer_image: listing.footer_image,
                header_image: listing.header_image,
                status : listing.status,
                show_search: listing.show_search,
                show_share: listing.show_share,
                name: listing.name,
                top_padding: listing.top_padding,
                
            }
            if(listing.feedId){
                data.feed_id = listing.feedId
            }

            // check weather content_id id array
            if( !data.widget_list[index].content_id.length) {
                data.widget_list[index].content_id = [ data.widget_list[index].content_id ]
            }

            if( data.widget_list[index].alignment?.name){
                data.widget_list[index].alignment = data.widget_list[index].alignment.id
            }

            // debugger;
            setLoader(true)
            let response = await saveMiniFeed(data)
            setLoader(false)

            if(response.success){
                navigate(`/edit/${listing.feedId}/${listing.pricing_zone_id}/mini-feed-detail/`)
            }
            else if(response.error.code == 4001){
                let message = JSON.parse(response.error.message)
                console.log(message)

                setPopup({
                    status: true,
                    message: message
                })
            }
            else if(response.error.status_code == 422){
                // let message = JSON.parse(response.error.message)
                // console.log(message)

                setErrorList({
                    status: true,
                    message: response.detail
                })
            }

        }
    }

    // handle upload image
    const handleUploadImage = async(id, type, idx) => {
        let image = document.getElementById(id).files[0];
        console.log(image);

        let imgTypeExt = image.type.split('/')

        var image_type = 'large';
        let data = new FormData();
        data.append('file', image);
        data.append('file_ext', '.' + imgTypeExt[1]);
        data.append('image_type', image_type)

        if( image.type !== 'image/png' &&
            image.type !== 'image/jpg' &&
            image.type !== 'image/jpeg' && 
            image.type !== 'image/webp' ){
            image = "";
            ErrorHandling('Please select correct image format')
        }
        else {
            let img = new Image;
            img.onload = async function () {
                
                setLoader(true)
                let res = await uploadSelImage(data);
                setLoader(false)

                let widget = deepCopyObject(widgetData)
                if(type === 'contentData'){
                    let localContentData = deepCopyObject(contentData)
                    
                    // getting first image height and width
                    // this will be further standard for further images to be uploaded
                    if(!widget.content_width || !widget.content_height) {
                        widget.content_height = img.height;
                        widget.content_width = img.width;
                        localContentData[idx].image = res
                    }
                    else if(
                        !(widgetData.widget_type === "image_carousal_wd" ||
                        widgetData.widget_type === "video_carousal_wd" ||
                        widgetData.widget_type === "dynamic_banner_wd" ||
                        widgetData.widget_type === "dynamic_end_to_end_banner_wd" ) || 

                        (widget.content_height == img.height && widget.content_width  == img.width)
                    ) {
                        localContentData[idx].image = res;
                    }
                    else {
                        console.log(img.height, img.width );

                        ErrorHandling(`Please upload Image with dimension height: ${widget.content_height} px, width: ${widget.content_width} px`)
                        return;
                    }

                    setContentData(localContentData);
                    setWidgetData(widget);
                }
                else {
                    let widget = Object.assign({}, widgetData)
                    widget.title = res
                    widget.title_width = img.width;
                    widget.title_height = img.height;
                    setWidgetData(widget);
                }
                console.log(img.height, img.width );
                image = "";
            }
            img.src = img.src = URL.createObjectURL(image);
        }

    }

    // handle upload video
    const handleUploadVideo = async(id, type, idx) => {
        var file = document.getElementById(id).files[0];
        console.log(file);

        let videoTypeExt = file.type.split('/')

        var video_type = '';
        let data = new FormData();

        data.append('file', file);
        data.append('file_name', videoTypeExt[0]);
        data.append('file_ext', '.' + videoTypeExt[1]);
        data.append('path', video_type)

        var video = document.createElement('video');
        video.preload = 'metadata';

        if( file.type !== 'video/mp4' ){
            file = "";
            ErrorHandling('Please select correct video format')
        }
        else{
            video.onloadedmetadata = async function() {

                window.URL.revokeObjectURL(video.src);

                if (video.duration < 1) {
                    ErrorHandling("Invalid Video! video is less than 1 second");
                    return;
                }
                console.log(video.duration);

                setLoader(true)
                let res = await uploadSelVideo(data) ;
                setLoader(false)

                let widget = deepCopyObject(widgetData)
                let localContentData = deepCopyObject(contentData)

                // getting first video / image videoHeight and videoWidth
                // this will be further standard for further images to be uploaded
                if(!idx && localContentData.length == 1) {
                    widget.content_height = video.videoHeight;
                    widget.content_width = video.videoWidth;
                    localContentData[idx].media = res
                }
                else if(widget.content_height == video.videoHeight && widget.content_width  == video.videoWidth) {
                    localContentData[idx].media = res;
                }
                else {
                    console.log(video.videoHeight, video.videoWidth );

                    ErrorHandling(`Please upload Video with dimension height: ${widget.content_height} px, width: ${widget.content_width} px`)
                    return;
                }
                setContentData(localContentData);
                setWidgetData(widget);

                console.log(video.videoHeight, video.videoWidth );
                file = "";
            }
            video.src = URL.createObjectURL(file);
        }

    }

    // hanlde lottie upload --> .json files Only
    const handleUploadLottie = async(id, type, idx) => {
        var file = document.getElementById(id).files[0];
        console.log(file);

        let fileTypeExt = file.type.split('/')

        var filePath = 'lotties/';
        let data = new FormData();

        data.append('file', file);
        data.append('file_name', fileTypeExt[0]);
        data.append('file_ext', '.' + fileTypeExt[1]);
        data.append('path', filePath)

          
        setLoader(true)
        let res = await uploadSelVideo(data) ;
        setLoader(false)
        if(file.type !== 'application/json') {
            file = "";
            ErrorHandling(`Please select '.json' file for Lottie !`)
        }
        else{
            let localContentData = deepCopyObject(contentData)
            localContentData[idx].media = res;
            setContentData(localContentData);
            file = "";
        }

    }

    // input field change
    const handleWidgetDataChange = (key, value) => {

        let data = deepCopyObject(widgetData);
        data[key] = value;
        if(key === "widget_type"){
            let obj = Object.assign({}, listing);
            // setting default values
            data.content_height = 0;
            data.content_width  = 0;
            data.alignment = aligmentType[0];
            data.is_carousal_auto = false;
            data.show_video_timeline = false;
            data.play_video_sound = false;
            data.show_sound_control = false;

            data.content_data = [{
                screenType : {name: "Select Screen"},
                image: "",
                media: "",
                media_type: value == "video_carousal_wd" ? media[1] : media[0],
                landingScreen: "",
                landingScreenDropDown: {name: "Select Landing Screen"},
                type_name: 'Select Screen',
                content_type: 0,
            }]
            setOptionalField(data, obj)
        }
        else if(key === 'title_type' || key === 'visual_type'){
            data.title = "";
            data.sub_title = "";
            data.title_text_color = "";
            data.title_background_color = "";

            if(value.id === 3 || value.id === 4){
                data.visual_type = visualType[0]
            }
            setWidgetData(data);
        }
        else {
            setWidgetData(data);
        }
    }
    
    // setOptional field Data 
    // use this when the widget type is changed as well
    const setOptionalField = async(selItem, obj) => {
        console.log(selItem);
        console.log(obj);

        // zone name: 
        let zone = JSON.parse(localStorage.getItem('zone'))
        obj.zone_name = zone.name

        // to handle new widget 
        if(!selItem){
            console.log("add new widget")
        }
        // hanlding the optional field data 
        else if(selItem.widget_type === "subcat_prod_wd"){
            let list = await getSubCatListZoneWise(selZone);
            obj.optionalField = {
                show: true,
                inputType: 'dropdown',
                list : list
            }
            let selSubCat = list.filter((item) => item.id == selItem.content_id[0]);
            if(selSubCat.length)
                selItem.selSubCat = selSubCat[0];
            else 
                selItem.selSubCat = list[0];
        }
        else if(selItem.widget_type === "cat_prod_wd"){
            let list = await getCatListZoneWise(selZone)
            obj.optionalField = {
                show: true,
                inputType: 'dropdown',
                list : list
            }

            let selSubCat = list.filter((item) => item.id == selItem.content_id[0]);
            if(selSubCat.length)
                selItem.selSubCat = selSubCat[0];
            else 
                selItem.selSubCat = list[0];

        }
        else if(
            selItem.widget_type === "virtual_cat_wd"  ||
            selItem.widget_type === "subscription_wd" ||
            selItem.widget_type === "image_list_wd"   ||
            selItem.widget_type === "large_prod_wd"   || 
            selItem.widget_type === "medium_prod_wd"  ||
            selItem.widget_type === "image_video_wd"  ||
            selItem.widget_type === "square_prod_wd"  
        ){
            obj.optionalField = {
                show: true,
                inputType: 'number',
                list : []
            }
        }
        else if(
            selItem.widget_type === "image_carousal_wd" ||
            selItem.widget_type === "video_carousal_wd" ||
            selItem.widget_type === "dynamic_banner_wd" ||
            selItem.widget_type === "dynamic_end_to_end_banner_wd" ||

            selItem.widget_type === "3_element_dynamic_wd" ||
            selItem.widget_type === "static_carousal_auto_wd" ||
            selItem.widget_type === "mix_random_wd"  ||
            selItem.widget_type === "4_mix_wd"       ||
            selItem.widget_type === "6_mix_wd"       ||
            selItem.widget_type === "4_box_wd"       ||
            selItem.widget_type === "3_box_horiz_wd" ||
            selItem.widget_type === "3_box_vert_wd"  ||
            selItem.widget_type === "large_img_wd"   ||
            selItem.widget_type === "small_img_wd"   ||
            selItem.widget_type === "end_to_end_banner_wd"   
        ){
            obj.optionalField = {
                show: true,
                inputType: 'list',
                list : []
            }

            // set alignment type 
            if(selItem.widget_type === 'image_carousal_wd'){
                if(isNaN(selItem.alignment)) {
                    selItem.alignment = aligmentType[0]
                    console.log("hereeee defaultttt ")
                }
                else {
                    let selAlignment = aligmentType.filter((alignment) => alignment.id == selItem.alignment)
                    selItem.alignment = selAlignment[0]
                }
            }

            // set content data
            if(!obj.subCatlist.length){
                obj.subCatlist = await getSubCatListZoneWise(selZone);
            }
            if(!obj.catList.length){
                obj.catList = await getCatListZoneWise(selZone);
            }
            if(selItem.content_data && selItem.content_data.length){

                let contentDataList = []
                selItem.content_data.forEach((content)=> {
                    let contentObj = {
                        screenType : {name: "Select Screen", id: 0},
                        image: "",
                        landingScreen: "",
                        landingScreenDropDown: {name: 'Select landing Screen'}
                    }
                    contentObj.image = content.image;
                    contentObj.screenType.name = content.type_name;
                    contentObj.screenType.id = content.content_type;
                    contentObj.name = content.name;
                    contentObj.media = content.media;

                    // TODO: handle for dynamic media_type || dropdown
                    let mediaType = media.filter((type) => content.media_type == type.id)
                    if( selItem.widget_type === "video_carousal_wd" ) {
                        contentObj.media_type = media[1];
                    }
                    else if(mediaType.length){
                        contentObj.media_type = mediaType[0];
                    }
                    else{
                        contentObj.media_type = media[0];
                    }
                    
                    // to be made conditonal 
                    if(content.content_type === 4){

                        let selSubCat = obj.subCatlist.filter((item) => item.id == content.content_id)
                        if(selSubCat.length)
                            contentObj.landingScreenDropDown = selSubCat[0]
                        else contentObj.landingScreenDropDown = obj.subCatlist[0]

                    }
                    else if(content.content_type === 5){
                        
                        let selSubCat = obj.catList.filter((item) => item.id == content.content_id)
                        if(selSubCat.length)
                            contentObj.landingScreenDropDown = selSubCat[0]
                        else contentObj.landingScreenDropDown = obj.catList[0]

                    }
                    else{
                        contentObj.landingScreen = content.content_id;
                    }

                    contentDataList.push(contentObj)
                })
                setContentData(contentDataList)
            }
        }
        else {
            obj.optionalField = {
                show: false,
                inputType: null,
                list : []
            }
            // debugger
            if(selItem.selSubCat) 
                selItem.selSubCat.id = 0
        }
        setListing(obj)
        setWidgetData(selItem)
        
    }

    // fetch data const
    const fetchData = async() => {
        // get banner list 
        setLoader(true)
        let feeds = await getMiniFeedDetail(selFeedId);
        setLoader(false)
        
        let feed = feeds[0]
        let bannerList = feed.widget_list
        if(!bannerList )
            bannerList = []
        //     navigate('/app-feed/')

        // variables for edit banner 
        let selFeed, widData;

        if(status === "edit"){
            // sort the selected banner item
            selFeed = bannerList.filter(item => item.id == id)
            widData = selFeed[0];

            // set padding
            selFeed[0].bottom_padding = selFeed[0].bottom_padding ? selFeed[0].bottom_padding  : 16

            // set header type
            if(selFeed[0].title_type !== undefined && selFeed[0].title_type !== "" ){
                if( !isNaN(selFeed[0].title_type) ){
                    let selHeaderType = headerType.filter(type => type.id == selFeed[0].title_type);
                    selFeed[0].title_type = selHeaderType[0];
                }
                else{
                    selFeed[0].title_type = {id:0, name: "Select Header Type" }
                }
    
                // set visual type
                if(selFeed[0].title_type !== undefined && (selFeed[0].title_type.id == 3 || selFeed[0].title_type.id == 4)){
                    let selVisualType = visualType.filter(type => type.id == selFeed[0].visual_type)
                    selFeed[0].visual_type = selVisualType[0]
                }
                else{
                    selFeed[0].visual_type = { id: 0, name: "Select Visual Type" }

                }
            }
            else{
                selFeed[0].title_type = headerType[0]
            }
            
        }

        // get widget list for dropdown
        let list = await getWidgetList();
        let obj = Object.assign({}, listing);
        obj.widgetType = list;
        obj.bannerList = bannerList;
        obj.feedId = feed.feed_id;
        obj.feed_tag = feed.feed_tag;
        obj.pricing_zone_id = feed.pricing_zone_id;
        obj.footer_image =  feed.footer_image;
        obj.header_image = feed.header_image;
        obj.status =  feed.status;
        obj.show_search =  feed.show_search;
        obj.show_share =  feed.show_share;
        obj.top_padding = feed.top_padding;

        // set addition field data
        if(status === "edit")
            setOptionalField(selFeed[0], obj)
        else 
            setOptionalField(widgetData, obj)
    } 

    useEffect(() => {
        fetchData()
    },[])

    return (
        <>
            {/* header */}
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>
                    <BackIcon 
                        className={`${style.backIcon}`} 
                        onClick={() => navigate(`/edit/${selFeedId}/${selZone}/mini-feed-detail/`)}
                    />{" "}
                    Crofarm ERP | <span className={`bold green`}>Add Mini Feed Widget </span>
                </div>
            </div> 

                <div className={`${style.mrgL}`}>
                    <div className={`${style.createWidget}`}>
                        {status === "edit"? 'Edit' : 'Add'} Mini Feed Widget for <b>' {listing.zone_name} '</b>
                    </div>
                    
                    <div className={`${style.listingContainer}`}>
                        <div className={`${style.widget_container}`}>
                            {/* input fields container */}
                            <div className={`${style.firstBox}`}>

                                {/* Widget Type*/}
                                <div className={`${style.row}`}>

                                    {/* widget tag */}
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Widget Tag</div>
                                        <input 
                                            className={`${style.inputField}`} 
                                            value={widgetData.widget_tag} 
                                            onChange={(e) => handleWidgetDataChange('widget_tag',e.target.value)} 
                                            type='text' 
                                            placeholder='Widget Tag'
                                            disabled={widgetData.disableFields}
                                        />
                                    </div>

                                    {/* bottom padding */}
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>WIDGET BOTTOM PADDING (in px) </div>
                                        <input 
                                            className={`${style.inputField}`} 
                                            value={widgetData.bottom_padding}
                                            onChange={(e) => handleWidgetDataChange('bottom_padding',e.target.value)} 
                                            type='number' 
                                            min={'0'}
                                            max={'24'}
                                            placeholder='Widget Bottom Padding'
                                            onWheel={(e) => e.target.blur()}
                                            disabled={widgetData.disableFields}
                                        />
                                    </div>

                                    {/* widget type */}
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Widget Type</div>
                                        <div 
                                            className={`dropdown_dummy_div hide`} 
                                            id={`child_widget_type_div`} 
                                            onClick={()=>{
                                                toggle('widget_type')
                                                toggle('widget_type_div')
                                            }}></div>
                                        <div className={`${style.dropdown} ${style.width100}`}>
                                            <button onClick={()=>{
                                                toggle('widget_type')
                                                toggle('widget_type_div')
                                                }} className={`flex flex-space-between ${style.dropdownMenu}`}>{
                                                    widgetData.widget_type} <img src={ArrowIcon}/>
                                            </button>
                                            
                                            <ul className={`${style.dropdownList} ${style.overlay12} hide`} id={'child_widget_type'}>
                                                {
                                                    listing.widgetType.map((elem, index)=>{
                                                        return (
                                                        <li key={index} onClick={()=>{
                                                            // setBannerType(elem);
                                                            handleWidgetDataChange('widget_type', elem.type)
                                                            toggle('widget_type')
                                                            toggle('widget_type_div')
                                                        }}>{elem.type}</li>
                                                        );
                                                    })
                                                }
                                            </ul>
                                            
                                        </div>
                                        {/* <input value={widgetData.widget_type} onChange={(e) => handleWidgetDataChange('widget_type',e.target.value)} type='text' placeholder='Title'/> */}
                                    </div>
                                                
                                    {/* conditional field */}
                                    {
                                        listing.optionalField.show && listing.optionalField.inputType === 'number' ? 
                                            <div className={`${style.width_25}`}>
                                                <div className={`${style.title}`}>Banner Id</div>
                                                <input 
                                                    onWheel={(e) => e.target.blur()} 
                                                    className={`${style.inputField}`} 
                                                    type='number' value={widgetData.content_id} 
                                                    onChange={(e) => handleWidgetDataChange('content_id', e.target.value)} 
                                                    placeholder='Sub Title'
                                                />
                                            </div>
                                        : null
                                    }
                                    {
                                        listing.optionalField.show && listing.optionalField.inputType === 'dropdown' ? 
                                            <div className={`${style.width_25}`}>
                                                <div className={`${style.title}`}>
                                                    {widgetData.widget_type === "cat_prod_wd" ? "Category List" : ""}
                                                    {widgetData.widget_type === "subcat_prod_wd" ? "Sub Category List" : ""}
                                                </div>
                                                <Dropdown 
                                                    height={'8rem'}
                                                    list={listing.optionalField.list}
                                                    name={widgetData.selSubCat}
                                                    getDropDownValue={(elem)=>{
                                                        handleWidgetDataChange('selSubCat', elem)
                                                    }}
                                                />

                                                {/* <input type='text' placeholder='Sub Title'/> */}
                                            </div>
                                        : null
                                    }
                                </div>
                                
                                {/* Rank, Sku limit, subscription checkBox, Quality Attribute checkBox */}
                                <div className={`flex-end ${style.row}`}>
                                    {/* rank */}
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Rank</div>
                                        <input className={`${style.inputField}`} min={0} value={widgetData.rank} onChange={(e) => handleWidgetDataChange('rank',e.target.value)} onWheel={(e) => e.target.blur()}  type='number' placeholder='Rank'/>
                                    </div>
                                    {/* sku limit  */}
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Skus Limit</div>
                                        <input className={`${style.inputField}`} min={0} max={13} value={widgetData.no_of_children}  onChange={(e) => handleWidgetDataChange('no_of_children',e.target.value)} onWheel={(e) => e.target.blur()}  type='number' placeholder='Sku limit'/>
                                    </div>
                                    {/* subscription */} 
                                    {
                                        // (widgetData.widget_type === "subscription_wd" || widgetData.widget_type === "subscription_wd") && 
                                        <div className={`${style.width_25}`}>
                                            <label className={`pointer`}>
                                                <input
                                                    checked={widgetData.show_subs_btn} 
                                                    onChange={(e) => handleWidgetDataChange('show_subs_btn',e.target.checked)} 
                                                    type='checkbox' /> 
                                                    <span >Subscription</span>
                                            </label>
                                        </div>
                                        
                                    }
                                    {/* quality attribute */} 
                                    <div className={`${style.width_25}`}>
                                        <label className={`pointer`}>
                                            <input
                                                checked={widgetData.show_quality_attributes} 
                                                onChange={(e) => handleWidgetDataChange('show_quality_attributes',e.target.checked)} 
                                                type='checkbox' /> 
                                                <span >Quality Attributes</span>
                                        </label>
                                    </div>
                                </div>
                                
                                {/* bg color start, bg color end, text color */}
                                <div className={`${style.row}`}>
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Background Color - Start</div>
                                        <input className={`${style.inputField}`} value={widgetData.bg_color_1} onChange={(e) => handleWidgetDataChange('bg_color_1',e.target.value)} type='text' placeholder='Background Color'/>
                                    </div>
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Background Color - End</div>
                                        <input className={`${style.inputField}`} value={widgetData.bg_color_2} onChange={(e) => handleWidgetDataChange('bg_color_2',e.target.value)} type='text' placeholder='Background Color'/>
                                    </div>
                                    <div className={`${style.width_25}`}>
                                        <div className={`${style.title}`}>Text Color</div>
                                        <input className={`${style.inputField}`} value={widgetData.text_color} onChange={(e) => handleWidgetDataChange('text_color',e.target.value)} type='text' placeholder='Text Color'/>
                                    </div>
                                </div>

                                {/* header data */}
                                <div className={`${style.headerSection}`}>

                                    <div className={`flex-start ${style.row}`}>
                                        <div className={`${style.width_25}`}>
                                            <div className={`${style.title}`}>Header Type</div>
                                            {/* DD - title_type*/}
                                            <Dropdown 
                                                header_width={'50%'}
                                                height={'8rem'}
                                                list={headerType}
                                                name={widgetData.title_type}
                                                getDropDownValue={(elem)=>{
                                                    handleWidgetDataChange('title_type', elem)
                                                }}
                                            />
                                        </div>
                                        {
                                            widgetData.title_type.id !==0 &&
                                            <>
                                                {
                                                    (widgetData.title_type.id === 3 ||  widgetData.title_type.id === 4) &&
                                                    <>
                                                        <div className={`${style.width_25}`}>
                                                            <div className={`${style.title}`}>Visual Type</div>
                                                            <Dropdown 
                                                                header_width={'50%'}
                                                                height={'8rem'}
                                                                list={visualType}
                                                                name={widgetData.visual_type}
                                                                getDropDownValue={(elem)=>{
                                                                    handleWidgetDataChange('visual_type', elem)
                                                                }}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        {/* image upload */}
                                                        {
                                                            (widgetData.visual_type.id === 2 ) && 
                                                            <div className={`${style.width_25}`}>
                                                                <div className={`${style.title}`}>Header Image</div>
                                                                <div>
                                                                    <input 
                                                                        id={`header-image`} 
                                                                        type='file' 
                                                                        onChange={() => handleUploadImage('header-image','headerImg')}  
                                                                        className={`hide`} 
                                                                        accept='image/*'
                                                                    />
                                                                    {
                                                                        !widgetData.title ? 
                                                                            <button 
                                                                                onClick={() => document.getElementById(`header-image`).click()} 
                                                                                className={`green bold ${style.imgUploadBtn }`}
                                                                            >
                                                                                <div>
                                                                                    <UploadIcon className={`iconLightGrey`}/>
                                                                                </div>
                                                                                Upload 
                                                                            </button>
                                                                        :   
                                                                            <img 
                                                                                style={{width: '84%', height: '30px'}}
                                                                                className={`pointer ${style.sku_img}`}
                                                                                src={ProductWidgetImgUrl + widgetData.title} 
                                                                                onClick={() => document.getElementById(`header-image`).click()} 
                                                                            />
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </>

                                                }
                                                {
                                                    seeMoreWidgets.includes(widgetData.widget_type) && 
                                                    <div className={`${style.width_25}`}>
                                                        <label className={`pointer ${style.seeMore}`}>
                                                            <input
                                                                checked={widgetData.show_see_more} 
                                                                onChange={(e) => handleWidgetDataChange('show_see_more',e.target.checked)} 
                                                                type='checkbox' 
                                                            /> 
                                                                <span >See More</span>
                                                        </label>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                    {
                                        (widgetData.title_type.id !== 3 && widgetData.title_type.id !== 4 && widgetData.title_type.id !==0 ) &&
                                        <div className={`${style.row}`}>
                                            <div className={`${style.width_25}`}>
                                                <div className={`${style.title}`}>Title</div>
                                                <input className={`${style.inputField}`} value={widgetData.title} onChange={(e) => handleWidgetDataChange('title',e.target.value)} type='text' placeholder='Title'/>
                                            </div>
                                            {
                                                widgetData.title_type.id == 2 &&
                                                <div className={`${style.width_25}`}>
                                                    <div className={`${style.title}`}>Sub Title</div>
                                                    <input className={`${style.inputField}`} value={widgetData.sub_title} onChange={(e) => handleWidgetDataChange('sub_title',e.target.value)} type='text' placeholder='Sub Title'/>
                                                </div>
                                            }
                                            <div className={`${style.width_25}`}>
                                                <div className={`${style.title}`}>Header Text Color</div>
                                                <input className={`${style.inputField}`} value={widgetData.title_text_color} onChange={(e) => handleWidgetDataChange('title_text_color',e.target.value)} type='text' placeholder='Text Color'/>
                                            </div>
                                            <div className={`${style.width_25}`}>
                                                <div className={`${style.title}`}>Background Color</div>
                                                <input className={`${style.inputField}`} value={widgetData.title_background_color} onChange={(e) => handleWidgetDataChange('title_background_color',e.target.value)} type='text' placeholder='Background Color'/>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/*  height | width |alignment | auto scroll | show video timeline*/}
                                {
                                    (widgetData.widget_type === "image_carousal_wd" ||
                                    widgetData.widget_type === "video_carousal_wd" ||
                                    widgetData.widget_type === "dynamic_banner_wd" ||
                                    widgetData.widget_type === "dynamic_end_to_end_banner_wd" 
                                    ) &&
                                    <div className={`${style.row}`}>
                                       
                                        {/* height | width */}
                                        <div className={`${style.width_25}`}>
                                            <div className={`${style.title}`}>MEDIA DIMENSIONS (in px)</div>
                                            <input 
                                                className={`${style.inputField}`} 
                                                value={`H: ${widgetData.content_height}   |   W: ${widgetData.content_width}   `} 
                                                type='text'
                                                min={0} 
                                                placeholder='Media height'
                                                disabled={true}
                                            />
                                        </div> 

                                        {/* alignment | auto scroll */}
                                        {
                                            widgetData.widget_type === "image_carousal_wd" &&
                                            <>
                                                {/* alignment*/}
                                                <div className={`${style.width_25}`}>
                                                    <div className={`${style.title}`}>ALIGNMENT</div>
                                                    <Dropdown 
                                                        header_width={'50%'}
                                                        height={'8rem'}
                                                        // width={'50%'}
                                                        list={aligmentType}
                                                        name={widgetData.alignment}
                                                        getDropDownValue={(elem)=>{
                                                            handleWidgetDataChange('alignment', elem)
                                                        }}
                                                        disabled={widgetData.disableFields}
                                                    />
                                                </div> 

                                                {/* auto scroll */}
                                                <div className={`${style.width_25}`}>
                                                    <label className={`pointer ${style.seeMore}`}>
                                                        <input
                                                            disabled={widgetData.disableFields}
                                                            checked={widgetData.is_carousal_auto} 
                                                            onChange={(e) => handleWidgetDataChange('is_carousal_auto',e.target.checked)} 
                                                            type='checkbox' /> 
                                                            <span >Carousal Auto Scroll</span>
                                                    </label>
                                                </div>
                                            </>
                                        }

                                        {/* show video timeline */}
                                        {
                                            widgetData.widget_type === "video_carousal_wd" &&
                                            <>
                                                <div className={`${style.width_25}`}>
                                                    <label className={`pointer ${style.seeMore}`}>
                                                        <input
                                                            disabled={widgetData.disableFields}
                                                            checked={widgetData.show_video_timeline} 
                                                            onChange={(e) => handleWidgetDataChange('show_video_timeline',e.target.checked)} 
                                                            type='checkbox' /> 
                                                            <span >Show Video Timeline</span>
                                                    </label>
                                                </div>
                                                        
                                                <div className={`${style.width_25}`}>
                                                    <label className={`pointer ${style.seeMore}`}>
                                                        <input
                                                            disabled={widgetData.disableFields}
                                                            checked={widgetData.play_video_sound} 
                                                            onChange={(e) => handleWidgetDataChange('play_video_sound',e.target.checked)} 
                                                            type='checkbox' /> 
                                                            <span >Play Video Sound</span>
                                                    </label>
                                                </div>
                                                <div className={`${style.width_25}`}>
                                                    <label className={`pointer ${style.seeMore}`}>
                                                        <input
                                                            disabled={widgetData.disableFields}
                                                            checked={widgetData.show_sound_control} 
                                                            onChange={(e) => handleWidgetDataChange('show_sound_control',e.target.checked)} 
                                                            type='checkbox' /> 
                                                            <span >Show Sound Control</span>
                                                    </label>
                                                </div>
                                            </>

                                        }

                                    </div>
                                }
                                
                                {/* content data lisiting */}
                                {
                                    listing.optionalField.show && listing.optionalField.inputType === 'list' &&
                                    <div className={`${style.prodContainer}`}>
                                        <div className={`bold grid ${ 
                                            (widgetData.widget_type == "dynamic_end_to_end_banner_wd" || widgetData.widget_type == "dynamic_banner_wd") ?
                                            style.gridContentDataExtra :
                                            style.gridContentData
                                            } ${style.prodListThead}`}
                                        >
                                            <div className={`${style.titlePd}`}>Rank</div>
                                            {
                                                (widgetData.widget_type == "dynamic_end_to_end_banner_wd" || widgetData.widget_type == "dynamic_banner_wd") &&
                                                <div className={`${style.titlePd}`}>Media Type</div>
                                            }
                                            <div className={`${style.titlePd}`}>
                                                {
                                                    widgetData.widget_type === "video_carousal_wd" ? "Preview + Video" :
                                                    widgetData.widget_type === "dynamic_banner_wd" ? "Media" :
                                                    widgetData.widget_type === "dynamic_end_to_end_banner_wd" ? "Media" :
                                                    "Image"
                                                }
                                            </div>
                                            <div className={`${style.titlePd}`}>Screen Type</div>
                                            <div className={`${style.titlePd}`}>Landing Screen</div>
                                            <div className={`${style.titlePd}`}>Name</div>
                                            <div className={`${style.titlePd} textCenter`}>Action</div>
                                        </div>
                                        {
                                            contentData.map((product, index) => {
                                                const key = `${index} ${product.screenType.name} ${product.image}`
                                                return(
                                                    <div className={`grid ${ 
                                                        (widgetData.widget_type == "dynamic_end_to_end_banner_wd" || widgetData.widget_type == "dynamic_banner_wd") ?
                                                        style.gridContentDataExtra :
                                                        style.gridContentData
                                                        } ${style.prodList}`} 
                                                    key={key}
                                                >
                                                        <div className={`${style.titlePd}`}>{index + 1}</div>
                                                        {
                                                            (widgetData.widget_type == "dynamic_end_to_end_banner_wd" || widgetData.widget_type == "dynamic_banner_wd") &&
                                                            <div className={`${style.titlePd}`}>
                                                                <Dropdown 
                                                                    header_width={'66%'}
                                                                    height={'8rem'}
                                                                    list={media}
                                                                    name={product.media_type}
                                                                    getDropDownValue={(elem)=>{
                                                                        handleScreenTypeChange(index, elem, 'media_type')
                                                                    }}
                                                                    disabled={widgetData.disableFields}
                                                                />
                                                            </div>
                                                        }
                                                        <div className={`flex flex-space-between ${style.titlePd}`}>

                                                        {/* image upload */}
                                                        <div>
                                                            <input 
                                                                id={`${index}-image`} 
                                                                type='file' 
                                                                onChange={() => handleUploadImage(`${index}-image`, 'contentData', index)}  
                                                                className={`hide`} 
                                                                accept='image/*'
                                                            />
                                                            {
                                                                !product.image ? 
                                                                    <button 
                                                                        disabled={widgetData.disableFields}
                                                                        onClick={() => document.getElementById(`${index}-image`).click()} 
                                                                        className={`green bold ${style.imgUploadBtn }`}
                                                                    >
                                                                        <div>
                                                                            <UploadIcon className={`iconLightGrey`}/>
                                                                        </div>
                                                                        Upload 
                                                                    </button>
                                                                :   
                                                                    <img 
                                                                        className={`${widgetData.disableFields ? 'not_allowed' : 'pointer'} ${style.sku_img}`}
                                                                        src={ProductWidgetImgUrl + product.image} 
                                                                        onClick={() => {
                                                                            if(!widgetData.disableFields)
                                                                            document.getElementById(`${index}-image`).click()
                                                                        }} 
                                                                    />
                                                            }
                                                        </div>

                                                        {/* video upload */}
                                                        {
                                                            (widgetData.widget_type === "video_carousal_wd" || product.media_type?.id == 1) &&
                                                            <>
                                                                <PlusIcon />

                                                                <div>
                                                                    <input 
                                                                        id={`${index}-video`} 
                                                                        type='file' 
                                                                        onChange={() => handleUploadVideo(`${index}-video`, 'contentData', index)}  
                                                                        className={`hide`} 
                                                                        accept='video/mp4'
                                                                    />

                                                                    {
                                                                        product.media ? 
                                                                        <div className={`flexStretch`}>
                                                                            {
                                                                                !widgetData.disableFields &&
                                                                                <div 
                                                                                    onClick={() => { document.getElementById(`${index}-video`).click()}}
                                                                                    className={`textCenter pointer ${style.mrg_x_6} ${style.imgUpload} `} 
                                                                                >
                                                                                    <img src={UploadVideoIcon} alt="" className={`iconM`}/>
                                                                                </div>
                                                                            }

                                                                            <div 
                                                                                onClick={() => {
                                                                                    setPopup({
                                                                                        ...showPopup,
                                                                                        showVideoPopup: true,
                                                                                        videoUrl: product.media,
                                                                                    })
                                                                                }}
                                                                                className={`pointer textCenter green fontS ${style.imgUpload}`}
                                                                            >
                                                                                <PlayIcon width={'1.6vw'}/>
                                                                                <div>VIEW</div>
                                                                            </div>  
                                                                        </div> : 
                                                                        <div 
                                                                            onClick={() => {
                                                                                if(!widgetData.disableFields)
                                                                                document.getElementById(`${index}-video`).click()
                                                                            }}
                                                                            className={`textCenter pointer ${style.imgUpload} `} >
                                                                                <img src={UploadVideoIcon} alt="" className={`iconM`}/>
                                                                                <div className={`bold green`}>Upload</div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </>
                                                        }

                                                        {/* lottie upload */}
                                                        {
                                                            product.media_type?.id == 2 &&
                                                            <>
                                                                <PlusIcon />

                                                                <input 
                                                                    id={`${index}-lottie`} 
                                                                    type='file' 
                                                                    onChange={() => handleUploadLottie(`${index}-lottie`, 'contentData', index)}  
                                                                    className={`hide`} 
                                                                    accept='application/json'
                                                                />
                                                                {
                                                                    !product.media ? 
                                                                        <button 
                                                                            disabled={widgetData.disableFields}
                                                                            onClick={() => document.getElementById(`${index}-lottie`).click()} 
                                                                            className={`green bold ${style.imgUploadBtn }`}
                                                                        >
                                                                            <div>
                                                                                <UploadIcon className={`iconLightGrey`}/>
                                                                            </div>
                                                                            Upload Lottie 
                                                                        </button>
                                                                    :   
                                                                    <>
                                                                        <Lottie  
                                                                            loop
                                                                            // animationData={lottieData}
                                                                            path = {LottieFileUrl + product.media}
                                                                            // path ={'https://storage.googleapis.com/dummy_buckets/f7d4384a1343.json'}
                                                                            play
                                                                            className={`${ widgetData.disableFields ? 'not_allowed' : 'pointer'}`}
                                                                            style={{ width: 100, height: 70, border: '1px solid cecece', borderRadius: '8px'}}
                                                                            onClick={() => {
                                                                                if(!widgetData.disableFields)
                                                                                document.getElementById(`${index}-lottie`).click()
                                                                            }} 
                                                                        />
                                                                    </>
                                                                }
                                                            </>

                                                        }
                                                        </div>
                                                        <div className={`${style.titlePd}`}>
                                                            <Dropdown 
                                                                header_width={'66%'}
                                                                height={'8rem'}
                                                                list={screenType}
                                                                name={product.screenType}
                                                                getDropDownValue={(elem)=>{
                                                                    handleScreenTypeChange(index, elem, 'screenType')
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={`${style.titlePd}`}>
                                                            {
                                                                (product.screenType.id === 4 || product.screenType.id === 5) ? 
                                                                <>
                                                                    <Dropdown 
                                                                        header_width={'66%'}
                                                                        height={'8rem'}
                                                                        list={ product.screenType.id === 5 ? listing.catList : listing.subCatlist}
                                                                        name={product.landingScreenDropDown}
                                                                        getDropDownValue={(elem)=>{
                                                                            handleScreenTypeChange(index, elem, 'landingScreenDropDown')
                                                                        }}
                                                                    />
                                                                </> : 
                                                                <input 
                                                                    disabled={!product.screenType.id || widgetData.disableFields || !product.screenType.has_landing_screen}
                                                                    className={`${style.inputField}`} 
                                                                    value={product.landingScreen} 
                                                                    onChange={(e) => handleScreenTypeChange(index, e.target.value, 'landingScreen')}
                                                                    type='text' 
                                                                    placeholder={ 
                                                                        product.screenType.id == 1  ? 'Mini Feed ID': 
                                                                        product.screenType.id == 2  ? 'Web View Link': 
                                                                        product.screenType.id == 3  ? 'Banner ID': 
                                                                        product.screenType.id == 13 ? 'Product ID': 
                                                                         'Landing Screen url'}
                                                                />
                                                            }
                                                        </div>
                                                        <div className={`${style.titlePd} `}>
                                                            <input 
                                                                className={`${style.inputField}`} 
                                                                value={product.name} 
                                                                onChange={(e) => handleScreenTypeChange(index, e.target.value, 'name')}
                                                                type='text' 
                                                                placeholder='name'
                                                            />
                                                        </div>
                                                        <div className={`textCenter ${style.titlePd}`}>
                                                            <img 
                                                                height='20px' 
                                                                src={BinGreyIcon} 
                                                                className={`pointer ${style.deleteIcon}`} 
                                                                onClick={() => handleDeleteSku(index)} 
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className={`textCenter ${style.pd_bg}`}>
                                            <button className={`${style.addBtn}`} onClick={handleAddNewWidgetType}>
                                                Add More <img src={AddIcon} className={`alignIcon`}/>
                                            </button>
                                        </div>    
                                    </div>    
                                }
                            </div>
                        </div>
                        <div className={`${style.textCenter}`}>
                            <button onClick={handleSubmit} className={`${style.submitBtn}`}>SAVE CHANGES</button>
                        </div>
                    </div>
                </div>
                 {/* 422 error handling */}
                {errorList.status && <div>
                    <PopUp 
                        width={formStyle.popupWidthS} 
                        title={'Errors'} 
                        closeBtn={() => { setErrorList({
                                status: false,
                                message: [] 
                            })
                        }}>
                        <div className={`${formStyle.inputContainer}`}>
                            {errorList.message.map((err, idx) => {
                                return <div className={`${style.pd_bg1} red`}>{`${idx + 1}. ${err.loc[1]} : ${err.msg}`}</div>
                            })}
                        </div>
                    </PopUp>
                </div>}

                {/* 4001 error handling */}
                {
                    showPopup.status && 
                    <PopupErrorList
                        list={showPopup.message} 
                        closeBtn={() => setPopup({
                            status: false,
                            message: []
                        })}
                    /> 
                }
                {
                    showPopup.showVideoPopup && 
                    <PopupShowVideo
                        videoUrl={showPopup.videoUrl}
                        closeBtn={() => setPopup({
                            ...showPopup,
                            showVideoPopup: false,
                            videoUrl: ""
                        })}
                    />
                }
                {isLoading && <Loader />}
        </>
    );
}

export default MiniFeedWidget;
