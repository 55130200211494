import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { addNewBanner, deepCopyObject, getCategoryItems, getOrderBannerZonewiseList, searchBannerApi, updateBanner, uploadBannerImage, uploadSelImage, uploadSelVideo } from "../../../../utils/api";
import { AllowedBannerWidget, ContentType, cta_values, landingPageList, widgetRank } from "../../../../utils/constData";
import { EpochTimeTOHumanFormat, UTCTimeFormat } from "../../../../utils/TimeManager";
import DatePicker from "../../../../commonComponent/DatePicker/DatePicker";
import Dropdown from "../../../../commonComponent/Dropdown/Dropdown";
import PopUpAddWidget from "../../../../commonComponent/PopupAddWidget";
import PopupAddSku from "../../../Marketing/CreateCampaign/components/PopupAddSku";
import style from "../css/Banner.module.css";
import LocationIcon from "../../../../icons/location.svg";
import deleteIcon from '../../../../icons/bin.svg';
import ImgPlaceholder from '../../../../icons/picture-icon.svg';
import UploadIcon from  '../../../../icons/ico-upload.svg';
import { ReactComponent as BinGreyIcon } from '../../../../icons/bin-grey.svg';
import { ReactComponent as DownToggleBtn } from '../../../../icons/down-toggle.svg';
import { ErrorHandling, SuccessHandling } from "../../../../State";
import { BaseImgUrl, catImgUrl } from "../../../../utils/config";
import Loader from "../../../../commonComponent/Loader/Loader";


function AddBanner(props) {
    const { status, banner_id, slot } = useParams()
    const navigate = useNavigate()
    const inputRef = useRef({})

    const [zoneList, setZoneList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedZoneList, setSelectedZoneList] = useState([]);
    const [selectContentid, setContentid] = useState({ name: 'Select Category' })
    const [selCtaValue, setSelCtaValue] = useState({ id: 0, value: 'SHOW MORE', name: "SHOW MORE" })
    const [popup, setPopup] = useState({
        showAddSkuPopup: false,
        showAddWidget: false, 
    });
    const [isAllZoneSelected, setIsAllZoneSelected] = useState(false);
    const [validateDate, setValidateDate] = useState({ start_at: '', end_at: '' })
    const [file, setFile] = useState(ImgPlaceholder);
    const [fileError, setFileError] = useState(false);
    const [secondFile, setSecondFile] = useState(ImgPlaceholder);
    const [secondFileError, setSecondFileError] = useState(false);
    const [isLoading, setLoader] = useState(false);

    const [bannerData, setBannerData] = useState({
        associated_banner_id: 0,
        pricing_zone_id: 0,
        start_at: '',
        end_at: '',
        product_list: [],
        campaign_name: '',
        rank: '',
        image_id: '',
        new_image_id: '',
        selectedImage: '',
        newselectedImage: '',
        is_active: false,
        landing_page: { name: 'Landing Screen' },
        content_type: { name: 'Content Type' },
        content_id: 0,
        is_banner_text: false,
        is_cta: false,
        campaign_tag: '',
        banner_text: '',
        cta_value: '',
        url: '',
        is_category: false,
        is_subcategory: false,
        show_category: '',
        is_deal: false,
        cta_screen: '',
        is_web_banner: false,
        is_app_banner: true,

        widget_list: [],
        isWidgetListCollapsed: false,
        filter_chip_image: "",
    })

    const findName = (name) => {
        const obj = landingPageList.filter((item) => { return item.value === name })
        if (obj.length !== 0) return obj[0];
    }

    const findCategory = (category_list, content_id) => {
        return category_list.filter((item) => item.id === content_id)[0]
    }

    const selectZone = (zoneElement) => {
        console.log(zoneElement);
        var all_zone_data = [...zoneList]
        let selectedZones = [...selectedZoneList]
        if (zoneElement.target.checked) {
            const updatedZoneList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = true;
                    selectedZones.push(zone);
                }
                return zone;
            });
            if (selectedZones.length === zoneList.length) {
                setIsAllZoneSelected(true);
            }

            setSelectedZoneList(selectedZones)
            setZoneList(updatedZoneList)

        }

        else {
            const updatedList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = false;
                }
                return zone;
            });

            let selectedZones = selectedZoneList.filter((zone) => zone.id !== parseInt(zoneElement.target.id))
            setSelectedZoneList(selectedZones)
            setZoneList(updatedList)
            setIsAllZoneSelected(false);
        }
    }

    const selectAllZone = (event) => {
        let selected_zones = []
        if (event.target.checked) {
            const pricing_zones = zoneList.map(zoneElement => {
                zoneElement.is_selected = true;
                selected_zones.push(zoneElement)
                return zoneElement;

            })
            setSelectedZoneList(selected_zones);
            setIsAllZoneSelected(true);
            setZoneList(pricing_zones)

        } else {
            const pricing_zone = zoneList.map(zoneElement => {
                zoneElement.is_selected = false;
                return zoneElement;

            })
            setSelectedZoneList([]);
            setIsAllZoneSelected(false);
            setZoneList(pricing_zone)

        }
    }


    const handleInputChange = (e) => {
        let obj = bannerData;
        obj[e.target.name] = e.target.value;
        setBannerData({ ...obj });
    }

    const handleCheckBoxChange = (e) => {
        let obj = bannerData;
        
        if(e.target.name === 'is_cta' && !e.target.checked){
            obj.cta_value = ""
            setSelCtaValue({ id: 0, value: 'SHOW MORE', name: "SHOW MORE" })
        }

        obj[e.target.name] = e.target.checked;
        setBannerData({ ...obj });
    }

    // get start and end date
    const getDate = (whom, date) => {
        if (whom === "start") {
            let obj = bannerData;
            obj.start_at = date;
            setBannerData({ ...obj });
            let date_array = date.split("T")[0].split("-")
            let start_at = `${date_array[1].length === 2 ? date_array[1] : `0${date_array[1]}`}/${date_array[2].length === 2 ? date_array[2] : `0${date_array[2]}`}/${date_array[0]}`
            setValidateDate({ start_at: start_at, end_at: validateDate.end_at })
        }
        if (whom === "end") {
            let obj = bannerData;
            obj.end_at = date;
            setBannerData({ ...obj });
            let date_array = date.split("T")[0].split("-")
            let end_at = `${date_array[1].length === 2 ? date_array[1] : `0${date_array[1]}`}/${date_array[2].length === 2 ? date_array[2] : `0${date_array[2]}`}/${date_array[0]}`
            setValidateDate({ start_at: validateDate.start_at, end_at: end_at })
        }
    }

    // on change CTA value 
    const changeCTAValue = (cta_value) => {
        setSelCtaValue(cta_value)
        let obj = bannerData;

        if (!bannerData.is_cta) {
            obj.cta_value = '';
        }

        setBannerData({ ...obj });
    }

    // on change landing page
    const getLandingPage = (landing_page) => {
        let obj = bannerData;
        obj.landing_page = landing_page;


        if (categoryList.length === 0 && (bannerData.content_type.value === 'BANNER' || bannerData.content_type.value === "IMAGE") && bannerData.landing_page.value === 'CATEGORY') {
            getCategoryItems().then((resp) => {
                setCategoryList(resp.data)
            })
        }

        if (landing_page.value !== 'PRODUCT') {
            obj.content_id = 0;
        }

        if (landing_page.value !== 'CATEGORY') {
            obj.content_id = 0;
            setContentid({ name: 'Select Category' });
        }

        if (landing_page.value !== 'DEAL') {
            obj.product_list = [];
        }

        setBannerData({ ...obj });
    }

    // on change content type
    const getContentType = (content_type) => {
        let obj = bannerData;
        obj.content_type = content_type;

        // if (content_type.value !== 'SAFE') {
        //     obj.product_list = [];
        // }


        // TO empty widget_list in case of order content type
        if( !(content_type.value === "IMAGE" || content_type.value === "BANNER" || content_type.value === "VIRTUL") ){
            obj.widget_list = [];
        }   

        if (content_type.value !== 'IMAGE' && content_type.value !== 'BANNER') {
            obj.content_id = 0;
            obj.new_image_id = "";
            setContentid({ name: 'Select Category' });

        }

        if (content_type.value !== 'VIRTUL' && content_type.value !== 'SUBSCRIPTION' && content_type.value !== "BANNER") {
            obj.is_category = false;
            obj.is_subcategory = false;
            obj.show_category = 0;
            obj.associated_banner_id = 0;

        }

        if (categoryList.length === 0 && (bannerData.content_type.value === 'BANNER' || bannerData.content_type.value === "IMAGE") && bannerData.landing_page.value === 'CATEGORY') {
            getCategoryItems().then((resp) => {
                setCategoryList(resp.data)

            })
        }




        if ((content_type.value !== 'IMAGE' && content_type.value !== 'BANNER') || bannerData.landing_page.value !== 'CATEGORY') {
            obj.show_category = 0;
            obj.is_subcategory = false;
            obj.is_category = false;
        }


        setBannerData({ ...obj });
    }

    // handle Category Selection
    const handleCategorySelection = (elem) => {
        setContentid(elem)
        setBannerData({...bannerData, content_id: elem.id})
    }

    // handle lottie upload click
    const handleFileClick = (id) => {
        inputRef.current[id].click()
    }

    // handle lottie upload 
    const handleFileUpload = async(id) => {
        let file = inputRef.current[id].files[0]

        let imgTypeExt = file.type.split('/')

        let image_type = 'images';
        if(id === 'filter_chip_image'){
            image_type = 'category';
        }

        let data = new FormData();
        data.append('file', file);
        data.append('file_ext', '.' + imgTypeExt[1]);
        data.append('image_type', image_type)
    

        setLoader(true)
        let file_id = await uploadSelImage(data)
        setLoader(false)
        if(file_id && id === 'lottie'){
            setBannerData({...bannerData, new_image_id: file_id})
        }
        else if (file_id && id === 'filter_chip_image'){
            setBannerData({...bannerData, filter_chip_image: file_id})
        }
        inputRef.current[id].value = "" 
    }

    // for selectiong image from device
    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    // on file selection ONLY
    const saveFile = (e, state) => {
        setFileError(false);
        setSecondFileError(false);

        if (state == 1) {
            setFile(URL.createObjectURL(e.target.files[0]));
            if (e.target.files[0].size > 1000000) {
                setFileError(true);
            }
        }
        if (state == 2) {
            if (e.target.files[0].size > 1000000) {
                setSecondFileError(true);
            }
            setSecondFile(URL.createObjectURL(e.target.files[0]));
        }

        let obj = bannerData;
        obj[e.target.name] = e.target.files[0];
        setBannerData({ ...obj });
    }

    // upload to server - old banner image
    const uploadImage = async () => {
        var image_type = bannerData.content_type.value;
        let data = new FormData();

        // validation for empty upload
        if( !bannerData.image_id || typeof bannerData.image_id == 'string'){
            ErrorHandling("Please upload image before uploading!!")
            return;
        }

        data.append('file', bannerData.image_id);
        data.append('image_type', image_type)
        let res = await uploadBannerImage(data)

        if (res.success) {
            SuccessHandling('Image Uploaded Successfully');
        } else {
            ErrorHandling(res.error.message)
        }


        let obj = bannerData;
        obj.selectedImage = res.image_id;
        console.log(obj);
        setBannerData({ ...obj })
    }

    // upload to server - new banner image
    const uploadNewImage = async () => {
        var image_type = bannerData.content_type.value;
        let data = new FormData();

        // validation for empty upload
        if( !bannerData.new_image_id || typeof bannerData.new_image_id == 'string'){
            ErrorHandling("Please upload image before uploading!!")
            return;
        }

        let img = new Image();
        img.onload = async function () {
            console.log(`Dimensions --> height: ${img.height} | width ${img.width}`)
            if(img.height === 300 && img.width === 630){

                data.append('file', bannerData.new_image_id);
                data.append('image_type', image_type)
                let res = await uploadBannerImage(data)
                if (res.success) {
                    SuccessHandling('Image Uploaded Successfully');
                } else {
                    ErrorHandling(res.error.message)
                }
                
                let obj = bannerData;
                obj.newselectedImage = res.image_id;
                console.log(obj);
                setBannerData({ ...obj })

            }
            else{
                ErrorHandling(`Aspect ratio doesn't match`);
            }
        }
        img.src = img.src = URL.createObjectURL(bannerData.new_image_id);
    }

    // add sku
    const addSkuForDeal = (sku) => {
        // setPopup(false);
        if (bannerData.landing_page.value === 'PRODUCT' && bannerData.product_list.length === 1) {
            ErrorHandling('Can not add more than 1 product for this landing screen')
        } else {

            let exist_prod = bannerData.product_list.length > 0 ? bannerData.product_list.some((prod) => prod.prod_id === sku.id) : '';
            if (exist_prod) {
                ErrorHandling("Product already added");

            } else {
                let list = bannerData.product_list;
                list.push(sku)
                setBannerData({ ...bannerData, product_list: [...list] })
                // bannerData.product_list.push(sku);
            }
        }
    }

    // WIDGET | adding widget from widget list using popup 
    const handleAddWidget = (widgetList) => {
        let localBannerData = deepCopyObject(bannerData);

        try {
            widgetList.map((widget) => {
                console.log('data', localBannerData)
                delete widget.rank
                localBannerData.widget_list.push(widget);
            }) 
        } catch (error) {
            console.log("error", error)

            widgetList.map((widget) => {
                delete widget.rank
            }) 
            localBannerData.widget_list = widgetList
        }

        setBannerData(localBannerData);
    }

    // WIDGET | widget rank change through dropdown
    const handleRankChange = (value, widgetIndex) => {
        let localBannerData = deepCopyObject(bannerData);
        localBannerData.widget_list[widgetIndex].rank = value;
        setBannerData(localBannerData);
    }

    // WIDGET | deleting widget from widget list
    const handleDeleteWidget = (idx) => {
        let localBannerData = deepCopyObject(bannerData);
        localBannerData.widget_list.splice(idx, 1);
        setBannerData(localBannerData);
    }

    // WIDGET | check eligibilty for widget to be added
    const checkWidgetEligibilty = (widget) => {

        let selectedZones = zoneList.filter(zone => zone.is_selected);

        if(!AllowedBannerWidget.includes(widget.widget_type)){
            ErrorHandling(`Allowed widgets are : ${AllowedBannerWidget}`)
            return false;
        }

        if(selectedZones.length ) {

            let commonZones = selectedZones.filter((zone) => 
                widget.zone_list.includes(zone.id)
            )

            if(!commonZones.length) {
                ErrorHandling(`Widget Does not have same pricing zones as banner!`);
                return false;
            }
        }

        if(bannerData.id && bannerData.id == widget.content_id){
            ErrorHandling(`Widget: ${widget.widget_tag}(${widget.id}) has the same banner id ${bannerData.id}!`)
            return false;
        }

        return true;
    }

    // WIDEGT | toggle widget listing 
    const widgetCollapse = () => {
        setBannerData({
            ...bannerData,
            isWidgetListCollapsed: !bannerData.isWidgetListCollapsed,
        })
    }

    // WIDGET | active inactive widgett count
    const widgetCount = () => {
        let count = {
            active : 0,
            inactive : 0,
        }
        bannerData.widget_list.map((widget) => {
            if(widget.is_active){
                count.active++;
            }
            else count.inactive++;
        })

        return count;
    }

    // image delete
    const deleteImage = (key) =>{
        setBannerData({...bannerData, [key]: ""})
    }

    // drag and drop position change
    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(bannerData.product_list);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let obj = bannerData;
        obj.product_list = items;
        setBannerData({ ...obj })

    }

    // open add popup sku / widget
    const openAddPopup = (key) => {
        if (selectedZoneList.length === 0) {
            ErrorHandling("Please Select Zone First");
        } else {
            setPopup({
                ...popup,
                [key]: true,
            });
        }
    }

    // delete sku 
    const onDeleteProd = (item_id) => {
        let obj = bannerData;
        let item = obj.product_list.filter((prod) => (prod.id !== item_id));
        obj.product_list = item;
        setBannerData({ ...obj });

    }

    // on click category checkbox
    const change_category = () => {
        let obj = bannerData;
        if (bannerData.is_category) {
            obj.show_category = 0;
            obj.is_category = false;
            setBannerData({ ...obj })
        } else {
            obj.show_category = 1;
            obj.is_category = true;
            obj.is_subcategory = false;
            setBannerData({ ...obj })
        }
    }

    // on click sub category checkbox
    const change_sub_category = () => {
        let obj = bannerData;
        if (bannerData.is_subcategory) {
            obj.show_category = 0;
            obj.is_category = false;
            setBannerData({ ...obj })
        } else {
            obj.show_category = 2;
            obj.is_category = false;
            obj.is_subcategory = true;
            setBannerData({ ...obj })
        }
    }

    // publish banner - validation and data formation 
    const publishCampaign = (e) => {
        e.preventDefault();
        debugger;

        let hasRank = true, hasZones = true;
        if((bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.widget_list.length ){
            let zonesSelected = zoneList.filter(zone => zone.is_selected);

            bannerData.widget_list.map((widget) => {
                if(!widget.rank){
                    hasRank = false
                }

                if(zonesSelected.length){
                    let commonZones = zonesSelected.filter((zone) => 
                        widget.zone_list.includes(zone.id)
                    )

                    if(!commonZones.length){
                        hasZones = false;
                    }
                }
            })
        }

        if (!bannerData.campaign_name.trim()) {
            ErrorHandling('please enter campaign name')
        } else if (bannerData.is_banner_text && bannerData.banner_text.trim().length === 0) {
            ErrorHandling('please enter the banner text')
        } else if (!bannerData.campaign_tag.trim()) {
            ErrorHandling('please enter the campaign tag')
        } else if (!bannerData.start_at) {
            ErrorHandling('please select a start date')
        } else if (!bannerData.end_at) {
            ErrorHandling('please select a end date')
        } else if (isNaN(bannerData.rank) || bannerData.rank < 0 || bannerData.rank === "") {
            ErrorHandling('Please enter valid rank')
        } else if (bannerData.start_at.split("T")[0] < validateDate.start_at && !bannerData.is_edited) {
            ErrorHandling('please select a valid start date')
        } else if (bannerData.end_at.split("T") < validateDate.end_at && !bannerData.is_edited) {
            ErrorHandling('please select a valid end date')
        } else if (bannerData.start_at > bannerData.end_at && !bannerData.is_edited) {
            ErrorHandling('Please Correct the Time')
        } else if (bannerData.content_type.value === "Content Type") {
            ErrorHandling('Please select the Content Type')
        } else if ((bannerData.content_type.value === 'VIRTUL' || bannerData.content_type.value === 'SUBSCRIPTION') && !bannerData.is_category && !bannerData.is_subcategory) {
            ErrorHandling('please select the category')
        } else if (bannerData.content_type.value === 'BANNER' && (bannerData.newselectedImage === undefined || !bannerData.newselectedImage)) {
            ErrorHandling("New Feed Banner Image cannot be left empty !");
        }
        else if (bannerData.content_type.value !== 'VIRTUL' && bannerData.content_type.value !== "LOTTIE" && bannerData.content_type.value !== "SAFE"  && (bannerData.selectedImage === undefined || !bannerData.selectedImage)) {
            ErrorHandling("Banner Image cannot be left empty !");
        }
        else if (bannerData.content_type.value === "SAFE" && !bannerData.product_list.length){
            ErrorHandling("Please Add Products")
        }
        else if (bannerData.content_type.value === "LOTTIE" && !bannerData.new_image_id){
            // ErrorHandling("Please Upload Lottie File");
            ErrorHandling("Please Upload image File");
        }
        else if(!hasRank){
            ErrorHandling("Please Select Widget Rank!")
        }
        else if(!hasZones){
            ErrorHandling('Widget does not have same pricing zones as banner!')
        }
        else if( (bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.landing_page.value === 'DEAL' && !bannerData.filter_chip_image){
            ErrorHandling("Please upload filter chip image!");
        }
        else if (bannerData.landing_page.name === "Landing Screen") {
            ErrorHandling('please select the landing screen')
        } else if ((bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "IMAGE") && bannerData.landing_page.value === 'PRODUCT' && bannerData.content_id === 0) {
            ErrorHandling('please enter the Prod id')
        } else if ((bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "IMAGE") && bannerData.landing_page.value === 'CATEGORY' && bannerData.content_id === 0) {
            ErrorHandling('please select content id')
        } else if (bannerData.landing_page.value === "WEBV" && !bannerData.url) {
            ErrorHandling("Please Input web view URL")
        } else {
            if (selectedZoneList.length > 0) {
                let product_list = bannerData.product_list.length > 0 ? bannerData.product_list.map((prod) => { return prod.id }) : []
                // console.log(product_list);
                selectedZoneList.map((zone) => {
                    let new_image_id = "", is_app_web_banner = 0, widget_list = [];

                    // for new image id
                    if(bannerData.content_type.value === "LOTTIE")
                        new_image_id = bannerData.new_image_id
                    else if(bannerData.content_type.value !== 'VIRTUL')
                        new_image_id = bannerData.selectedImage


                    // for is_app_web_banner

                    if(bannerData.is_app_banner && !bannerData.is_web_banner){
                        // for only app
                        is_app_web_banner = 1
                    }
                    else if(bannerData.is_web_banner && bannerData.is_app_banner){
                        // for only web
                        is_app_web_banner = 2
                    }
                    else if(bannerData.is_web_banner && bannerData.is_app_banner){
                        // both app web
                        is_app_web_banner = 3     
                    }               


                    //  for widget list
                    if((bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.widget_list.length ){
                        bannerData.widget_list.map((widget) => {
                            let obj = {
                                id: widget.id,	
                                rank: parseInt(widget.rank),
                            }
                            widget_list.push(obj)
                            debugger;
                        })
                    }
                    debugger;

                    var data = {
                        'banner_type': bannerData.content_type.value,
                        'is_active': bannerData.is_active,
                        'is_web_banner': bannerData.is_web_banner,
                        'is_app_banner': bannerData.is_app_banner,
                        'is_app_web_banner' : is_app_web_banner,
                        'start_at': UTCTimeFormat(new Date(bannerData.start_at)) + ":00.000Z",
                        'end_at': UTCTimeFormat(new Date(bannerData.end_at)) + ":00.000Z",
                        'campaign_name': bannerData.campaign_name.trim(),
                        'landing_page': bannerData.landing_page.value,
                        'product_list': product_list,
                        'image_id': bannerData.newselectedImage,
                        'banner_text': bannerData.banner_text.trim(),
                        'is_cta': bannerData.is_cta,
                        'cta_value':  bannerData.is_cta ? selCtaValue.value : "",
                        'cta_screen': bannerData.is_cta ? 'Select CTA Screen' : '',
                        'is_deal': bannerData.is_deal,
                        'url': bannerData.content_type.value === 'VIDEO' || bannerData.landing_page.value === "WEBV" ? bannerData.url : '',
                        'content_id': bannerData.content_id,
                        'rank': bannerData.rank,
                        'pricing_zone_id': zone.id,
                        'distributor_id': 6,
                        // 'new_image_id': bannerData.content_type.value !== 'VIRTUL' ? bannerData.selectedImage : '',
                        'new_image_id': new_image_id,
                        'show_category': bannerData.show_category ? bannerData.show_category : 0,
                        'campaign_tag': bannerData.campaign_tag.trim(),
                        'is_banner_text': bannerData.is_banner_text,
                        'associated_banner_id': bannerData.associated_banner_id ? bannerData.associated_banner_id : 0,
                        // 'cta_screen': bannerData.cta_screen
                        'widget_list': widget_list,
                        'filter_chip_image': (bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.landing_page.value === 'DEAL' ? bannerData.filter_chip_image : ""
                    }
                    console.log("submitting data",data)

                    if (bannerData.banner_id) {
                        data.banner_id = bannerData.banner_id;
                        editBanner(data);
                    } else {
                        addBanner(data);
                    }

                })
            } else {
                ErrorHandling('please select the zone')
            }
        }

    }

    // for new banner creation
    const addBanner = async (data) => {
        setLoader(true)
        let res = await addNewBanner(data);
        setLoader(false)

        if (res.success) {
            SuccessHandling('Banner Created Successfully');
            navigate(`/feed-content/${slot}/`)
        } else {
            ErrorHandling(res.error.message)
        }
    }

    // for edit banner banner
    const editBanner = async (data) => {
        setLoader(true)
        let res = await updateBanner(data);
        setLoader(false)
        
        if (res.success) {
            SuccessHandling('Banner updated Successfully');
            navigate(`/feed-content/${slot}/`)
        } else {
            ErrorHandling(res.error.message)
        }
    }


    // handle listing, selected banner 
    const fetchData = async () => {
        // for gettig the zone list
        let resListing = await getOrderBannerZonewiseList();

        if (status === "edit") {
            // for fetching the selected banner 
            // const type = JSON.parse(localStorage.getItem('type'))
            // const zone = JSON.parse(localStorage.getItem('zone'))
            // const nslot = JSON.parse(localStorage.getItem('nslot'))
            const res = await searchBannerApi(banner_id);
            let selBanner = res.data.filter((banner) => banner.id == banner_id)[0]

            setBannerData({ ...selBanner });


            let obj = Object.assign({}, selBanner);

            let selContentType = ContentType.filter(item => item.value == selBanner.banner_type);

            if(selContentType.length){
                obj.content_type = selContentType[0]
            }
            else{
                obj.content_type = { name: selBanner.banner_type, value: selBanner.banner_type};
            }

            obj.isWidgetListCollapsed = !!obj.widget_list?.length;
            obj.landing_page = findName(selBanner.landing_page);
            obj.start_at = EpochTimeTOHumanFormat(selBanner.start_at);
            obj.end_at = EpochTimeTOHumanFormat(selBanner.end_at);
            obj.banner_id = selBanner.id;
            obj.image_id = selBanner.image_id;
            obj.new_image_id = selBanner.new_image_id;
            obj.selectedImage = selBanner.new_image_id;
            obj.newselectedImage = selBanner.image_id;
            obj.is_banner_text = selBanner.banner_text ? true : false;

            setSelectedZoneList([{ id: selBanner.pricing_zone_id }]);

            if(obj.widget_list?.length){
                obj.widget_list.map((widget) => {
                    widget.rank = widget.rank.toString();
                })
            }

            if (selBanner.banner_type === 'VIRTUL' || selBanner.banner_type === 'SUBSCR') {
                if (selBanner.banner_type === 'SUBSCR') {
                    obj.content_type = { name: 'SUBSCRIPTION' }
                }
                if (obj.show_category === 1) {
                    obj.is_category = true;
                    obj.is_subcategory = false;
                } else {
                    obj.is_category = false;
                    obj.is_subcategory = true;
                }
            }

            if(selBanner.is_cta){
                setSelCtaValue({ name: selBanner.cta_value, value: selBanner.cta_value })
            }

            if (categoryList.length === 0 && (obj.content_type.value === 'BANNER' || obj.content_type.value === "IMAGE") && obj.landing_page.value === 'CATEGORY') {
                getCategoryItems().then((resp) => {
                    setCategoryList(resp.data);
                    setContentid(findCategory(resp.data, obj.content_id));
                })
            }

            // for is_app_web_banner

            if(selBanner.is_app_web_banner == 1){
                // for only app
                obj.is_app_banner = true 
                obj.is_web_banner = false
            }
            else if(selBanner.is_app_web_banner == 2){
                // for only web
                obj.is_app_banner = false 
                obj.is_web_banner = true
            }
            else if(selBanner.is_app_web_banner == 3){
                // both app web  
                obj.is_app_banner = true 
                obj.is_web_banner = true
            }     


            setBannerData({ ...obj })

            resListing.data.forEach((elem, index) => {
                resListing.data[index].is_selected = false;
                if (elem.id == selBanner.pricing_zone_id) {
                    resListing.data[index].is_selected = true;
                }
            });
            setZoneList(resListing.data)


        }
        else {
            setZoneList(resListing.data)
        }
    }


    useEffect(() => {
        fetchData()
    }, [])


    return (
        <>
            {/* header */}
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>{status === "edit" ?  "Edit " : "Add "} Feed Content</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>{status === "edit" ?  "Edit" : "Create"} Banner</div>

                <div className={`${style.addContainer}`}>

                    {/* first section - input and checkboxes */}
                    <div className={`${style.separator}`}>
                        {/* is active, campaign name, tag and rank */}
                        <div className={`grid  ${style.grid_template}`}>

                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input type="checkbox" name="is_active" checked={bannerData.is_active} onChange={(e) => { handleCheckBoxChange(e) }} />
                                <span>Is Active</span>
                            </div>

                            <div className={`${style.width_50}`}>
                                <div className={`${style.title}`}>Campaign Name</div>
                                <input className={`${style.input_field}`} name='campaign_name' type='text' placeholder='Campaign Name' value={bannerData.campaign_name} onChange={(e) => { handleInputChange(e) }} />
                            </div>

                            <div className={`${style.width_50}`}>
                                <div className={`${style.title}`}>Campaign Tag</div>
                                <input className={`${style.input_field}`} name='campaign_tag' type='text' placeholder='Campaign Tag' value={bannerData.campaign_tag} onChange={(e) => { handleInputChange(e) }} />
                            </div>

                            <div className={`${style.width_50}`}>
                                <div className={`${style.title}`}>Rank</div>
                                <input 
                                    className={`${style.input_field}`} 
                                    name='rank' 
                                    type='number' 
                                    placeholder='Rank' 
                                    value={bannerData.rank} 
                                    onWheel={(e) => e.target.blur()}
                                    onChange={(e) => { handleInputChange(e) }} 
                                />
                            </div>
                        </div>

                        {/* is banner text, banner text and start date */}
                        <div className={`grid  ${style.grid_template}`}>

                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input 
                                    type="checkbox" 
                                    name="is_banner_text" 
                                    checked={bannerData.is_banner_text} 
                                    onChange={(e) => { handleCheckBoxChange(e) }} 
                                />
                                <span>Is Banner Text</span>
                            </div>

                            <div className={`${style.width_50}`}>
                                <div className={`${style.title}`}>Banner Text</div>
                                <input className={`${style.input_field}`} type='text' name="banner_text" disabled={!bannerData.is_banner_text} value={bannerData.banner_text} placeholder='Banner Text' onChange={(e) => { handleInputChange(e) }} />
                            </div>

                            <div>
                                <DatePicker isEndHide={true} isTodTomVisible={true} getDate={getDate} start_time={bannerData.start_at && bannerData.start_at} />
                            </div>

                            <div>
                                <DatePicker isStartHide={true} isTodTomVisible={true} getDate={getDate} end_time={bannerData.end_at && bannerData.end_at} />
                            </div>

                        </div>

                        {/* is cta, dropdown for is CTA, end date */}
                        <div className={`grid  ${style.grid_template}`}>
                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input type="checkbox" name="is_cta" checked={bannerData.is_cta} onChange={(e) => { handleCheckBoxChange(e) }} />
                                <span>Is CTA</span>
                            </div>

                            {bannerData.is_cta && <Dropdown list={cta_values} name={selCtaValue} getDropDownValue={changeCTAValue} />}

                        </div>
                    </div>

                    {/* zone section */}
                    <div className={`${style.separator}`}>
                        {/* zone listing header*/}
                        <div className={`${style.mrg_top_3}`}>
                            <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                            <span className={`${style.zone_heading}`}>Select Zones</span>
                        </div>

                        {/* zone listing check boxes */}
                        <div className={`flex flex-sace-between flex-wrap`}>

                            {/* zone check boxes */}
                            <div onClick={() => { }} className={`${style.list_item}`}>
                                <label className={`${status === 'new' ? "pointer" : "not_allowed"}`}>
                                    <input type="checkbox" disabled={bannerData.banner_id} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.checkbox}`} checked={isAllZoneSelected} onChange={(e) => { selectAllZone(e) }} />
                                    <span className={`${style.checkmark}`}>All</span>
                                </label>
                            </div>

                            {
                                zoneList.map((city, index) => {
                                    return (
                                        <div key={city.id} onClick={() => { }} className={`${style.list_item}`}>
                                            <label className={`${status === 'new' ? "pointer" : "not_allowed"}`}>
                                                <input type="checkbox" disabled={bannerData.banner_id} className={`${status === 'new' ? "pointer" : "not_allowed"} ${style.checkbox}`} id={city.id} checked={city.is_selected} onChange={(e) => { selectZone(e) }} />
                                                <span className={`${style.checkmark}`}>{city.name}</span>
                                            </label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* dropdowns - content type, landing screen, category */}
                    <div className={`grid ${style.grid_template} ${style.mrg_top_3}`}>
                        <div>
                            <span className={`${style.land_page}`}>Content Type</span>
                            <Dropdown name={bannerData.content_type} list={ContentType} getDropDownValue={getContentType} height={'10rem'} />
                        </div>

                        <div>
                            <span className={`${style.land_page}`}>Landing Screen</span>
                            <Dropdown name={bannerData.landing_page} list={landingPageList} getDropDownValue={getLandingPage} height={'10rem'} />
                        </div>

                        { bannerData.landing_page.value === "CATEGORY" && 
                        <div>
                            <span className={`${style.land_page}`}>Category</span>
                            <Dropdown name={selectContentid} list={categoryList} height={'10rem'} getDropDownValue={handleCategorySelection}/>
                        </div>}

                    </div>

                    {/* condiiton section based on dropdowns */}
                    <div>

                        <div className={`grid ${style.grid_template} ${style.mrg_top_3} ${style.mrg_btm_3}`}>

                            {/* is web / app banner */}
                            {bannerData.content_type.value === "BANNER" &&
                            <>
                                <div>
                                    <label>
                                        <input type="checkbox" name="is_web_banner" checked={bannerData.is_web_banner} onChange={(e) => { handleCheckBoxChange(e) }} />
                                        Is Web Banner
                                    </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="checkbox" name="is_app_banner" checked={bannerData.is_app_banner} onChange={(e) => { handleCheckBoxChange(e) }} />
                                        Is App Banner
                                    </label>
                                </div>
                            </>
                            }

                            {/* is category */}
                            {(bannerData.content_type.value === "VIRTUL" || bannerData.content_type.value === "SUBSCRIPTION") && 
                            <div>
                                <label className={`${style.list_item}`}>
                                    <input type="checkbox" className={`${style.checkbox}`} checked={bannerData.is_category} onChange={() => { change_category() }} />
                                    <span className={`${style.checkmark}`}>Is Category</span>
                                </label>
                            </div>}

                            {/* is sub category*/}
                            {(bannerData.content_type.value === "VIRTUL" || bannerData.content_type.value === "SUBSCRIPTION") && 
                            <div>
                                <label className={`${style.list_item}`}>
                                    <input type="checkbox" className={`${style.checkbox}`} checked={bannerData.is_subcategory} onChange={() => { change_sub_category() }} />
                                    <span className={`${style.checkmark}`}>Is SubCategory</span>
                                </label>
                            </div>}

                            {/* lottie upload */}
                            {bannerData.content_type.value === "LOTTIE" && 
                            <>
                                <input 
                                    ref={el => inputRef.current['lottie'] = el}
                                    type='file' 
                                    onChange={() => handleFileUpload('lottie')}  
                                    className={`hide`} 
                                    // accept='.json, .lottie, .lott, .lt'
                                    accept='image/*'
                                />
                                <div className={` ${style.mrg_x_6} ${style.uploadLottie}`}>
                                    {
                                        bannerData.new_image_id ? 
                                        <>
                                            {/* <div className={`bold green`}>Image File</div>
                                            <div className={`flex flex-space-between`}>
                                                <a href={LottieFileUrl + bannerData.new_image_id} target={'_blank'}>
                                                    <span className={`green textUnderline`}> {bannerData.new_image_id}</span>
                                                </a>
                                                <button className={`${style.btn_outlined}`} onClick={() => handleFileClick('lottie')}>Choose file</button>
                                            </div>     */}
                                            <img 
                                                src={BaseImgUrl + bannerData.new_image_id} 
                                                onClick={() => handleFileClick('lottie')}
                                                width={'274px'}
                                            />
                                        </> : 
                                        <div className={`pointer textCenter`} onClick={() => handleFileClick('lottie')}>
                                            <img src={UploadIcon} alt="upload image" />
                                            <div className={`bold green`}>Upload Image File</div>
                                        </div>
                                    }
                                </div>
                            </>}

                            {/* associated banner id */}
                            {(bannerData.content_type.value === "VIRTUL" || bannerData.content_type.value === "SUBSCRIPTION" || bannerData.content_type.value == "BANNER" ) && 
                            <div>
                                <div className={`${style.title}`}>Associate Banner Id</div>
                                <input 
                                    className={`${style.input_field}`} 
                                    type={'number'} 
                                    value={bannerData.associated_banner_id} 
                                    name='associated_banner_id' 
                                    onWheel={(e) => e.target.blur()}
                                    placeholder='Associate Banner Id' 
                                    onChange={(e) => { handleInputChange(e) }} 
                                />
                            </div>}

                            {/* prod id */}
                            {(bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "IMAGE") && bannerData.landing_page.value === "PRODUCT" && 
                            <div>
                                <div className={`${style.title}`}>Prod id</div>
                                <input 
                                    className={`${style.input_field}`} 
                                    type='number' 
                                    value={bannerData.content_id} 
                                    name='content_id' 
                                    onWheel={(e) => e.target.blur()}
                                    placeholder='Prod id' 
                                    onChange={(e) => { handleInputChange(e) }} 
                                />
                            </div>}

                            {/* YT video and web view url */}
                            {(bannerData.content_type.value === "VIDEO" || bannerData.landing_page.value === "WEBV")&& 
                            <div >
                                <div className={`${style.title}`}>
                                    {bannerData.content_type.value === "VIDEO" ? 'Add Youtube Link Here' : 'Add Web View Url' }
                                </div>
                                <input 
                                    className={`${style.input_field}`} 
                                    type='text' 
                                    name="url" 
                                    value={bannerData.url}
                                    placeholder={bannerData.content_type.value === "VIDEO" ? 'Enter Youtube Video Id' : "Enter Url"}
                                    onChange={(e) => { handleInputChange(e) }} 
                                />
                            </div>}


                            {/* filter chip image for virtual banner */}
                            {
                                ((bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.landing_page.value === 'DEAL') &&
                                <div className={`grid ${style.filterImgContainer}`}>
                                        <input 
                                            ref={el => inputRef.current['filter_chip_image'] = el}
                                            type='file' 
                                            onChange={() => handleFileUpload(`filter_chip_image`)}  
                                            className={`hide`} 
                                            accept='image/*'
                                        />
                                        {
                                            bannerData.filter_chip_image? 
                                            <>
                                                <div className={`flex flex-space-between green bold fontS bold ${style.mrg}`}>
                                                    FILTER CHIP IMAGE   
                                                    <img 
                                                        src={deleteIcon}  
                                                        className={`pointer ${style.mrg_x_6}`}
                                                        onClick={ ()=> {if(bannerData.filter_chip_image){
                                                            deleteImage('filter_chip_image')
                                                            }
                                                        }
                                                    }
                                                    />                                              
                                                </div>
                                                <img 
                                                    src={catImgUrl + bannerData.filter_chip_image}
                                                    onClick={() => handleFileClick(`filter_chip_image`)}
                                                    className={` pointer ${style.filterImage}`} 
                                                />
                                            </> :
                                            <div 
                                                onClick={() => handleFileClick(`filter_chip_image`)}
                                                className={` pointer `} 
                                            >
                                                <div className={`textCenter`}>
                                                    <img src={UploadIcon} />
                                                    <div className={`green upperCase bold fontS`}>Upload Filter chip Image</div>
                                                </div>
                                            </div>
                                        }
                                </div>
                            }

                        </div>

                        <div className={`grid grid-justify-center ${style.grid_template}`}>

                            {/* old banner image */}
                            {(bannerData.content_type.value !== "VIRTUL" && bannerData.content_type.value !== "SAFE" && bannerData.content_type.value !== "LOTTIE") &&
                            <div className={`${style.mrg_x_6}`}>
                                <div className={`${style.land_page}`}>Old Banner Image</div>
                                <input 
                                    type="file" 
                                    name="image_id" 
                                    id="firstImg" 
                                    accept="image/*" 
                                    style={{ display: "none" }} 
                                    onChange={(e) => saveFile(e, 1)} 
                                />
                                <img 
                                    src={bannerData.selectedImage ? 'https://img.crofarm.com/images/promotions/' + bannerData.selectedImage : file} 
                                    className={`${style.imgContainer}`} onClick={() => { uploadImg("firstImg"); }} 
                                />
                                <div className={`fontS green`}> ASPECT RATIOS SHOULD BE (320 x 375)px </div>

                                <div>
                                    {fileError? <div style={{ color: '#f00' }}>Please upload image smaller than 100 Kb</div> :  <button className={`${style.uploadBtn}`} onClick={() => { uploadImage() }}>Upload</button>}
                                </div>
                            </div>}


                            {/* new banner image */}
                            {bannerData.content_type.value === "BANNER" && 
                            <div className={`${style.mrg_x_6}`}>
                                <div className={`${style.land_page}`}>New Banner Image</div>
                                <input 
                                    type="file" 
                                    style={{ display: "none" }} 
                                    name="new_image_id" 
                                    id="secondImg" 
                                    accept="image/png,image/jpeg,image/jpg,image/webp,image/svg" 
                                    onChange={(e) => saveFile(e, 2)} 
                                />
                                <img 
                                    src={bannerData.newselectedImage ? 'https://img.crofarm.com/images/promotions/' + bannerData.newselectedImage : secondFile} 
                                    className={`${style.imgContainer}`} onClick={() => { uploadImg("secondImg"); }} 
                                />
                                <div className={`fontS green`}> ASPECT RATIOS SHOULD BE (630 x 300)px </div>
                                <div>
                                    {secondFileError? <div style={{ color: '#f00' }}>Please upload image smaller than 100 Kb</div> :  <button className={`${style.uploadBtn}`} onClick={() => { uploadNewImage() }}>Upload</button>}
                                </div>

                                
                            </div>}

                        </div>

                        {/* widget addition */}
                        {/* only for content ype to be -> image, virtual & banner */}
                        {
                            ( (bannerData.content_type.value === "IMAGE" || bannerData.content_type.value === "BANNER" || bannerData.content_type.value === "VIRTUL") && bannerData.landing_page.value === "DEAL") &&
                            <div className={`${style.mrg_top_3}`}>

                                <div className={`${style.title}`}>Widget ({widgetCount().active} active, {widgetCount().inactive} inactive)</div>
                                <div className={`greyBdr brdRdS`}>

                                    {/* heading */}
                                    <div className={style.ul_tag}>
                                        <li className={`grid ${style.grid_template_widget} ${style.sku_thead}`}>
                                            <div className={`${style.pd_bg}`}> Status </div>
                                            <div className={`${style.pd_bg}`}> Widget Details </div>
                                            <div className={`${style.pd_bg}`}> Pricing Zone </div>
                                            <div className={`${style.pd_bg}`}> Rank </div>
                                            <div className={`flex flex-space-between ${style.pd_bg}`}> 
                                                Action
                                                {
                                                    !!bannerData.widget_list.length &&
                                                    <DownToggleBtn 
                                                        className={`iconM whiteIcon ${bannerData.isWidgetListCollapsed ? '' : 'rotate180'}`}
                                                        onClick={widgetCollapse}
                                                    />
                                                }
                                            </div>
                                        </li>
                                    </div>

                                    {
                                        bannerData.isWidgetListCollapsed ?
                                        <div className={`textCenter fontS labelGrey bold ${style.pd_bg}`}>
                                            <span className={`pointer`} onClick={widgetCollapse}>. . .  VIEW WIDGET LIST</span>
                                        </div> :
                                        <>
                                            {/* listing */}
                                            <div>
                                                {
                                                    bannerData.widget_list.map((widget, index) => {
                                                        return(
                                                            <li className={`grid ${style.grid_template_widget} ${style.sku_row} ${widget.is_active ? style.row_green : style.row_red}`} key={`${widget.id}_${index}`}>
                                                                <div className={`${style.pd_bg}`}>
                                                                    {
                                                                        widget.is_active ? 
                                                                        <span className={`bold fontS green`}>ACTIVE</span> : 
                                                                        <span className={`bold fontS red`}>INACTIVE</span>
                                                                    }
                                                                </div>
                                                                <div className={`${style.pd_bg}`}>
                                                                    <div><span className={`bold fontS`}>ID: </span>{widget.id}</div>
                                                                    <div><span className={`bold fontS`}>TAG: </span>{widget.widget_tag ? widget.widget_tag : "--"}</div>
                                                                    <div><span className={`bold fontS`}>TYPE: </span>{widget.widget_type}</div>
                                                                </div>
                                                                <div className={`${style.pd_bg}`}>{widget.zone_list ? widget.zone_list.join(", ") : ""}</div>
                                                                <div className={`${style.pd_bg}`}>
                                                                    <Dropdown 
                                                                        NoKey={true}
                                                                        name={widget.rank} 
                                                                        placeholder={'Select Rank'}
                                                                        list={widgetRank} 
                                                                        getDropDownValue={(elem) => handleRankChange(elem, index)} 
                                                                        height={'10rem'} 
                                                                    />
                                                                    {(isNaN(widget.rank)) && <div className={`fontXS red`}>{'* Please Enter Valid Rank'}</div>}
                                                                </div>
                                                                <div className={`${style.pd_bg}`}>
                                                                    <BinGreyIcon
                                                                        height='20px'
                                                                        width='20px'
                                                                        className={`pointer redIcon`}
                                                                        onClick={() => { handleDeleteWidget(index) }}
                                                                    />
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </div>  

                                            {/* btn */}
                                            <div className={` textCenter ${style.pd_bg}`}>
                                                <button className={`${style.outlinedBtn} `} onClick={() => { openAddPopup('showAddWidget') }}>Add Widget (+)</button>
                                            </div>  
                                        </>
                                    }
                                </div>

                            </div>


                        }

                        {/* add sku and listing - drag and drop */}
                        <div className={`${style.mrg_top_3}`}>
                            {
                                (bannerData.landing_page.value === 'DEAL' || bannerData.content_type.value === "SAFE") && 
                                <div className={`greyBdr brdRdS`}>
                                    <div className={style.ul_tag}>
                                        <li className={`grid ${style.grid_template_sku} ${style.sku_thead}`}>
                                            <div className={style.pd_bg}> Image </div>
                                            <div className={style.pd_bg}> Product Name </div>
                                            <div className={style.pd_bg}> Price </div>
                                            <div className={style.pd_bg}> Action </div>
                                        </li>
                                    </div>
                                    <DragDropContext onDragEnd={handleOnDragEnd}>
                                        <Droppable droppableId='skuProd'>
                                            {(provided) => (
                                                <ul className={style.ul_tag}  {...provided.droppableProps} ref={provided.innerRef}>
                                                    {bannerData.product_list.length > 0 && bannerData.product_list.map((item, index) => {
                                                        if (item) {
                                                            return (
                                                                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                                    {(provided) => (
                                                                        <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={`grid ${style.grid_template_sku} ${style.sku_row} ${style.row_green}`}>
                                                                            <div className={style.pd_bg} style={{ float: 'left' }}>
                                                                                <img className={`${style.sku_img}`} src={'https://img.crofarm.com/images/prodsmall/' + item.image_id} />
                                                                            </div>
                                                                            <div className={style.pd_bg}>
                                                                                <strong className={style.product_id}>[{item.prod_id}] </strong>
                                                                                <span className={style.prod_name}>{item.prod_name} </span>
                                                                                <span className={`labelGrey`}>( Pack: {item.pack_qt} )</span>
                                                                            </div>
                                                                            <div className={style.pd_bg}>
                                                                                <span className={style.prod_name}>{item.price}</span>
                                                                            </div>
                                                                            <span className={style.pd_bg}><img style={{ height: '20px' }} src={deleteIcon} onClick={() => onDeleteProd(item.id)} /></span>
                                                                        </li>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        }
                                                    })}
                                                    {provided.placeholder}
                                                </ul>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <div className={` textCenter ${style.pd_bg}`}>
                                        <button className={`${style.outlinedBtn} `} onClick={() => { openAddPopup('showAddSkuPopup') }}>Add Skus (+)</button>
                                    </div>
                                </div>
                            }

                        </div>

                    </div>

                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => navigate(`/feed-content/${slot}/`)} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={(e) => { publishCampaign(e) }}>
                            {status === "edit" ? "Update" : "Publish"} Banner
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>

            {
                popup.showAddSkuPopup && 
                <PopupAddSku
                    zoneList={zoneList}
                    dealType={`Banner`}
                    burnSkuList={[]}
                    nonBurnSkuList={[]}
                    closeBtn={() => { setPopup({
                        ...popup, 
                        showAddSkuPopup: false,
                    }) }}
                    addSkuForDeal={addSkuForDeal}
                    removeSkuForDeal={onDeleteProd}
                />
            }

            {
                popup.showAddWidget && 
                <PopUpAddWidget
                    closeBtn={() => { setPopup({ ...popup, showAddWidget: false }) }}
                    title={'Search Widget'}
                    handleAddWidget={handleAddWidget}
                    isSearchMiniFeed={false}
                    showAllWidgets={false}
                    hasCondition={true}
                    checkEligibilty={checkWidgetEligibilty}
                />
            }

            {isLoading && <Loader />}
        </>
    );
}

export default AddBanner;
