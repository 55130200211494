import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { downloadExcelFile } from '../../../../utils/api';
import style from '../css/Bonus.module.css'
import Downloadcsv from '../../../../icons/Downloadcsv.png'
import Uploadcsv from '../../../../icons/Uploadcsv.png'
import { communicationExcelFormat } from '../../../../utils/constData';
import PopupExcelError from './PopupExcelError';

function Header({ csvList, setCsvList, varBulkUpload}) {
    

    const excelVarCount = 11;
    const [errorPopup, setErrorPopup]= useState({
        show: false,
        error : [],
    })

    const generateData = (e, jsonData, arrayData) => {

        let isOk = true, message = [];
        
        let downloadObj = varBulkUpload == 1 ? communicationExcelFormat.varList[0] : communicationExcelFormat.consumer[0];
        downloadObj = Object.keys(downloadObj);

        // checking the header length same as templete
        if(downloadObj.length !== arrayData[0].length){
            isOk = false;
            message = ["Wrong Templete Used!"];
        }
        
        // checking the headers are same as the templete provided
        downloadObj.map((headerName) => {
            if(!arrayData[0].includes(headerName)){
                isOk = false;
                message = ["Wrong Templete Used!"];
            }
        })

        // to handle new format for variable
        if( isOk && varBulkUpload == 1){
            let csvDataVarList = [];

            // csvdata mapping
            jsonData.map((row) => {

                // check for no consumer_id || phone || user_id 
                if(!row.consumer_id && !row.phone && !row.user_id){
                    return;
                }

                let varList = [];
                
                // for adding variable list upto var 11;
                for(let i=1; i <= excelVarCount; i++) {
                    varList.push(row[`var${i}`]);
                }

                // checking for undefined values
                let emptyIdxList = [];
                varList.reduce((emptyIdxList, elem, idx) => {
                    if(elem === undefined){
                        emptyIdxList.push(idx)
                    }
                    return emptyIdxList;
                }, emptyIdxList)
                
                console.log("---->", varList, row)
                console.log(emptyIdxList)

                // validations
                // no variable check
                if(emptyIdxList.length === excelVarCount){
                    isOk = false;
                    let userIdentification = 
                        row.consumer_id ? 'consumer_id :' + row.consumer_id : 
                        row.phone ? 'phone : ' + row.phone :
                        'data.user_id :' + row.user_id;

                    let errorMessage = `No variables defined for ${userIdentification}`;
                    message.push(errorMessage);
                }
                // non consecutive index undefined check
                else if(emptyIdxList.length){
                    emptyIdxList.map((value, index) => {

                        // last index must 10 i.e. total variable in templete - 1
                        if( (index == emptyIdxList.length - 1) && (value !== excelVarCount - 1)){
                            isOk = false;
                        }
                        // checking next value for non last value
                        else if( (index !== emptyIdxList.length - 1) && ((value + 1) !== (emptyIdxList[index + 1])) ){
                            isOk = false;
                            console.log(value + 1, emptyIdxList[index + 1], emptyIdxList, index)
                        }
                    })

                    if(!isOk){
                        let userIdentification = 
                        row.consumer_id ? 'consumer_id :' + row.consumer_id : 
                        row.phone ? 'phone : ' + row.phone :
                        'data.user_id :' + row.user_id;

                        let errorMessage = `Issues with variables for ${userIdentification}`;
                        message.push(errorMessage);
                    }
                }
                

                let consumerData = {
                    consumer_id: row.consumer_id,
                    phone: row.phone,
                    user_id: row.user_id, 
                    var_list: varList.filter(data => data !== undefined),
                }
                csvDataVarList.push(consumerData)
            })

            jsonData = csvDataVarList;

        }

        if(isOk){
            setCsvList(jsonData);
        }
        else {
            // error handling
            console.log("error", message);
            setErrorPopup({
                show: true,
                error: message
            })
        }
        document.getElementById('upload_sms_field').value = "";
    }

    // read excel
    const handleFileChange = (e) => {
        e.preventDefault();

        var files = e.target.files,
          f = files[0];
        var newData ;
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: "binary" });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            // for proper json response use dataParse2
            const dataParse2 = XLSX.utils.sheet_to_json(ws);
            newData = Object.assign({}, dataParse2);
            console.log("Parsed Data:", dataParse);
            console.log("Parsed Data 2:", dataParse2);

            generateData(e, dataParse2, dataParse)

        };
        reader.readAsBinaryString(f);
    };


    // on upload click
    const uploadExcel = () => {
        document.getElementById('upload_sms_field').click();
    }

    // download format
    const downloadCsv = async () => {

        let downloadList = varBulkUpload == 1 ? communicationExcelFormat.varList : communicationExcelFormat.consumer;

        downloadExcelFile(downloadList,'Sample-Bonus','csv');

    }

    return (
        <>
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Send Bonus Communication</span> </div>

                {
                    (varBulkUpload == 0 || varBulkUpload == 1) &&
                    <div className={`flex`}>
                
                        <div className="d-none file_input_new">
                            <input type="file" onChange={handleFileChange} accept=".csv" className="mb-4 pull-left" id="upload_sms_field" />
                        </div>
                        <div className={`bold`}>Total {csvList.length} records found</div>

                        <button className={`${style.btnM} ${style.csvBtn} ${style.outlinedBtn}`} onClick={ downloadCsv} >
                            <img className={`${style.mrg_x_6} ${style.height_width_30px}`} src={Downloadcsv} />    
                            Download SAMPLE
                        </button>
                        
                        <button className={`${style.btnM} ${style.csvBtn} ${style.outlinedBtn}`} onClick={ uploadExcel} >
                            <img className={`${style.mrg_x_6} ${style.height_width_30px}`} src={Uploadcsv} />
                            Upload CSV
                        </button>

                        
                    </div>
                }
            </div>


            {/* excel error */}
            {
                errorPopup.show &&
                <PopupExcelError 
                    title={`Error!`} 
                    closeBtn={() => setErrorPopup({
                        show: false,
                        error: []
                    })}
                    errorMessage={errorPopup.error}
                />
            }
        </>
    );
}

export default Header;