import React,{useState,useEffect} from 'react';
import { getCutOffZoneListing } from '../../../../utils/api';
import EditIcon from '../../../../icons/edit.svg'
import style from '../css/CutOff.module.css';
import PopUpEditCutOff from './PopUpEditCutOff';

function CutOffList(props) {
    const [itemList,setItemList] = useState([]);
    const [showPopUp,setshowPopUp] = useState(false);
    const [pricingZone,setPricingZone] = useState({});


    const togglePopUp = () => {
        setshowPopUp(!showPopUp);
    }


    const updateItemList = async () => {
       let res = await getCutOffZoneListing();
       console.log(res);
       if(res.data.length){
        setItemList(res.data);
       }
       else {
        setItemList([]);
       }
    }
    useEffect(()=>{
        updateItemList();
    },[]);


    return (
        <>
            <div className={`${style.container}`}>
              <div className={`flex ${style.thead}`}>
                <div className={`${style.pd_bg} ${style.width25}`}>Prizing Zone ID</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Prizing Zone Name</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Cut Off Time(HH:MM)</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Cut Off Time(Military)</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Action</div>
              </div>

              <div className={`${style.tbody}`}>
            {
                 itemList.map((item,idx) => {
                   return (
                    <div className={` flex flex-space-around ${style.trow}`} key={idx}>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.id}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.pricing_zone_name}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.cut_off_time_hh_mm}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.cut_off_time_military}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>
                        <div>
                            <span className={`pointer ${style.EditIcon}`} onClick={()=>{
                                setPricingZone(item);
                                togglePopUp();
                            }}><img src={EditIcon}/></span>
                        </div>
                        </div>
                    </div>
                   )
                })
            }
              </div>

           </div>
           {
            showPopUp ? 
            <PopUpEditCutOff 
            closeBtn={() => togglePopUp()} 
            zone={pricingZone.pricing_zone_name} 
            time = {pricingZone.cut_off_time_military}
            msg = {pricingZone.cut_off_time_hh_mm}
            id = {pricingZone.id}
            fetchData={updateItemList}
            /> : null
           }
        </>
    );
}

export default CutOffList;