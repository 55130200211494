// api urls and static data urls will be here
import { get_access_token } from "./auth";
export const access_token = get_access_token();
export const configApi = "appconfig/otipybzapp/";

// export const Base = 'https://gcptest.crofarm.com/';
// export const Product = 'https://gcptest.crofarm.com/spa/';
// export const pcms = 'https://gcptest.crofarm.com/cms/';
// export const prodCms = 'https://gcptest.crofarm.com/productcms/'

//  export const Base = 'https://api.crofarm.com/';
//  export const Product = 'https://api.crofarm.com/spa/';
//  export const pcms = 'https://api.crofarm.com/cms/'
// export const prodCms = 'https://api.crofarm.com/productcms/'
// export const otipyCoupons = 'https://api.crofarm.com/otipycoupons/'
// export const Ondc = 'https://ondc.crofarm.com/bap/';
// export const OndcFeed = 'https://ondc.crofarm.com/bap/erp/';

 export const Base = 'https://qa1.crofarm.com/';
 export const Product = 'https://qa1.crofarm.com/spa/';
 export const pcms = 'https://qa1.crofarm.com/cms/'
export const prodCms = 'https://qa1.crofarm.com/productcms/'
export const otipyCoupons = 'https://qa1.crofarm.com/otipycoupons/'
export const Ondc = 'https://qa1.crofarm.com/bap/';
export const OndcFeed = 'https://qa1.crofarm.com/bap/erp/';


//  export const Base = 'https://stage5.crofarm.com/';
//  export const Product = 'https://stage5.crofarm.com/spa/';
//  export const pcms = 'https://stage5.crofarm.com/cms/'
// export const prodCms = 'https://stage5.crofarm.com/productcms/'
// export const otipyCoupons = 'https://stage5.crofarm.com/otipycoupons/'
// export const Ondc = 'https://stage5.crofarm.com/bap/';
// export const OndcFeed = 'https://stage5.crofarm.com/bap/erp/';


// export const Base = 'https://stage3.crofarm.com/';
// export const Product = 'https://stage3.crofarm.com/spa/';
// export const pcms = 'https://stage3.crofarm.com/cms/'
// export const prodCms = 'https://stage3.crofarm.com/productcms/'

// export const Base = 'https://stagemain.crofarm.com/';
// export const Product = 'https://stagemain.crofarm.com/spa/';
// export const pcms = 'https://stagemain.crofarm.com/cms/'
// export const prodCms = 'https://stagemain.crofarm.com/productcms/'


export const BasicImgUrl = 'https://img.crofarm.com/'
export const BaseImgUrl = 'https://img.crofarm.com/images/'
export const imgUrl = 'https://img.crofarm.com/images/prodsmall/'
export const catImgUrl = 'https://img.crofarm.com/images/category/'
export const deliveryImgUrl = 'https://img.crofarm.com/images/orders/delivery/';
export const widgetImgUrl = 'https://img.crofarm.com/images/widget/'
export const BannerImgUrl = 'https://img.crofarm.com/images/promotions/';
export const ProductWidgetImgUrl = 'https://img.crofarm.com/images/product-feed-banners/';
export const SubscriptionImgUrl = 'https://img.crofarm.com/images/subscription/';

// export const ProductVideoImgUrl = 'https://img.crofarm.com/static/videos/'
export const SmsImgUrl = 'https://img.crofarm.com/communication/'

// export const SmsVideoImgUrl = 'https://storage.googleapis.com/crofarm-video-source/communication/'
// export const ProductVideoImgUrl = 'https://storage.googleapis.com/crofarm-video-source/'

export const SmsVideoImgUrl = 'https://video.crofarm.com/communication/'
export const ProductVideoImgUrl = 'https://video.crofarm.com/'


export const LottieFileUrl = 'https://storage.googleapis.com/crofarm-lottie/lotties/';
// export const LottieFileUrl = 'https://img.crofarm.com/';

export const PdfUrl= 'https://img.crofarm.com/static/reports/'
