import React, { useEffect } from 'react'
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DatePicker from '../../../../commonComponent/DatePicker/DatePicker';
import { getScheduleItem } from '../../../../utils/api';
import style from "../css/BonusManagement.module.css"
import DeleteIcon from "../../../../icons/bin.svg"
import { ErrorHandling } from '../../../../State';
// import Alert from '../../../CommonService/AlertMessage/MessagePopup';
// import { post_data } from '../../../CommonService/APIService/RequestMethod';
// import { Base, OrderSuccesBanner } from '../../../CommonService/config';
// import DatePicker from '../../../CommonService/DatePicker/DatePicker'
// import deleteIcon from "../../../Images/ico-delete.svg"

export default function ScheduleModal(props) {
    const { closeModal, updateSchecduleSegment, data } = props;
    const { id } = useParams();
    const [schudleData, setScheduleData] = useState({ whatsapp_template: '', whatsapp_image_link: '', vars_list: [] })
    const [selectedImage, setSelectedImage] = useState('');
    const [imageId, setImageId] = useState('');
    const [variable, setVar] = useState('');
    const [alert, setAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [error, setError] = useState(false);
    const [file, setFile] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let res = await getScheduleItem(id).then((res) => {
            console.log(res);
            setScheduleData({ ...schudleData, ...res.data[0] });
        })
    }
    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    const getDate = (from, date) => {
        console.log(date);
        let obj = { schedule_time: date }
        setScheduleData({ ...schudleData, ...obj })
    }

    const updateData = (e) => {
        let obj = {}
        obj[e.target.name] = e.target.value;
        setScheduleData({ ...schudleData, ...obj });
    }
    const handleChange = (e) => {
        console.log(e.target.files);
        setFile(URL.createObjectURL(e.target.files[0]));
    };

    const Add_vars = () => {
        if (variable.trim() === '' || !variable) {
            ErrorHandling('Please Enter the variable')
        } else {
            let list = schudleData.vars_list;
            list.push(variable);
            let obj = schudleData;
            obj.vars_list = list;
            setScheduleData({ ...obj });
            setVar('');
        }
    }

    const onDelete = (vars) => {
        let list = schudleData.vars_list;
        let index = list.indexOf(vars);
        list.splice(index, 1);
        let obj = schudleData;
        obj.vars_list = list;
        setScheduleData({ ...obj });

    }

    const showErrorAlert = (text) => {
        setAlertMessage(text)
        setError(true)
        setAlert(true);
        setTimeout(() => {
            setAlertMessage('');
            setAlert(false)
            setError(false)
        }, 3000)
    }

    const showSuccessAlert = (text) => {
        setAlertMessage(text)
        setAlert(true);
        setTimeout(() => {
            setAlertMessage('');
            setAlert(false)
        }, 3000)
    }

    // const uploadImage = () => {
    //     var image_type = 'images';
    //     let data = new FormData();
    //     data.append('file', imageId);
    //     data.append('image_type', image_type)
    //     // let new_data = {image_type:'spec',file:file}
    //     post_data(Base + 'spa/products/image/v1/', data).then((response) => {
    //         if (response.success) {
    //             setSelectedImage(response.image_id)
    //             let link = OrderSuccesBanner + response.image_id;
    //             let obj = schudleData;
    //             obj.whatsapp_image_link = link;
    //             setScheduleData({ ...obj });
    //             showSuccessAlert('Uploaded Successfully');
    //         } else {
    //             showErrorAlert(response.error.message);
    //         }
    //     })

    // }

    const saveFile = (e) => {
        setImageId(e.target.files[0])
    }



    return (
        <div>
            <div className={`${style.header} flex flex-space-between`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP | <span className={`bold green`}>Bonus Management</span>
                </div>
            </div>
            <div className={`${style.addCouponContainer}`}>
                <div className={`${style.addCouponForm}`}>
                    <div className={`${style.couponHeader} flex flex-space-between`}>
                        <div className={`${style.heading} bold green`}>Schedule Segment</div>
                        <button className={`${style.filledBtn}`} onClick={() => navigate('/bonus-management/')}>Cancel</button>
                    </div>
                    <div className={`${style.firstRow} flex flex-space-between`}>
                        <div className={`${style.width_50}`}>
                            <DatePicker isHide={true} isEndHide={true} isTodTomVisible={false} start_time={schudleData.schedule_time} getDate={getDate} />
                        </div>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.uploadImg}`}>
                                <div className={`${style.title_banner}`}>
                                    Aspect ratio should be (632 x 300)px
                                </div>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    name="first"
                                    id="firstImg"
                                    accept="image/*"
                                    onChange={handleChange}
                                />
                                <img
                                    src={file}
                                    className={`${style.imgContainer}`}
                                    onClick={() => {
                                        uploadImg("firstImg");
                                    }}
                                />
                                <button className={`${style.uploadBtn}`}>Upload</button>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.seperatorRow} flex flex-space-between`}>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>SMS Text</div>
                            <input className={`${style.inputSegmentField} ${style.width75}`} name='sms_text' placeholder="SMS Text for segment" type='text' defaultValue={schudleData.sms_text} onChange={(e) => { updateData(e) }} />
                        </div>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>Whatsapp Template</div>
                            <input className={`${style.inputSegmentField} ${style.width75}`} name='whatsapp_template' placeholder="Whatsapp Template for segment" type='text' defaultValue={schudleData.whatsapp_template} onChange={(e) => { updateData(e) }} />
                        </div>

                    </div>
                    <div className={`${style.seperatorRow} flex flex-space-between`}>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>PN Head</div>
                            <input className={`${style.inputSegmentField} ${style.width75}`} name='pn_head' placeholder="PN Head for segment" type='text' defaultValue={schudleData.pn_head} onChange={(e) => { updateData(e) }} />
                        </div>
                        <div className={`${style.width_50}`}>
                            <div className={`${style.title}`}>PN Text</div>
                            <input className={`${style.inputSegmentField} ${style.width75}`} name='pn_text' placeholder="PN Text for segment" type='text' defaultValue={schudleData.pn_text} onChange={(e) => { updateData(e) }} />
                        </div>
                    </div>
                    <div className={`${style.seperatorRow} flex flex-space-between`}>
                        <div className={`${style.width100}`}>
                            <div className={`${style.title}`}>Variable List</div>
                            <input className={`${style.inputSegmentField} ${style.width87}`} name='variable' placeholder="Variable List for segment" type='text' value={variable} onChange={(e) => { setVar(e.target.value) }} />
                        </div>
                    </div>
                    <div>
                        <button className={`${style.outlinedBtn}`} style={{ marginTop: "30px", marginLeft: "20px" }} onClick={Add_vars}>Add Variable</button>
                    </div>
                    {
                        schudleData.vars_list && schudleData.vars_list.length > 0 &&
                        <div className='vars_list_display_container'>
                            <div className={`${style.heading} bold green`}>Variable List</div>
                            {
                                schudleData.vars_list.map((vars, index) => {
                                    return (
                                        <div className='vars_list_container' key={index}>
                                            <div className='var_list_item'>{vars}</div>
                                            <div className='var_list_item'><img src={DeleteIcon} alt="delete" onClick={() => { onDelete(vars) }} /></div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    <div className={`${style.secondRow} flex`} style={{ flexDirection: "row-reverse" }}>
                        <div className={`flex`}>
                            <div>
                                <button className={`${style.filledBtn}`} style={{ marginBottom: "30px" }} onClick={() => { updateSchecduleSegment(schudleData) }}>Submit</button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

    )
}