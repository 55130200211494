import React, { useState, useEffect } from 'react';
// import NavBar from '../../../CommonService/Header/NavBar';
// import Dropdown from '../../../CommonService/Dropdown/Dropdown';
import { useRef } from 'react';
import { useImperativeHandle } from 'react';
import { useNavigate } from 'react-router';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import { resetSegement } from '../../../../utils/api';
import style from "../css/BonusManagement.module.css"


const Header = React.forwardRef((props, ref) => {
    const { segmentModal, scheduleModal, getRechargeList } = props;
    const [selectWalletType, setWalletType] = useState({ "id": 1, name: 'Reward Wallet' })
    const navigate = useNavigate();


    const wallet_list = [{ "id": 1, "name": "Reward Wallet" }, { "id": 0, "name": "Cash Wallet" }]
    const wallet_type = useRef(null);

    useImperativeHandle(ref, () => ({
        hideDropdownMenu() {
            hideDropdownMenu()
        }
    }))

    useEffect(() => {
        getRechargeList(selectWalletType);
    }, [])


    const hideDropdownMenu = () => {
        if (!props.createFeedForm) {
            wallet_type.current.hideDropdownMenu();
        }
    }



    const getWalletBasedList = (value) => {
        setWalletType(value)
        getRechargeList(value)
    }



    return (
        <>
            {/* <header>
                <div className='header_bg' onClick={() => { hideDropdownMenu() }}>
                    {/* <div className="nav_container">
                        <NavBar />
                    </div> */}
            {/* <div className='hdr_title header_width'>
                        Crofarm ERP | <span>App Feed</span>
                    </div>
                    <div style={{ width: '60%' }}>
                        <div>
                            <div style={{ display: "flex", }}>
                                {
                                    !scheduleModal &&  <div className="banner_container"><Dropdown ref={wallet_type} list={wallet_list} name={selectWalletType} getDropDownValue={getWalletBasedList} /></div>}
                                <div className='bonus_management_header_btn_container'>
                                    {!scheduleModal && <div className=""><button className={`create-campaign-btn btn-color`} onClick={() => { navigate(`/${selectWalletType.id}/add-segment/`) }}>{segmentModal ? 'back' : 'Add Segment'}</button></div>}
                                    {(!segmentModal && !scheduleModal) && <div className=""><button className={`create-campaign-btn btn-color`} onClick={() => { resetSegement(selectWalletType) }}>Reset</button></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}

            <div className={`${style.header} flex flex-space-between`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Bonus Management</span></div>

                <div className={`flex flex-space-between`}>
                    {!scheduleModal && <div><Dropdown ref={wallet_type} list={wallet_list} name={selectWalletType} getDropDownValue={getWalletBasedList} /></div>}
                    <div className={`flex`}>
                        {!scheduleModal && <div><button className={`${style.filledBtn}`} onClick={() => { navigate(`/${selectWalletType.id}/add-segment/`) }}>{segmentModal ? 'Back' : 'Add Segment'}</button></div>}
                        {(!segmentModal && !scheduleModal) && <div><button className={`${style.outlinedBtn}`} onClick={() => { resetSegement(selectWalletType) }}>Reset</button></div>}
                    </div>
                </div>
            </div>
        </>
    )

})
export default Header;