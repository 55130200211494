import React, { useEffect, useState } from 'react';
import { searchSku } from '../../../../utils/api';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css'
import popupStyle from '../../../../commonComponent/PopUp/Popup.module.css'
import { imgUrl } from '../../../../utils/config';
import { ErrorHandling } from '../../../../State';

function PopupAddSku(props) {
    const [searchQuery, setSearchQuery] = useState("");
    const [skuList, setSkuList] = useState([]);
    const [skuIdList, setSkuIdList] = useState([]);

    const searchProd = async () => {
        let selzone = props.zoneList.filter(item => item.is_selected)
        let list = await searchSku(selzone[0].id, searchQuery);
        if(props.showInactive){
            setSkuList(list)
        }
        else{
            list = list.filter(( sku ) => sku.is_active)
            setSkuList(list)
        }
    }

    const AddSku = (sku, isChecked) => {
        if (isChecked === true) {

            let checkBurnList = props.burnSkuList.filter(item => item.prod_id == sku.prod_id)
            let checkNonBurnList = props.nonBurnSkuList.filter(item => item.prod_id == sku.prod_id)
            if (checkNonBurnList.length || checkBurnList.length) {
                ErrorHandling("Product already added");
                return;
            }
            else {
                let list = skuIdList
                let obj = { is_checked: isChecked, prod_id: sku.prod_id }
                list.push(obj)
                setSkuIdList([...list])

                props.addSkuForDeal(sku)
            }

        } else {
            let list = skuIdList.filter(item => item.prod_id !== sku.prod_id)
            setSkuIdList([...list])
            props.removeSkuForDeal(sku.prod_id)
        }

    }
    useEffect(() => {
        // if(searchQuery){
        // apiCall
        searchProd();
        // }
        // else{
        //     // set skuList empty
        //     setSkuList([])
        // }
        document.getElementById('searchInput').focus()
    }, [searchQuery])
    return (
        <PopUp
            title={`Add Sku for ${props.dealType}`}
            width={`${formStyle.popupWidthM}`}
            closeBtn={props.closeBtn}
        >
            {skuIdList.length > 0 && <div className={`${popupStyle.heading} textLeft`}>
                {skuIdList.map(sku => { console.log("yesssssssssss"); return (<span>{sku.prod_id}, </span>) })}
            </div>}
            <div className={`${formStyle.inputContainer}`}>
                {/* input */}
                <div>
                    <input
                        className={`${formStyle.searchBar} ${formStyle.fullwidth}`}
                        id={'searchInput'}
                        type={'text'}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder={'Search Product ID / name'}
                    />
                </div>
                {/* skuList */}
                <div className={`${formStyle.overlay_36} ${formStyle.custom_scrollbar}`}>
                    {skuList.map((sku) => {
                        return (
                            <div className={`grid ${sku.is_active ? formStyle.greenBackgroundColor : formStyle.redBackgroundColor} ${formStyle.sku_list}`} key={sku.id}>
                                <input className='pointer' type='checkbox' onChange={(e) => AddSku(sku, e.target.checked)} />
                                <div>
                                    <img className={`${formStyle.sku_img}`} height='55px' width='55px' src={imgUrl + sku.image_id} />
                                </div>
                                <div>
                                    <div><strong>[{sku.prod_id}]</strong> {sku.prod_name}</div>
                                    <div><strong>Pack:</strong> {sku.pack_qt}</div>
                                    {sku.is_active ? <div className='green bold'>ACTIVE</div> : <div className='red bold'>INACTIVE</div>}
                                </div>
                                <div>
                                    <strong>Price:</strong> {sku.price}
                                </div>

                            </div>
                        )
                    })}
                </div>
            </div>
        </PopUp>

    );
}

export default PopupAddSku;