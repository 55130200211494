import React,{useState,useEffect} from 'react';
import { getBurnMappingList } from '../../../../utils/api';
import style from '../css/BurnMapping.module.css';
import EditIcon from '../../../../icons/edit.svg';
import BurnMappingPopUp from './BurnMappingPopUp';
function BurnMappingList({showPopUp, setshowPopUp}) {

    const [itemList,setItemList] = useState([]);
    // const [showPopUp,setshowPopUp] = useState(false);
    const [maxBurn,setmaxBurn] = useState({});

    const updateItemList = async () => {
        let res = await getBurnMappingList();
        console.log(res);
        if(res.data.length){
         setItemList(res.data);
        }
        else {
         setItemList([]);
        }
     }

     const togglePopUp = (clearBurnSegment) => {
        setshowPopUp(!showPopUp);
        if(clearBurnSegment) setmaxBurn({})
     }

     useEffect(()=>{
         updateItemList();
     },[]);


    return (
        <>
            <div className={`${style.container}`}>
              <div className={`flex ${style.thead}`}>
                <div className={`${style.pd_bg} ${style.width25}`}>Prizing Zone ID</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Prizing Zone Name</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Existing User</div>
                <div className={`${style.pd_bg} ${style.width25}`}>New User</div>
                <div className={`${style.pd_bg} ${style.width25}`}>Action</div>
              </div>

              <div className={`${style.tbody}`}>
            {
                 itemList.map((item,idx) => {
                   return (
                    <div className={` flex flex-space-around ${style.trow}`} key={idx}>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.zone_id}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.zone_name}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.max_burn_old_user}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>{item.max_burn_new_user}</div>
                        <div className={`${style.pd_bg} ${style.width25}`}>
                        <div>
                            <span className={`pointer ${style.EditIcon}`} 
                            onClick={()=>{
                                setmaxBurn(item);
                                togglePopUp();
                            }}>
                            <img src={EditIcon}/>
                            </span>
                        </div>
                        </div>
                    </div>
                   )
                })
            }
              </div>
           </div>
           {
            showPopUp ? 
            <BurnMappingPopUp
            closeBtn={() => togglePopUp(true)} 
            fetchData={updateItemList}
            zoneId={maxBurn.zone_id}
            zoneName={maxBurn.zone_name}
            max_burn_ext_user={maxBurn.max_burn_old_user}
            max_burn_new={maxBurn.max_burn_new_user}
            id={maxBurn.id}
            /> : null
           }
        </>
    );
}

export default BurnMappingList;