import React, { useEffect, useState } from "react";
import { searchOfferSegment } from "../../../../utils/api";
import PopUp from "../../../../commonComponent/PopUp/PopUp";
import formStyle from "../../../../commonComponent/PopUp/Form.module.css";
import { imgUrl } from "../../../../utils/config";
import { ReactComponent as DeleteIcon } from "../../../../icons/bin.svg";
import { SEGMENT_STATUS } from "../../../../utils/constData";

function PopupUpdateSegment(props) {
  const [skuList, setSkuList] = useState([]);

  useEffect(() => {
    setSkuList(props.list || []);
  }, [props.list]);

  return (
    <PopUp
      title={`Update Segment for ${props.dealType}`}
      width={`${formStyle.popupWidthM}`}
      closeBtn={props.closeBtn}
    >
      <div className={`${formStyle.inputContainer}`}>
        {/* skuList */}
        <div
          className={`${formStyle.overlay_36} ${formStyle.custom_scrollbar}`}
        >
          {skuList.map((sku) => {
            let status_name = SEGMENT_STATUS[sku.status].name
            return (
              <div
                className={` ${
                  SEGMENT_STATUS[sku.status].name == "ACTIVE"
                    ? formStyle.greenBackgroundColor
                    : formStyle.redBackgroundColor
                } ${formStyle.update_sku_list}`}
                key={sku.id}
              >
                <div
                  className={`${formStyle.flex_row} ${formStyle.justify_center} ${formStyle.pdX}`}
                >
                  <div className={`${formStyle.flex_row}  ${formStyle.flex2}`}>
                    <div className={`${formStyle.flex_col}`}>
                      <strong>id: {sku.id}</strong>
                      <strong>Name: {sku.name}</strong>{" "}
                      <strong>description: {sku.description}</strong>
                    </div>
                  </div>
                  <div className={`textCenter`}>
                    <DeleteIcon
                      className={`pointer`}
                      onClick={() => props.removeSkuForDeal(sku.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PopUp>
  );
}

export default PopupUpdateSegment;
