import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { CSVLink } from 'react-csv';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import { catImgUrl, imgUrl } from '../../../../utils/config';
import { cloneCampaign, createNewCampaign, deepCopyObject, getInventryInfoCampaignSku, getMaxMinSkuPrice, getOrderBannerZonewiseList, getUserSegmentInfo, refreshminmaxSkuData, uploadSelImage, userSegmentList } from '../../../../utils/api';
import { EpochTimeInHumanLan, EpochTimeTOHumanFormat,  UTCTimeFormat } from '../../../../utils/TimeManager';
import DatePicker from '../../../../commonComponent/DatePicker/DatePicker';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import PopupAddSku from './PopupAddSku';
import PopupCsvUpload from './PopupCsvUpload';
import PopupUserSegment from './PopupUserSegment';
import PopupOverlapingCampaign from './PopupOverlapingCampaign';
import PopupZoneBurnError from './PopupZoneBurnError';
import style from '../css/CreateCampaign.module.css';
import LocationIcon from "../../../../icons/location.svg";
import DealIcon from '../../../../icons/ico-avail.svg';
import InfoIcon from '../../../../icons/more-info-icon.svg';
import BurnIcon from '../../../../icons/burn_image.svg';
import NonBurnIcon from '../../../../icons/non_burn_image.svg';
import AddIcon from '../../../../icons/add-icon-green.svg';
import BinGreyIcon from '../../../../icons/bin-grey.svg';
import UploadIcon from '../../../../icons/ico-upload.svg';
import CloseIcon from '../../../../icons/close-circel.svg';
import RightIcon from '../../../../icons/right-circle.svg';
import DeactiveIcon from '../../../../icons/ic_deactivate.svg';
import ActiveIcon from '../../../../icons/ic_active.svg';
import SortArrowIcon from '../../../../icons/sortArrows.svg';
import GreyRightArrow from '../../../../icons/right-arrow-grey.svg';
import { ReactComponent as UploadCsvIcon} from '../../../../icons/upload.svg';
import { ReactComponent as Downloadicon} from '../../../../icons/download-icon.svg';
import { ReactComponent as DownToggleIcon} from '../../../../icons/down-toggle.svg';
import { ReactComponent as RefreshIcon} from '../../../../icons/refresh.svg';
import { includeExclude, csvHeaders } from '../../../../utils/constData';
import Loader from '../../../../commonComponent/Loader/Loader'

function CreateCompaign(props) {

    const navigate = useNavigate();
    const {campaignStatus, id} = useParams()

    const [showPopup, setShowPopup] = useState({
        show: false,
        type: "", 
        showSegment: false,
        csvPopup: false,
        dealType: "",
        overlapCampaign: false,
        overlapCampDetail: {},
        burnMapping: false,
        burnErrorList: [],
    })
    const [zoneList, setZoneList] = useState([]);
    const [userSegment, setUserSegment] = useState([]);
    const [burnSegment, setBurnSegment] = useState([]);
    const [campaignData, setCampaignData] = useState({
        campaign_id: 0,
        is_all_selected: false,
        is_new_user: false,
        isAllAvailDeal: false,
        avail_count: "",
        start_time:  "",
        end_time: "",
        burn_count: "",
        non_burn_count: "",
        send_pn: false,
        pn_title: "",
        pn_body: "",
        min_reward_value:10,
        discount_markdown:25,
        segment_info: {},
        updated_img_prod_list: [],
        deactivate_runnning_campain: false,
        is_configurable_segment: false,
        conflicting_campaign_id: 0
    })
    const [burnSkuList, setBurnSkuDeal] = useState([])
    const [nonBurnSkuList, setNonBurnSkuDeal] = useState([])
    const [debounceTimer, setDebounceTimer] = useState(0)
    const [toggleDealProducts, setToggleDealProducts] = useState({
        burnDeal: true,
        nonBurnDeal: true,
    })
    const [isLoading, setLoader] = useState(false)
    
    // handle popup burn and non burn deal
    const handleAddSkuPopup = (type) => {
        let selZone = zoneList.filter(item => item.is_selected)

        if(selZone.length){
            let popup = Object.assign({}, showPopup)
            popup.show = !showPopup.show;
            popup.type = type;
            setShowPopup(popup)
        }
        else {
            ErrorHandling("Please select zones first!")
        }
    }

    // handle add sku to burn / non burn deal
    const addSkuForDeal= async(product) => {
        let skuInventory = await getInventryInfoCampaignSku(product.prod_id, zoneList)
        let minMaxValue = await getMaxMinSkuPrice(product.prod_id, zoneList);
        let sku = {}

        sku.prod_name = product.prod_name;
        sku.prod_id = product.prod_id;
        sku.pack_qt = product.pack_qt;
        sku.image_id = product.image_id;
        sku.discount_amount = "";
        sku.discount = 0;
        sku.associated_prod_id = 0;
        sku.is_pn = false;
        
        sku.dc_stock_left_list = skuInventory;
        sku.max_zone_id=  minMaxValue.max_zone_id;
        sku.max_zone_name= minMaxValue.max_zone_name;
        sku.min_product_price=  minMaxValue.min_price;
        sku.min_zone_id= minMaxValue.min_zone_id;
        sku.min_zone_name=  minMaxValue.min_zone_name;
        sku.price=  minMaxValue.price;
        sku.pricing_zone_id=  minMaxValue.pricing_zone_id;
        sku.deal_price = "";
        sku.banner_image_id = minMaxValue.banner_image_id ? minMaxValue.banner_image_id: ""
        sku.vertical_banner_image_id = minMaxValue.vertical_banner_image_id ? minMaxValue.vertical_banner_image_id : "";
        sku.is_banner_image = false;
        
        const initialValue = 0;
        if(showPopup.type === "burn") {
            sku.is_burn_deal = true;
            let list = Object.assign([], burnSkuList)
            
            const maxRank = list.reduce(
                (accumulator, currentValue) => accumulator > parseInt(currentValue.rank) ? accumulator : parseInt(currentValue.rank),
                initialValue
            );
            sku.rank = maxRank + 1;
            sku.segment_info = getDefaultSegmentInfoValue(userSegment, burnSegment, "burn")
            list.push(sku);
            setBurnSkuDeal(list)
        }
        else {
            sku.is_burn_deal = false; 
            let list = Object.assign([], nonBurnSkuList)
            
            const maxRank = list.reduce(
                (accumulator, currentValue) => accumulator > currentValue.rank ? accumulator : currentValue.rank,
                initialValue
            );
            sku.rank = maxRank + 1;
            sku.segment_info = getDefaultSegmentInfoValue(userSegment, burnSegment, "non-burn")

            list.push(sku);
            setNonBurnSkuDeal(list)
        }
        setShowPopup({
            show: false,
            type: "", 
            showSegment: false,
        })
    }

    // handle sku deal price and rank
    const handlePriceRank = (key, value, index, type) => {
        if(type === 'burn'){
            let list = Object.assign([], burnSkuList); 
            if(key == 'rank'){
                list[index].prev_rank =  list[index].rank 
            }
            list[index][key] = value;
            console.log(type, key, list[index][key])
            setBurnSkuDeal(list)
        }
        else{
            let list = Object.assign([], nonBurnSkuList); 
            if(key == 'rank'){
                list[index].prev_rank =  list[index].rank 
            }
            list[index][key] = value;
            console.log(type, key, list[index][key])
            setNonBurnSkuDeal(list)
        }
    }

    // handle debounce rank
    const handleDebounceRank = (value, index, type) => {
        let timer = setTimeout(() => 
            handlePriceRank('rank',value, index, type), 800)
        clearTimeout(debounceTimer);
        setDebounceTimer(timer);
    }

    // handle delete sku 
    const handleDeleteSku = (prodId, type) => {
        if(type === 'burn'){
            let list = Object.assign([], burnSkuList); 
            list = list.filter((item) => item.prod_id !== prodId)
            setBurnSkuDeal(list)
        }
        else{
            let list = Object.assign([], nonBurnSkuList); 
            list = list.filter((item) => item.prod_id !== prodId)
            setNonBurnSkuDeal(list)
        }

        let localCampaignData = Object.assign({}, campaignData)
        localCampaignData.updated_img_prod_list = localCampaignData.updated_img_prod_list.filter((id) => id !== prodId)
        setCampaignData(localCampaignData)
    }

    // upload image 
    const UploadBannerimage = async(e, idx, type, banner_type) => {
        let file = e.target.files[0]
        if( e.target.files[0]){
            let image_type = 'category';
    
            let imgTypeExt = file.type.split('/')

            let data = new FormData();
            data.append('file', file);
            data.append('file_ext', '.' + imgTypeExt[1]);
            data.append('image_type', image_type);
    
            if( file.type !== 'image/png' &&
                file.type !== 'image/jpg' &&
                file.type !== 'image/jpeg' && 
                file.type !== 'image/webp' ){
                ErrorHandling('Please select correct image format')
            }
            else{
                let res = await uploadSelImage(data);
                // api call
                let localCampaignData = Object.assign({}, campaignData)
                if(type==='burn'){
                    let list = Object.assign([], burnSkuList);
                    list[idx].is_banner_image = false;
                    if(banner_type == 'vertical_banner'){
                        list[idx].vertical_banner_image_id = res
                    }
                    else{
                        list[idx].banner_image_id = res;
                    }

                    // updated imag prod list
                    if(!localCampaignData.updated_img_prod_list.includes(list[idx].prod_id)){
                        localCampaignData.updated_img_prod_list.push(list[idx].prod_id)
                        setCampaignData(localCampaignData)
                    }

                    setBurnSkuDeal(list);
                }
                else {
                    let list = Object.assign([], nonBurnSkuList);
                    list[idx].is_banner_image = false;
                    if(banner_type == 'vertical_banner'){
                        list[idx].vertical_banner_image_id = res
                    }
                    else{
                        list[idx].banner_image_id = res;
                    }
                    // updated imag prod list
                    if(!localCampaignData.updated_img_prod_list.includes(list[idx].prod_id)){
                        localCampaignData.updated_img_prod_list.push(list[idx].prod_id)
                        setCampaignData(localCampaignData)
                    }
     
                    setNonBurnSkuDeal(list);
                }
            }

        }
    }

    // handle submit campaign
    const handleSubmit = async(disableCamp) => {
        // selelected zones
        let selZone = zoneList.filter(item => item.is_selected)

        // image banner in sku 
        let flag = false, priceFlag = false, rankFlag= false;
        let prodIds = [], priceProdIds = [], rankProdIds = [];
        let burnList = Object.assign([], burnSkuList)
        burnList.map((prod) => {
            // for horizontal banner id
            if(!prod.banner_image_id){
                prod.is_banner_image = true;
                flag = true;
                prodIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
            }
            else 
                prod.is_banner_image = false;

            // for vertical banner id
            if(!prod.vertical_banner_image_id){
                prod.is_vertical_banner_image = true;
                flag = true;
                if(!prodIds.includes(` ${prod.prod_name} - (${prod.prod_id}) `)){
                    prodIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
                }
            }
            else
                prod.is_vertical_banner_image = false;

            // for sku price
            // if(!(prod.deal_price ? parseInt(prod.deal_price) : 0) && !(prod.discount_amount ? parseInt(prod.discount_amount) : 0)){
            if((prod.discount_amount === "" || parseInt(prod.discount_amount) < 0) && (prod.deal_price === "" || parseInt(prod.deal_price) <= 0 )){
                prod.is_price = true;
                priceFlag=true
                priceProdIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
            }
            else 
                prod.is_price = false;

            // for rank
            if(!parseInt(prod.rank)){
                prod.is_rank = true;
                rankFlag=true
                rankProdIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
            }
            else 
                prod.is_rank = false;

        })
        let nonBurnList = Object.assign([], nonBurnSkuList)
        nonBurnList.map((prod) => {
            // for horizontal banner id
            if(!prod.banner_image_id){
                prod.is_banner_image = true;
                flag = true;
                prodIds.push(`${prod.prod_name} - (${prod.prod_id})`)
            }
            else 
                prod.is_banner_image = false;

            // for vertical banner id
            if(!prod.vertical_banner_image_id){
                prod.is_vertical_banner_image = true;
                flag = true;
                if(!prodIds.includes(` ${prod.prod_name} - (${prod.prod_id}) `)){
                    prodIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
                }
            }
            else
                prod.is_vertical_banner_image = false;

            // for sku price
        // if(!(prod.deal_price ? parseInt(prod.deal_price) : 0) && !(prod.discount_amount ? parseInt(prod.discount_amount) : 0)){
            if((prod.discount_amount === "" || parseInt(prod.discount_amount) < 0) && (prod.deal_price === "" || parseInt(prod.deal_price) <= 0 )){
                prod.is_price = true;
                priceFlag=true
                priceProdIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
            }
            else
                prod.is_price = false;


            // for rank
            if(!parseInt(prod.rank)){
                prod.is_rank = true;
                rankFlag=true
                rankProdIds.push(` ${prod.prod_name} - (${prod.prod_id}) `)
            }
            else 
                prod.is_rank = false;

        })
        setBurnSkuDeal(burnList)
        setNonBurnSkuDeal(nonBurnList)

        if(!campaignData.start_time){
            ErrorHandling("Please select start date")
        }
        else if(!campaignData.end_time){
            ErrorHandling("Please select end date")
        }
        else if(!selZone.length){
            ErrorHandling("Please select Zones")
        }
        else if(!campaignData.isAllAvailDeal && !campaignData.avail_count){
            ErrorHandling("Please select number of deal avail count")
        }        
        else if(campaignData.burn_count==="" || campaignData.non_burn_count===""){
            ErrorHandling("Please enter burn / non burn deal count")
        }      
        else if(campaignData.send_pn && !campaignData.pn_title){
            ErrorHandling("Please enter PN Title")
        }     
        else if(campaignData.send_pn && !campaignData.pn_body){
            ErrorHandling("Please enter PN Body")
        }
        else if(campaignData.burn_count > burnSkuList.length){
            ErrorHandling(`Please add minimun ${campaignData.burn_count} products in Burn Deal`)
        }   
        else if(campaignData.non_burn_count > nonBurnSkuList.length){
            ErrorHandling(`Please add minimun ${campaignData.non_burn_count} products in Non Burn Deal`)
        }
        else if(flag){
            ErrorHandling(`Image not added for prods ${prodIds}`);  
        }
        else if(priceFlag){
            ErrorHandling(`Price / Discount not added for prods ${priceProdIds}`)
        }
        else if(rankFlag){
            ErrorHandling(`Rank cannot be zero for ${rankProdIds}`)
        }
        else {
            let data = Object.assign({}, campaignData);

            let prodList = [...burnSkuList, ...nonBurnSkuList];

            prodList = deepCopyObject(prodList);
            prodList.forEach((sku, idx) => {
                debugger
                if(sku.deal_price === "" || parseInt(sku.discount_amount) >= 0 ){
                    prodList[idx].deal_price = -1 
                }
                else if(sku.discount_amount === "" || parseInt(sku.deal_price) >= 0 ){
                    prodList[idx].discount_amount = -1 
                }

                if(sku.is_burn_deal){
                    let segment = {}
                    segment.is_include_user_segment = sku.segment_info.is_include_user_segment.id
                    segment.is_include_burn_segment = sku.segment_info.is_include_burn_segment.id

                    let userSegment = {}, burnSegment = {};

                    // user segment ids
                    sku.segment_info.user_segment_list.map((segmentDetail, idx) => {
                        userSegment[segmentDetail.id] = segmentDetail.is_selected ? 1 : 0
                    })
                    segment.user_segment_ids = userSegment

                    // burn segment ids
                    sku.segment_info.burn_segment_list.map((segmentDetail, idx) => {
                        burnSegment[segmentDetail.id] = segmentDetail.is_selected ? 1 : 0
                    })
                    segment.burn_segment_ids = burnSegment

                    if(sku.segment_info.segment_price_7){
                        segment.segment_price = {'7' : sku.segment_info.segment_price_7}
                    }
                    else segment.segment_price = {}

                    // setting the new data
                    prodList[idx].segment_info = segment
                }
            })

            data.product_list = prodList;

            let zoneId = []
            selZone.map((item) => zoneId.push(item.id))
            data.zones_list = zoneId

            delete data.is_all_selected
            delete data.isAllAvailDeal

            data.start_time = UTCTimeFormat(new Date(data.start_time))
            data.end_time = UTCTimeFormat(new Date(data.end_time))
            data.deactivate_runnning_campain = disableCamp;
            data.conflicting_campaign_id = showPopup.overlapCampDetail && showPopup.overlapCampDetail.id ? showPopup.overlapCampDetail.id: []

            console.log(data)
            
            setLoader(true)
            let res = await createNewCampaign(data)
            setLoader(false)
            if(res.success){

                navigate('/campaign-list/')
            } 
            else if(res.error.status_code == 406){
                try {
                    let errorObj = JSON.parse(res.error.message)
                    errorObj.start_time = EpochTimeInHumanLan(errorObj.start_time)
                    errorObj.end_time = EpochTimeInHumanLan(errorObj.end_time)
                    console.log("sahi jaa rha hai 406", errorObj)
                    setShowPopup({... showPopup, overlapCampaign: true, overlapCampDetail: errorObj})

                } catch (error) {
                    console.log("--->",error);
                    ErrorHandling(res.error.message)
                }
            }
            else if(res.error.code == 4001){
                try{
                    let errorObj = JSON.parse(res.error.message)
                    console.log("sahi jaa rha hai 4001", errorObj)
                    setShowPopup({... showPopup, burnMapping: true, burnErrorList: errorObj})
                }
                catch(error) {
                    console.log("--->",error);
                    ErrorHandling(res.error.message)
                }
            }
            else{
                ErrorHandling(res.error.message)
            }
        }
    }

    // set start and end date
    const getDate = (whom, date) => {
        let campData = Object.assign({}, campaignData)
        if(whom === "start") campData.start_time = date
        if(whom === "end") campData.end_time = date
        setCampaignData(campData)
    }

    // handle zone selection 
    const handleSelZone = (key, value) => {
        // for 'all' zone
        if(key === "is_all_selected") {
            let campData = Object.assign({}, campaignData)
            campData.is_all_selected = value;

            // setting all zones to be selected / unselected
            var list = Object.assign([], zoneList);
            list.forEach((elem, index) => 
            list[index].is_selected = value
            )
            setZoneList(list);
            setCampaignData(campData);
        }
        else {
            let campData = Object.assign({}, campaignData)

            var list = Object.assign([], zoneList);
            list[key].is_selected = value;
            let selZoneList = list.filter((zone) => zone.is_selected === true)
            if(zoneList.length === selZoneList.length)
                campData.is_all_selected = true;
            else 
                campData.is_all_selected = false;

            setZoneList(list);
            setCampaignData(campData);
        }

        handleSkuListPriceRefresh(list)
    }

    // handle sku list refresh 
    const handleSkuListPriceRefresh = async(list) => {
        // selected zone ids
        let selZone = [], prodIdList= [];
        list.map((item) => {
            if(item.is_selected) {
                selZone.push(item.id)
            }
        })
        
        // get burn list prod Id
        burnSkuList.map((item) => {
            prodIdList.push(item.prod_id)
        })
                
        // get non burn list prod Id
        nonBurnSkuList.map((item) => {
            prodIdList.push(item.prod_id)
        })
        
        // update max burn data
        if(selZone.length && (burnSkuList.length || nonBurnSkuList.length)){
            let data = {
                prod_ids: prodIdList,
                zone_ids: selZone
            }
            let res = await refreshminmaxSkuData(data);
            // console.log(res)
            let updatedBurnData = res.slice(0, burnSkuList.length)
            let updatedNonBurnData = res.slice( burnSkuList.length)

            let burnList = Object.assign([], burnSkuList)
            let nonBurnList = Object.assign([], nonBurnSkuList)

            burnList.map((sku, index) => {
                sku.max_zone_id=  updatedBurnData[index].max_zone_id;
                sku.max_zone_name= updatedBurnData[index].max_zone_name;
                sku.min_product_price=  updatedBurnData[index].min_price;
                sku.min_zone_id= updatedBurnData[index].min_zone_id;
                sku.min_zone_name=  updatedBurnData[index].min_zone_name;
                sku.price=  updatedBurnData[index].max_price;
            })

            nonBurnList.map((sku, index) => {
                sku.max_zone_id=  updatedNonBurnData[index].max_zone_id;
                sku.max_zone_name= updatedNonBurnData[index].max_zone_name;
                sku.min_product_price=  updatedNonBurnData[index].min_price;
                sku.min_zone_id= updatedNonBurnData[index].min_zone_id;
                sku.min_zone_name=  updatedNonBurnData[index].min_zone_name;
                sku.price=  updatedNonBurnData[index].max_price;
            })

            setBurnSkuDeal(burnList)
            setNonBurnSkuDeal(nonBurnList)
            SuccessHandling("Product Price and zones are up to date!")
        }
    }

    // handle campData change
    const handleCampaignDataChange = (key, value) => {
        let campData = Object.assign({}, campaignData)
        campData[key] = value

        if(key === 'isAllAvailDeal'){
            campData.avail_count = 0
        }

        if(key === 'is_new_user' && value) {
            campData.min_reward_value = 10000
        }
        else if(key === 'is_new_user' && !value){
            campData.min_reward_value = 10
        }

        if(key === 'send_pn' && !value){
            campData.pn_title = ""
            campData.pn_body = ""
        }
        setCampaignData(campData)
    }
    
    // setting initial zone list:
    const getZoneList = async(selZone) => {
        let resListing = await getOrderBannerZonewiseList();
        resListing.data.forEach((zone, index) => {
            if(selZone.includes(zone.id)){
                resListing.data[index].is_selected = true;
            }
            else{
                resListing.data[index].is_selected = false;
            }
        })
        setZoneList(resListing.data)
        if(selZone.length == resListing.data.length) return true
        else return false
    }

    // user segment:
    const getUserSegment = async() => {
        // burn segment list 
        let list = await getUserSegmentInfo();

        // user segment list
        let userSegment2 = await userSegmentList()

        let user_segment = {};
        list.map((item, index) => {
            user_segment[item.id] = item.mark_down;
            list[index].name = list[index].segment_name
            list[index].is_selected = true
        })
        userSegment2.map((item, index) => {
            userSegment2[index].name = userSegment2[index].segment_name
            userSegment2[index].is_selected = true
        })

        // console.log(user_segment)
        setUserSegment(userSegment2)
        setBurnSegment(list)
        if(campaignStatus==="create-campaign"){
            handleCampaignDataChange('segment_info' ,user_segment)
        }
        else return {user_segment: user_segment, list: userSegment2, burnList: list};
    }

    // to clone a campaign
    const cloneEditData =  async() => {
        let cloneData = await cloneCampaign(id);
        // console.log(cloneData)
        let data = {}
        let burnList = [], nonBurnList = [];

        // campaign data 
        data.is_new_user = cloneData.is_new_user  
        data.isAllAvailDeal = cloneData.avail_count == 0;
        data.avail_count = cloneData.avail_count  
        data.burn_count = cloneData.burn_count   
        data.non_burn_count = cloneData.non_burn_count
        data.send_pn = cloneData.send_pn 
        data.pn_title = cloneData.pn_title
        data.pn_body = cloneData.pn_body
        data.min_reward_value = cloneData.min_reward_value
        data.discount_markdown = cloneData.discount_markdown
        data.updated_img_prod_list = []
        data.deactivate_runnning_campain = false
        data.is_configurable_segment = cloneData.is_configurable_segment ? true : false
        
        // user segment
        let segment =  await getUserSegment()
        data.segment_info = segment.user_segment
        
        // zone listing
        data.is_all_selected = await getZoneList(cloneData.zone_list)

        // separating burn non burn list
        cloneData.productList.map((item) => {
            item.dc_stock_left_list = []

            // setting deal_price zero if discount_amount >= 0
            if(item.discount_amount >= 0){
                item.deal_price = 0;
            }

            // setting discount_amount zero if discount_amount is -1
            if(item.discount_amount == -1){
                item.discount_amount = "";
            }

            // segment data 
            if(item.is_burn_deal) {
                let segmentInfo = getDefaultSegmentInfoValue(segment.list, segment.burnList, "burn")

                // setting data if values are present
                if(item.segment_info && Object.keys(item.segment_info).length){
                    if(!item.segment_info.is_include_user_segment){
                        segmentInfo.is_include_user_segment = {id: 0, name: "Exclude"}
                    }
                    
                    if(!item.segment_info.is_include_burn_segment){
                        segmentInfo.is_include_burn_segment = {id: 0, name: "Exclude"}
                    }

                    if(Object.keys(item.segment_info.segment_price).length){
                        segmentInfo.segment_price_7 = item.segment_info.segment_price['7']
                    }

                    segmentInfo.user_segment_list.map((segmentDetail, sgmntIdx) => {
                        segmentInfo.user_segment_list[sgmntIdx].is_selected = item.segment_info.user_segment_ids[segmentDetail.id] ? true : false
                    })
                    
                    segmentInfo.burn_segment_list.map((segmentDetail, sgmntIdx) => {
                        segmentInfo.burn_segment_list[sgmntIdx].is_selected = item.segment_info.burn_segment_ids[segmentDetail.id] ? true : false
                    })
                }

                item.segment_info = segmentInfo
                burnList.push(item);
            }
            else {
                item.segment_info = getDefaultSegmentInfoValue(segment.list, segment.burnList,"non-burn")
                nonBurnList.push(item)
            }
        })
        if(campaignStatus==="edit-campaign"){
            data.campaign_id = cloneData.campaign_id
            data.start_time = EpochTimeTOHumanFormat(cloneData.start_time)
            data.end_time = EpochTimeTOHumanFormat(cloneData.end_time)
        }

        setCampaignData(data);
        setBurnSkuDeal(burnList);
        setNonBurnSkuDeal(nonBurnList);
    }

    // sort product rank wise
    const sortProductList = (isBurn) => {
        let prodList =[]
        if(isBurn){
            prodList = Object.assign([], burnSkuList);
            prodList.sort(function(a, b){
                return a.rank-b.rank
            })
            console.log("sortedddd --->",prodList)
            setBurnSkuDeal(prodList)
        }
        else{
            prodList = Object.assign([], nonBurnSkuList);
            prodList.sort(function(a, b){
                return a.rank-b.rank
            })
            console.log("sortedddd --->",prodList)
            setNonBurnSkuDeal(prodList)
        }
        SuccessHandling(`${isBurn ? "Burn" : "Non-Burn"} Deal Products Sorted Rank Wise`)
    }

    // Handle go click to sort the rank accordingly
    const handleRankSort = (isBurn, index) => {

        let prodDetailList = []
        if(isBurn){
            prodDetailList = Object.assign([], burnSkuList)
        }
        else {
            prodDetailList = Object.assign([], nonBurnSkuList)
        }
        debugger;
        let rankList = prodDetailList.map(({rank}) => parseInt(rank))
        let selProd = prodDetailList[index]
        let rankFlag = selProd.rank

        if(prodDetailList[index].rank == 0){
            ErrorHandling(`Rank cannot be zero!`)
            return;
        }

        prodDetailList.map((prod, idx) => {
            prodDetailList[idx].show_rank_change = false;
        })

        
        let currentFlagProd = selProd;
        while(rankList.includes(parseInt(rankFlag))){
            debugger;
            // finding the index of rankFlag
            let rankflagIdx = prodDetailList.findIndex(prod => prod.rank == rankFlag && prod.prod_id !== currentFlagProd.prod_id)

            if(rankflagIdx >=0 ){
                prodDetailList[rankflagIdx].show_rank_change = true
            
                /* when rank no. is deceased ex: from 10 to 5
                 add 1 to rank and setting the rankFlag to the same 
                */
                if(!selProd.prev_rank || selProd.prev_rank - selProd.rank >= 0 || selProd.prev_rank==""){
                    rankFlag = ++prodDetailList[rankflagIdx].rank;
                    console.log("rank increased -- >",prodDetailList[rankflagIdx], prodDetailList[rankflagIdx].rank)
                }
                /* when rank no. is increased ex: from 3 to 5
                 subtract 1 to rank and setting the rankFlag to the same 
                */
                else{
                    rankFlag = --prodDetailList[rankflagIdx].rank;
                    console.log("rank decreased -- >",prodDetailList[rankflagIdx], prodDetailList[rankflagIdx].rank)
                }
                currentFlagProd = prodDetailList[rankflagIdx];
            }
            else{
                break;
            }
        }
    
        // sort the product array
        // prodDetailList.sort((a,b) => a.rank - b.rank)
        if(isBurn){
            setBurnSkuDeal(prodDetailList)
        }
        else {
            setNonBurnSkuDeal(prodDetailList)
        }
    }

    // handle selection user segment and non burn segment 
    const handleSegmentInfoDatachange = (key, value, idx, type, elemIdx) =>{
        let localBurnDeal = deepCopyObject( burnSkuList)
        let selProdSegmentDetail = deepCopyObject(localBurnDeal[idx].segment_info)

        // include exclude DD 
        if(key == 'is_include_user_segment' || key == "is_include_burn_segment"){
            selProdSegmentDetail[key] = value
        }
        // Segment DD 
        else if (key == 'user_segment_list' || key == 'burn_segment_list'){

            // for all selection 
            if(value.id == '0'){
                selProdSegmentDetail[key].forEach((item, itemIndex) => {
                    selProdSegmentDetail[key][itemIndex].is_selected = !value.is_selected
                })
            }
            // for any other key
            else{
                selProdSegmentDetail[key][elemIdx].is_selected = !value.is_selected

                // to check all selected
                selProdSegmentDetail[key][0].is_selected = false;

                let selLength = selProdSegmentDetail[key].filter((item) => item.is_selected)
                console.log("sel count", selLength.length, selProdSegmentDetail[key].length -1)
                if(selLength.length == selProdSegmentDetail[key].length -1 ){
                    selProdSegmentDetail[key][0].is_selected = true;
                }
            }
        }
        else if(key == 'segment_price_7'){
            selProdSegmentDetail[key] = value 
        }
        localBurnDeal[idx].segment_info = selProdSegmentDetail
        setBurnSkuDeal(localBurnDeal)
    }

    // handle Default values for segment info
    const getDefaultSegmentInfoValue = (userSegmentList, burnSegmentList, type) => {
        let user_segment_ids = {'0' : 1}, burn_segment_ids = {'0': 1}
        userSegmentList.forEach((item) => user_segment_ids[item.id] = 1)
        burnSegmentList.forEach((item) => burn_segment_ids[item.id] = 1)
        let segment = {
            is_include_user_segment: 1,
            is_include_burn_segment: 1,
            user_segment_ids: user_segment_ids,
            burn_segment_ids: burn_segment_ids,
            // TODO: set the dict
            segment_price: {},
        }
        if(type == 'burn'){
            userSegmentList = [{id: '0', name: "All", is_selected: true}, ...userSegmentList]
            burnSegmentList = [{id: '0', name: "All", is_selected: true}, ...burnSegmentList]
            segment = {
                is_include_user_segment: {id: 1, name: "Include"},
                is_include_burn_segment: {id: 1, name: "Include"},
                user_segment_list: deepCopyObject(userSegmentList),
                burn_segment_list: deepCopyObject(burnSegmentList),
                // TODO: set the dict
                segment_price_7: "",
            }
        }
        return segment

    }

    const handleUploadCsvClick = (dealType) => {
        let selZone = zoneList.filter((zone) => zone.is_selected)
        if(selZone.length){
            setShowPopup({...showPopup, csvPopup: true, dealType: dealType})
        }
        else ErrorHandling("Please select Zone first!")
    }

    // handle toggle deal products listing 
    const toggleDealProductLisiting = (key, value) => {
        let localToggleState = deepCopyObject(toggleDealProducts)
        localToggleState[key] = value
        setToggleDealProducts(localToggleState)
    }

    // find count for selected values from given list 
    const handleSeletedSegmentCount = (list) => {
        if(list[0].is_selected){
            return (list.length - 1 )
        }
        else {
            let selSegmentList = list.filter(segment => segment.is_selected)
            return selSegmentList.length
        }
    }


    useEffect(() => {
        // console.log(campaignStatus, id);
        // get zone list:
        if(campaignStatus==="create-campaign"){
            getZoneList([]);
            getUserSegment();
        }
        else if(campaignStatus==="clone-campaign" || campaignStatus==="edit-campaign"){
            cloneEditData();
        }


    },[])
    return (
        <div className={`formWrapper`}>
            <div className={`${style.main_heading}`}>
                {campaignStatus==="edit-campaign" ? 'Edit' : 'Create'} Deal for campaign
            </div>
            <div className={`${style.container}`}>

                {/* zone and start end day container */}
                <div className={`flex flex-space-between ${style.mrg_btm_3}`}>

                    {/* left container 40 percentage*/}
                    <div className={`${style.width40}`}>
                        {/* new customer campaign check box*/}
                        <div className={`flex ${style.isNewCheckbox} ${style.mrg_btm_3}`}>
                            <input 
                            type="checkbox" 
                            width="20px" 
                            height="20px" 
                            checked={campaignData.is_new_user}
                            onChange={(e) => {handleCampaignDataChange('is_new_user', !campaignData.is_new_user)}}
                            />
                            <span> New Customers Only</span>
                        </div>

                        {/* zone listing header */}
                        <div>
                            <img
                            width="24px"
                            height="24px"
                            alt="" 
                            src={LocationIcon}
                            style={{ marginBottom: "-9px", marginRight: "7px" }}
                            />
                            <span className={`${style.zone_heading}`}>Select Zones</span>
                        </div>

                        {/* zone listing */}
                        <div className={`${style.zone_list}`}>
                        
                            {/* zone check boxes */}
                            <label className={`${style.list_item}`}>
                                <input 
                                type="checkbox" 
                                onChange={() => {handleSelZone("is_all_selected", !campaignData.is_all_selected)}}
                                className={`pointer ${style.checkbox}`} 
                                checked={campaignData.is_all_selected}
                                />
                                <span className={`${style.checkmark}`}>All</span>
                            </label>
                            {
                                zoneList.map((city, index) => {
                                    return(
                                    <label key={city.id} className={`${style.list_item}`}>
                                        <input 
                                        type="checkbox" 
                                        onChange={() => {handleSelZone(index, !city.is_selected)}}
                                        className={`pointer ${style.checkbox}`} 
                                        checked={city.is_selected}
                                        />
                                        <span className={`${style.checkmark}`}>{city.name}</span>
                                    </label>
                                    )
                                })
                            }
                        </div>

                    </div>

                    {/* right container */}
                    <div className={`${style.width50}`}>

                        <DatePicker 
                            isTodTomVisible={true} 
                            getDate={getDate} 
                            start_time={campaignData.start_time} 
                            end_time={campaignData.end_time}/>

                    </div>
                </div>

                {/* number of deals  */}
                <div className={`flex flex-space-between ${style.mrg_btm_3}`}>

                    {/* left container 40 percentage */}
                    <div className={`${style.width40}`}>

                        {/* no of deals that can be availed */}
                        <div className={`${style.mrg_btm}`}>
                            <img 
                                src={DealIcon} 
                                alt="" 
                                width="24px"
                                height="24px"
                                style={{ marginBottom: "-6px", marginRight: "7px" }}
                            />
                            Consumer can avail &apos;X&apos; number of deals
                        </div>
                        <div className={`flex flex-space-between`}>
                            <label>
                                <input 
                                type="checkbox" 
                                checked={campaignData.isAllAvailDeal}
                                className={`pointer ${style.checkbox}`} 
                                onChange={() => handleCampaignDataChange('isAllAvailDeal', !campaignData.isAllAvailDeal)}
                                />
                                <span className={`${style.checkmark}`}>All</span>
                            </label>
                            <span>OR</span>
                            <input 
                                value={campaignData.avail_count}
                                onWheel={(e) => e.target.blur()}
                                onChange={(e) => handleCampaignDataChange('avail_count', e.target.value)}
                                disabled={campaignData.isAllAvailDeal} 
                                className={`${campaignData.isAllAvailDeal ? "not_allowed" : "pointer"}  ${style.input_field}`} 
                                type={'number'} 
                                min={0}
                            />
                        </div>
                    </div>

                    {/* right container */}
                    <div className={`${style.width50}`}>

                        {/* no of burn deals */}
                        <div className={`${style.mrg_btm}`}>
                            <img 
                                src={DealIcon} 
                                alt="" 
                                width="24px"
                                height="24px"
                                style={{ marginBottom: "-6px", marginRight: "7px" }}
                            />
                            Consumer can SEE &apos;X&apos; number of deals
                        </div>
                        <div className={`flex `}>
                            <input 
                                value={campaignData.burn_count}
                                onChange={(e) => handleCampaignDataChange('burn_count', e.target.value)}
                                className={`${style.input_field}`} 
                                placeholder={'Burn Deals'} 
                                type={'number'} 
                                min={0}
                                onWheel={(e) => e.target.blur()}
                            />
                            <input 
                                value={campaignData.non_burn_count}
                                onChange={(e) => handleCampaignDataChange('non_burn_count', e.target.value)}
                                className={`${style.input_field}`} 
                                placeholder={'Non-Burn Deals'} 
                                type={'number'} 
                                min={0}
                                onWheel={(e) => e.target.blur()}
                            />
                        </div>
                    </div>
                </div>

                {/* user segment */}
                <div className={`${style.mrg_btm_3}`}>
                    User Segment
                    <img 
                        width="24px"
                        height="24px"
                        alt="" 
                        src={InfoIcon}
                        className={`pointer`}
                        style={{ marginBottom: "-6px", marginLeft: "8px" }}
                        onClick={() => setShowPopup({...showPopup, showSegment: true})}
                     />
                </div>

                {/* send notification */}
                <div className={`${style.mrg_btm_3}`}>
                    <div> 
                        Send notification {
                            campaignData.send_pn ? 
                            <img 
                                width="28px"
                                height="28x"
                                style={{ marginBottom: "-9px", marginLeft: "8px" }}
                                onClick={() => handleCampaignDataChange('send_pn', !campaignData.send_pn)}
                                src={ActiveIcon} /> :
                            <img 
                                width="28px"
                                height="28px"
                                style={{ marginBottom: "-9px", marginLeft: "8px" }}
                                onClick={() => handleCampaignDataChange('send_pn', !campaignData.send_pn)}
                                src={DeactiveIcon} /> 
                        }
                    </div>
                    {
                        campaignData.send_pn ? 
                            <div className={`flex ${style.mrg_tp}`}>
                                <div className={`${style.width40}`}>
                                    <div className={`fontS labelGrey`}>PN TITLE</div>
                                    <input 
                                        value={campaignData.pn_title}
                                        onChange={(e) => handleCampaignDataChange('pn_title', e.target.value)}
                                        type='text' 
                                        className={`${style.input_field}`}/>
                                </div>
                                <div>
                                    <div className={`fontS labelGrey`}>PN Body</div>
                                    <textarea 
                                        value={campaignData.pn_body}
                                        onChange={(e) => handleCampaignDataChange('pn_body', e.target.value)}
                                        className={`${style.input_field}`}>
                                    </textarea>
                                </div>
                            </div>
                        :""
                        
                    }
                </div>

                {/* burn and non burn deal skus */}
                <div>
                    {/*main heading */}
                    <div className={`textCenter ${style.title} ${style.list_thead}`}>
                        <span className={`bold`}>Add Product (SKU) and Deal Price for campaign</span>
                        <span className={`rightFloat pointer`} onClick={() => handleSkuListPriceRefresh(zoneList)}> 
                            <RefreshIcon style={{marginRight: '4px'}}/> Refresh 
                        </span>
                    </div>

                    {/* deals lists container */}
                    <div>

                        {/* burn deal container */}
                        <div className={`${style.deal_container}`}>
                            
                            {/* section heading */}
                            <div className={`flex flex-space-between ${style.list_thead} ${style.sku_list_title}`}>
                                {/* left heading */}
                                <div>
                                    <img 
                                    alt="" 
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: "-4px", marginRight: "7px" }}
                                    src={BurnIcon}/> Burn deals (SKUs:{burnSkuList.length})
                                </div>

                                {/* right action btns */}
                                <div>
                                    <CSVLink
                                        headers={csvHeaders}
                                        data={[]}
                                        filename="burn_deal.csv"
                                        target="_blank"
                                    >
                                        <button className={`bold ${style.csvBtn}`}>
                                            Download CSV  <Downloadicon className={`${style.greenIcon} ${style.icon22}`}/>
                                        </button>
                                    </CSVLink>  

                                    {/* open popup for it */}
                                    <button 
                                        className={`bold ${style.csvBtn}`}
                                        // onClick={handleUpload} >
                                        onClick={() => handleUploadCsvClick('burn')}>
                                            Upload CSV   <UploadCsvIcon className={`${style.greenIcon} ${style.icon22}`}/>
                                    </button>

                                    <DownToggleIcon 
                                        className={`pointer alignIcon ${toggleDealProducts.burnDeal ? "rotate180" : ""}`} 
                                        onClick={() => toggleDealProductLisiting('burnDeal', !toggleDealProducts.burnDeal)}
                                    />
                                </div>
                            </div>
                            {
                                toggleDealProducts.burnDeal ?
                                <div className={`${style.list_container}`}>
                                    {/* thead */}
                                    <div className={`grid ${style.list_thead} ${style.grid_column_list}`}>
                                        <div className={`${style.pd_sm}`}>SKU</div>
                                        <div className={`${style.pd_sm}`}>
                                            Banner Horizontal
                                            <div className={`fontXS labelGrey`}>(Image size 540 x 140px)</div>
                                        </div>
                                        <div className={`${style.pd_sm}`}>
                                            Banner Vertical
                                            <div className={`fontXS labelGrey`}>(Image size 144 x 186px)</div>
                                        </div>
                                        <div className={`${style.pd_sm}`}>Deal Price (₹) </div>
                                        <div className={`${style.pd_sm}`}>
                                            Rank 
                                            <img 
                                                src={SortArrowIcon} 
                                                onClick={() => {
                                                    if(burnSkuList.length){
                                                        sortProductList(true)} 
                                                    } 
                                                }
                                                className={`pointer iconAlign`} 
                                                alt=""
                                            />
                                        </div>
                                        <div className={`${style.pd_sm}`}>Associate Product ID</div>
                                        <div className={`${style.pd_sm}`}>Push Notification</div>
                                    </div>
                                    
                                    {/* list */}
                                    <div >
                                        {
                                            burnSkuList.map((sku, idx) => {
                                                return(
                                                    <div className={`${style.sku_container} ${style.min_height_150}`} key={sku.prod_id}>
                                                        {/* sku image banner row */}
                                                        <div className={`grid grid-align-start ${style.grid_column_list}`}>
                                                            <div className={`${style.pd_sm}`}>
                                                                <img 
                                                                className={`${style.sku_img}`}
                                                                width='50px'
                                                                height='50px'
                                                                src={imgUrl + sku.image_id}/>
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <input id={`${sku.prod_id}-burn`} type='file' onChange={(e) => UploadBannerimage(e, idx, 'burn')}  className={`hide`} accept='image/*'/>
                                                                {
                                                                    !sku.banner_image_id ? 
                                                                        <button 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-burn`).click()} 
                                                                            className={`green bold ${style.horizontal_upload_btn} ${style.upload_btn}`}
                                                                        >
                                                                            Upload <img style={{marginBottom: '-7px', marginLeft: '5px'}} src={UploadIcon}/>
                                                                        </button>
                                                                    :   
                                                                        <img 
                                                                            width='175px' 
                                                                            height='50px' 
                                                                            className={`pointer ${style.sku_img}`}
                                                                            src={catImgUrl + sku.banner_image_id} 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-burn`).click()} 
                                                                        />
                                                                }
                                                                {
                                                                    sku.is_banner_image ?
                                                                    <div><i className={`red fontS`}>*Please upload image</i></div> :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <input id={`${sku.prod_id}-vertical-burn`} type='file' onChange={(e) => UploadBannerimage(e, idx, 'burn', 'vertical_banner')}  className={`hide`} accept='image/*'/>
                                                                {
                                                                    !sku.vertical_banner_image_id ? 
                                                                        <button 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-vertical-burn`).click()} 
                                                                            className={`green bold ${style.vertical_upload_btn} ${style.upload_btn}`}
                                                                        >
                                                                            Upload <img style={{marginBottom: '-7px', marginLeft: '5px'}} src={UploadIcon}/>
                                                                        </button>
                                                                    :   
                                                                        <img 
                                                                            width='72px' 
                                                                            height='93px' 
                                                                            className={`pointer ${style.sku_img}`}
                                                                            src={catImgUrl + sku.vertical_banner_image_id} 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-vertical-burn`).click()} 
                                                                        />
                                                                }
                                                                {
                                                                    sku.is_vertical_banner_image ?
                                                                    <div><i className={`red fontS`}>*Please upload image</i></div> :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <div>
                                                                    <div className={`fontXS labelGrey`}>DEAL PRICE</div>
                                                                    <input  
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handlePriceRank('deal_price', e.target.value, idx, 'burn')}
                                                                        value={sku.deal_price} 
                                                                        className={`${style.rank_field}`}
                                                                        disabled={parseFloat(sku.discount_amount) >= 0}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className={`fontXS labelGrey`}>DISCOUNT (₹)</div>
                                                                    <input 
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handlePriceRank('discount_amount', e.target.value, idx, 'burn')} 
                                                                        value={sku.discount_amount} 
                                                                        className={`${style.rank_field}`}
                                                                        disabled={parseFloat(sku.deal_price)} 
                                                                    />
                                                                </div>    
                                                                {
                                                                    sku.is_price && 
                                                                    <div><i className={`red fontS`}>*Please add Price / Discount</i></div>
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                {/* rank inputs */}
                                                                <div>
                                                                    <input 
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handleDebounceRank( e.target.value, idx, 'burn')} 
                                                                        key={sku.rank} 
                                                                        defaultValue={sku.rank} 
                                                                        className={`${style.rank_field}`} 
                                                                    />
                                                                    <button className={`${style.smallFilledBtn}`} onClick={() => handleRankSort(true, idx)}>
                                                                        GO <img src={GreyRightArrow} className={`${style.goIcon}`} alt="" />
                                                                    </button>
                                                                </div>

                                                                {/* show_rank_change div */}
                                                                {
                                                                    sku.show_rank_change &&
                                                                    <div>
                                                                        <span className={`${style.rankChange}`}>Rank Changed to {sku.rank}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    sku.is_rank &&
                                                                    <div><i className={`red fontS`}>*Rannk cannot be zero</i></div>
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm_y}`}>
                                                                <div className={`flex`}>
                                                                    <Dropdown
                                                                        NoKey={true}
                                                                        placeholder={"Search Associate ID "}
                                                                        height={'8rem'}
                                                                        list={burnSkuList.map( ({prod_id}) => prod_id)}
                                                                        showSearch={true}
                                                                        name={sku.associated_prod_id}
                                                                        getDropDownValue={(elem)=>{
                                                                            handlePriceRank('associated_prod_id', elem, idx, 'burn')
                                                                        }}
                                                                    />
                                                                    <img 
                                                                        src={BinGreyIcon} 
                                                                        className={`pointer ${sku.associated_prod_id ? '' : 'visibility_hidden'}`} 
                                                                        onClick={() =>  handlePriceRank('associated_prod_id', 0, idx, 'burn')}
                                                                        height='20px' 
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className={`${style.pd_sm}`}>

                                                                <label>
                                                                    <input 
                                                                        type='checkbox'
                                                                        onChange={(e) => handlePriceRank('is_pn', e.target.checked, idx, 'burn')} 
                                                                        checked={sku.is_pn}
                                                                    /> PN
                                                                </label>
                                                            </div>
                                                            <div className={`textCenter ${style.pd_sm}`}>
                                                                <img height='20px' className={`pointer ${style.deleteIcon}`} onClick={() => handleDeleteSku(sku.prod_id, 'burn')} src={BinGreyIcon} />
                                                            </div>
                                                        </div>

                                                        {/* sku name / burn deal */}
                                                        <div className={`grid ${style.sku_deatil_column}`}>
                                                            {/* sku deals */}
                                                            <div className={`fontS labelGrey ${style.pd_sm}`}>
                                                                <div style={{paddingBottom: '8px'}}>
                                                                    <strong>[{sku.prod_id}]</strong> {sku.prod_name}
                                                                </div>
                                                                <div>
                                                                    <strong>Pack: </strong> {sku.pack_qt}
                                                                </div>
                                                            </div>
                                                            {/* max burn */}
                                                            <div className={`fontS labelGrey ${style.pd_sm}`}>
                                                                <strong>Max Burn: {sku.deal_price ? sku.price - sku.deal_price : sku.discount_amount}</strong>
                                                            </div>

                                                            {/* max min value */}
                                                            <div className={`flex flex-space-between fontS labelGrey ${style.pd_sm}`}>
                                                                <div style={{paddingRight: '8px'}}>
                                                                    <strong>Max Price: {sku.price} ({sku.max_zone_name})</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Min Price: {sku.min_product_price} ({sku.min_zone_name})</strong>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* stock in DC */}
                                                        {
                                                            sku.dc_stock_left_list.length ? 
                                                                <div className={`flex fontS flex-justify-center ${style.white_outlined_container}`}>
                                                                    {
                                                                        sku.dc_stock_left_list.map((item, index) => {
                                                                            return(
                                                                                <div className={`${style.pd_sm}`} key={index}>
                                                                                    <strong>{item.name}:</strong> {item.stock} 
                                                                                    {(sku.dc_stock_left_list.length-1 !== index) ? <span> | </span> : null}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            : null
                                                        }
                                                    </div>   
                                                )
                                            })
                                        }
                                    </div>

                                    {/* add btn */}
                                    <div className={`textCenter bg_white`}>
                                        <button onClick={()=>handleAddSkuPopup('burn')} className={`${style.outlined_btn}`}>
                                            Add Product (Skus) 
                                            <img 
                                            alt="" 
                                            width="20px"
                                            height="20px"
                                            style={{ marginBottom: "-5px", marginLeft: "8px" }}
                                            src={AddIcon} />
                                        </button>
                                    </div>
                                </div> :
                                <div className={`textCenter separatorDashed ${style.pd_sm}`}>
                                    <span className={` pointer fontS labelGrey `} onClick={() => toggleDealProductLisiting('burnDeal', !toggleDealProducts.burnDeal)}>. . . VIEW BURN DEAL PRODUCTS</span>
                                </div>
                            }
                        </div>

                        {/* non burn deal container */}
                        <div className={`${style.deal_container}`}>

                            {/* section heading */}
                            <div className={`flex flex-space-between ${style.list_thead} ${style.sku_list_title}`}>
                                {/* left heading */}
                                <div>
                                    <img 
                                    alt="" 
                                    width="20px"
                                    height="20px"
                                    style={{ marginBottom: "-4px", marginRight: "7px" }}
                                    src={NonBurnIcon}/> Non-Burn deals (SKUs:{nonBurnSkuList.length})
                                </div>

                                {/* right action btns */}
                                <div>
                                    <CSVLink
                                        headers={csvHeaders}
                                        data={[]}
                                        filename="non_burn_deal.csv"
                                        target="_blank"
                                    >
                                        <button className={`bold ${style.csvBtn}`}>
                                            Download CSV  <Downloadicon className={`${style.greenIcon} ${style.icon22}`}/>
                                        </button>
                                    </CSVLink>  

                                    {/* open popup for it */}
                                    <button 
                                        className={`bold ${style.csvBtn}`}
                                        // onClick={handleUpload} >
                                        onClick={() => handleUploadCsvClick('non Burn')}>
                                            Upload CSV   <UploadCsvIcon className={`${style.greenIcon} ${style.icon22}`}/>
                                    </button>

                                    <DownToggleIcon 
                                        className={`pointer alignIcon ${toggleDealProducts.nonBurnDeal ? "rotate180" : ""}`} 
                                        onClick={() => toggleDealProductLisiting('nonBurnDeal', !toggleDealProducts.nonBurnDeal)}
                                    />
                                </div>
                            </div>
                            
                            {
                                toggleDealProducts.nonBurnDeal ?
                                <div className={`${style.list_container}`}>
                                    {/* thead */}
                                    <div className={`grid ${style.list_thead} ${style.grid_column_list}`}>
                                        <div className={`${style.pd_sm}`}>SKU</div>
                                        <div className={`${style.pd_sm}`}>
                                            Banner Horizontal
                                            <div className={`fontXS labelGrey`}>(Image size 540 x 140px)</div>
                                        </div>
                                        <div className={`${style.pd_sm}`}>
                                            Banner Vertical
                                            <div className={`fontXS labelGrey`}>(Image size 144 x 186px)</div>
                                        </div>
                                        <div className={`${style.pd_sm}`}>Deal Price (₹) </div>
                                        <div className={`${style.pd_sm}`}>
                                            Rank 
                                            <img 
                                                src={SortArrowIcon} 
                                                onClick={() => {
                                                    if(nonBurnSkuList.length){
                                                        sortProductList(false)
                                                    }
                                                }
                                            } 
                                                className={`pointer iconAlign`} 
                                                alt=""
                                            />
                                        </div>                                    
                                        <div className={`${style.pd_sm}`}>Associate Product ID</div>
                                        <div className={`${style.pd_sm}`}>Push Notification</div>
                                    </div>

                                    {/* list */}
                                    <div >
                                        {
                                            nonBurnSkuList.map((sku, idx) => {
                                                return(
                                                    <div className={`${style.sku_container} ${style.min_height_150}`} key={sku.prod_id}>
                                                        {/* sku image banner row */}
                                                        <div className={`grid grid-align-start ${style.grid_column_list}`}>
                                                            <div className={`${style.pd_sm}`}>
                                                                <img 
                                                                className={`${style.sku_img}`}
                                                                width='50px'
                                                                height='50px'
                                                                src={imgUrl + sku.image_id}/>
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <input id={`${sku.prod_id}-non-burn`} type='file' onChange={(e) => UploadBannerimage(e, idx, 'non-burn')}  className={`hide`} accept='image/*'/>
                                                                {
                                                                    !sku.banner_image_id ? 
                                                                        <button 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-non-burn`).click()} 
                                                                            className={`green bold ${style.horizontal_upload_btn} ${style.upload_btn}`}
                                                                        >
                                                                            Upload <img style={{marginBottom: '-7px', marginLeft: '5px'}} src={UploadIcon}/>
                                                                        </button>
                                                                    :   
                                                                        <img 
                                                                            width='175px' 
                                                                            height='50px' 
                                                                            className={`pointer ${style.sku_img}`}
                                                                            src={catImgUrl + sku.banner_image_id} 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-non-burn`).click()} 
                                                                        />
                                                                }
                                                                {
                                                                    sku.is_banner_image ?
                                                                    <div><i className={`red fontS`}>*Please upload image</i></div> :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <input id={`${sku.prod_id}-vertical-non-burn`} type='file' onChange={(e) => UploadBannerimage(e, idx, 'non-burn', 'vertical_banner')}  className={`hide`} accept='image/*'/>
                                                                {
                                                                    !sku.vertical_banner_image_id ? 
                                                                        <button 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-vertical-non-burn`).click()} 
                                                                            className={`green bold ${style.vertical_upload_btn} ${style.upload_btn}`}
                                                                        >
                                                                            Upload <img style={{marginBottom: '-7px', marginLeft: '5px'}} src={UploadIcon}/>
                                                                        </button>
                                                                    :   
                                                                        <img 
                                                                            width='72px' 
                                                                            height='93px' 
                                                                            className={`pointer ${style.sku_img}`}
                                                                            src={catImgUrl + sku.vertical_banner_image_id} 
                                                                            onClick={() => document.getElementById(`${sku.prod_id}-vertical-non-burn`).click()} 
                                                                        />
                                                                }
                                                                {
                                                                    sku.is_vertical_banner_image ?
                                                                    <div><i className={`red fontS`}>*Please upload image</i></div> :
                                                                    null
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <div>
                                                                    <div className={`fontXS labelGrey`}>DEAL PRICE</div>
                                                                    <input 
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handlePriceRank('deal_price', e.target.value, idx, 'non-burn')} 
                                                                        value={sku.deal_price} 
                                                                        className={`${style.rank_field}`}
                                                                        disabled={parseFloat(sku.discount_amount) >= 0}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <div className={`fontXS labelGrey`}>DISCOUNT (₹)</div>
                                                                    <input  
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handlePriceRank('discount_amount', e.target.value, idx, 'non-burn')} 
                                                                        value={sku.discount_amount} 
                                                                        className={`${style.rank_field}`}
                                                                        disabled={parseFloat(sku.deal_price)} 
                                                                    />
                                                                </div>
                                                                {
                                                                    sku.is_price && 
                                                                    <div><i className={`red fontS`}>*Please add Price</i></div>
                                                                } 
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                {/* rank inputs */}
                                                                <div>
                                                                    <input 
                                                                        type='number' 
                                                                        min={0} 
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onChange={(e) => handleDebounceRank( e.target.value, idx, 'non-burn')} 
                                                                        key={sku.rank} 
                                                                        defaultValue={sku.rank} 
                                                                        className={`${style.rank_field}`} 
                                                                    />
                                                                    <button className={`${style.smallFilledBtn}`} onClick={() => handleRankSort(false, idx)}>
                                                                        GO <img src={GreyRightArrow} className={`${style.goIcon}`} alt="" />
                                                                    </button>
                                                                </div>

                                                                {/* show_rank_change div */}
                                                                {
                                                                    sku.show_rank_change &&
                                                                    <div>
                                                                        <span className={`${style.rankChange}`}>Rank Changed to {sku.rank}</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    sku.is_rank &&
                                                                    <div><i className={`red fontS`}>*Rannk cannot be zero</i></div>
                                                                }
                                                            </div>
                                                            <div className={`${style.pd_sm_y}`}>
                                                                <div className={`flex`}>
                                                                    <Dropdown
                                                                        NoKey={true}
                                                                        placeholder={"Search Associated ID"}
                                                                        height={'8rem'}
                                                                        list={nonBurnSkuList.map(({prod_id}) => prod_id)}
                                                                        showSearch={true}
                                                                        name={sku.associated_prod_id}
                                                                        getDropDownValue={(elem)=>{
                                                                            handlePriceRank('associated_prod_id', elem, idx, 'non-burn')
                                                                        }}
                                                                    />
                                                                    <img 
                                                                        src={BinGreyIcon} 
                                                                        className={`pointer ${sku.associated_prod_id ? '' : 'visibility_hidden'}`} 
                                                                        onClick={() => handlePriceRank('associated_prod_id', 0, idx, 'non-burn')}
                                                                        height='20px' 
                                                                    />
                                                                </div>    
                                                            </div>
                                                            <div className={`${style.pd_sm}`}>
                                                                <label>
                                                                    <input 
                                                                        type='checkbox'
                                                                        onChange={(e) => handlePriceRank('is_pn', e.target.checked, idx, 'non-burn')} 
                                                                        checked={sku.is_pn}
                                                                    /> PN
                                                                </label>
                                                            </div>
                                                            <div className={`textCenter ${style.pd_sm}`}>
                                                                <img height='20px' className={`pointer ${style.deleteIcon}`} onClick={() => handleDeleteSku(sku.prod_id, 'non-burn')} src={BinGreyIcon} />
                                                            </div>
                                                        </div>

                                                        {/* sku name / burn deal */}
                                                        <div className={`grid ${style.sku_deatil_column}`}>
                                                            {/* sku deals */}
                                                            <div className={`fontS labelGrey ${style.pd_sm}`}>
                                                                <div style={{paddingBottom: '8px'}}>
                                                                    <strong>[{sku.prod_id}]</strong> {sku.prod_name}
                                                                </div>
                                                                <div>
                                                                    <strong>Pack: </strong> {sku.pack_qt}
                                                                </div>
                                                            </div>
                                                            {/* max burn */}
                                                            <div className={`fontS labelGrey ${style.pd_sm}`}>
                                                                <strong>Max Burn: {sku.deal_price ? sku.price - sku.deal_price : sku.discount_amount}</strong>
                                                            </div>

                                                            {/* max min value */}
                                                            <div className={`flex flex-space-between fontS labelGrey ${style.pd_sm}`}>
                                                                <div style={{paddingBottom: '8px'}}>
                                                                    <strong>Max Price: {sku.price} ({sku.max_zone_name})</strong>
                                                                </div>
                                                                <div>
                                                                    <strong>Min Price: {sku.min_product_price} ({sku.min_zone_name})</strong>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* stock in DC */}
                                                        {
                                                            sku.dc_stock_left_list.length ? 
                                                                <div className={`flex fontS flex-justify-center ${style.white_outlined_container}`}>
                                                                    {
                                                                        sku.dc_stock_left_list.map((item, index) => {
                                                                            return(
                                                                                <div className={`${style.pd_sm}`} key={index}>
                                                                                    <strong>{item.name}:</strong> {item.stock} 
                                                                                    {(sku.dc_stock_left_list.length-1 !== index) ? <span> | </span> : null}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            : null
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {/* add btn */}
                                    <div className={`textCenter bg_white`}>
                                        <button onClick={()=>handleAddSkuPopup('non-burn')} className={`${style.outlined_btn}`}>
                                            Add Product (Skus)
                                            <img 
                                            alt="" 
                                            width="20px"
                                            height="20px"
                                            style={{ marginBottom: "-5px", marginLeft: "8px" }}
                                            src={AddIcon} />
                                        </button>
                                    </div>
                                </div> :
                                <div className={`textCenter separatorDashed ${style.pd_sm}`}>
                                    <span className={` pointer fontS labelGrey `} onClick={() => toggleDealProductLisiting('nonBurnDeal', !toggleDealProducts.nonBurnDeal)}>. . . VIEW NON BURN DEAL PRODUCTS</span>
                                </div>
                            }
                        </div>
                    </div>

                </div>

                {/* segement checkbox */}
                <div className={`${style.mrg_tp} ${style.mrg_btm_2}`}>
                    <label className={`pointer`}>
                        <input 
                            checked={campaignData.is_configurable_segment}
                            type={'checkbox'}
                            onChange={(e) => {handleCampaignDataChange('is_configurable_segment', e.target.checked)}}
                        /> <span className={`bold`}> Add configuration at user-segment and non-burn segment level</span>
                    </label>
                </div>
            
                {/* product segment details */}
                {
                    campaignData.is_configurable_segment && 
                    <div className={`${style.list_container}`}>
                        {/* thead */}
                        <div className={`grid ${style.list_thead} ${style.grid_column_segment}`}>
                            <div className={`${style.pd_sm}`}>SKU Details</div>
                            <div className={`${style.pd_sm}`}>User Segment</div>
                            <div className={`${style.pd_sm}`}></div>
                            <div className={`${style.pd_sm}`}>Non Burn Segment</div>
                            <div className={`${style.pd_sm}`}></div>
                            <div className={`${style.pd_sm}`}>Segment 7 Price</div>
                        </div>
                        <div>
                            {
                                burnSkuList.map((sku, idx) => {
                                    return(
                                        <div className={`grid ${style.sku_container}  ${style.grid_column_segment}`} key={sku.prod_id}>
                                            <div className={`${style.pd_sm}`}><b>[{sku.prod_id}]</b> {sku.prod_name}</div>
                                            <div className={`${style.pd_sm_y}`}>
                                                <Dropdown 
                                                    height={'8rem'}
                                                    list={includeExclude}
                                                    name={sku.segment_info.is_include_user_segment}
                                                    getDropDownValue={(elem)=>{
                                                        handleSegmentInfoDatachange('is_include_user_segment', elem, idx, 'burn')
                                                    }}
                                                />
                                            </div>
                                            <div className={`${style.pd_sm_y}`}>
                                                <Dropdown 
                                                    height={'8rem'}
                                                    checkboxList={true}
                                                    list={sku.segment_info.user_segment_list}
                                                    name={{name: `${handleSeletedSegmentCount(sku.segment_info.user_segment_list)} - User Segment`, id: 0}}
                                                    getDropDownValue={(elem, type, elemIdx)=>{
                                                        handleSegmentInfoDatachange('user_segment_list', elem, idx, 'burn', elemIdx)
                                                    }}
                                                />
                                            </div>
                                            <div className={`${style.pd_sm_y}`}>
                                                <Dropdown 
                                                    height={'8rem'}
                                                    list={includeExclude}
                                                    name={sku.segment_info.is_include_burn_segment}
                                                    getDropDownValue={(elem)=>{
                                                        handleSegmentInfoDatachange('is_include_burn_segment', elem, idx, 'burn')
                                                    }}
                                                />
                                            </div>
                                            <div className={`${style.pd_sm_y}`}>
                                                {/* burn_segment_list */}
                                                <Dropdown 
                                                    height={'8rem'}
                                                    checkboxList={true}
                                                    list={sku.segment_info.burn_segment_list}
                                                    name={{name: `${handleSeletedSegmentCount(sku.segment_info.burn_segment_list)} - Burn Segment`, id: 0}}
                                                    getDropDownValue={(elem, type, elemIdx)=>{
                                                        handleSegmentInfoDatachange('burn_segment_list', elem, idx, 'burn', elemIdx)
                                                    }}
                                                />
                                            </div>
                                            <div className={`${style.pd_sm}`}>
                                                <input  
                                                    type='number' 
                                                    min={0} 
                                                    onWheel={(e) => e.target.blur()}
                                                    onChange={(e) => handleSegmentInfoDatachange('segment_price_7', e.target.value, idx, 'burn')} 
                                                    value={sku.segment_info.segment_price_7} 
                                                    className={`${style.rank_field}`}
                                                />
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                                
                    </div>
                }

                {/* create cancel campaign */}
                <div className={`flex flex-justify-center`}>
                    <div className={`textRight ${style.deal_container}`}>
                        <button onClick={()=>navigate('/campaign-list/')} className={`bold ${style.big_btn} ${style.outlined_green_btn}`}>
                            Cancel Campaign 
                            <img 
                                style={{ marginBottom: '-7px', marginLeft: '8px'}}
                                src={CloseIcon} 
                            />
                        </button>
                    </div>
                    <div className={`${style.deal_container}`}>
                        <button onClick={() => handleSubmit(false)} className={`bold ${style.big_btn} ${style.filled_green_btn}`}>
                            {campaignStatus==="edit-campaign" ? 'Update' : 'Create'}  Campaign 
                            <img 
                                style={{ marginBottom: '-7px', marginLeft: '8px'}}
                                src={RightIcon} 
                            />
                        </button>
                    </div>
                </div>
            </div>
            {
                showPopup.show ? 
                <PopupAddSku 
                    zoneList={zoneList} 
                    dealType={showPopup.type} 
                    burnSkuList={burnSkuList}
                    nonBurnSkuList={nonBurnSkuList}
                    closeBtn={() => handleAddSkuPopup("")}
                    addSkuForDeal={addSkuForDeal}
                /> 
                : null
            }

            {
                showPopup.showSegment ? 
                <PopupUserSegment data={burnSegment} closeBtn={() => setShowPopup({...showPopup, showSegment: false})}/> 
                : null
            }

            {
                showPopup.csvPopup &&
                <PopupCsvUpload 
                    burnSkuList={burnSkuList}
                    setBurnSkuDeal={setBurnSkuDeal}
                    nonBurnSkuList={nonBurnSkuList}
                    setNonBurnSkuDeal={setNonBurnSkuDeal}
                    zoneList={zoneList} 
                    dealType={showPopup.dealType}
                    campaignData={campaignData}
                    setCampaignData={setCampaignData}
                    userSegment={userSegment}
                    burnSegment={burnSegment}
                    getDefaultSegmentInfoValue={getDefaultSegmentInfoValue}
                    setLoader={setLoader}
                    closeBtn={() => setShowPopup({...showPopup, csvPopup: false, dealType: ""})} />
                
            }
            {
                showPopup.overlapCampaign && 
                <PopupOverlapingCampaign 
                    onSubmit={() => handleSubmit(true)}
                    overlapCampDetail={showPopup.overlapCampDetail}
                    closeBtn={() => setShowPopup({... showPopup, overlapCampaign: false, overlapCampDetail: {}})}
                />
            }
            {
                showPopup.burnMapping && 
                <PopupZoneBurnError
                    burnErrorList={showPopup.burnErrorList}
                    closeBtn={() => setShowPopup({... showPopup, burnMapping: false, burnErrorList: []})}
                />
            }
            
            {
                isLoading && <Loader />
            }
        </div>
    );
}

export default CreateCompaign;