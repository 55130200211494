import React from 'react'
import style from './ToolTip.module.css';

function ToolTip(props) {
    return (
        <div className={`relative ${style.toolTip}`}>
            {props.icon}
            <div className={`absolute flex-end flex-direction-col labelGrey fontS ${style.toolTipText}`}>
                <div className={`textRight`}>
                    <div className={`${style.tip} ${props.black ? style.tipBlack : style.tipWhite}`}></div>
                </div>
                <div className={` ${style.toolText} ${props.black ? style.blackTheme: style.whiteTheme}`}>
                    {props.infoText}
                </div>
            </div>

        </div>
    )
}

export default ToolTip