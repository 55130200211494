import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import { cancelCampaign, changeCampaignStatus, cloneCampaign, downloadExcelFile, getCampaignDetails, getCampaignListing, removeSkusFromZones } from '../../../../utils/api'
import { EpochTimeInHumanLan } from '../../../../utils/TimeManager';
import PopupOverlapingCampaign from '../../CreateCampaign/components/PopupOverlapingCampaign';
import CapmaignDeatils from './CapmaignDeatils';
import PopUpPushNotification from './PopUpPushNotification';
import style from '../css/CampaignList.module.css'
import ArrowIcon from '../../../../icons/down-arrow.png';
import PushNotification from '../../../../icons/check_pn.svg'
import { ReactComponent as DeleteIcon } from '../../../../icons/trash.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as BurnDownload } from '../../../../icons/burn-download.svg';
import { ReactComponent as NonBurnDownload } from '../../../../icons/non-burn-download.svg';

import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';

import Loader from '../../../../commonComponent/Loader/Loader'


export default function CampaignListing({ Nslot, filter, zoneList }) {
    const [campaignList, setCampaignList] = useState([]);
    const [showPopups, setShowPopups] = useState({
        notificationPopup: false,
        activeDeactivePopup: false,
        deletePopup: false,
    })
    const [selCamp, setSelCamp] = useState({
        id: 0,
        title: "",
        status : 0,
        campaign_details: "",
    })
    const [showPopup, setShowPopup] = useState({
        show: false,
        type: "", 
        showSegment: false,
        csvPopup: false,
        dealType: "",
        overlapCampaign: false,
        overlapCampDetail: {},
    })

    const [isLoading, setLoader] = useState(false)
    const [showDetails, setShowDetails] = useState(false);
    const [productInfo, setProductInfo] = useState({ id: '', products: [] });
    const navigate = useNavigate();

    // get campaign detail according to the filters
    const getCampaignList = async (Nslot, filter, zoneList) => {
        setLoader(true);
        let res = await getCampaignListing(Nslot, filter, zoneList);
        setCampaignList(res.data);
        setLoader(false);
    }

    // toggle the popups
    const togglePopUp = (key, value) => {
        setShowPopups({... showPopups, [key]: value})
    }

    // navigate to clone / edit campaign
    const editCloneCampaign = (id, type) => {
        navigate(`/${type}-campaign/${id}/`)
    }

    // activate / deactive campaign
    const activateDeactiveCampaign = async (campaign_id, status, deactivateRunnningCampain) => {
        debugger;
        setLoader(true)
        let res = await changeCampaignStatus(campaign_id, status, deactivateRunnningCampain);
        getCampaignList(Nslot, filter, zoneList);
        togglePopUp('activeDeactivePopup', false)
        setLoader(false)
        if (res.success) {
            SuccessHandling(res.data.message)
            setShowPopup({... showPopup, overlapCampaign: false, overlapCampDetail: {}})
        } else {
            try {
                let errorObj = JSON.parse(res.error.message)
                errorObj.start_time = EpochTimeInHumanLan(errorObj.start_time)
                errorObj.end_time = EpochTimeInHumanLan(errorObj.end_time)
                console.log("sahi jaa rha hai", errorObj)
                setShowPopup({... showPopup, overlapCampaign: true, overlapCampDetail: errorObj})

            } catch (error) {
                console.log("--->",error);
                ErrorHandling(res.error.message)
            }
        }
    }

    // delete / cancel campaign
    const cancelSelCampaign = async (campaign_id) => {
        setLoader(true)
        let res = await cancelCampaign(campaign_id);
        getCampaignList(Nslot, filter, zoneList);
        togglePopUp('deletePopup', false)
        setLoader(false)
        if (res.success) {
            SuccessHandling(res.data.message)
        } else {
            ErrorHandling(res.error.message)
        }
    }

    // campaign detail
    const showCampDetails = async (id) => {
        let obj = {};
        if (!showDetails || productInfo.id !== id) {
            let res = await getCampaignDetails(id);
            obj.id = id;
            obj.products = res.data.products_info;
            setProductInfo({ ...obj });
            setShowDetails(true)
        } else {
            setProductInfo({ id: '', products: [] });
            setShowDetails(false);
        }

    }

    // download list 
    const downloadProductList = async (type, id) => {
        let cloneData = await cloneCampaign(id)
        console.log('cloneData ------->>>>', cloneData)
        if(cloneData){
            let csvData = []
            cloneData.productList.forEach((item) => {
                if(
                    (item.is_burn_deal && type == 'burn') || 
                    (!item.is_burn_deal && type == 'non-burn') 
                ) {
                    let obj = {}
                    obj['Prod ID'] = item.prod_id
                    obj['Prod Name'] = item.prod_name
                    obj['Rank'] = item.rank
                    obj['Price'] = item.deal_price
                    obj['Discount Amount'] = item.discount_amount == -1 ? "" : item.discount_amount
                    obj['Associate Prod ID'] = item.associated_prod_id
                    obj['PN (0 /1)'] = item.is_pn | 0
                    obj['User Segment Include (0/1)'] = item.segment_info.is_include_user_segment | 0
                    obj['Non Burn Segment Include (0/1)'] = item.segment_info.is_include_burn_segment | 0

                    let SegmentKeys = Object.keys(item.segment_info)
    
                    if(SegmentKeys.length){
                        obj['Segment 7 Price'] = item.segment_info && item.segment_info.segment_price['7'] ? item.segment_info.segment_price['7'] : ""

                        let userSegmentId = [], burnSegmentId = []

                        for (const [key, value] of Object.entries(item.segment_info.user_segment_ids)) {
                            if(value){
                                userSegmentId.push(key)
                            }
                        }

                        for (const [key, value] of Object.entries(item.segment_info.burn_segment_ids)) {
                            if(value){
                                burnSegmentId.push(key)
                            }
                        }

                        obj['User Segments'] = userSegmentId.join('|')
                        obj['Non Burn Segments'] = burnSegmentId.join('|')
                    }
                    else {
                        obj['Segment 7 Price'] =''
                        obj['User Segments'] = ''
                        obj['Non Burn Segments'] = ''
                    }
                    csvData.push(obj)
                }
            })

            if(csvData.length){
                // downloadExcel(csvData, id, type)
                downloadExcelFile(csvData, `${type}-camp-${id}`, 'csv')
            }
            else ErrorHandling(`No ${type} Products Available in Campaign ${id}`)
        }
    } 

    // const downloadExcel = (data, campId, type) => {
    //     const worksheet = XLSX.utils.json_to_sheet(data);
    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //     XLSX.writeFile(workbook, `${type}-camp-${campId}.csv`);
    // };

    useEffect(() => {
        if (Nslot)
            getCampaignList(Nslot, filter, zoneList);
    }, [Nslot, filter, zoneList])

    return (
        <div>
            <div className={`${style.tabHeader} grid ${style.campaign_listing_header}`}>
                <div style={{ fontWeight: "bold" }} className={`bold green`}>CAMPAIGN NAME</div>
                <div className='textCenter'>Start Time</div>
                <div className='textCenter'>End Time</div>
                <div className={`textCenter`}>Actions</div>
                <div className='textCenter'>Push Notifications</div>
                <div></div>
            </div>
            <div className={`${style.campaign_listing_container}`}>
                {campaignList && campaignList.length > 0 &&
                    campaignList.map((campaign) => {
                        return (
                            <div key={campaign.campain_id}>
                                <div className={` grid ${style.campaign_listing} ${style.separator} ${campaign.is_new_user ? style.bgLightYellow : ""}`}>
                                    <div className={`${style.strip_title_container}`}>
                                        <div className={`${style.strip_title}`}>{`${campaign.display_msg}`}</div>
                                        {campaign.creation_info.creator && <div className={`${style.strip_description}`}>Created By: <span className='bold green'>{campaign.creation_info.creator} | {campaign.created_at}</span></div>}
                                        {campaign.creation_info.last_edited_by && <div className={`${style.strip_description}`}>Edited By: <span className='bold green'>{campaign.creation_info.last_edited_by} | {campaign.creation_info.last_edited_time}</span></div>}
                                        <div className={`${style.strip_description}`}>Avail Count: <span className='bold green'>{campaign.avail_count}</span> | Burn Count: <span className='bold green'>{campaign.burn_count} </span> | Non Burn Count: <span className='bold green'>{campaign.non_burn_count}</span></div>
                                    </div>
                                    <div className={`textCenter ${style.campaignList_item}`}>
                                        <div><span className='bold'>{new Date(parseInt(campaign.start_time + '000')).toTimeString().substr(0, 5)}</span></div>
                                        <div className={`${style.strip_description}`}>{campaign.start_date}</div>
                                    </div>
                                    <div className={`textCenter ${style.campaignList_item}`}>
                                        <div><span className='bold'>{new Date(parseInt(campaign.end_time + '000')).toTimeString().substr(0, 5)}</span></div>
                                        <div className={`${style.strip_description}`}>{campaign.end_date}</div>
                                    </div>
                                    <div className={`grid ${style.campaign_list_item}`}>
                                        <div className={`textCenter`}>
                                            <button className={`${style.outlinedBtn}`}
                                                onClick={() => { editCloneCampaign(campaign.campain_id, 'clone') }}
                                            >Clone</button>
                                        </div>
                                        {
                                            campaign.status === 2 || campaign.status === 5?
                                            <div>
                                                <button 
                                                    className={`${style.outlinedBtn}`} 
                                                    style={
                                                        campaign.status === 2 ? 
                                                        { backgroundColor: "#e4fffc" } :
                                                        { backgroundColor: "#eeeeee", color: '#999', borderColor: '#999', cursor: "not-allowed" }
                                                    }>
                                                    {
                                                        campaign.status === 2 ? "Completed" : "Cancelled"
                                                    }
                                                </button>
                                            </div> : 
                                            campaign.status === 1 ?
                                            <div>
                                                <div className={`${style.isActiveCheckbox}`}>
                                                    <input id="activecheck" type="checkbox" checked={campaign.is_active} onChange={() => { setSelCamp({ id: campaign.campain_id, status: 0, campaign_details: campaign.display_msg }); togglePopUp('activeDeactivePopup', true) }} />
                                                    <span>Active</span>
                                                </div>
                                            </div> : 
                                            <div>
                                                <div className={`${style.isActiveCheckbox}`}>
                                                    <input id="activecheck" type="checkbox" checked={campaign.is_active} onChange={() => { setSelCamp({ id: campaign.campain_id, status: 1, campaign_details: campaign.display_msg }); togglePopUp('activeDeactivePopup', true) }} />
                                                    <span>Active</span>
                                                </div>
                                            </div>
                                        }
                                        {campaign.status === 3 ?
                                            <div>
                                                <DeleteIcon className={` pointer redIcon`}
                                                    onClick={() => {
                                                        setSelCamp({ 
                                                            id: campaign.campain_id, 
                                                            status: 1, 
                                                            campaign_details: campaign.display_msg
                                                        })
                                                        togglePopUp('deletePopup', true)
                                                    }}
                                                    height='26px' width='26px'
                                                />{ " "}
                                                <EditIcon className={` pointer ${style.EditIcon}`}
                                                    onClick={() => editCloneCampaign(campaign.campain_id, 'edit')}
                                                    height='26px' width='26px' 
                                                />
                                            </div> : null
                                        }
                                    </div>
                                    <div className={`flex flex-space-around ${style.campaignList_item} textCenter`}>
                                        <img 
                                            src={PushNotification} 
                                            onClick={() => {
                                                setSelCamp({
                                                    id: campaign.campain_id,
                                                    title: campaign.campaign_name,
                                                })
                                                togglePopUp('notificationPopup', true);
                                            }} 
                                            width="26px" 
                                            height="26px" 
                                            className={`pointer`}
                                        />
                                        <BurnDownload width="26px" height="26px" className={`pointer`} onClick={() => downloadProductList('burn', campaign.campain_id) }/>
                                        <NonBurnDownload width="26px" height="26px" className={`pointer`} onClick={() => downloadProductList('non-burn', campaign.campain_id) }/>
                                    </div>
                                    <div className={`${style.campaignList_item} pointer`}><img src={ArrowIcon} width="20px" height="20px" style={{ float: "right" }} onClick={() => { showCampDetails(campaign.campain_id) }} /></div>
                                </div>

                                {/* Campaign Detail section */}

                                {showDetails && productInfo.id === campaign.campain_id && <div className={`${style.detail_container}`}>
                                    {
                                        productInfo.products.length > 0 && productInfo.products.map((product, index) => {
                                            return (
                                                <div key={index}>
                                                    <CapmaignDeatils productInfo={product} campaign_id={productInfo.id} />
                                                </div>
                                            );
                                        })
                                    }
                                </div>}
                            </div>
                        )
                    })}
            </div>

            {/* Push Notification detail Popup */}
            {
                showPopups.notificationPopup &&
                <PopUpPushNotification
                    closeBtn={() => togglePopUp('notificationPopup', false)}
                    selCamp={selCamp}
                />
            }

            {/* Confirmation Popup */}
            {showPopups.activeDeactivePopup && <div>
                <PopUp 
                    width={formStyle.popupWidthS} 
                    title={`${selCamp.status===1?'Activate':'Deactivate'} Campaign`} 
                    closeBtn={() => { togglePopUp('activeDeactivePopup', false) }} 
                >
                    <div style={{ textAlign: 'center' }} className={`${formStyle.inputContainer} `}>
                        { selCamp.campaign_details}
                    </div>
                    <div className={`${formStyle.btnContainer}`}>
                        <button 
                            onClick={() => { activateDeactiveCampaign(selCamp.id, selCamp.status, false) }} 
                            className={`${formStyle.confirmBtn}`}>
                                Submit
                        </button>
                    </div>
                </PopUp>
            </div>}

            {/* delete campaign Popup */}
            {showPopups.deletePopup && <div>
                <PopUp 
                    width={formStyle.popupWidthS} 
                    title={`Cancel Campaign`} 
                    closeBtn={() => { togglePopUp('deletePopup', false) }} 
                >
                    <div style={{ textAlign: 'center' }} className={`${formStyle.inputContainer} `}>
                        Are you sure you want to Cancel campaign <b>{ selCamp.campaign_details} ?</b>
                    </div>
                    <div className={`${formStyle.btnContainer}`}>
                        <button 
                            onClick={() => { cancelSelCampaign(selCamp.id, selCamp.status) }} 
                            className={`${formStyle.confirmBtn}`}>
                                Submit
                        </button>
                    </div>
                </PopUp>
            </div>}

            {/* overlapping campaign */}
            {
                showPopup.overlapCampaign && 
                <PopupOverlapingCampaign
                    onSubmit={() => activateDeactiveCampaign(selCamp.id, selCamp.status, true) }
                    overlapCampDetail={showPopup.overlapCampDetail}
                    closeBtn={() => setShowPopup({... showPopup, overlapCampaign: false, overlapCampDetail: {}})}
                />
            }
            {isLoading && <Loader />}
        </div>
    )
}
