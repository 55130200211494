import React from 'react';
import style from '../css/CutOff.module.css';
function Header(props) {
    return (
        <>
            
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Cut Off Time</span></div>
            </div> 
            
        </>
    );
}

export default Header;