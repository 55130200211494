import React, { useEffect, useState } from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { getZoneData, updateBurnMappingList } from '../../../../utils/api';
import { ErrorHandling } from '../../../../State';
import ArrowIcon from '../../../../icons/down-arrow.png';
import { toggle } from '../../../../utils/api';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';

function BurnMappingPopUp(props) {
    const [oldUser,setoldUser] = useState(props.max_burn_ext_user);
    const [newUser,setnewUser] = useState(props.max_burn_new);
    const [list,setList] = useState([]);
    const [selZone,setselZone] = useState({});
    const handleSubmit = async () => {
        if (!oldUser || oldUser===undefined) {
            ErrorHandling('Please fill the max burn for exisiting user')
        } else if(!newUser || newUser===undefined){
            ErrorHandling('Please fill the max burn for new user')
        } 
        else {
            var data = {
                zone_id: selZone.id,
                pricing_zone_name:selZone.name,
                max_burn_new_user:newUser,
                max_burn_old_user:oldUser
            }  
            
            if (props.id) {
                data.id = props.id;
                data.zone_id = props.zoneId;
                data.pricing_zone_name = props.zoneName;
            }

            console.log(data);
            await updateBurnMappingList(data);
            await props.fetchData();
            props.closeBtn();  

        }
    }
   const fetchZoneList = async() => {
        let res = await getZoneData();
        setselZone(res.data[0])
        setList(res.data)
   }
   useEffect(() => {
    if(!props.id){
        fetchZoneList();
    }
   },[])

    return (
        <PopUp
        title={
            (props.max_burn_ext_user) ? 'Update Mapping' : 'Add Mapping'
         }
        width={`${formStyle.popupWidthXL}`}
        closeBtn={() => props.closeBtn(false)}
    >
        <div className={`${formStyle.inputContainer}`}>
            <div className={`${formStyle.popup_title}`}>
                 <div className={`${formStyle.width_20}`}>Zone ID</div>
                 <div className={`${formStyle.width_20}`}>Zone</div>
                 <div className={`${formStyle.width_30}`}>Max Burning Existing User</div>
                 <div className={`${formStyle.width_30}`}>Max Burning New User</div>
            </div>
            <div className={`${formStyle.popup_body}`}>
            {
                props.id?
                <div className={`${formStyle.width_20}`}>{props.zoneId}</div>
                :
                <div className={`${formStyle.width_20}`}>{selZone.id}</div>
            }
               

                {
                  props.id ? 
                    <div className={`${formStyle.width_20}`}>{props.zoneName}</div> 
                    :
                    <Dropdown
                        height={'8rem'} 
                        list={list}
                        name={selZone}
                        getDropDownValue={(elem) => {
                            setselZone(elem);
                        }}/>
                }
                 <div className={`${formStyle.width_30}`}><input type="number" value={oldUser} onChange={(e)=>setoldUser(e.target.value)} className={`${formStyle.inputField}`}/></div>
                 <div className={`${formStyle.width_30}`}><input type="number" value={newUser} onChange={(e)=>setnewUser(e.target.value)} className={`${formStyle.inputField}`}/></div>
            </div>
        </div>
        <div className={`${formStyle.btnContainer}`}>
            <button className={`${formStyle.confirmBtn}`} onClick={handleSubmit}>
            {
                (props.max_burn_ext_user) ? 'Update' : 'Add'
            }
            </button>
        </div>

    </PopUp>
    );
}

export default BurnMappingPopUp;