export const drop2 = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Draft",
  },
  {
    id: 3,
    name: "History",
  },
];

export const webStatusList = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Draft",
  },
];

export const drop3 = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Disabled",
  },
];

export const drop4 = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
];
export const landingPageList = [
  { id: 0, value: "HOME", name: "Home Screen" },
  { id: 1, value: "CART", name: "Cart Screen" },
  { id: 2, value: "WALL", name: "Cash Wallet Screen" },
  { id: 3, value: "REFR", name: "Referral Screen" },
  { id: 4, value: "DEAL", name: "Banner Details" },
  { id: 5, value: "PRODUCT", name: "Product Subscription Screen" },
  { id: 6, value: "PAYMENT", name: "Payment Gateway Screen" },
  { id: 7, value: "SUBSCRIPTION", name: "Subscription Membership Screen" },
  { id: 8, value: "REWARD", name: "Reward Wallet Screen" },
  { id: 9, value: "SODEXO", name: "Sodexo Wallet Screen" },
  { id: 10, value: "CATEGORY", name: "Particular Category" },
  { id: 11, value: "WEBV", name: "Web View" },
  // { id: 12, value: 'NOACTION', name: 'No Action' },
];

export const cta_values = [
  { id: 0, value: "SHOW MORE", name: "SHOW MORE" },
  { id: 1, value: "BUY NOW", name: "BUY NOW" },
];

export const ContentType = [
  { id: 1, value: "IMAGE", name: "IMAGE" },
  { id: 2, value: "VIDEO", name: "VIDEO" },
  { id: 3, value: "VIRTUL", name: "VIRTUL" },
  { id: 4, value: "BANNER", name: "BANNER" },
  { id: 5, value: "SQUARE", name: "SQUARE" },
  { id: 6, value: "SUBSCRIPTION", name: "SUBSCRIPTION" },
  { id: 7, value: "SAFE", name: "100% SAFE" },
  { id: 8, value: "LOTTIE", name: "LOTTIE BANNER" },
];

export const ProdImageInfo = [
  { id: 1, name: "Large Banner" },
  { id: 2, name: "Medium Banner" },
  { id: 3, name: "Square Banner" },
  { id: 4, name: "All" },
];

export const MonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const WeekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const OneTimeUseDropdown = [
  { id: 0, name: "Default" },
  { id: 1, name: "Consumer" },
  { id: 2, name: "Overall" },
];

export const checkboxes = [
  { id: 1, name: "Product", isChecked: true },
  { id: 2, name: "Category", isChecked: false },
  { id: 3, name: "Sub Category", isChecked: false },
];

export const discountType = [
  { name: "Absolute", id: 0 },
  { name: "Percentage", id: 1 },
];

export const HeaderBannerType = [
  { id: 1, name: "VIDEO" },
  { id: 2, name: "IMAGE" },
  { id: 3, name: "GIF" },
];

// usp dropdown
export const usptype = [
  { id: 1, name: "VIDEO" },
  { id: 2, name: "IMAGE" },
  // { id: 3, name: "GIF" },
  { id: 4, name: "LOTTIE" },
];

// APP FEED > constant data
export const headerType = [
  { id: 0, name: "No Heading" },
  { id: 1, name: "Textual Heading" },
  { id: 2, name: "Textual Heading with Subtitle" },
  { id: 3, name: "Small Visual Heading" },
  { id: 4, name: "Large Visual Heading" },
];

// APP FEED > constant data
export const visualType = [
  { id: 2, name: "header_image" },
  { id: 3, name: "video" },
  { id: 4, name: "Lottie" },
];

// APP FEED > constant data
export const screenType = [
  { id: 1, has_landing_screen: true, name: "mini feed widget" },
  { id: 2, has_landing_screen: true, name: "web view" },
  { id: 3, has_landing_screen: true, name: "virtual category" },
  { id: 4, has_landing_screen: true, name: "subcategory" },
  { id: 5, has_landing_screen: true, name: "category" },
  { id: 6, has_landing_screen: false, name: "home screen" },
  { id: 7, has_landing_screen: false, name: "Cash wallet" },
  { id: 8, has_landing_screen: false, name: "Reward Wallet" },
  { id: 9, has_landing_screen: false, name: "Payment Screen" },
  { id: 10, has_landing_screen: false, name: "Refer Screen" },
  // {id:11, has_landing_screen: false, name: "Cart Screen", },
  {id:12, has_landing_screen: false, name: "Subscription Landing Page", },
  {id:13, has_landing_screen: true , name: "Subscription screen", },
  {id:14, has_landing_screen: false, name: "no action", },
  {id:15, has_landing_screen: true, name: "Auth Web View", }
]

// APP FEED > widget carousal aligment
export const aligmentType = [
  { id: 0, name: "centre" },
  { id: 1, name: "left" },
  { id: 2, name: "center_expand" },
];

// APP FEED > media
export const media = [
  { id: 0, name: "image" },
  { id: 1, name: "video" },
  { id: 2, name: "lottie" },
];

// APP FEED > see more widgets
export const seeMoreWidgets = [
  "cat_prod_wd",
  "subcat_prod_wd",
  "virtual_cat_wd",
  "large_prod_wd",
  "medium_prod_wd",
  "square_prod_wd",
  "subscription_wd",
  "image_video_wd",
];

// flash deal const
export const includeExclude = [
  { id: 1, name: "Include" },
  { id: 0, name: "Exclude" },
];

//
export const csvHeaders = [
  { label: "Prod ID" },
  { label: "Rank" },
  { label: "Price" },
  { label: "Discount Amount" },
  { label: "Associate Prod ID" },
  { label: "PN (0 /1)" },
  { label: "User Segment Include (0/1)" },
  { label: "User Segments" },
  { label: "Non Burn Segment Include (0/1)" },
  { label: "Non Burn Segments" },
  { label: "Segment 7 Price" },
];

export const acceptedExcelTypes = [
  ".csv",
  ".xls",
  ".xlsx",
  "text/csv",
  "application/csv",
  "text/comma-separated-values",
  "application/csv",
  "application/excel",
  "application/vnd.msexcel",
  "text/anytext",
  "application/vnd. ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

// MARKETINT > membership edit / add
export const inGramsValues = [250, 500, 750, 1000];

// BRANDING > APP FEED > top padding
export const topPaddingList = ["0", "4", "8", "12", "16"];

// BRANDING > Mini Feed > Search Mini Feed
export const searchMiniFeedWidgetList = ["past_order_wd", "recent_search_wd"];

// PRODUCT DETAILS >
export const prodDetailKeys = [
  {
    id: 1,
    name: "Product Details",
  },
  {
    id: 2,
    name: "Commodity",
  },
  {
    id: 3,
    name: "Tags",
  },
];

//PDP > BULK UPLOAD > Product Images/Videos
export const bulkUploadList = [
  {
    id: 1,
    name: "PDP",
  },
  {
    id: 0,
    name: "PLP",
  },
];

//PDP > BULK UPLOAD > PRODUCT DETAIL CSV HEADER
export const productDetailHeader = {
  prod_id: "",
  prod_name: "",
  cat_id: "",
  subcat_id: "",
  brand_id: "",
  commodity_id: "",
};

export const zipType = [
  "application/zip",
  "application/octet-stream",
  "application/x-zip-compressed",
  "multipart/x-zip",
  "application/zip-compressed",
  "application/x-zip",
];

//BULK UPLOAD > PRODUCT DETAIL CSV HEADER
export const SparProductDetails = {
  subcat_id:"",
  prod_id:"",
  prod_name:"",
  dc_id	:"",
  pricing_zone_id	:"",
  pricing_zone_name:"",
  is_mrp_product:"",	
  mrp	:"",
  price	:"",
  max_price	:"",
  deal_of_day	:"",
  offer_type:"",
  mkt_cost	:"",
  is_active:"",
  rank	:"",
  max_quant_limit	:"",
  partially_enabled:"",	
  md5	:"",
  is_visible:"",	
  widget_id	:"",
  reward_percentage	:"",
  min_quant_limit:"",
};


// COMMUNICATION DASHBOARD > DAY INCLUDES
export const comLastOrder = [
  { id: 0, name: "Exclude" },
  { id: 1, name: "Include" },
  { id: 2, name: "No Exclusion" },
];

// COMMUNICATION DASHBOARD > DAY INCLUDES
export const communicationExcelFormat = {
  consumer: [
    {
      consumer_id: "",
      phone: "",
      user_id: "",
    },
  ],

  varList: [
    {
      consumer_id: "",
      phone: "",
      user_id: "",
      var1: "",
      var2: "",
      var3: "",
      var4: "",
      var5: "",
      var6: "",
      var7: "",
      var8: "",
      var9: "",
      var10: "",
      var11: "",
    },
  ],
};

// COMMUNICATION DASHBOARD > SELECT USER
export const communicationSelectUser = [
  {
    id: 0,
    name: "Upload CSV",
  },
  {
    id: 1,
    name: "Variable Through Bulk Upload",
  },
  {
    id: 2,
    name: "Segment",
  },
  {
    id: 3,
    name: "Offer",
  },
]

// BANNER | FEED CONTENT - widget rank const
export const widgetRank = ["0", "6", "10", "14", "20"];

// BANNER | FEED CONTENT - allowed widgets
export const AllowedBannerWidget = [
  "image_carousal_wd",
  "dynamic_banner_wd",
  "dynamic_end_to_end_banner_wd",
];

// ONDC
export const ondcFeedStatus = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 2,
    name: "Draft",
  },
  {
    id: 0,
    name: "History",
  },
];

export const ondcScreenType = [
  { id: 1, has_landing_screen: true, name: "Category" },
  { id: 2, has_landing_screen: true, name: "Store" },
];
// user segment constants

export const SEGMENT_STATUS = {
  0: {
    name: "DRAFT",
    color: "yellow",
  },
  1: {
    name: "ACTIVE",
    color: "green",
  },
  2: {
    name: "DELETED",
    color: "red",
  },
};

export const SEGMENT_TYPE = {
  0: "Static",
  // yellow

  1: "Dynamic",
  // Blue
};

// OFFERS CREATION
export const OfferdiscountType = [
  { name: 'Absolute', id: 1 },
  { name: 'Percentage', id: 2 },
  { name: 'Fixed', id: 3 },
]



export const cartParameter = [
  {
    id: 1,
    name: "total cart value",
    field_type: null,
  },
  {
    id: 2,
    name: "Category ID",
    field_type: "dropdown",
  },
  {
    id: 3,
    name: "Sub Category ID",
    field_type: "dropdown",
  },
  {
    id: 4,
    name: "Prod ID",
    field_type: "button",
  },
  {
    id: 5,
    name: "Virtual Category ID",
    field_type: "button",
  },
  // {
  //   id: 6,
  //   name: 'Brand ID',
  //   field_type: 'button',
  // },
  // {
  //   id: 7,
  //   name: 'Commodity ID',
  //   field_type: 'button',
  // },
  {
    id: 8,
    name: "No Check",
    field_type: null,
  },
];

export const offerBenefitType = [
  {
    id: 1,
    name: "Upfront discount",
  },
  {
    id: 2,
    name: "Product offer",
  },
  {
    id: 3,
    name: 'Cashback in Cash wallet'
  },
  {
    id: 4,
    name: 'Cashback in Reward wallet'
  },
]

export const marketingheaderTempletes = {
  marketingHeader1: {
    0:[],
    1: [
      {
        id: 1,
        name: 'Flat ₹{AB} OFF',
        hasVariable: ['AB'],
        discount_type: [1]
      }, {
        id: 2,
        name: 'Flat {P}% OFF ',
        hasVariable: ['P'],
        discount_type: [2]
      }, {
        id: 3,
        name: 'Flat {P}% OFF up to ₹{X2}',
        hasVariable: ['P', 'X2'],
        discount_type: [2]

      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      }, 
    ],
    2: [
      {
        id: 1,
        name: 'FLAT {P}% OFF on {A} of {B}',
        hasVariable: ['P', 'A', 'B'],
        discount_type: [2],
      }, {
        id: 2,
        name: 'FREE {A} of {B}',
        hasVariable: ['A', 'B'],
        discount_type: [1, 2, 3],
      }, {
        id: 3,
        name: 'Get {A} of {B} for ₹{X}',
        hasVariable: ['A', 'B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 4,
        name: 'Get {B} ({A}) for ₹{X}',
        hasVariable: ['A', 'B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 5,
        name: '{B} at just ₹{X}',
        hasVariable: ['B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 6,
        name: 'Get ₹{AB} OFF on {B}',
        hasVariable: ['B', 'AB'],
        discount_type: [1, 3]
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2, 3]
      }, 
    ],
    3: [
      {
        id: 1,
        name: 'Get ₹{AB} cashback in your Otipy Cash Wallet',
        hasVariable: ['AB',],
        discount_type: [1],
      }, {
        id: 2,
        name: 'Get {P}% cashback in your Otipy Cash Wallet',
        hasVariable: ['P',],
        discount_type: [2],
      }, {
        id: 3,
        name: 'Get {P}% cashback upto ₹{X2} in Cash Wallet',
        hasVariable: ['P', 'X2',],
        discount_type: [2],
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      },
    ],
    4: [
      {
        id: 1,
        name: 'Get ₹{AB} cashback in your Reward Wallet',
        hasVariable: ['AB',],
        discount_type: [1],
      }, {
        id: 2,
        name: 'Get {P}% cashback in your Reward Wallet',
        hasVariable: ['P',],
        discount_type: [2],
      }, {
        id: 3,
        name: 'Get {P}% cashback up to ₹{X2} in your Reward Wallet',
        hasVariable: ['P', 'X2',],
        discount_type: [2],
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      }, 
    ],
  },
  cartHeader1: {
    0:[],
    1: [
      {
        id: 1,
        name: 'Flat ₹{AB} OFF',
        hasVariable: ['AB'],
        discount_type: [1]
      }, {
        id: 2,
        name: 'Flat {P}% OFF ',
        hasVariable: ['P'],
        discount_type: [2]
      }, {
        id: 3,
        name: 'Flat {P}% OFF up to ₹{X2}',
        hasVariable: ['P', 'X2'],
        discount_type: [2]

      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      }, 
    ],
    2:[
      {
        id: 1,
        name: 'FLAT {P} OFF on {A} of {B}',
        hasVariable: ['P', 'A', 'B'],
        discount_type: [2]
      }, {
        id: 2,
        name: 'FREE {A} of {B}',
        hasVariable: ['A', 'B'],
        discount_type: [1, 2, 3]
      }, {
        id: 3,
        name: 'Get {A} of {B} for ₹{X}',
        hasVariable: ['A', 'B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 4,
        name: 'Get {B} ({A}) for ₹{X}',
        hasVariable: ['A', 'B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 5,
        name: '{B} at just ₹{X}',
        hasVariable: ['B', 'X'],
        discount_type: [1, 3]
      }, {
        id: 6,
        name: 'Get ₹{AB} OFF on {B}',
        hasVariable: ['B', 'AB'],
        discount_type: [1, 3]
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2, 3]
      },
    ],
    3: [
      {
        id: 1,
        name: 'Get ₹{AB} cashback in your Otipy Cash Wallet',
        hasVariable: ['AB',],
        discount_type: [1],
      }, {
        id: 2,
        name: 'Get {P}% cashback in your Otipy Cash Wallet',
        hasVariable: ['P',],
        discount_type: [2],
      }, {
        id: 3,
        name: 'Get {P}% cashback upto ₹{X2} in Cash Wallet',
        hasVariable: ['P', 'X2',],
        discount_type: [2],
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      },
    ],
    4: [
      {
        id: 1,
        name: 'Get ₹{AB} cashback in your Reward Wallet',
        hasVariable: ['AB',],
        discount_type: [1],
      }, {
        id: 2,
        name: 'Get {P}% cashback in your Reward Wallet',
        hasVariable: ['P',],
        discount_type: [2],
      }, {
        id: 3,
        name: 'Get {P}% cashback up to ₹{X2} in your Reward Wallet',
        hasVariable: ['P', 'X2',],
        discount_type: [2],
      }, {
        id: -1,
        name: 'custom',
        hasVariable: [],
        discount_type: [1, 2]
      }, 
    ],
  },
  header2: [
    {
      id: 1,
      name: 'On purchase of {D} above ₹{Z}',
      hasVariable: ['D', 'Z'],
      cart_params: [2, 3, 5,],
      message: "Category, Sub-Category & Virtual Banner",
    },{
      id: 2,
      name: 'On orders above ₹{Z}',
      hasVariable: ['Z'],
      cart_params: [1],
      message: "Total Cart Value",
    }, {
      id: 3,
      name: 'On {D} above ₹{Z}',
      hasVariable: ['D', 'Z'],
      cart_params: [2, 3, 5,],
      message: "Category, Sub-Category & Virtual Banner",
    }, {
      id: 4,
      name: 'On purchase of {D}',
      hasVariable: ['D'],
      cart_params: [4],
      message: "Prod ID",
    }, {
      id: -1,
      name: 'custom',
      hasVariable: [],
      cart_params: [1, 2, 3, 4, 5, 8,]
    }, 
  ]

}

export const offerScreenType = [
  {id:1,  has_landing_screen: true , name: "mini feed widget", },
  {id:3,  has_landing_screen: true , name: "virtual category", },
  {id:4,  has_landing_screen: true , name: "subcategory", },
  {id:5,  has_landing_screen: true , name: "category", },
  {id:6,  has_landing_screen: false, name: "home screen", },
  {id:0,  has_landing_screen: false , name: "No Landing Screen", },
]

export const offerSettingsRedemptionTypes = [
  {
    id: 0,
    name: "no check",
  },
  {
    id: 1,
    name: "lifetime",
  },
  {
    id: 2,
    name: "delivery slot",
  },
  {
    id: 3,
    name: "weekly",
  },
];

export const offerInvDetails = [
  { dc_id: 8, inventory: 0, text: "Noida Dc" },
  { dc_id: 6, inventory: 0, text: "Gurugram Dc" },
  { dc_id: 13, inventory: 0, text: "Mumbai DC" },
];

export const Ordertype=[
  { 
    name:"N"
  },
  {
    name:"F"
  },
  {
    name:"S"
  },
  {
    name:"NS"
  },
  {
    name:"FS"
  },
  {
    name:"NF"
  },
  {
    name:"NFS"
  }
]


export const datatype = [
  {
    id: "Before",
    name: "Less than",
  },
  {
    id: "After",
    name: "More than",
  },
  {
    id: "Between",
    name: "Between",
  },
];

export const optionalDateType = [
  {
    id: "Before",
    name: "More Than",
  },
  {
    id: "After",
    name: "Less Than",
  },
  {
    id: "Between",
    name: "Between",
  },
  {
    id: "Not Selected",
    name: "Not Selected",
  }
];

export const locationId=[
  {
    id:"pricing_zone_id",
    name:"Pricing zone ID",
  },
  {
    id:"agent_id",
    name:"Partner  code",
  },
  {
    id:"zone_polygon_id",
    name:"Zone polygon ID"
  },
  {
    id:"dc_id",
    name:"DC ID"
  }
]

export const subscriptionType = [
  {
    id:"hmm", 
    label: "HMM" 
  },
  { 
    id:"lmm",
    label: "LMM"
  },
  {
    id:"coconut",
    label: "Coconut" 
  },
  { 
    id:"others",
    label: "Others" 
  },
];

export const params = [
  {
    id:"prod_id",
    name:"Prod ID",
  },
  {
    id:"combo_id",
    name:"Combo ID",
  },
  {
    id:"cat_id",
    name:"Cat Id",
  },
  {
    id:"subcat_id",
    name:"Sub-cat ID",
  },
]
export const membershipType = [
  { id:"active", label: "Active (Items pendings)" },
  { id:"consumed",  label: "Active (Items consumed)" },
  { id:"expired", label: "Expired" },
  { id:"never_purchased",label: "Never purchased" },
];

export const lastOrders = [
  { id: 'btl', label: 'BTL' },
  { id: 'digital', label: 'Digital' },
  { id: 'referral', label: 'Referral' },
  { id: 'ev', label: 'EV' },
  { id: 'ondc', label: 'ONDC' }
];
export const lastOrdersCount = [{ label: 3 }, { label: 5 }, { label: 10 }, { label: 20 }];

export const BurnType = [
  { id: 'coupon', name: 'Coupon' },
  { id: 'reward', name: 'Reward' },
  { id: 'promo', name: 'Promo' },
  { id: 'flash', name: 'Flash' }
]

export const supplier_name=[
  {
    id:"upload_pricing",
    name:"Upload Pricing",
    type:1
  },
  {
    id:"combo_products",
    name:"Combo Products",
    type:2
  }
];

export const ComboProductDetails={
  base_prod_id:"",
  combo_prod_id:"",
  combo_name:"",
  base_quantity:"",
  is_combo_visible:"",
  is_combo_active:"",
  combo_distributor_id:"",
  combo_pricing_zone_id:"",
  discount_percent:"",
  fixed_price:"",
  is_combo_new_design:"",
  pack_qt:"",
  new_pack_qt:""
}

