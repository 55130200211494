import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { ProductVideoImgUrl } from '../../../../utils/config';

function PopupShowVideo({ closeBtn, videoUrl }) {
    return (
        <PopUp
            title="Video View!"
            width={`${formStyle.popupWidthM}`}
            closeBtn={closeBtn}
        >
            <div className={` ${formStyle.inputContainer}`}>

                <div className={`textCenter`}>
                    <video controls style={{height: '210px'}} key={videoUrl}>
                        <source src={ProductVideoImgUrl + videoUrl} type="video/mp4" key={videoUrl} />
                        Your browser does not support the video tag.
                    </video> 
                    <div style={{marginTop: "40px"}}>
                        <button className={`${formStyle.btn} ${formStyle.secondaryBtn}`} onClick={closeBtn}>Close</button>
                    </div>
                </div>

            </div>
        </PopUp>
    );
}

export default PopupShowVideo;