import React from 'react';
import { useParams } from 'react-router-dom';
import style from '../css/CreateCampaign.module.css'

function Header(props) {
    const {campaignStatus} = useParams()

    return (
        <div className={`${style.header} flex flex-space-between`}>
            <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>{campaignStatus==="edit-campaign"  ? "Edit " : "Create "}Campaign</span></div>
        </div>
    );
}

export default Header;