import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import style from '../css/Banner.module.css'
import { ContentType } from '../../../../utils/constData';
import { downloadBannerExcel, downloadExcelFile, getOrderBannerZonewiseList, getZoneData, searchBannerApi, toggle, uploadBannerExcel } from '../../../../utils/api'
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../icons/ic_grey_search.svg'
import Calender from '../../../../commonComponent/Calender/Calender';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import Downloadcsv from '../../../../icons/Downloadcsv.png'
import Uploadcsv from '../../../../icons/Uploadcsv.png'
import { getTodaySlot } from '../../../../utils/TimeManager';

function Header({ selZone, contentType, setselZone, setContentType, searchQuery, itemList, setItemList, updateItemList, slot }) {
    const [list, setList] = useState([]);
    const [popup, setPopup] = useState(false);
    const [errorPopup, seterrorpopup] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [uploadBannerList, setUploadBannerList] = useState([]);
    const [debounceTimer, setDebounceTimer] = useState(0)
    const navigate = useNavigate();


    const updateList = async () => {
        let res = await getZoneData();
        //console.log('res', res);
        setselZone(res.data[0])
        setList(res.data);
        setContentType(ContentType[3])
    }

    const AddBanner = () => {
        navigate(`/new/${0}/${selZone.id}/banner/${slot}`);
    }

    const searchBanner = async (search_text) => { 
        if (search_text.length >2) { 
            let res = await searchBannerApi(search_text);
            if (res.success) {
                setItemList(res.data);
            } else { 
                ErrorHandling(res.error.message);
            }
        } else {
            updateList()
        }
    }

    const loadWorksheet = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
            const text = e.target.result;
            processCSV(text);
        }

        reader.readAsText(file);
        document.getElementById('upload_sms_field').value = null;

    };

    const uploadExcel = () => {
        document.getElementById('upload_sms_field').click();
    }

    const processCSV = (str, delim = ',') => {
        const headers = str.slice(0, str.indexOf('\n')).split(delim);
        const rows = str.slice(str.indexOf('\n') + 1).split('\n');
        headers.pop()
        headers.push("campaign_name")
        let invalidProdIds = [];
        console.log("headers", headers)
        const newArray = rows.map(row => {
            const values = row.split(delim);
            const eachObject = headers.reduce((obj, header, i) => {
                if (header === 'banner_id' || header === 'prod_id' || header === 'rank') {
                    if (values[i] && values[i].trim() !== '') {
                        if (!/^[0-9]*$/.test(values[i].split(' ').join('').trim())) {
                            invalidProdIds.push({ 'banner_id': obj['banner_id'], 'prod_id': values[i] })
                        } else {
                            obj[header] = parseInt(values[i].split(' ').join('').trim());
                        }
                    }
                } else {
                    obj[header] = values[i];
                }
                return obj;
            }, {})
            return eachObject;
        })

        let bannerUploadList = newArray.filter((banner) => Object.keys(banner).length === 5);
        console.log(bannerUploadList);
        validateExcel(bannerUploadList, invalidProdIds);
    }

    const validateExcel = (banner_list, invalidProdIds) => {
        if (invalidProdIds.length > 0) {
            let ErrorsArr = [];
            invalidProdIds.map((errors) => {
                ErrorsArr.push(`"${errors.prod_id}" is not a valid Product Id for Banner ${errors.banner_id}`)
            })
            seterrorpopup(true);
            setErrorList(ErrorsArr)
            // console.log(ErrorsArr);
        } else if (banner_list.length > 0) {
            setPopup(true)
            setUploadBannerList(banner_list)
        }
    }

    const uploadCsv = async () => {
        console.log(uploadBannerList);
        let res = await uploadBannerExcel(uploadBannerList);
        setPopup(false);
        if (res.success) {
            SuccessHandling('CSV Uploaded Successfully');
            await updateItemList()
        } else if (res.error.code === 4001) {
            seterrorpopup(true);
            setErrorList(JSON.parse(res.error.message))
        } else {
            ErrorHandling(res.error.message)
        }
    }

    const downloadCsv = async () => {
        let res = await downloadBannerExcel(slot);
        if (res.success) {
            let downloadBannersList = [];
            // let headers = ['banner_id,prod_id,rank,pricing_zone_name,campaign_name']
            // let usersCsv = downloadBannersList.reduce((acc, user) => {
            //     const { banner_id, prod_id, rank, pricing_zone_name, campaign_name } = user
            //     acc.push([banner_id, prod_id, rank, pricing_zone_name, campaign_name].join(','))
            //     return acc
            // }, [])
            // console.log("downloadBannersExcel", ...usersCsv)
            // let data = [...headers, ...usersCsv].join('\n')
            // const csvData = new Blob([data], { type: 'text/csv;' });
            // saveAs(csvData, 'Banners' + '.csv');
            res.data.map((row) => {
                const obj = { 
                    banner_id: row.banner_id, 
                    prod_id: row.prod_id, 
                    rank: row.rank, 
                    pricing_zone_name: row.pricing_zone_name, 
                    campaign_name: row.campaign_name 
                } 
                downloadBannersList.push(obj)
            })
            
            downloadExcelFile(downloadBannersList, 'Banners', 'csv')
        }

    }

    const deleteProdcutsFromExcel = async () => { 
        let uploadBannerExcelObj = []
        let invalidProdBannerObj = {}
        errorList.map(error => { 
            let splitedError = error.split(' ')
            let stringifiedProdIds = splitedError[4]
            let prodIdsList = stringifiedProdIds.split(',')
            let bannerId = splitedError[splitedError.length - 1]
            invalidProdBannerObj[bannerId] = [...prodIdsList.map(prod_id => parseInt(prod_id))]
        })
        console.log(invalidProdBannerObj);

        uploadBannerList.forEach((banner) => {
            let prodList = invalidProdBannerObj[banner.banner_id] ? invalidProdBannerObj[banner.banner_id] : []
            let isInvalid = prodList.includes(banner.prod_id);
            console.log(isInvalid, prodList.includes(banner.prod_id), banner.prod_id, banner.banner_id, prodList)
            if( !isInvalid ){
                uploadBannerExcelObj.push(banner);
            }
        })

        setUploadBannerList(uploadBannerExcelObj)
        seterrorpopup(false)
        setPopup(true)
        

    }


    const handleSearchText = (value) => {
        let timer = setTimeout(() => {
            searchBanner(value)
        }, 1000);
        clearTimeout(debounceTimer);
        setDebounceTimer(timer);
    }

    const getDate = (selDate) => {
        let date = selDate.substring(0, 2);
        let month = parseInt(selDate.substring(2, 4)) - 1;
        let year = '20' + selDate.substring(4, 6);
        // debugger;
        let urlDate = new Date(year, month, date)
        return urlDate
    }

    const selDate = (e) => {
        console.log(e)
        let selSlot = getTodaySlot(e);
        navigate(`/feed-content/${selSlot}/`)
    }


    useEffect(() => {
        updateList();
    }, [])

    return (
        <>
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Feed Content</span> </div>
                <div className={`flex`}>
                    <div className="d-none file_input_new">
                        <input type="file" onChange={(e) => loadWorksheet(e)} accept=".csv" className="mb-4 pull-left" id="upload_sms_field" />
                    </div>
                    <img className={`${style.mrg_x_6} ${style.height_width_30px}`} src={Downloadcsv} onClick={ downloadCsv} />
                    <img className={`${style.mrg_x_6} ${style.height_width_30px}`} src={Uploadcsv} onClick={uploadExcel} />
                    <div className={`textCenter ${style.mrg_x_6}`}>
                        <div className={`flex flex-justify-center`}>
                            <input
                                onChange={(e) => { handleSearchText(e.target.value) }}
                                // onChange={(e) => { searchBanner(e.target.value) }}
                                className={`${style.searchInput}`}
                                placeholder={'search Banner'}
                            />
                            <img className={`${style.searchIcon}`} src={SearchIcon}  />
                        </div>
                    </div>

                    <div className={`textCenter ${style.mrg_x_6}`}>
                        {/* <Calender setNslot={setNslot} /> */}
                        <DatePicker 
                            todayButton="Today"
                            onChange={(e) => selDate(e)} 
                            selected={getDate(slot)}
                            className={style.custom_date_picker}
                            dateFormat="dd MMMM yyyy"
                        />
                    </div>

                    {/* city dropdown */}
                    <Dropdown 
                        list={list}
                        name={selZone}
                        getDropDownValue={(elem) =>{
                            setselZone(elem);
                        }}
                    />

                    {/* content type dropdown */}
                    <Dropdown 
                        list={ContentType}
                        name={contentType}
                        getDropDownValue={(elem)=>{
                            setContentType(elem);
                        }}/>
                    <button className={`${style.btnM} ${style.filledBtn}`} onClick={AddBanner}>Feed Content</button>
                </div>
            </div>
            {/* Confirmation Popup */}
            {popup && <div>
                <PopUp width={formStyle.popupWidthS} title={'Upload CSV'} closeBtn={() => { setPopup(false) }} >
                    <div style={{ textAlign: 'center' }} className={`${formStyle.inputContainer} `}>
                        Are You sure you want to upload CSV ?
                    </div>
                    <div className={`${formStyle.btnContainer}`}>
                        <button onClick={uploadCsv} className={`${formStyle.confirmBtn}`}>Submit</button>
                    </div>
                </PopUp>
            </div>}

            {/* Error Popup */}
            {errorPopup && <div>
                <PopUp width={formStyle.popupWidthM} title={'Do you want to continue without these products'} closeBtn={() => { seterrorpopup(false) }} >
                    <div className={`${formStyle.inputContainer} `}>
                        {errorList.map((error) => {
                            return (
                                <div>
                                    {error}
                                </div>
                            )
                        })}
                    </div>
                    <div className={`${formStyle.btnContainer}`}>
                        <button onClick={() => { deleteProdcutsFromExcel() }} className={`${formStyle.confirmBtn}`}>Submit</button>
                    </div>
                </PopUp>
            </div>}

        </>
    );
}

export default Header;