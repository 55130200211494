import React from 'react';
import CampaignListing from './components/CampaignListing';
import Header from './components/Header';

function index(props) {
    return (
        <div>
            <Header/>
            <CampaignListing/>
        </div>
    );
}

export default index;