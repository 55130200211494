import React, { useEffect, useRef, useState } from 'react'
import style from '../css/Subscription.module.css'
import { discountType } from '../../../../utils/constData';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../../../commonComponent/Dropdown/Dropdown';
import LocationIcon from "../../../../icons/location.svg";
import { createSubscriptionProduct, deepCopyObject, editSubscriptionProduct, getAllOffertype, getOrderBannerZonewiseList, getSubscriptionProduct, searchSku, uploadBannerImage } from '../../../../utils/api';
import { SubscriptionImgUrl } from '../../../../utils/config';
import EditIcon from '../../../../icons/edit.svg';
import PopupAddSku from '../../../Marketing/CreateCampaign/components/PopupAddSku';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import PopupConfirmation  from './PopupConfirmation';

export default function ({ }) {
    const { status, selZone } = useParams()
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [selDiscountType, setSelDiscountType] = useState({ name: 'Select Discount' });
    const [selectedProd, setSelectedProd] = useState({});
    const [zoneList, setZoneList] = useState([]);
    const [isAllZoneSelected, setIsAllZoneSelected] = useState(false);
    const [selectedZoneList, setSelectedZoneList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [selectedType, setSelectedType] = useState({})
    const [showAddSkuPopup, setAddSkuPopup] = useState(false);
    const [popup, setPopup] = useState(false);
    const [confirmationPopup, setConfirmationPopup ] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const rankRef = useRef();
    const discountRef = useRef();
    const startDayRef = useRef();
    const subscriptionPriceRef = useRef();
    const [images, setImages] = useState({
        banner_image: '',
        success_image: '',
        new_app_banner_image: '',
        new_subs_banner_image: '',
    })
    const [Subscriptiondata, setSubscriptiondata] = useState({
        prod_id: '',
        subscription_discount: 0,
        is_active: false,
        is_monthly_pack: false,
        is_trial_pack: false,
        monthly_pack_days: 0,
        start_day: '',
        banner_image: '',
        success_image: '',
        discount_type: '',
        subscription_price: 0,
        rank: '',
        unit_price: 0,
        new_app_banner_image: '',
        new_subs_banner_image: '',
        zone_list: [],
        subs_offer_type: 1,
    })
    const navigate = useNavigate()

    const selectZone = (zoneElement) => {
        console.log(zoneElement);
        var all_zone_data = [...zoneList]
        let selectedZones = [...selectedZoneList]
        if (zoneElement.target.checked) {
            const updatedZoneList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = true;
                    selectedZones.push(zone.id);
                }
                return zone;
            });
            if (selectedZones.length === zoneList.length) {
                setIsAllZoneSelected(true);
            }

            setSelectedZoneList(selectedZones)
            setZoneList(updatedZoneList)

        }

        else {
            const updatedList = all_zone_data.map((zone) => {
                if (zone.id === parseInt(zoneElement.target.id)) {
                    zone.is_selected = false;
                }
                return zone;
            });

            selectedZones = selectedZoneList.filter((zone) => zone !== parseInt(zoneElement.target.id))
            setSelectedZoneList(selectedZones)
            setZoneList(updatedList)
            setIsAllZoneSelected(false);
        }

        let obj = Subscriptiondata;
        obj.zone_list = selectedZones;
        setSubscriptiondata({ ...obj })
    }

    const selectAllZone = (event) => {
        let selected_zones = []
        if (event.target.checked) {
            const pricing_zones = zoneList.map(zoneElement => {
                zoneElement.is_selected = true;
                selected_zones.push(zoneElement.id)
                return zoneElement;

            })
            setSelectedZoneList(selected_zones);
            setIsAllZoneSelected(true);
            setZoneList(pricing_zones)

        } else {
            const pricing_zone = zoneList.map(zoneElement => {
                zoneElement.is_selected = false;
                return zoneElement;

            })
            setSelectedZoneList([]);
            setIsAllZoneSelected(false);
            setZoneList(pricing_zone)

        }

        let obj = Subscriptiondata;
        obj.zone_list = selected_zones;
        setSubscriptiondata({ ...obj })
    }

    const handleInputChange = (e) => {
        let obj = Subscriptiondata;
        obj[e.target.name] = e.target.value;
        setSubscriptiondata({ ...obj });
    }

    const handleCheckBoxChange = (e) => {
        let obj = Subscriptiondata;
        obj[e.target.name] = e.target.checked;

        if (e.target.name == 'is_trial_pack' && e.target.checked) {
            obj['is_monthly_pack'] = e.target.checked;
        }
        if (!obj['is_monthly_pack' || 'is_trial_pack']) {
            obj['monthly_pack_days'] = 0
            // console.log('monthly',obj['is_monthly_pack'], 'trial', obj['is_monthly_pack'], 'days',obj['monthly_pack_days'])
        }
        setSubscriptiondata({ ...obj });
    }

    const handleRadioChange = (e) => {
        let obj = Subscriptiondata;
        obj.is_monthly_pack = false;
        obj.is_trial_pack = false;

        obj[e.target.value] = true;

        console.log('obj', obj);
        setSubscriptiondata({ ...obj });

        console.log('Subscriptiondata', Subscriptiondata);
    }


    const addSkuForDeal = (sku) => {
        setAddSkuPopup(false);
        setSelectedProd(sku);
        let obj = Subscriptiondata;
        obj.prod_id = sku.prod_id;
    }

    const openAddSkuPopup = () => {
        if (selectedZoneList.length === 0) {
            ErrorHandling("Please Select Zone First");
        } else {
            setAddSkuPopup(true);
        }
    }


    const setDiscounType = (value) => {
        setSelDiscountType(value);
        let obj = Subscriptiondata;
        obj.discount_type = value.id
        setSubscriptiondata({ ...obj });
    }

    const setOfferType = (val) => {
        setSelectedType(val);
        let obj = Subscriptiondata;
        obj.subs_offer_type = val.id;
        setSubscriptiondata({ ...obj })
    }

    const editOfferText = async () => {

        localStorage.setItem('productData', JSON.stringify(Subscriptiondata));
        localStorage.setItem('id', Subscriptiondata.zone_list[0]);
        navigate(`/${status}/${selectedType.id}/offer-type/`)
    }


    const uploadImg = (id) => {
        let img = document.getElementById(id);
        img.click();
    };

    const saveFile = (e) => {
        let obj = images;
        obj[e.target.name] = e.target.files[0];
        setImages({ ...obj });
    }

    const uploadImage = async (e) => {
        var image_type = 'subscription';
        let Imgdata = new FormData();
        Imgdata.append('file', images[e.target.id]);
        Imgdata.append('image_type', image_type)
        if (images[e.target.id] === '') {
            ErrorHandling('Please select the image')
        } else {
            if (e.target.id === 'new_subs_banner_image') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 540 && img.width === 750) {
                        let res = await uploadBannerImage(Imgdata)
                        if (res.success) {
                            let obj = Subscriptiondata;
                            obj[e.target.id] = res.image_id;
                            console.log(obj);
                            setSubscriptiondata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(images[e.target.id]);
            } else if (e.target.id === 'new_app_banner_image') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 400 && img.width === 750) {
                        let res = await uploadBannerImage(Imgdata)
                        if (res.success) {
                            let obj = Subscriptiondata;
                            obj[e.target.id] = res.image_id;
                            console.log(obj);
                            setSubscriptiondata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(images[e.target.id]);
            } else if (e.target.id === 'new_subs_banner_image') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 540 && img.width === 750) {
                        let res = await uploadBannerImage(Imgdata)
                        if (res.success) {
                            let obj = Subscriptiondata;
                            obj[e.target.id] = res.image_id;
                            console.log(obj);
                            setSubscriptiondata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = img.src = URL.createObjectURL(images[e.target.id]);
            } else if (e.target.id === 'banner_image') {
                let img = new Image;
                img.onload = async function () {
                    if (img.height === 750 && img.width === 750) {
                        let res = await uploadBannerImage(Imgdata)
                        if (res.success) {
                            let obj = Subscriptiondata;
                            obj[e.target.id] = res.image_id;
                            console.log(obj);
                            setSubscriptiondata({ ...obj })
                        } else {
                            ErrorHandling(res.error.message)
                        }
                    } else {
                        ErrorHandling(`Aspect ratio doesn't match`)
                    }
                }
                img.src = URL.createObjectURL(images[e.target.id]);
            } else {
                let res = await uploadBannerImage(Imgdata)
                if (res.success) {
                    let obj = Subscriptiondata;
                    obj[e.target.id] = res.image_id;
                    console.log(obj);
                    setSubscriptiondata({ ...obj })
                } else {
                    ErrorHandling(res.error.message)
                }
            }
        }
    }


    const validationSubscription = function () {
        if (Subscriptiondata.monthly_pack_days) {
            Subscriptiondata.monthly_pack_days = parseInt(Subscriptiondata.monthly_pack_days);
        }
        // debugger;
        setIsSubmitButtonClicked(true);
        if (Subscriptiondata.zone_list.length == 0) {
            setErrorList([{ loc: ["", "Zones"], msg: 'Please Select Zone' }])
            setPopup(true);
        } else if (Subscriptiondata.is_monthly_pack && (Subscriptiondata.unit_price === '' || Subscriptiondata.unit_price === undefined || Subscriptiondata.unit_price < 0)) {
            setErrorList([{ loc: ["", "Unit Price"], msg: 'Please Add a valid unit price value' }])
            setPopup(true);
        } else if (Subscriptiondata.is_monthly_pack && Subscriptiondata.subscription_price <= 0) {
            setErrorList([{ loc: ["", "Subscription Price"], msg: 'Please Add valid Subscription Price' }])
            setPopup(true);
        } else if (Subscriptiondata.subscription_discount < 0) {
            setErrorList([{ loc: ["", "Subscription Discount"], msg: 'Please Add a valid discount value' }])
            setPopup(true);
        } else if (Subscriptiondata.start_day < 0) {
            setErrorList([{ loc: ["", "Start Day"], msg: 'Please Enter A valid start day' }])
            setPopup(true);
        } else if ((Subscriptiondata.discount_type !== '') && (Subscriptiondata.subscription_discount === undefined || Subscriptiondata.subscription_price < 0)) {
            setErrorList([{ loc: ["", "Discount Type"], msg: 'Please Add Subscription Discount greater than 0' }])
            setPopup(true);
        } else if (Subscriptiondata.subscription_price < 0) {
            setErrorList([{ loc: ["", "Subscription Price"], msg: 'Please Add valid Subscription Price' }])
            setPopup(true);
        } else if (!Subscriptiondata.prod_id || Subscriptiondata.prod_id == undefined) {
            setErrorList([{ loc: ["", "Product"], msg: 'Please Select Product' }])
            setPopup(true);
        } else if (!Subscriptiondata.banner_image || Subscriptiondata.banner_image == undefined) {
            setErrorList([{ loc: ["", "Banner Image"], msg: 'Please Add Banner Image (OLD APP)' }])
            setPopup(true);
        } else if (!Subscriptiondata.success_image || Subscriptiondata.success_image == undefined) {
            setErrorList([{ loc: ["", "Success Image"], msg: 'Please Add  ACTIVATED SCREEN BANNER Image (OLD APP)' }])
            setPopup(true);
        } else if (!Subscriptiondata.new_app_banner_image || Subscriptiondata.new_app_banner_image == undefined) {
            setErrorList([{ loc: ["", "New Banner"], msg: 'Please Add NEW BANNER (FREE MEMBERSHIP APP)' }])
            setPopup(true);

        } else if (!Subscriptiondata.new_subs_banner_image || Subscriptiondata.new_subs_banner_image == undefined) {
            setErrorList([{ loc: ["", "New Subscription Banner"], msg: 'Please Add NEW SUBSCRIPTION BANNER (FREE MEMBERSHIP APP)' }])
            setPopup(true);

        } else {
            status == 'new' ? 
                createBannerData() : 
                status == 'edit-all' ? 
                setConfirmationPopup(true) :
                editBannerData()
        }
    }

    const createBannerData = async () => {
        let res = await createSubscriptionProduct(Subscriptiondata);
        if (res.success) {
            SuccessHandling('Subscription Product created successfully');
            navigate('/subscription')
        } else {
            if (res.error.status_code === 422) {
                setErrorList(res.detail)
            } else {
                setErrorList([{ loc: ["", "Error"], msg: res.error.message }])
            }
            setPopup(true);
        }
    }

    const editBannerData = async () => {

        localStorage.removeItem('productData')

        let data = deepCopyObject(Subscriptiondata)
        if(status === 'edit'){
            data.zone_list = [parseInt(selZone)]
        }

        let res = await editSubscriptionProduct(data);
        if (res.success) {
            SuccessHandling('Subscription Product updated successfully');
            navigate('/subscription')
        } else {
            if (res.error.status_code === 422) {
                setErrorList(res.detail)
            } else {
                setErrorList([{ loc: ["", "Error"], msg: res.error.message }])
            }
            setPopup(true);
        }
    }

    const fetchData = async () => {
        // for gettig the zone list
        let resListing = await getOrderBannerZonewiseList();
        let typeList = await getAllOffertype();
        setTypeList(typeList.data.data);
        setSelectedType(typeList.data.data[0]);

        if (status === "editOld") {
            if (localStorage.getItem('productData')) {
                let data = await JSON.parse(localStorage.getItem('productData'));
                let skuData = await searchSku(selZone, data.prod_id);
                setSelectedProd(skuData[0])
                setSelDiscountType(discountType.filter((discount) => discount.id === data.discount_type)[0]);
                //setSelectedType(typeList.data.data.filter((type) => type.id === data.subs_offer_type)[0]);
                setSelectedZoneList([data.pricing_zone_id]);
                delete data.prod_name;
                delete data.small_image_id;
                data.zone_list = [parseInt(selZone)];
                delete data.pricing_zone_id;
                setSubscriptiondata({ ...data });
                let selectedZones = [...resListing.data];

                let obj = {};
                obj.banner_image = data.banner_image;
                obj.success_image = data.success_image;
                obj.new_app_banner_image = data.new_app_banner_image;
                obj.new_subs_banner_image = data.new_subs_banner_image;
                setImages({ ...obj })
                selectedZones.forEach((zone) => {
                    if (parseInt(selZone) === zone.id) {
                        zone.is_selected = true;
                    }
                })

                setZoneList(selectedZones)



            } else {
                setZoneList(resListing.data);
            }
        } else if (status === "edit-all" || status === "edit") {
            // console.log(localStorage);
            const res = await getSubscriptionProduct(localStorage.getItem('Prod_id'), selZone)
            let data = res.data.data[0];

            console.log('data', data);
            //let skuData = await searchSku(selZone, data.prod_id);

            //console.log('skuData', skuData);
            setSelectedProd(data)
            setSelDiscountType(discountType.filter((discount) => discount.id === data.discount_type)[0]);
            setSelectedType(typeList.data.data.filter((type) => type.id === data.subs_offer_type)[0]);
            setSubscriptiondata({ ...data })
            setSelectedZoneList(data.zone_list);
            delete data.prod_id

            let obj = {};
            obj.banner_image = data.banner_image;
            obj.success_image = data.success_image;
            obj.new_app_banner_image = data.new_app_banner_image;
            obj.new_subs_banner_image = data.new_subs_banner_image;
            setImages({ ...obj })
            let selectedZones = [...resListing.data];
            for (let i = 0; i < data.zone_list.length; i++) {
                selectedZones.forEach((zone) => {
                    if (data.zone_list[i] === zone.id) {
                        zone.is_selected = true;
                    }
                })
            }
            setZoneList(selectedZones)

            if (data.zone_list.length === resListing.data.length) {
                setIsAllZoneSelected(true);
            }

        } else {

            if (localStorage.getItem('productData')) {
                let data = await JSON.parse(localStorage.getItem('productData'));
                if (data.zone_list.length > 0) {
                    let skuData = await searchSku(data.zone_list[0], data.prod_id);
                    setSelectedProd(skuData[0])
                }
                if (data.discount_type) {
                    setSelDiscountType(discountType.filter((discount) => discount.id === data.discount_type)[0]);
                }
                setSelectedType(typeList.data.data.filter((type) => type.id === data.subs_offer_type)[0]);
                setSelectedZoneList(data.zone_list)
                let selectedZones = [...resListing.data];
                for (let i = 0; i < data.zone_list.length; i++) {
                    selectedZones.forEach((zone) => {
                        if (data.zone_list[i] === zone.id) {
                            zone.is_selected = true;
                        }
                    })
                }
                setZoneList(selectedZones)
                localStorage.removeItem('productData')
                setSubscriptiondata({ ...data });



            } else {
                setZoneList(resListing.data);
            }
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            {/* header */}
            <div className={`flex flex-space-between ${style.header}`}>
                <div className={`${style.header_title}`}>
                    Crofarm ERP |{" "}
                    <span className={`bold green`}>Subscription</span>{" "}
                </div>
            </div>
            <div className={`formWrapper`}>

                {/* page heading */}
                <div className={`${style.createWidget}`}>{status !== "new" ? "Update" : "Create"} Subscription {status !== 'new' ? ` | ${selectedProd.prod_name}` : ''}</div>

                <div className={`${style.addContainer}`}>

                    {/* first section - input and checkboxes */}
                    <div className={`${style.separator}`}>
                        {/* is active, campaign name, tag and rank */}
                        <div className={`${style.grid_template_3col}`}>

                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input type="checkbox" name="is_active" checked={Subscriptiondata.is_active} disabled={status === 'edit-all'} onChange={(e) => { handleCheckBoxChange(e) }} />
                                <span>Is Active</span>
                            </div>
                            {/* checkbox for monthly and trial pack  */}
                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input type="checkbox" name="is_monthly_pack" checked={Subscriptiondata.is_monthly_pack} disabled={status === 'edit-all' || Subscriptiondata.is_trial_pack} onChange={(e) => { handleCheckBoxChange(e) }} />
                                <span>Is Monthly Pack</span>
                            </div>
                            
                            <div className={`${style.isActiveCheckbox} ${style.width_50}`}>
                                <input type="checkbox" name="is_trial_pack" checked={Subscriptiondata.is_trial_pack} disabled={status === 'edit-all'} onChange={(e) => { handleCheckBoxChange(e) }} />
                                <span>Is Trial Pack</span>
                            </div>

                            {/* 
                            <div className='flex' onChange={(e) => { handleRadioChange(e) }}>                              
                                <input type="radio" value="is_monthly_pack" name='packtype' className={`${style.customradio}`} defaultChecked={Subscriptiondata.is_monthly_pack} disabled={status === 'edit-all'}/>
                                <span className={`${style.checkbox_title}`}>Is Monthly Pack</span>
                            </div>
                            <div className='flex' onChange={(e) => { handleRadioChange(e) }}>                              
                                <input type="radio" value="is_trial_pack" name='packtype' className={`${style.customradio}`} defaultChecked={Subscriptiondata.is_trial_pack} disabled={status === 'edit-all'}/>
                                <span className={`${style.checkbox_title}`}>Is Trial Pack</span>
                            </div>
                            */}


                        </div>
                        {(Subscriptiondata.is_monthly_pack || Subscriptiondata.is_trial_pack) && <div className={`${style.grid_template_2col} ${style.mrg_top_3}`}>

                            <div className={`${style.width_50} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Monthly Pack Validation Days</div>
                                <input className={`${isSubmitButtonClicked && (Subscriptiondata.monthly_pack_days < 0 || Subscriptiondata.monthly_pack_days === '') ? 'outlineRed' : ''}  
                                ${style.input_field}`} name='monthly_pack_days' min={0} type='number'
                                    placeholder='Monthly pack valid days' value={Subscriptiondata.monthly_pack_days} ref={discountRef} onWheel={() => { discountRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                                {isSubmitButtonClicked && (Subscriptiondata.monthly_pack_days < 0 || Subscriptiondata.monthly_pack_days === '') && <div className={`errorMessage`}>* Please Enter Valid days value</div>}
                            </div>

                            <div className={`${style.width_50} ${style.margin_1rem} `}>
                                <div className={`${style.title}`}>Unit Price </div>
                                <input className={`${style.input_field}`} name='unit_price' type='number' placeholder='Unit Price' min={0} value={Subscriptiondata.unit_price} ref={rankRef} onWheel={() => { rankRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                                {isSubmitButtonClicked && (Subscriptiondata.unit_price === '') && <div className={`errorMessage`}>* Please Enter Valid Unit Price</div>}
                            </div>

                        </div>

                        }



                        <div className={`${style.grid_template_3col} ${style.mrg_top_3}`}>

                            <div className={`${style.width_50} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Discount {Subscriptiondata.is_monthly_pack ? 'disablled for monthly pack' : ''}</div>
                                <input className={`${isSubmitButtonClicked && (Subscriptiondata.subscription_discount < 0 || Subscriptiondata.subscription_discount === '') ? 'outlineRed' : ''} 
                                ${style.input_field}`} name='subscription_discount' min={0} type='number'
                                    disabled={(Subscriptiondata.subscription_price > 0 || Subscriptiondata.is_monthly_pack || Subscriptiondata.is_trial_pack) && selDiscountType.id==1 }
                                    placeholder='Discount' value={Subscriptiondata.subscription_discount} ref={discountRef} onWheel={() => { discountRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                                {isSubmitButtonClicked && (Subscriptiondata.subscription_discount < 0 || Subscriptiondata.subscription_discount === '') && <div className={`errorMessage`}>* Please Enter Valid Discount value</div>}
                            </div>

                            <div className={`${style.width_50} ${style.margin_3rem} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Start Day</div>
                                <input className={`${style.input_field}`} name='start_day' type='number' min={0} placeholder='Start Day' value={Subscriptiondata.start_day} ref={startDayRef} onWheel={() => { startDayRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                                {isSubmitButtonClicked && (Subscriptiondata.start_day < 0 || Subscriptiondata.start_day === '') && <div className={`errorMessage`}>* Please Enter Valid strat day</div>}
                            </div>

                            <div className={`${style.margin_3rem} ${style.margin_1rem}`}>
                                <span className={`${style.land_page}`}>Discount Type</span>
                                <Dropdown name={selDiscountType} list={discountType} getDropDownValue={setDiscounType} height={'4.25rem'} />
                                {isSubmitButtonClicked && (selDiscountType.id === undefined) && <div className={`errorMessage`}>* Please select discount type</div>}
                            </div>
                        </div>
                    </div>

                    {/* dropdowns - content type, landing screen */}
                    <div className={`${style.grid_template_3col} ${style.mrg_top_3} ${style.separator}`}>

                        <div className={`${style.width_50} ${style.margin_1rem}`}>
                            <div className={`${style.title}`}>Subscription price</div>
                            <input className={`${style.input_field}`} name='subscription_price' type='number' min={0}
                                disabled={Subscriptiondata.subscription_discount > 0}
                                placeholder='Subscription Price' value={Subscriptiondata.subscription_price} ref={subscriptionPriceRef} onWheel={() => { subscriptionPriceRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                            {isSubmitButtonClicked && (Subscriptiondata.subscription_price < 0 || Subscriptiondata.subscription_price === '') && <div className={`errorMessage`}>* Please Enter Valid strat day</div>}
                        </div>

                        <div className={`${style.width_50} ${style.margin_1rem} `}>
                            <div className={`${style.title}`}>Rank</div>
                            <input className={`${style.input_field}`} name='rank' type='number' placeholder='Rank' min={0} value={Subscriptiondata.rank} ref={rankRef} onWheel={() => { rankRef.current.blur() }} onChange={(e) => { handleInputChange(e) }} />
                            {isSubmitButtonClicked && (Subscriptiondata.rank === '') && <div className={`errorMessage`}>* Please Enter Valid strat day</div>}
                        </div>

                    </div>

                    <div className={`${style.feed_type_wrapper} ${style.mrg_top_3} ${style.separator}`}>
                        {typeList.length > 0 && <div className={`${style.offerTypeContainer}`}>
                            <span className={`${style.land_page}`}>OfferType</span>
                            <Dropdown name={selectedType} list={typeList} getDropDownValue={setOfferType} height={'10rem'} />
                            <div>
                                <div className={`${style.banner_wrapper}`}>
                                    <img src={selectedType.bg_image ? SubscriptionImgUrl + selectedType.bg_image : null} className={`${style.offer_type_img}`} onClick={() => { editOfferText(); }} />
                                    <div className={`${style.heading_1}`}>
                                        {selectedType.heading_1}
                                    </div>
                                    <div className={`${style.heading_2}`}>
                                        {selectedType.heading_2}
                                    </div>
                                    <div className={`fontM ${style.mrg_x_6}`}> To edit details of offer type click on the image *</div>
                                </div>
                            </div>
                        </div>}


                        {status === 'new' && <div className={`${style.margin_1rem} `} style={{ gridColumn: '1 / span 2' }}>
                            {!selectedProd.id &&
                                <>
                                    <div className={`${style.title}`}>Add SKUs for this banner</div>
                                    <button className={`${style.filledBtn} `} onClick={() => { openAddSkuPopup() }}>Add Skus (+)</button>
                                </> ||

                                <>
                                    <li className={`grid ${style.grid_template_sku} ${style.sku_row}`}>
                                        <div className={style.pd_bg} style={{ float: 'left' }}>
                                            <img className={`${style.sku_img}`} src={'https://img.crofarm.com/images/prodsmall/' + selectedProd.image_id} />
                                        </div>
                                        <div className={style.pd_bg}>
                                            <strong className={style.product_id}>[{selectedProd.prod_id}] </strong>
                                            <span className={style.prod_name}>{selectedProd.prod_name} </span>
                                            <span className={`labelGrey`}>( Pack: {selectedProd.pack_qt} )</span>
                                        </div>
                                        <span className={style.pd_bg}><img style={{ height: '20px' }} src={EditIcon} onClick={() => openAddSkuPopup()} /></span>
                                    </li>
                                </>

                            }

                            {isSubmitButtonClicked && (Subscriptiondata.prod_id === '' || Subscriptiondata.prod_id === undefined) && <div className={`errorMessage`}>* Please Select a product</div>}
                        </div>}


                    </div>


                    {/* zone section */}
                    {status !== 'edit' && <div className={`${style.separator}`}>
                        {/* zone listing header*/}
                        <div className={`${style.mrg_top_3}`}>
                            <img width="24px" height="24px" src={LocationIcon} style={{ marginBottom: "-9px", marginRight: "7px" }} />
                            <span className={`${style.zone_heading}`}>Select Zones</span>
                        </div>

                        {/* zone listing check boxes */}
                        <div className={`flex flex-sace-between flex-wrap`}>

                            {/* zone check boxes */}
                            <label onClick={() => { }} className={`${style.list_item}`}>
                                <input type="checkbox" className={`${style.checkbox}`} checked={isAllZoneSelected} onChange={(e) => { selectAllZone(e) }} />
                                <span className={`${style.checkmark}`}>All</span>
                            </label>

                            {
                                zoneList.map((city, index) => {
                                    return (
                                        <label key={city.id} onClick={() => { }} className={`${style.list_item}`}>
                                            <input type="checkbox" className={`${style.checkbox}`} id={city.id} checked={city.is_selected} onChange={(e) => { selectZone(e) }} />
                                            <span className={`${status === 'edit-all' && !city.is_selected ? 'red' : ''} ${style.checkmark}`}>{city.name}</span>
                                        </label>
                                    )
                                })
                            }
                        </div>
                        {isSubmitButtonClicked && (Subscriptiondata.zone_list.length === 0) && <div className={`errorMessage`}>* Please Select Zone(s)</div>}
                    </div>}



                    {/* condiiton section based on dropdowns */}
                    <div className={`${style.mrg_top_3}`}>

                        <div className={`grid grid-justify-center ${style.grid_template_images}`}>

                            <div className={`${style.mrg_x_6} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Upload Banner (old app)</div>
                                <input type="file" name="banner_image" id="banner_image" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                <img src={Subscriptiondata.banner_image ? `${SubscriptionImgUrl}${Subscriptiondata.banner_image}` : null} className={`${style.imgContainer}`} onClick={() => { uploadImg("banner_image"); }} />
                                <div className={`fonts ${style.mrg_y_6} ${images.banner_image !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{images.banner_image ? images.banner_image.name : 'please select the image'}</div>
                                <div className={`fontS gray`}> * ASPECT RATIOS SHOULD BE (750 x 750)px </div>
                                <button id='banner_image' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                {isSubmitButtonClicked && (Subscriptiondata.banner_image === '' || Subscriptiondata.banner_image === undefined) && <div className={`errorMessage`}>* Please upload Banner Image (OLD APP)</div>}
                            </div>

                            <div className={`${style.mrg_x_6} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Upload Activated screen banner (Old app)</div>
                                <input type="file" name="success_image" id="success_image" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                <img src={Subscriptiondata.success_image ? `${SubscriptionImgUrl}${Subscriptiondata.success_image}` : null} className={`${style.imgContainer}`} onClick={() => { uploadImg("success_image"); }} />
                                <div className={`fonts ${style.mrg_y_6} ${images.success_image !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{images.success_image ? images.success_image.name : 'please select the image'}</div>
                                <div className={`fontS gary`}> * ASPECT RATIOS SHOULD BE (686 x 260)px </div>
                                <button id='success_image' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                {isSubmitButtonClicked && (Subscriptiondata.success_image === '' || Subscriptiondata.success_image === undefined) && <div className={`errorMessage`}>* Please Upload  ACTIVATED SCREEN BANNER Image (OLD APP)</div>}
                            </div>

                            <div className={`${style.mrg_x_6} ${style.margin_1rem}`}>
                                <div className={`${style.title}`}>Upload New Banner (Free membership app)</div>
                                <input type="file" name="new_app_banner_image" id="new_app_banner_image" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                <img src={Subscriptiondata.new_app_banner_image ? `${SubscriptionImgUrl}${Subscriptiondata.new_app_banner_image}` : null} className={`${style.imgContainer}`} onClick={() => { uploadImg("new_app_banner_image"); }} />
                                <div className={`fonts ${style.mrg_y_6} ${images.new_app_banner_image !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{images.new_app_banner_image ? images.new_app_banner_image.name : 'please select the image'}</div>
                                <div className={`fontS gray`}> * ASPECT RATIOS SHOULD BE (750 x 400)px </div>
                                <button id='new_app_banner_image' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                {isSubmitButtonClicked && (Subscriptiondata.new_app_banner_image === '' || Subscriptiondata.new_app_banner_image === undefined) && <div className={`errorMessage`}>* Please Upload NEW BANNER (FREE MEMBERSHIP APP)</div>}
                            </div>
                        </div>

                        <div className={`grid grid-justify-center ${style.grid_template_images}`}>
                                <div className={`${style.mrg_x_6} ${style.margin_1rem}`} style={{ margin: "3rem" }}>
                                    <div className={`${style.title}`}>Upload New Subscription Banner (Free membership app)</div>
                                    <input type="file" name="new_subs_banner_image" id="new_subs_banner_image" accept="image/*" style={{ display: "none" }} onChange={(e) => saveFile(e)} />
                                    <img src={Subscriptiondata.new_subs_banner_image ? `${SubscriptionImgUrl}${Subscriptiondata.new_subs_banner_image}` : null} className={`${style.imgContainer}`} onClick={() => { uploadImg("new_subs_banner_image"); }} />
                                    <div className={`fonts ${style.mrg_y_6} ${images.new_subs_banner_image !== '' ? 'green' : 'red'}`}><span>Selected Image : </span>{images.new_app_banner_image ? images.new_subs_banner_image.name : 'please select the image'}</div>
                                    <div className={`fontS gray`}> * ASPECT RATIOS SHOULD BE (750 x 540)px </div>
                                    <button id='new_subs_banner_image' className={`${style.uploadBtn}`} onClick={(e) => { uploadImage(e) }}>Upload</button>
                                    {isSubmitButtonClicked && (Subscriptiondata.new_subs_banner_image === '' || Subscriptiondata.new_subs_banner_image === undefined) && <div className={`errorMessage`}>* Please Upload NEW SUBSCRIPTION BANNER (FREE MEMBERSHIP APP)</div>}
                                </div>

                            </div>


                    </div>

                    {/* btn container */}
                    <div className={`textCenter ${style.mrg_top_3} ${style.btnCenter}`}>
                        <button onClick={() => { localStorage.removeItem('productData'); navigate('/subscription/') }} className={`${style.outlinedBtn}`}> Cancel</button>
                        <button className={`${style.filledBtn} `} onClick={validationSubscription}>
                            {status !== "new" ? "Update" : "Add"} Subscription
                        </button>
                    </div>

                    {/* Right section - image section */}

                </div>
            </div>

            {showAddSkuPopup && <PopupAddSku
                zoneList={zoneList}
                dealType={`Banner`}
                burnSkuList={[]}
                nonBurnSkuList={[]}
                closeBtn={() => { setAddSkuPopup(false) }}
                addSkuForDeal={addSkuForDeal}
            />}

            {
                confirmationPopup && 
                <PopupConfirmation 
                    closeBtn={() => setConfirmationPopup(false)}
                    selZoneList={zoneList.filter( city => city.is_selected)}
                    onSubmit={editBannerData}
                />

            }

            {popup && <div>
                <PopUp width={formStyle.popupWidthS} title={'Errors'} closeBtn={() => { setPopup(false) }} >
                    <div className={`${formStyle.inputContainer}`}>
                        {errorList.map((err, idx) => {
                            return <div className={`${style.pd_bg1} red`}>{`${idx + 1}. ${err.loc[1]} : ${err.msg}`}</div>
                        })}
                    </div>
                </PopUp>
            </div>}

        </>
    );
}
