import React, { useEffect, useState } from "react";
import { searchUserSegment } from "../../../../utils/api";
import PopUp from "../../../../commonComponent/PopUp/PopUp";
import formStyle from "../../../../commonComponent/PopUp/Form.module.css";
import popupStyle from "../../../../commonComponent/PopUp/Popup.module.css";
import { imgUrl } from "../../../../utils/config";
import { SEGMENT_STATUS } from "../../../../utils/constData";

function PopupAddSegment(props) {
  const [searchQuery, setSearchQuery] = useState("");
  const [skuList, setSkuList] = useState([]);
  const [skuIdList, setSkuIdList] = useState([]);

  const searchProd = async () => {
    let list = await searchUserSegment(searchQuery);
    if(list.success){
      list = list.data
      // setting segment status read values
      list.map((segment) => {
        segment.status_name = SEGMENT_STATUS[segment.status].name;
        segment.status_color = SEGMENT_STATUS[segment.status].color;
      });
    }
    else{
       list = []
    }
    setSkuList(list);
  };

  const AddSku = (sku, isChecked) => {
    if (isChecked === true) {
      let list = skuIdList;
      let obj = { is_checked: isChecked, id: sku.id };
      list.push(obj);
      setSkuIdList([...list]);

      props.addSkuForDeal(sku);
    } else {
      let list = skuIdList.filter((item) => item.id !== sku.id);
      setSkuIdList([...list]);
      props.removeSkuForDeal(sku.id);
    }
  };
  useEffect(() => {
    // if(searchQuery){
    // apiCall
    searchProd();
    setSkuIdList([...props.list]);
    // }
    // else{
    //     // set skuList empty
    //     setSkuList([])
    // }
    document.getElementById("searchInput").focus();
  }, [searchQuery]);

  const checkCheckedBox = (id) => {
    let filterList =
      props.list.filter((item) => {
        return item.id === id;
      }) || [];

    return filterList?.length > 0;
  };
  return (
    <PopUp
      title={`Add Segment for ${props.dealType}`}
      width={`${formStyle.popupWidthM}`}
      closeBtn={props.closeBtn}
    >
      {skuIdList.length > 0 && (
        <div className={`${popupStyle.heading} textLeft`}>
          {skuIdList.map((sku) => {
            return <span>{sku.id}, </span>;
          })}
        </div>
      )}
      <div className={`${formStyle.inputContainer}`}>
        {/* input */}
        <div>
          <input
            className={`${formStyle.searchBar} ${formStyle.fullwidth}`}
            id={"searchInput"}
            type={"text"}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder={"Search segment"}
          />
        </div>
        {/* skuList */}
        <div
          className={`${formStyle.overlay_36} ${formStyle.custom_scrollbar}`}
        >
          {skuList?.map((sku) => {
            return (
              <div
                className={` ${
                  sku.status_name == "ACTIVE"
                    ? formStyle.greenBackgroundColor
                    : formStyle.redBackgroundColor
                }  ${formStyle.sku_list}`}
                key={sku.id}
              >
                <div
                  className={`${formStyle.flex_row} ${formStyle.justify_center}`}
                >
                  <div className={`${formStyle.flex_row}  ${formStyle.flex2}`}>
                    <div className={formStyle.flex_col}>
                      <input
                        className="pointer"
                        type="checkbox"
                        checked={checkCheckedBox(sku.id)}
                        onChange={(e) => AddSku(sku, e.target.checked)}
                        disabled={sku.status_name !== "ACTIVE"}
                      />{" "}
                      {/* {!sku.is_active && (
                        <span className={formStyle.red_color}>InActive</span>
                      )} */}
                    </div>
                    <div className={`${formStyle.flex_col}`}>
                      <strong>id: {sku.id}</strong>
                      <strong>Name: {sku.name}</strong>
                    </div>
                  </div>
                  <div className={`${formStyle.flex1} `}>
                    <strong>description: {sku.description}</strong>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PopUp>
  );
}

export default PopupAddSegment;
