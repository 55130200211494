import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { ErrorHandling, SuccessHandling } from '../../../../State';
import { deleteSmsCampaign, downloadExcelFile, downloadUserList, getCommunicationListing } from '../../../../utils/api'
import { ReactComponent as DeleteIcon }  from '../../../../icons/bin.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import style from '../css/CampaignList.module.css'
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import { EpochTimeInHumanLan } from '../../../../utils/TimeManager';
import Loader from '../../../../commonComponent/Loader/Loader';


export default function CampaignListing({ Nslot, filter }) {
    const [campaignList, setCampaignList] = useState([]);
    const [popup, setPopup] = useState(false);
    const [campInfo, setCampInfo] = useState({});
    const [isLoading, setLoader] = useState(false);
    const navigate = useNavigate();


    const downloadCampaignUserList = async(id) => {
        setLoader(true)
        let list = await downloadUserList(id);
        list = JSON.parse(list);

        let consumerIdList = [];
        list.map((id) => {
            consumerIdList.push({
                consumer_id: id,
            })
        })

        if(!consumerIdList.length){
            consumerIdList=[{'consumer_id': ''}]
        }
        
        downloadExcelFile(consumerIdList, `campaign_${id}_consumer_list`, 'csv')
        setLoader(false)
    }

    const getList = async (Nslot, filter) => {
        setLoader(true)
        let res = await getCommunicationListing(filter,1, Nslot);
        setLoader(false)

        console.log('res', res.campaigns_list)
        setCampaignList(res.campaigns_list);
    }

    const editCloneCampaign = (id, type) => {
        navigate(`/communication/${type}-campaign/${id}/`)
    }

    const activateCampaign = async (campaign_id) => {
        let res = await deleteSmsCampaign(campaign_id);
        getList(Nslot, filter);
        setPopup(false)
        if (res.success) {
            SuccessHandling(res.data.message)
        } else {
            ErrorHandling(res.error.message)
        }
    }

    const handleDelete = (selCamp, value) => {
        setPopup(value)
        setCampInfo(selCamp)
    }

    useEffect(() => {
        console.log("n slot ->>", Nslot);
        if (Nslot)
            getList(Nslot, filter);
    }, [Nslot, filter])

    return (
        <>
        <div>
            <div className={`bold grid ${style.tabHeader} ${style.campaign_listing}`}>
                <div className={`green`}>CAMPAIGN </div>
                <div>Start Time & Time</div>
                <div>Recharge Amount</div>
                <div>Send Count</div>
                <div className={`textCenter`}>Actions</div>
                <div></div>
            </div>
            <div className={`${style.campaign_listing_container}`}>
                {campaignList && campaignList.length > 0 &&
                    campaignList.map((campaign) => {
                        let epoch = new Date(campaign.schedule_time)
                        epoch = Date.parse(epoch)
                        let humanReadableFormat = EpochTimeInHumanLan(epoch/1000, true)

                        let bgColor = ""
                        if(campaign.status == "active") bgColor = 'lightbgBlue'
                        else if(campaign.status == "completed" ) bgColor = 'lightBgGreen'
                        else bgColor = 'lightBgYellow'
                        return (
                            <div key={campaign.id}>
                                <div className={`grid ${bgColor} ${style.campaign_listing} ${style.separator}`}>
                                    <div>
                                        <div className={`${style.mrg_btm_s} ${style.strip_description}`}>
                                            <span className='bold'>ID:</span> <span>{campaign.id} </span>
                                            <span className='bold'> Status:</span> <span>{campaign.status}</span>
                                        </div>
                                        <div className={`${style.mrg_btm_s} ${style.strip_description}`}>
                                            <span className='bold'>Campign Type: </span> <span>{campaign.campaign_type} </span>
                                        </div>
                                        <div className={`${style.mrg_btm_s} ${style.strip_description}`}>
                                            <span className='bold'>Template: </span> <span>{campaign.whatsapp_template_name} </span>
                                        </div>
                                        {
                                            campaign.creation_info.creator_id && 
                                            <div className={`${style.mrg_btm_s} ${style.strip_description}`}>
                                                <b>Created By:{" "}</b><span className='bold green'>{campaign.creation_info.creator_name} | {campaign.created_at}</span>
                                            </div>
                                        }
                                        {
                                            campaign.creation_info.last_edited_by_id && 
                                            <div className={` ${style.strip_description}`}>
                                                <b>Edited By:{" "}</b><span className='bold green'>{campaign.creation_info.last_edited_by_name} | {campaign.creation_info.last_edited_time}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className={`${style.strip_description}`}>{humanReadableFormat}</div>
                                    <div className={`${style.strip_description}`}>
                                        <div className={`${style.mrg_btm_s} `}> <span className={`bold `}>Cash Amount: </span> ₹ {campaign.cash_amount}</div>
                                        <div> <span className={`bold `}>Reward Amount: </span> ₹ {campaign.reward_amount}</div>
                                    </div>
                                    <div className={`${style.strip_description}`}>
                                        {campaign.campaign_type == 'sms' && campaign.sms_success_count}
                                        {campaign.campaign_type == 'whatsapp' && campaign.whatsapp_success_count}
                                        {campaign.campaign_type == 'pn' && campaign.pn_success_count}
                                        {
                                            campaign.status == "completed" && 
                                            <div className={`${style.mrg_top_s}`}>
                                                <span className={`pointer blue textUnderline`} onClick={() => downloadCampaignUserList(campaign.id)}>Download List</span>
                                            </div>
                                        }
                                    </div>
                                    <div className={`textCenter`}>
                                        {/* <div> */}
                                            <button 
                                                className={`${style.outlinedBtn}`}
                                                onClick={() => { editCloneCampaign(campaign.id, 'clone') }}
                                            >   
                                                Clone
                                            </button>
                                            
                                            <DeleteIcon 
                                                className={` pointer middleAlignIcon ${style.mrg_x_6} ${campaign.status !== "planned" ? 'visibility_hidden' : null}`}
                                                onClick={() => handleDelete(campaign, true)}
                                                height='26px' width='26px' 
                                            />
                                            <EditIcon 
                                                className={` pointer middleAlignIcon ${campaign.status !== "planned" ? 'visibility_hidden' : null}`}
                                                onClick={() => editCloneCampaign(campaign.id, 'edit')}
                                                height='26px' width='26px' 
                                            />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>

            {/* Confirmation Popup */}
            {popup && <div>
                <PopUp 
                    width={formStyle.popupWidthS} 
                    title={`Delete Campaign`} 
                    closeBtn={() => { setPopup(false) }} 
                >
                    <div style={{ textAlign: 'center' }} className={`${formStyle.inputContainer} `}>
                        <div style={{marginBottom: "10px"}}> Do you wish to Delete this campaign ?</div>
                        <div style={{marginBottom: "6px"}}><b>ID:</b> {campInfo.id} | <b>Status:</b> {campInfo.status}</div>
                        <div style={{marginBottom: "2rem"}}><b>Campaign Type</b> {campInfo.campaign_type}</div>

                        <div className={`flex flex-space-between`}>
                            <button onClick={() => { setPopup(false) }} className={`${formStyle.btn} ${formStyle.secondaryBtn}`}>Cancel</button>
                            <button onClick={() => { activateCampaign(campInfo.id) }} className={`${formStyle.btn} ${formStyle.successBtn}`}>Delete</button>
                        </div>
                    </div>
                </PopUp>
            </div>}
        </div>
        {
            isLoading && <Loader />
        }
        </>
    )
}
