import React, { useState, useEffect } from 'react';
import style from '../css/CampaignList.module.css'
import { getZoneData, toggle } from '../../../../utils/api'
import { useNavigate } from 'react-router-dom';
import ArrowIcon from '../../../../icons/down-arrow.png';
function Header({filter , setFilter, zoneList, setZoneList}) {
    const navigate = useNavigate();
    

    const getZoneList = async () => {
        let resList = await getZoneData();
        resList.data.forEach((zone,index)=>{
            resList.data[index].is_selected = false;
        })
        setZoneList(resList.data);
    }

    const handleSelZone = (key,value) => {
        let list = Object.assign([], zoneList);
        list[key].is_selected = value;
        setZoneList(list);
        console.log(list);
    }

    const handleMasterActive = (value) => {
        if(value) setFilter(4)
        else setFilter(0)
    }

    // const updateList = async () => {
    //     let res = await getZoneData();
    //     setselZone(res.data[0])
    //     setList(res.data);
    //     setContentType(ContentType[3])
    // }

    const CreateCampaign = () => {
        navigate(`/create-campaign/0/`);
    }

    useEffect(() => {
       getZoneList();
    }, [])

    return (
        <>
            <div className={`${style.header} flex flex-space-between`}>
                <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Campaign List</span> </div>
                <div className='flex flex-space-between'>
                    <label>
                        <div className={`pointer ${style.isActiveCheckbox}`}>
                            <input 
                                checked={filter == 4}
                                type="checkbox" 
                                onChange={(e)=> {handleMasterActive(e.target.checked)}} 
                                width="20px" 
                                height="20px"
                                style={{float:"left"}} 
                            />
                            <span style={{display:"inline-block",paddingTop:"2px"}}>Active</span>
                        </div>
                    </label>
                <div className={`${style.dropdown} ${style.mrg_x_6}`}>
                    <div className={`dropdown_dummy_div hide`} id={`child_drop1_div`} onClick={()=>{
                        toggle('drop1')
                        toggle('drop1_div')
                    }}></div>
                    <button onClick={()=>{
                            toggle('drop1')
                            toggle('drop1_div')
                        }} className={`flex flex-space-between ${style.dropdownMenu}`}>
                        <span>Select Zone</span>
                        <img src={ArrowIcon}/>
                    </button>
                    <ul className={`${style.dropdownList} hide`} id={'child_drop1'}>
                        {
                            zoneList.map((elem, index)=>{
                                return (
                                <li key={index}>
                                    <label className={`pointer`}>
                                        <input 
                                        type="checkbox" 
                                        className={`${style.zoneInput}`}  
                                        onChange={() => {handleSelZone(index,!elem.is_selected)}}
                                        checked={elem.is_selected}
                                        /> {elem.name}
                                    </label>
                                </li>
                                );
                            })                       
                            }
                    </ul>
                </div>
                <div>
                    <button className={`${style.btnM} ${style.filledBtn}`} onClick={() => { CreateCampaign() }} >Create Campaign</button>
                </div>
                </div>
            </div>
        </>

    );
}

export default Header;