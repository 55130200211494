import React, { Component } from 'react'
import Style from '../DatePicker/DatePicker.module.css';
import Calendar from '../../icons/calender.svg';

export class DatePicker extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.state = {
            start_time: props.start_time ? props.start_time : '',
            end_time: props.end_time ? props.end_time : '',
            is_start_time_error: false,
            is_end_time_error: false,
            interval_start_time: '',
            interval_end_time: '',
            is_start_time_today: false,
            is_start_time_tomorrow: false,
            is_end_time_today: false,
            is_end_time_tomorrow: false,
            interval_start_time_for_end_date: '',
            interval_end_time_for_end_date: '',
            min_date_select: ''
        }

    }

    componentDidUpdate() {
        if (this.props.start_time !== this.state.start_time) {
            document.getElementById('start_time').type = 'datetime-local';
            this.setState({ start_time: this.props.start_time });
        }

        if (this.props.end_time !== this.state.end_time) {
            document.getElementById('end_time').type = 'datetime-local';
            this.setState({ end_time: this.props.end_time });
        }
    }

    onFocus = (e) => {
        e.target.type = 'datetime-local';
        this.minDateTimeSelected();

    }

    onFocusForEndDate = (e) => {
        e.target.type = 'datetime-local';
        this.minDateTimeSelected();
    }

    selEndTimeCalendarDate = (e) => {
        e.target.type = 'datetime-local';
        const today1 = e.target.value;
        this.setState({ end_time: e.target.value });
        this.setState({ is_end_time_error: false });
        this.dateValidation(e.target.value, true);
        this.props.getDate("end", today1)
    }

    todayDateselected = (e) => {
        document.getElementById('start_time').type = 'datetime-local';
        let today = new Date(new Date().setTime(new Date().getTime() + (30 * 60 * 1000)));
        let year = String(today.getFullYear())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let setMinutes = today.getMinutes() >= 10 ? String(today.getMinutes()) : String('0' + today.getMinutes())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '-' + month + '-' + dat_value + 'T' + (String(today.getHours()).length === 1 ? `0${String(today.getHours())}` : String(today.getHours())) + ':' + setMinutes;
        let interval_start_time = String(today.getHours()) + ':' + setMinutes
        let last_interval_set_time = new Date(today.setTime(today.getTime() + (60 * 60 * 1000)))
        let interval_end_time = String(last_interval_set_time.getHours()) + ':' + String(last_interval_set_time.getMinutes())
        this.setState({ start_time: today1 })
        this.setState({ is_start_time_error: false })
        this.setState({ interval_start_time: interval_start_time })
        this.setState({ interval_end_time: interval_end_time })
        this.setState({ is_start_time_today: true })
        this.setState({ is_start_time_tomorrow: false })
        this.props.getDate("start", today1)

    }

    tomorrowDateselected = (e) => {
        document.getElementById('start_time').type = 'datetime-local';
        var today = new Date();
        today.setDate(today.getDate() + 1);
        let year = String(today.getFullYear())
        let setMinutes = today.getMinutes() >= 10 ? String(today.getMinutes()) : String('0' + today.getMinutes())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '-' + month + '-' + dat_value + 'T' + (String(today.getHours()).length === 1 ? `0${String(today.getHours())}` : String(today.getHours())) + ':' + setMinutes;
        let interval_start_time = String(today.getHours()) + ':' + setMinutes
        let last_interval_set_time = new Date(today.setTime(today.getTime() + (60 * 60 * 1000)))
        let interval_end_time = String(last_interval_set_time.getHours()) + ':' + String(last_interval_set_time.getMinutes())
        this.setState({ start_time: today1 })
        this.setState({ is_start_time_error: false })
        this.setState({ interval_start_time: interval_start_time })
        this.setState({ interval_end_time: interval_end_time })
        this.setState({ is_start_time_tomorrow: true })
        this.setState({ is_start_time_today: false })
        this.props.getDate("start", today1)

    }

    selCalendarDate = (e) => {
        e.target.type = 'datetime-local';
        const today1 = e.target.value;
        this.setState({ start_time: e.target.value })
        this.setState({ is_start_time_error: false })
        this.dateValidation(e.target.value, false);
        this.props.getDate("start", today1)
    }

    dateValidation = (selDate, is_end_time) => {
        let selectDate = new Date(selDate);
        let today = new Date();
        let tommorow = new Date();
        tommorow.setDate(tommorow.getDate() + 1);
        // for Today
        let year = String(today.getFullYear())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today_date = year + '-' + month + '-' + dat_value;

        // for Tomorrow
        let dat_value_tomorrow = tommorow.getDate() >= 10 ? String(tommorow.getDate()) : '0' + String(tommorow.getDate())
        let tomorrow_date = year + '-' + month + '-' + dat_value_tomorrow;
        // for selectedDate
        let sel_dat_value = selectDate.getDate() >= 10 ? String(selectDate.getDate()) : '0' + String(selectDate.getDate())
        let selected_date = year + '-' + month + '-' + sel_dat_value;
        if (selected_date === today_date && !is_end_time) {
            this.setState({ is_start_time_tomorrow: false })
            this.setState({ is_start_time_today: true })
        } else if (selected_date === tomorrow_date && !is_end_time) {
            this.setState({ is_start_time_tomorrow: true })
            this.setState({ is_start_time_today: false })
        }
        else if (selected_date === today_date && is_end_time) {
            this.setState({ is_end_time_today: true })
            this.setState({ is_end_time_tomorrow: false })
        } else if (selected_date === tomorrow_date && is_end_time) {
            this.setState({ is_end_time_today: false })
            this.setState({ is_end_time_tomorrow: true })
        } else {
            if (is_end_time) {

                this.setState({ is_end_time_today: false });
                this.setState({ is_end_time_tomorrow: false });
            } else {
                this.setState({ is_start_time_tomorrow: false });
                this.setState({ is_start_time_today: false });
            }

        }

    }


    todayEndDateselected = (e) => {
        document.getElementById('end_time').type = 'datetime-local';
        let today = new Date(new Date().setTime(new Date().getTime() + (60 * 60 * 1000)));
        let year = String(today.getFullYear())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let setMinutes = today.getMinutes() >= 10 ? String(today.getMinutes()) : String('0' + today.getMinutes())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '-' + month + '-' + dat_value + 'T' + String(today.getHours()) + ':' + setMinutes;
        let interval_start_time = String(today.getHours()) + ':' + setMinutes
        let last_interval_set_time = new Date(today.setTime(today.getTime() + (60 * 60 * 1000)))
        let interval_end_time = String(last_interval_set_time.getHours()) + ':' + String(last_interval_set_time.getMinutes())
        this.setState({ end_time: today1 })
        this.setState({ interval_start_time_for_end_date: interval_start_time })
        this.setState({ interval_end_time_for_end_date: interval_end_time })
        this.setState({ is_end_time_error: false })
        this.setState({ is_end_time_today: true })
        this.setState({ is_end_time_tomorrow: false })
        this.props.getDate("end", today1)
    }


    tomorrowEndDateselected = () => {
        document.getElementById('end_time').type = 'datetime-local';
        let today = new Date(new Date().setTime(new Date().getTime() + (30 * 60 * 1000)));
        today.setDate(today.getDate() + 1);
        let year = String(today.getFullYear())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let setMinutes = today.getMinutes() >= 10 ? String(today.getMinutes()) : String('0' + today.getMinutes())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '-' + month + '-' + dat_value + 'T' + String(today.getHours()) + ':' + setMinutes;

        this.setState({ end_time: today1 })
        this.setState({ is_end_time_error: false })
        this.setState({ is_end_time_today: false })
        this.setState({ is_end_time_tomorrow: true })
        this.props.getDate("end", today1)


    }

    minDateTimeSelected = () => {
        // document.getElementById('end_time').type = 'datetime-local';
        let today = new Date()
        let year = String(today.getFullYear())
        let dat_value = today.getDate() >= 10 ? String(today.getDate()) : '0' + String(today.getDate())
        let setMinutes = today.getMinutes() >= 10 ? String(today.getMinutes()) : String('0' + today.getMinutes())
        let month = today.getMonth() + 1 > 9 ? today.getMonth() + 1 : '0' + (today.getMonth() + 1);
        let today1 = year + '-' + month + '-' + dat_value + 'T' + String(today.getHours()) + ':' + setMinutes;
        this.setState({ min_date_select: today1 }, () => {
            console.log("thsdd", this.state.min_date_select)
        })
    }

    render() {
        return (
            // <div style={{ width: "100%" }}>
            <div className={`${this.props.isHorizontal ? "flex" : ""}`}>
                {!this.props.isStartHide &&
                    <div className={`${Style.date_container} ${this.props.isHorizontal ? Style.width45 : ""}`} >
                        <div className={`flex`}> <img src={Calendar} />
                            <div className={Style.title}> Start Date &amp; Time {this.props.startMandatory && "*"}</div>
                        </div>
                        <div>
                            {this.props.isTodTomVisible && <div>
                                <button type="button" className={`${Style.date_outlined_btn} ${this.state.is_start_time_today ? Style.slot_btn_active : ''}`} onClick={(e) => this.todayDateselected(e)}>Today</button>
                                <button type="button" className={`${Style.date_outlined_btn} ${this.state.is_start_time_tomorrow ? Style.slot_btn_active : ''}`} onClick={this.tomorrowDateselected}>Tomorrow</button>
                            </div>}
                            <div>
                                <input id="start_time" className={Style.date_input_box} type="text" placeholder="Select Date and time" min={this.state.min_date_select} onFocus={this.onFocus} value={this.state.start_time} onChange={(e) => this.selCalendarDate(e)} />
                                {this.props.btnClicked || this.state.is_start_time_error ? (<div className={Style.error_txt}>*Please enter Start Date {'&'} Time</div>) : ''}
                            </div>
                        </div>
                    </div>}

                {!this.props.isEndHide &&
                    <div className={`${Style.date_container} ${this.props.isHorizontal ? Style.width45 : ""}`} >
                        <div className={`flex`}>
                            <img src={Calendar} />
                            <div className={Style.title}> End Date &amp; Time {this.props.endMandatory && "*"}</div>
                        </div>
                        <div>
                            {this.props.isTodTomVisible && <div style={{ display: 'flex' }}>
                                <button type="button" className={`${Style.date_outlined_btn} ${this.state.is_end_time_today ? Style.slot_btn_active : ''}`} onClick={this.todayEndDateselected}>Today</button>
                                <button type="button" className={`${Style.date_outlined_btn} ${this.state.is_end_time_tomorrow ? Style.slot_btn_active : ''}`} onClick={this.tomorrowEndDateselected}>Tomorrow</button>
                            </div>}
                            <div>
                                <input id="end_time" className={Style.date_input_box} placeholder="Select Date and time" min={this.state.min_date_select} onFocus={this.onFocusForEndDate} value={this.state.end_time} onChange={(e) => this.selEndTimeCalendarDate(e)} />
                                {this.props.btnClicked || this.state.is_end_time_error ? (<div className={Style.error_txt}>*Please enter End Date {'&'} Time</div>) : ''}
                            </div>
                        </div>

                    </div>}
            </div>
            // </div>
        )
    }
}

export default DatePicker