import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Header from './Header'
import { useRef } from 'react'
import '../css/BonusManagement.css'
import ScheduleModal from './ScheduleModal'
import style from "../css/BonusManagement.module.css"
import TooltipIcon from "../../../../icons/more-info-icon.svg"
import AddSegmentForm from './AddSegmentForm'
import EventIcon from "../../../../icons/event.png"
import { useNavigate } from 'react-router-dom'
import { addSegmentItem, getScheduleItem, getWallteRechargeList, resetSegement } from '../../../../utils/api'

export default function BonusManagement() {

    const [getList, setList] = useState([]);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [commonMessage, setCommonMessage] = useState(false);
    const [segmentModal, setSegmentModal] = useState(false);
    const [scheduleData, setScheduledata] = useState([]);
    const [walletType, setWalletType] = useState({});
    const [alertMessage, setAlertMessage] = useState('');
    const [alert, setAlert] = useState(false);
    const [error, setError] = useState(false);
    const [errorPopup, setErrorPopup] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        let items = localStorage.getItem('permissions')
        let perArr = items.split(',');
        if (!perArr.includes('BWM')) {
            navigate('/')
        }

    }, [])

    const showErrorAlert = (text) => {
        setAlertMessage(text)
        setError(true)
        setAlert(true);
        setTimeout(() => {
            setAlertMessage('');
            setAlert(false)
            setError(false)
        }, 3000)
    }

    const showSuccessAlert = (text) => {
        setAlertMessage(text)
        setAlert(true);
        setTimeout(() => {
            setAlertMessage('');
            setAlert(false)
        }, 3000)
    }

    const showErrorPopup = (data) => {
        setErrorList(data);
        setErrorPopup(true);
    }

    const closeErrorrPopup = () => {
        setErrorList([]);
        setErrorPopup(false)
    }


    const getRechargeList = async (wallet_type) => {
        // console.log(wallet_type);
        setWalletType(wallet_type);
        let res = await getWallteRechargeList(wallet_type)
        setList(res.data)

    }

    const updateData = (e, id) => {

        let updatedList = getList.map((item) => {
            if (item.object_id === id) {
                item[e.target.name] = parseInt(e.target.value)
            }
            return item;
        })
        console.log(updatedList);
        setList(updatedList)

    }

    const updateEditStatus = (e, id) => {
        let updatedList = getList.map((item) => {
            if (item.object_id === id) {
                item[e.target.name] = e.target.checked
            }
            return item;
        })
        setList(updatedList)
    }


    const closeScheduleModal = () => {
        setScheduleModal(false);
    }

    const openScheduleModal = () => {
        setScheduleModal(true);
    }

    const toogleAddSegmentModal = () => {
        setSegmentModal(!segmentModal)
    }

    const resetSegmentConfirmation = () => {
        setCommonMessage(true);
    }

    const scheduleSegment = async (id) => {
        navigate(`/${id}/schedule-segment/`)

    }

    const updateSchecduleSegment = async (data) => {
        console.log("data", data)
        if (validateScheduleData(data)) {
            data.vars_list = JSON.stringify(data.vars_list);
            let res = await addSegmentItem(data).then((response) => {
                if (response.success) {
                    showSuccessAlert('Segment Scheduled Successfully!!')
                    getRechargeList(walletType)
                    setScheduleModal(false);
                } else {
                    data.vars_list = JSON.parse(data.vars_list);
                    showErrorPopup(JSON.parse(response.error.message))
                }
            })
            console.log(res);
            // post_request_data(Base + `otipysegments/wallet/dashboard/v1/`, [data])
        }
    }

    const validateScheduleData = (data) => {
        if (!data.pn_text || data.pn_text.trim() === '') {
            showErrorAlert('Please Fill The PN Text')
            return false;
        } else if (!data.sms_text || data.sms_text.trim() === '') {
            showErrorAlert('Please Fill The SMS Text');
            return false;
        } else if (!data.pn_head || data.pn_head === '') {
            showErrorAlert('Please Fill The PN Head')
            return false;
        }

        return true;
    }

    const resetSegment = async () => {
        let res = await resetSegement(walletType).then((response) => {
            if (response.success) {
                getRechargeList(walletType)
                setCommonMessage(false);
                showSuccessAlert(response.data.message)
                getRechargeList(walletType);
            } else {
                showErrorPopup(JSON.parse(response.error.message))
            }
        })
        console.log(res);
        // get_data(Base + `otipysegments/wallet/restore_default/v1/?is_reward=${walletType.id}`)
    }

    const validateUpdateSegmnetData = () => {

        getList.forEach((data) => {
            if (!data.min_order_count || data.min_order_count === '') {
                showErrorAlert('Please Fill Minimum Order Count')
                return false;
            } else if (!data.max_order_count || data.max_order_count === '') {
                showErrorAlert('Please Fill Maximum Order Count')
                return false;
            } else if (!data.segment_periodicity || data.segment_periodicity === '') {
                showErrorAlert('Please Fill Segment Periodicity')
                return false;
            } else if (!data.min_last_order_day || data.min_last_order_day === '') {
                showErrorAlert('Please Fill Last Order Days Start')
                return false;
            } else if (!data.max_last_order_day || data.max_last_order_day === '') {
                showErrorAlert('Please Fill Last Order Days End')
                return false;
            } else if (!data.target_reward_balance && data.target_reward_balance === '') {
                showErrorAlert('Please Fill Target Balance')
                return false;
            } else if (!data.recharge_amount || data.recharge_amount === '') {
                showErrorAlert('Please Fill Recharge Amount');
                return false;
            } else if (data.max_order_count < data.min_order_count) {
                showErrorAlert('Maximum order count can not be greater than min order count')
                return false;
            } else if (data.max_last_order_day < data.min_last_order_day) {
                showErrorAlert('Last order days start can not be greater than last order days end')
                return false;
            } else if (data.min_order_count < 0) {
                showErrorAlert('Please Fill A valid Minimum Order Count')
                return false;
            } else if (data.max_order_count < 0) {
                showErrorAlert('Please Fill A Valid Maximum Order Count')
                return false;
            } else if (data.segment_periodicity < 0) {
                showErrorAlert('Please Fill A Valid Segment Periodicity')
                return false;
            } else if (data.min_last_order_day < 0) {
                showErrorAlert('Please Fill A Valid Last Order Days Start')
                return false;
            } else if (data.max_last_order_day < 0) {
                showErrorAlert('Please Fill A Valid Last Order Days End')
                return false;
            } else if (data.target_reward_balance < 0) {
                showErrorAlert('Please Fill A Valid Target Balance')
                return false;
            } else if (data.recharge_amount < 0) {
                showErrorAlert('Please Fill A Valid Recharge Amount');
                return false;
            }

        })
        return true;



    }

    const updateSegment = async () => {
        console.log("yes");
        if (validateUpdateSegmnetData()) {

            let data = [];
            getList.map((item) => {
                item.vars_list = JSON.stringify(data.vars_list);
                data.push(item);
            });

            let res = await addSegmentItem(data).then((response) => {
                if (response.success) {
                    showSuccessAlert('Segment Updated Successfully!!')
                    getRechargeList(walletType)
                } else {
                    showErrorPopup(JSON.parse(response.error.message))
                }
            })

            console.log(res);

            // post_request_data(Base + `otipysegments/wallet/dashboard/v1/`, data)
        }
    }

    const validateSegmnetData = (data) => {
        if (!data.min_order_count || data.min_order_count === '') {
            showErrorAlert('Please Fill Minimum Order Count')
            return false;
        } else if (!data.max_order_count || data.max_order_count === '') {
            showErrorAlert('Please Fill Maximum Order Count')
            return false;
        } else if (!data.segment_periodicity || data.segment_periodicity === '') {
            showErrorAlert('Please Fill Segment Periodicity')
            return false;
        } else if (!data.min_last_order_day || data.min_last_order_day === '') {
            showErrorAlert('Please Fill Last Order Days Start')
            return false;
        } else if (!data.max_last_order_day || data.max_last_order_day === '') {
            showErrorAlert('Please Fill Last Order Days End')
            return false;
        } else if (!data.target_reward_balance || data.target_reward_balance === '') {
            console.log(data.target_rewar_balance);
            showErrorAlert('Please Fill Target Balance')
            return false;
        } else if (!data.recharge_amount || data.recharge_amount === '') {
            showErrorAlert('Please Fill Recharge Amount');
            return false;
        } else if (data.max_order_count < data.min_order_count) {
            showErrorAlert('Maximum order count can not be greater than min order count')
            return false;
        } else if (data.max_last_order_day < data.min_last_order_day) {
            showErrorAlert('Last order days start can not be greater than last order days end')
            return false;
        } else if (data.min_order_count < 0) {
            showErrorAlert('Please Fill A valid Minimum Order Count')
            return false;
        } else if (data.max_order_count < 0) {
            showErrorAlert('Please Fill A Valid Maximum Order Count')
            return false;
        } else if (data.segment_periodicity < 0) {
            showErrorAlert('Please Fill A Valid Segment Periodicity')
            return false;
        } else if (data.min_last_order_day < 0) {
            showErrorAlert('Please Fill A Valid Last Order Days Start')
            return false;
        } else if (data.max_last_order_day < 0) {
            showErrorAlert('Please Fill A Valid Last Order Days End')
            return false;
        } else if (data.target_rewar_balance < 0) {
            showErrorAlert('Please Fill A Valid Target Balance')
            return false;
        } else if (data.reward_wallet_recharge_amount < 0) {
            showErrorAlert('Please Fill A Valid Recharge Amount');
            return false;
        }

        return true;

    }

    const addSegment = async (data) => {
        if (validateSegmnetData(data)) {
            let res = await addSegmentItem(data).then((response) => {
                if (response.success) {
                    setSegmentModal(false);
                    showSuccessAlert('Segment Added Successfully!!')
                    getRechargeList(walletType)
                    setSegmentModal(false);
                } else {
                    showErrorPopup(JSON.parse(response.error.message))
                }
            })
            console.log(res);
            // post_request_data(Base + `otipysegments/wallet/dashboard/v1/`, [data])
        }

    }

    return (
        <>
            <Header /*ref={headerRef}*/ resetSegment={resetSegmentConfirmation} toogleAddSegmentModal={toogleAddSegmentModal} segmentModal={segmentModal} scheduleModal={scheduleModal} getRechargeList={getRechargeList} />
            <div className={`${style.container}`} /*onClick={() => { headerRef.current.hideDropdownMenu() }}*/>

                {(segmentModal && <AddSegmentForm addSegment={addSegment} walletType={walletType} />) || (scheduleModal && <ScheduleModal closeModal={closeScheduleModal} updateSchecduleSegment={updateSchecduleSegment} data={scheduleData} />) || <>
                    <div className={`${style.thead} flex`} >
                        <div className={`${style.pd_bg} ${style.width10}`}>Segment<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Min Order<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Max Order<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Periodicity<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Min Days<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Max Days<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Balance<img src={TooltipIcon} className={`${style.tooltip}`} /></div>
                        <div className={`${style.pd_bg} ${style.width10}`}> ₹ Recharge</div>
                        <div className={`${style.pd_bg} ${style.width10}`}>Schedule</div>
                    </div>
                    <div>
                        {<div className={`${style.tbody}`}>
                            {getList.map((feed, index) => {

                                return (
                                    <div className={`${style.trow} flex flex-space-around`} key={index}>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input type="checkbox" name='is_active' checked={feed.is_active} className={`${style.checkbox}`} style={{ borderRight: "1px solid #2a9d8f" }} onChange={(e) => { updateEditStatus(e, feed.object_id) }} />
                                            {feed.segment_name}
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='min_order_count' defaultValue={feed.min_order_count} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='max_order_count' defaultValue={feed.max_order_count} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='segment_periodicity' defaultValue={feed.segment_periodicity} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='min_last_order_day' defaultValue={feed.min_last_order_day} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='max_last_order_day' defaultValue={feed.max_last_order_day} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='target_reward_balance' defaultValue={feed.target_reward_balance} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={`${style.pd_bg} ${style.width10}`} style={{ borderRight: "1px solid #ccc" }}>
                                            <input className={feed.is_active ? `${style.inputField}` : `${style.disabledField}`} disabled={!feed.is_active} name='recharge_amount' defaultValue={feed.recharge_amount} type="number" onChange={(e) => { updateData(e, feed.object_id) }} />
                                        </div>
                                        <div className={` ${style.pd_bg} ${style.width10}`} onClick={() => { scheduleSegment(feed.object_id) }}><img src={EventIcon} className='bonus_management_schedule_icon' alt='schedule' /></div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div></>}
                {(!scheduleModal && !segmentModal) && <div className={`${style.updateBtn}`}>
                    <button className={`${style.filledBtn}`} onClick={updateSegment}>Update Segment</button>
                </div>}
                {/* {alert && <Alert alert_message={alertMessage} is_error_msg={error} />} */}
                {/* {errorPopup && <Errorpopup ErrorTypeList={true} errorList={errorList} closeModal={closeErrorrPopup} />} */}
                {/* {commonMessage && <CommonPopup message={'Reset Segments'} description={'Do You Really Want To Reset All The Segments to Default'} closePopUp={() => { setCommonMessage(false) }} clickButton={resetSegment} />} */}
            </div>
        </>
    )
}