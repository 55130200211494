import React, { useState } from 'react';
import CampaignListing from './components/CommunicationListing';
import Header from './components/Header';
import SubHeader from './components/SubHeader';

function Index(props) {
    const [Nslot,setNslot] = useState('');
    const [filter,setFilter] = useState(4);
    
    return (
        <div style={{backgroundColor:"#f1f1f1",minHeight:"100vh"}}>
            <Header 
            filter={filter}
            setFilter={setFilter}
            />
            <SubHeader 
            Nslot={Nslot} 
            setNslot={setNslot}
            filter={filter}
            setFilter={setFilter}
            />
            <CampaignListing 
            Nslot={Nslot} 
            setNslot={setNslot}
            filter={filter}
            setFilter={setFilter}
            />
        </div>
    );
}

export default Index;