import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import "./NavBar.css";
import MenuIcon from "../../icons/hamgrey.svg";
import CloseIcon from "../../icons/close.png";
import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import { ReactComponent as Caret } from "../../icons/dropdown.svg";
import { getTodaySlot } from "../../utils/TimeManager";

function NavBar(props) {
  const navigation = useNavigate();
  const [sidebar, setSidebar] = useState(false);
  const [navList, setNavList] = useState(SidebarData)

  const showSidebar = () => {
    setSidebar(!sidebar)
  };

  const handleShowList = (index, item) => {

    // toggling the 'subListClass' of clicked item

    if(item.subList.length){
      let listing = Object.assign([], navList);


      // to show the given subList if it is hidden
      if(listing[index].subListClass === "hide"){
        listing[index].subListClass = "";
      }
      else{
        listing[index].subListClass = "hide";

      }

      // setting all the 'subListClass' to hide 
      // all sub listing except the one which is being toggled
      listing.forEach((element, localIdx) => {
          if(index !== localIdx)
            listing[localIdx].subListClass = "hide";
      });

      setNavList(listing);
    }
    else {
      handleClick(item.path)
    }
  }
  const handleClick = (path) => {
    navigation(path)
    showSidebar()
  }

  const logout = () => {
    console.log("logout");
    localStorage.clear();
    navigation("/login");
  };
  return (
    <div className='navContainer'>
      {/* hamberger menu icon */}
      <div className="navbar">
        <Link to="#">
          <img
            alt=""
            src={MenuIcon}
            onClick={showSidebar}
            className="iconDrawer"
          />
        </Link>
      </div>

      {/* nav list  */}
      <nav className={sidebar ? "navMenu active" : "navMenu"}>
        <div>
          {/* <img
            alt=""
            className={`pointer closeIcon ${sidebar ? 'active' : ''}`}
            src={CloseIcon}
            onClick={showSidebar}
            style={{ width: "2.1rem", marginBottom: "0.8rem" }}
          /> */}
          <div className="userDetail">
            <div className="fontL capital">{localStorage.getItem("name")}</div>
            <div className="fontM">{localStorage.getItem("ename")}</div>
          </div>
          {/* user details  */}
          {navList.map((item, index) => {
            return (
              <div key={index}>
                {/* parent listing */}
                <div 
                  className={`${item.subListClass === "hide" ? "" : "selOption"} nav-item flexSpaceBw`} 
                  onClick={() => handleShowList(index, item)}>
                  <div>
                    <img
                      alt=""
                      src={item.image}
                      className={`iconList`}
                    />
                    <span> {item.title} </span>
                  </div>
                  {
                    item.subList.length ? 
                    <div>
                      <Caret />
                    </div>
                    : null
                  }
                </div>
                {/* child Listing */}
                <div className={`${item.subListClass} subList`}>
                      {
                        item.subList.length ? 
                        <ul>
                          {
                            item.subList.map((subItem, idx) => {
                              let path = subItem.path
                              if(subItem.hasSlot){
                                path = path + getTodaySlot() + '/'
                              }
                              return(
                                <li 
                                  onClick={() => handleClick(path)}
                                  key={idx}>
                                  {subItem.title}
                                </li>
                              )
                            })
                          }
                        </ul>
                        : null
                      }
                </div>
              </div>
            );
          })}
        </div>
        <button className="logoutBtn" onClick={logout}>
          <LogoutIcon className="logoutIcon" /> logout
        </button>
      </nav>
      {/* dummy div */}
      <div
        onClick={showSidebar}
        className={sidebar ? "dummyDiv active" : "dummyDiv"}
      ></div>
    </div>
  );
}

export default NavBar;
