import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';

function PopupDuplicateRank({duplicateWidget, closeBtn, submitData}) {
    return (
        <PopUp
            title="Duplicate Rank"
            width={`${formStyle.popupWidthS}`}
            closeBtn={closeBtn}
        >
            <div className={`${formStyle.inputContainer}`} style={{lineHeight: "22px"}}>
                <div>Widget with tag: <b>{duplicateWidget.widget_tag}</b> and type: <b>{duplicateWidget.widget_type}</b> already exists at rank <b>{duplicateWidget.rank}</b>. </div>
                <div>Do you like to proceed with current rank ?</div>
                <div className={`flex flex-space-between`} style={{marginTop: '38px'}}>
                    <button className={`${formStyle.btn} ${formStyle.secondaryBtn}`} onClick={closeBtn}>NO</button>
                    <button className={`${formStyle.btn} ${formStyle.successBtn}`} onClick={submitData}>YES</button>
                </div>
            </div>
        </PopUp>
    );
}

export default PopupDuplicateRank;