function get_access_token() {
    let access_token = localStorage["token"];
    if (access_token) {
      return access_token;
    } else {
      console.log("access token not found");
      // window.location.href = "/";
      return;
    }
  }

  function setUserInfo(user){
    localStorage.setItem("token", user["access_token"]);
    localStorage.setItem("name", user["name"]);
    localStorage.setItem("ename", user["user_context"]["ename"]);
    localStorage.setItem("distributor_id", user["user_context"]["distributor_id"]);
    localStorage.setItem("email", user["email"]);
    localStorage.setItem("role", user["role_permission"]["roles"]);
    localStorage.setItem("uStatus", user["user_status"]);
    localStorage.setItem("phone", user["phone"]);
    localStorage.setItem("repMgr", user["rep_mgr"]);
    localStorage.setItem("permissions", user["role_permission"]["permissions"]);    
    localStorage.setItem("isAuthorized", true);
  }

  const version = 'v4.0.0';

  export { get_access_token, setUserInfo, version };
  