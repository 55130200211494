import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import ProtectedRoute from "./utils/ProtectedRoute";
import NavBar from "./commonComponent/Drawer/NavBar";

import Loader from "./commonComponent/Loader/Loader";
import WidgetScreen from "./screens/Branding/AppFeed/components/WidgetScreen";
import WebWidgetScreen from "./screens/Branding/WebFeed/components/WidgetScreen";
import AddProduct from "./screens/Branding/ProductWidget/components/AddProduct"
import OrderBanner from "./screens/Branding/OrderSuccessBanner/components/OrderBanner";
import CutOffTime from "./screens/CutOffTime/CutOffTime";
import BurnMapping from "./screens/Marketing/BurnMapping";
import CampaignList from "./screens/Marketing/CampaignList";
import CreateCampaign from "./screens/Marketing/CreateCampaign";
import Dashboard from "./screens/Marketing/Dashboard";
import Banner from "./screens/Branding/Banner/index.js";
import BonusCommunication from "./screens/Communication/BonusCommunication/index.js";
import CommunicationDashboard from "./screens/Communication/CommunicationDashboard/index.js";

import AddBanner from "./screens/Branding/Banner/components/AddBanner";
import AddHeaderBanner from "./screens/Branding/HeaderBanner/components/AddHeaderBanner";
import MiniFeedDetail from "./screens/Branding/MiniFeed/components/MiniFeedDetail";
import MiniFeedWidget from "./screens/Branding/MiniFeed/components/MiniFeedWidget";
import AddCoupon from "./screens/Coupon/CouponList/components/AddCoupon";
import SmsDashboard from "./screens/Communication/SmsDashboard";
import KafkaSwitcher from './screens/Communication/KafkaSwitcher';
import BonusManagement from "./screens/Marketing/BonusManagement/components/BonusManagement";
import ScheduleModal from "./screens/Marketing/BonusManagement/components/ScheduleModal";
import AddSegmentForm from "./screens/Marketing/BonusManagement/components/AddSegmentForm";
import CreateSubscriptionProduct from "./screens/Subscription/SubscriptionProduct/components/CreateSubscriptionProduct";
import EditOffertType from "./screens/Subscription/SubscriptionProduct/components/EditOffertType";
import AddTopBanner from "./screens/Subscription/SubscriptionTopBanner/components/AddTopBanner";

const Login = lazy(() => import("./screens/Login"));
const Validate = lazy(() => import("./screens/Login/validate"));
const Home = lazy(() => import("./screens/Home"));
const AppFeed = lazy(() => import("./screens/Branding/AppFeed"));
const AddEditWidget = lazy(() => import("./screens/Branding/Widgets/components/AddEditWidget"));
const ProductWidget = lazy(() => import("./screens/Branding/ProductWidget"));
const HeaderBanner = lazy(() => import("./screens/Branding/HeaderBanner"));
const MiniFeed = lazy(() => import("./screens/Branding/MiniFeed"));
const WebFeed = lazy(() => import("./screens/Branding/WebFeed"));
const OrderSuccessBanner = lazy(() => import("./screens/Branding/OrderSuccessBanner"));
const CouponDetail = lazy(() => import("./screens/Coupon/CouponDetail"));
const CouponList = lazy(() => import("./screens/Coupon/CouponList"))
const OfferList = lazy(() => import("./screens/Coupon/OfferListing"))
const SubscriptionProduct = lazy(() => import("./screens/Subscription/SubscriptionProduct"))
const SubscriptionTopBanner = lazy(() => import("./screens/Subscription/SubscriptionTopBanner"))
const ProductDetailVideo = lazy(() => import("./screens/ProductDetailVideo"))
const AddEditProductDetail = lazy(() => import("./screens/ProductDetailVideo/component/AddEditProdDetail"))
const MembershipProductListing = lazy(() => import("./screens/Marketing/MembershipProduct"))
const AddEditMembershipProduct = lazy(() => import("./screens/Marketing/MembershipProduct/components/AddEditMembership"))
const SubCategory = lazy(() => import("./screens/PDP/component/SubCategory"))
const ProdKeyDetail = lazy(() => import("./screens/PDP/component/ProductDetailsKey"))
const ListingOrder = lazy(() => import("./screens/PDP/component/ListingOrder"))
const WidgetListing = lazy (() => import("./screens/Branding/Widgets"))
const FeedContent = lazy(() => import("./screens/Branding/FeedContent"))
const FeedContentAddEdit= lazy(() => import("./screens/Branding/FeedContent/components/AddNewBanner"))
const NewMiniFeed = lazy(() => import("./screens/Branding/NewMiniFeed"))
const AddEditNewMiniFeed = lazy(() => import("./screens/Branding/NewMiniFeed/components/AddEditNewMiniFeed.js"))
const BulkUpload = lazy(() => import("./screens/PDP/component/BulkUpload"))
const UserSegmentListing = lazy(() => import("./screens/UserSegment"))
const AddEditUserSegment = lazy(() => import("./screens/UserSegment/components/AddEditSegment"))
const OndcProvider = lazy(() => import("./screens/ONDC/OndcStore"))
const OndcProviderCatalogue = lazy(() => import("./screens/ONDC/OndcStore/components/StoreCatalogue.js"))
const OndcWidgetListing = lazy(() => import("./screens/ONDC/Widgets"))
const OndcWidgetDetail = lazy(() => import("./screens/ONDC/Widgets/components/AddEditWidget.js"))
const OndcFeed = lazy(() => import("./screens/ONDC/FeedManagement"))
const OfferCreation = lazy(() => import("./screens/Coupon/OfferCreation"))
const BulkUploadData = lazy(()=> import("./screens/BulkUpload/"))
const NewAppFeed  = lazy(() => import("./screens/Branding/AppFeedManagement/index.tsx"))

export default function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        { <Route
          path="/login"
          element={
            <Suspense fallback={<Loader />}>
              <Login />
            </Suspense>
          }
        ></Route>}
        { <Route
          path="/validate"
          element={
            <Suspense fallback={<Loader />}>
              <Validate />
            </Suspense>
          }
        ></Route>}

        <Route  element={<ProtectedRoute />}>
          <Route path="/*" element={<WithNavbar />}>

            <Route
              path=""
              element={
                <Suspense fallback={<Loader />}>
                  <Home />
                </Suspense>
              }
            ></Route>
            {/* Brand USP on App and Feed Widgets */}
            {/* ProductWidget route */}
            <Route
              path="product-widget"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductWidget />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:id/:selZone/product-widget-image/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddProduct />
                </Suspense>
              }
            ></Route>
            
            {/* Header Banner */}
            <Route
              path="header-banner"
              element={
                <Suspense fallback={<Loader />}>
                  <HeaderBanner />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:uspid/:selZone/header-banner-image/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddHeaderBanner />
                </Suspense>
              }
            ></Route>

             {/* Mini Feed Detail */}
             {/* <Route
              path="mini-feed"
              element={
                <Suspense fallback={<Loader />}>
                  <MiniFeed></MiniFeed>
                </Suspense>
              }
            ></Route> */}
            {/* <Route
              path=":status/:selFeedId/:selZone/mini-feed-detail/"
              element={
                <Suspense fallback={<Loader />}>
                  <MiniFeedDetail />
                </Suspense>
              }
            ></Route> */}
            {/* <Route
              path=":status/:selFeedId/:id/:selZone/mini-feed-widget/"
              element={
                <Suspense fallback={<Loader />}>
                  <MiniFeedWidget />
                </Suspense>
              }
            ></Route> */}

            <Route
              path="app-feed"
              element={
                <Suspense fallback={<Loader />}>
                  <AppFeed />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:id/:selZone/app-feed-widget/"
              element={
                <Suspense fallback={<Loader />}>
                  <WidgetScreen />
                </Suspense>
              }
            ></Route>
            <Route
              path="web-feed"
              element={
                <Suspense fallback={<Loader />}>
                  <WebFeed />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:id/:selZone/web-feed-widget/"
              element={
                <Suspense fallback={<Loader />}>
                  <WebWidgetScreen />
                </Suspense>
              }
            ></Route>
            <Route
              path="order-success-banner"
              element={
                <Suspense fallback={<Loader />}>
                  <OrderSuccessBanner />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:banner_id/:selZone/order-banner/"
              element={
                <Suspense fallback={<Loader />}>
                  <OrderBanner />
                </Suspense>
              }
            ></Route>
            <Route
              path="feed-content/:slot/"
              element={
                <Suspense fallback={<Loader />}>
                  <Banner />
                </Suspense>
              }
            ></Route>
            <Route
              path=":status/:banner_id/:selZone/banner/:slot/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddBanner />
                </Suspense>
              }
            ></Route>
            <Route
              path="bonus-management"
              element={
                <Suspense fallback={<Loader />}>
                  <BonusManagement />
                </Suspense>
              }
            ></Route>
            <Route
              path=":id/schedule-segment/"
              element={
                <Suspense fallback={<Loader />}>
                  <ScheduleModal />
                </Suspense>
              }
            ></Route>
            <Route
              path=":wallet_id/add-segment/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddSegmentForm />
                </Suspense>
              }
            ></Route>
            {/* <Route
              path="/new/:banner_id/:selZone/order-banner/"
              element={
                <Suspense fallback={<Loader />}>
                  <OrderBanner/>
                </Suspense>
              }
            ></Route> */}
            <Route
              path="cut-off-time"
              element={
                <Suspense fallback={<Loader />}>
                  <CutOffTime />
                </Suspense>
              }
            ></Route>
            <Route
              path="coupon-detail/"
              element={
                <Suspense fallback={<Loader />}>
                  <CouponDetail />
                </Suspense>
              }
            ></Route>
            <Route
              path="coupon-list/"
              element={
                <Suspense fallback={<Loader />}>
                  <CouponList />
                </Suspense>
              }
            ></Route>
            <Route
              path="add-coupon/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddCoupon />
                </Suspense>
              }
            ></Route>
            <Route
              path="offer-list/"
              element={
                <Suspense fallback={<Loader />}>
                  <OfferList />
                </Suspense>
              }
            ></Route>
            <Route
              path="burn-mapping/"
              element={
                <Suspense fallback={<Loader />}>
                  <BurnMapping />
                </Suspense>
              }
            ></Route>
            <Route
              path="campaign-list/"
              element={
                <Suspense fallback={<Loader />}>
                  <CampaignList />
                </Suspense>
              }
            ></Route>
            <Route
              path=":campaignStatus/:id/"
              element={
                <Suspense fallback={<Loader />}>
                  <CreateCampaign />
                </Suspense>
              }
            ></Route>
            
            <Route
              path="dashboard/"
              element={
                <Suspense fallback={<Loader />}>
                  <Dashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="sms-dashboard/"
              element={
                <Suspense fallback={<Loader />}>
                  <SmsDashboard />
                </Suspense>
              }
            ></Route>
            <Route
              path="kafka-switcher/"
              element={
                <Suspense fallback={<Loader />}>
                  <KafkaSwitcher />
                </Suspense>
              }
            ></Route>
            {/* <Route
              path="bonus-communication/"
              element={
                <Suspense fallback={<Loader />}>
                  <BonusCommunication />
                </Suspense>
              }
            ></Route> */}
            <Route
              path="communication/:campaignStatus/:id/"
              element={
                <Suspense fallback={<Loader />}>
                  <BonusCommunication />
                </Suspense>
              }
            ></Route>
            <Route
              path="communication-dashboard/"
              element={
                <Suspense fallback={<Loader />}>
                  <CommunicationDashboard />
                </Suspense>
              }
            ></Route>
            
            <Route
              path="subscription/"
              element={
                <Suspense fallback={<Loader />}>
                  <SubscriptionProduct/>
                </Suspense>
              }
            ></Route>
            
            <Route
              path=":status/:selZone/subscription/"
              element={
                <Suspense fallback={<Loader />}>
                  <CreateSubscriptionProduct/>
                </Suspense>
              }
            ></Route>
            
            <Route
              path=":status/:id/offer-type/"
              element={
                <Suspense fallback={<Loader />}>
                  <EditOffertType/>
                </Suspense>
              }
            ></Route>
            
            <Route
              path="subscription-top-banner/"
              element={
                <Suspense fallback={<Loader />}>
                  <SubscriptionTopBanner/>
                </Suspense>
              }
            ></Route>
            
            <Route
              path=":status/:id/:zoneid/subscription-top-banner/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddTopBanner/>
                </Suspense>
              }
            ></Route>
            

            {/* product video details */}
            <Route
              path="product-detail-video/"
              element={
                <Suspense fallback={<Loader />}>
                  <ProductDetailVideo />
                </Suspense>
              }
            ></Route>

            <Route
              path=":status/:id/product-detail-video/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddEditProductDetail />
                </Suspense>
              }
            ></Route>

            <Route
              path="membership-product/"
              element={
                <Suspense fallback={<Loader />}>
                  <MembershipProductListing />
                </Suspense>
              }
            ></Route>

            <Route
              path=":status/:id/membership-product/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddEditMembershipProduct />
                </Suspense>
              }
            ></Route>
                       
           {/* widget details */}
           <Route
              path=":status/:id/widget/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddEditWidget />
                </Suspense>
              }
            ></Route>

            {/* PDP pages */}
            
            <Route
              path="sub-category/"
              element={
                <Suspense fallback={<Loader />}>
                  <SubCategory />
                  </Suspense>
              }
            ></Route>
            <Route
              path="widget-listing/" 
              element={
                <Suspense fallback={<Loader />}>
                  <WidgetListing />
                  
                </Suspense>
              }
            ></Route>
            <Route
              path="product-details-keys/"
              element={
                <Suspense fallback={<Loader />}>
                  <ProdKeyDetail />
                </Suspense>
              }
            ></Route>
            
            <Route
              path=":status/:id/pdp/listing/"
              element={
                <Suspense fallback={<Loader />}>
                  <ListingOrder />
                </Suspense>
              }
            ></Route>

            <Route
              path="new-feed-content/:slot" 
              element={
                <Suspense fallback={<Loader />}>
                  <FeedContent />
                </Suspense>
              }
            ></Route>
            <Route
              path="new/feed-content/:status/:parentId/:slot/:selZone/"
              element={
                <Suspense>
                  <FeedContentAddEdit />
                </Suspense>
              }>
            </Route>

            {/* New Mini Feed Detail */}
            <Route
              path="new-mini-feed"
              element={
                <Suspense fallback={<Loader />}>
                  <NewMiniFeed />
                </Suspense>
              }
            ></Route>
            <Route
                path=":status/:id/new-mini-feed/"
                element={
                    <Suspense>
                        <AddEditNewMiniFeed />
                    </Suspense>
                }
            ></Route>

            {/* pdp */}
            <Route
              path="bulk-upload/"
              element={
                <Suspense fallback={<Loader />}>
                  <BulkUpload />
                </Suspense>
              }
            ></Route>

            {/* user segment */}
            <Route
              path="user-segment/"
              element={
                <Suspense fallback={<Loader />}>
                  <UserSegmentListing />
                </Suspense>
              }
            ></Route>

            {/* add | edit user segment */}
            <Route
              path=":status/:id/user-segment/"
              element={
                <Suspense fallback={<Loader />}>
                  <AddEditUserSegment />
                  </Suspense>
              }
            ></Route>
                
            {/* ONDC */}
            <Route
              path="ondc-provider/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcProvider />
                </Suspense>
              }
            ></Route>

            <Route
              path="ondc-provider/:storeId/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcProviderCatalogue />
                </Suspense>
              }
            ></Route>

            {/* ONDC - widget listing */}
            <Route
              path="ondc-widget-listing/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcWidgetListing />
                </Suspense>
              }
            ></Route>
            
            <Route
              path=":status/:id/ondc-widget/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcWidgetDetail />
                </Suspense>
              }
            ></Route> 
            
            {/* ONDC - feed */}
            <Route
              path="ondc-feed/"
              element={
                <Suspense fallback={<Loader />}>
                  <OndcFeed />
                </Suspense>
              }
            ></Route>

            {/* OFFERS */}
            <Route
              path=":status/:id/offer-creation/"
              element={
                <Suspense fallback={<Loader />}>
                  <OfferCreation />
                </Suspense>
              }
            ></Route>
            
            {/* Bulk Upload */}
            <Route
              path="bulk-upload-data/"
              element={
                <Suspense fallback={<Loader />}>
                  <BulkUploadData />
                </Suspense>
              }
            ></Route>

            {/* new app feed */}
            <Route
              path="new-app-feed/"
              element={
                <Suspense fallback={<Loader />}>
                  <NewAppFeed />
                </Suspense>
              }
            ></Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function WithNavbar() {
  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
