import React,{useState} from 'react';
import Header from './components/Header';
import Listing from './components/Listing';

function Index(props) {
    const [nslot,setNslot] = useState('');

    return (
        <>
            <Header nslot={nslot} setNslot={setNslot}/>
            <Listing nslot={nslot} setNslot={setNslot}/>
        </>
    );
}

export default Index;