import React from 'react';
import CreateCompaign from './components/CreateCompaign';
import Header from './components/Header';

function index(props) {
    return (
        <>
            <Header />
            <CreateCompaign />
        </>
    );
}

export default index;