// get and post api functions will be here
import { access_token } from "./config";
import { get_access_token } from "./auth";

function get_auth_data(url) {
  return fetch(url, {
    method: "get",
    headers: {},
  }).then(function (res) {
    return res.json();
  });
}

function get_data(url) {
  return fetch(url, {
    method: "get",
    headers: new Headers({
      "app-client": "cr-erp" ,
      "Content-Type": "application/json",
      "access-token": get_access_token(),
    }),
  }).then(function (res) {
    if (res.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    }
    else if(res.status === 500){
      return Promise.reject({success: false, message: "Server is not responding, please try again !"})
    }
    // console.log("res in get", res)
    return res.json();
  });
}

function post_data(url, data) {
  return fetch(url, {
    method: "post",
    headers: new Headers({
      "app-client": "dcManagement" ,
      "Content-Type": "application/json",
      "access-token": get_access_token(),
    }),
    body: JSON.stringify(data),
  }).then(function (res) {
    return res.json();
  });
}

function post_image(url, data) {
  return fetch(url, {
    method: "post",
    headers: new Headers({
      "app-client": "dcManagement",
      "access-token": get_access_token(),
    }),
    body: data,
  }).then(function (res) {
    return res.json();
  });
}

function put_data(url, data) {
  return fetch(url, {
    method: "put",
    headers: new Headers({
      "app-client": "dcManagement" ,
      "Content-Type": "application/json",
      "access-token": get_access_token(),
    }),
    body: JSON.stringify(data),
  }).then(function (res) {
    return res.json();
  });
}

function patch_data(url, data) {
  return fetch(url, {
    method: "PATCH",
    headers: new Headers({
      "app-client": "dcManagement" ,
      "Content-Type": "application/json",
      "access-token": get_access_token(),
    }),
    body: JSON.stringify(data),
  }).then(function (res) {
    return res.json();
  });
}

function delete_data(url, data) {
  return fetch(url, {
    method: "delete",
    headers: new Headers({
      "app-client": "dcManagement" ,
      "Content-Type": "application/json",
      "access-token": get_access_token(),
    }),
    body: JSON.stringify(data),
  }).then(function (res) {
    return res.json();
  });
}


export { get_data, post_data, post_image, put_data, get_auth_data, delete_data, patch_data };
