import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from '../css/dashboard.module.css';
function Header(props) {
    const navigate = useNavigate();

    const routeToCreateCampaign = () => {
        navigate('/create-campaign/0/')
    }

    return (
        <>
           <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Dashboard</span></div>
               
               <div className={`flex flex-space-between`}>
                {/* <button className={`${style.outlinedBtn}`}>Default Deals</button> */}
                <button className={`${style.filledBtn}`} onClick={routeToCreateCampaign}>Create Campaign</button>
               </div>
            </div>   
        </>
    );
}

export default Header;