import React from 'react';
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import formStyle from '../../../../commonComponent/PopUp/Form.module.css';

function PopupErrorList({list, closeBtn}) {
    return (
        <PopUp
            title="Error"
            width={`${formStyle.popupWidthB}`}
            closeBtn={closeBtn}
        >
            <div className={`textLeft ${formStyle.overlay_48}`}>
                {list.map((error, index)=>{
                    return(
                        <div className={`red ${formStyle.cellPd}`}>
                            <span>{index+1} . </span> {error}
                        </div>
                    )
                })}
            </div>
        </PopUp>
    );
}

export default PopupErrorList;