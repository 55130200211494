import React from 'react';
import style from '../css/KafkaSwitcher.module.css'
function Header(props) {
    return (
        <>
            <div className={`${style.header} flex flex-space-between`}>
               <div className={`${style.header_title}`}>Crofarm ERP | <span className={`bold green`}>Kafka Switcher</span></div>
            </div> 
        </>
    );
}

export default Header;