import React,{useState,useEffect} from 'react';
import { getPNTextDetails } from '../../../../utils/api';
import { SuccessHandling } from '../../../../State';
import formStyle from "../../../../commonComponent/PopUp/Form.module.css"
import PopUp from '../../../../commonComponent/PopUp/PopUp';
import style from '../css/CampaignList.module.css'
import EditIcon from "../../../../icons/edit.svg"
import CopyIcon from "../../../../icons/copy.png"
function PopUpPushNotification({selCamp, closeBtn}) {
    
    const [itemList,setItemList] = useState([]);

    const updateItemList = async () => {
        let res = await getPNTextDetails(selCamp.id);
        console.log(res);
        if(res.data.length){
         setItemList(res.data);
        }
        else {
         setItemList([]);
        }
    }

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        SuccessHandling("Copied to Clipboard!")
    }

    useEffect(()=>{
        updateItemList();
    },[]);


    return (
            <PopUp
                title={selCamp.title}
                width={`${formStyle.popupWidthXXL}`}
                closeBtn={closeBtn}
            >
                <div className={`${formStyle.container} ${formStyle.overlay_70}`}>
                        {
                            itemList.map((item,idx)=>{
                                return(
                                    <div style={{marginBottom:"2rem"}}>
                                        <div className={`${style.sectionTitle}`}>{item.title}({item.id})</div>
                                                    <div className={`flex ${style.thead}`}>
                                                        <div className={`${style.pd_bg} ${style.width20}`}>Prizing Zone</div>
                                                        <div className={`${style.pd_bg} ${style.width60}`}>PN Text</div>
                                                        <div className={`${style.pd_bg} ${style.width20}`}>Action</div>
                                                    </div>
                                        <div>
                                        {
                                            item.pn_text.map((elem,idx)=>{
                                                return(
                                                    <>
                                                <div className={`${style.tbody}`}>
                                                    <div className={` flex flex-space-around ${style.trow}`} key={idx}>
                                                        <div className={`${style.pd_bg} ${style.width20}`}>{elem.zone}</div>
                                                        <div className={`${style.pd_bg} ${style.width60}`}>{elem.text}</div>
                                                        <div className={`${style.pd_bg} ${style.width20}`}>
                                                        <div>
                                                            <span className={`pointer ${style.EditIcon}`} onClick={()=>{
                                                                handleCopyToClipboard(elem.text)
                                                            }}><img src={CopyIcon} width="25px" height="25px"/></span>
                                                        </div>
                                                        </div>
                                                </div>
                                                    </div>
                                                    </>
                                                )
                                            })
                                        }
                                        </div>
                                    </div>
                                )
                            })
                        }
                </div>
                   
               
            </PopUp>
        );
}

export default PopUpPushNotification;