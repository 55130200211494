import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { EpochTimeInHumanLan, EpochTimetoDatePickerFormat } from '../../../../utils/TimeManager';
import { deepCopyObject, getFeedContentListing, updateBanner } from '../../../../utils/api';
import style from '../css/Banner.module.css'
import EditIcon from '../../../../icons/edit.svg';
import ActiveIcon from '../../../../icons/ic_active.svg';
import DeactiveIcon from '../../../../icons/ic_deactivate.svg';
import { BannerImgUrl } from '../../../../utils/config';
import { SuccessHandling } from '../../../../State';


function FeedContentListing({ selZone, contentType, itemList, setItemList, updateItemList, slot }) {
   
    const navigation = useNavigate();
    // const updateItemList = async () => {
    //     let res = await getFeedContentListing(selZone.id, contentType.value, nSlot);
    //     localStorage.setItem('zone', JSON.stringify(selZone))
    //     localStorage.setItem('type', JSON.stringify(contentType))
    //     localStorage.setItem('nslot', JSON.stringify(nSlot))
    //     if (res.data.length) {
    //         setItemList(res.data);
    //     }
    //     else
    //         setItemList([]);
    // }

    const editItem = (id) => {
        navigation(`/edit/${id}/${selZone.id}/banner/${slot}/`);
    }

    const ActiveInactiveBanner = async (banner, isActive) => {
        let product_list = banner.product_list.map((prod) => { return prod.id })
        let data = deepCopyObject(banner);

        data = {
            ...data, 
            'is_active': !banner.is_active,
            'banner_id': banner.id,
            'start_at': EpochTimetoDatePickerFormat(banner.start_at) + ":00.000Z",
            'end_at': EpochTimetoDatePickerFormat(banner.end_at) + ":00.000Z",
            'product_list': product_list,
            'distributor_id': 6,
        }
        let res = await updateBanner(data)

        if (res.success) {
            updateItemList()
            SuccessHandling(`Banner ${isActive ? 'deactivated' : 'activated'} Successfully !!`)
        }
      
    }


    useEffect(() => {
        if (selZone.id)
            updateItemList();
    }, [selZone, contentType, slot]);

    return (

        <>
            <div className={`${style.container}`}>
                <div className={`grid ${style.thead}`}>
                    <div className={`textCenter ${style.pd_bg}`}>Banner Detail</div>
                    <div className={`textCenter ${style.pd_bg}`}>Tag</div>
                    <div className={`textCenter ${style.pd_bg}`}>Start Date</div>
                    <div className={`textCenter ${style.pd_bg}`}>End Date</div>
                    <div className={`textCenter ${style.pd_bg}`}>Screen/Rank</div>
                    <div className={`textCenter ${style.pd_bg}`}>Action</div>
                </div>

                <div className={`${style.tbody}`}>
                    {
                        itemList.map((item, idx) => {
                            return (
                                <div style={{fontSize: "0.9rem"}} className={`grid ${style.trow} ${item.product_list.length == 0 ? 'lightBgGreen' : 'whiteBg'}`} key={idx}>
                                    {/* <div className={`flex flex-justify-center  ${style.pd_bg1} `}> */}
                                        <div className={`grid ${style.temp_col} ${style.pd_bg1}`}>
                                            <div>
                                                <img className={`${style.ht_wd_70}`} src={BannerImgUrl + item.new_image_id} />
                                            </div>
                                            <div style={{paddingLeft: '10px'}}>
                                                <div><strong>[{item.id}] </strong>{item.campaign_name}</div>
                                                <div className={`labelGrey fontS`}>
                                                    <strong>Status: </strong>
                                                    <span className={item.is_active ? 'green' : 'red'}>{item.is_active ? 'Active' : 'Disabled'}</span>
                                                </div>
                                                <div className={`labelGrey fontS`}>
                                                    <strong>Pricing Zone Id: </strong>
                                                    {item.pricing_zone_id}
                                                </div>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                    <div className={`textCenter ${style.pd_bg1} `}>{item.campaign_tag}</div>
                                    <div className={`textCenter ${style.pd_bg1} `}>{EpochTimeInHumanLan(item.start_at)}</div>
                                    <div className={`textCenter ${style.pd_bg1} `}>{EpochTimeInHumanLan(item.end_at)}</div>
                                    <div className={`textCenter ${style.pd_bg1} `}> {item.landing_page}  |  {item.rank}</div>
                                    <div className={`textCenter ${style.pd_bg1} `}>
                                        {/* <div> */}
                                            <span className={`pointer ${style.EditIcon} ${style.mrg_x_6}`}><img src={EditIcon} onClick={() => editItem(item.id, item)} /></span>
                                            <img 
                                                src={item.is_active ? ActiveIcon : DeactiveIcon}
                                                className={`pointer ${style.mrg_x_6}`}
                                                onClick={() => ActiveInactiveBanner(item, item.is_active)}
                                            />
                                        {/* </div> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </>

    );
}

export default FeedContentListing;