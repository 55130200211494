import { Base, Ondc, OndcFeed, pcms, prodCms, Product, otipyCoupons } from "./config";
import { delete_data, get_data, patch_data, post_data, post_image, put_data } from "./NetworkHandler";
import { ErrorHandling, SuccessHandling } from "../State";
import {
  getAppItemListEndpoint,
  getZoneListEndPoint,
  getNewAppFeedDraftVersionEndpoint,
  publishAppFeedEndpoint,
  bannerListingEndpoint,
  getWidgetListEndpoint,
  getSubCatListEndpoint,
  getCatListEndpoint,
  uploadImageEndPoint,
  saveAppFeedEndPoint,
  cutOffZoneListEndpoint,
  couponDetailEndpoint,
  burnMappingEndpoint,
  userSegmentEndpoint,
  getFeedContentListEndPoint,
  campaignListingEndpoint,
  searchSkuEndPoint,
  couponListEndpoint,
  getInventryInfoCampaignSkuEndPoint,
  getMaxMinSkuPriceEndPoint,
  productListEndpoint,
  categoryListEndpoint,
  subCategoryListEndpoint,
  refreshminmaxSkuDataEndPoint,
  getUserSegmentInfoEndPoint,
  submitCouponEndpoint,
  createNewCampaignEndPoint,
  uploadBannerImageEndPoint,
  inactiveAppFeedEndPoint,
  moveInactiveToDraftEndPoint,
  SmsDashboardListEndpoint,
  kafkaSwitcherListEndpoint,
  inactiveFeedDetailEndPoint,
  getWalletListEndPoint,
  resetSegmentListEndPoint,
  addSegemntEndPoint,
  cloneCampaignEndPoint,
  getcampaignListEndpoint,
  uploadBannerExcelEndpoint,
  pushNotificationListEndpoint,
  downloadBannerExcelEndpoin,
  addBannerEndPoint,
  searchBannerEndpoint,
  getcampaignDetailEndpoint,
  removeSkuFromZoneEndpoint,
  activateCampaingEndpoint,
  getSubscriptionProductEndpoint,
  getOfferTypeEndpoint,
  updateOffertypeEndpoint,
  subscriptionProductEndpoint,
  getProdDetailsZoneWise,
  getSubscriptionBanner,
  getSubscriptionBannerEndPoint,
  createSubscriptionBannerEndpoint,
  updateSubscriptionBannerEndpoint,
  getProdDetailsEndpoint, // product widget endpoints
  getProductWidgetEndpoint,
  createProductWidgetEndpoint,
  updateProductWidgetEndpoint,
  uploadProductImageEndPoint,
  getProductDetailEndpoint,
  deleteProductWidgetEndpoint,
  getHeaderBannerListEndpoint, // header banner endpoints
  createHeaderBannerEndpoint,
  getHeaderBannerEndpoint,
  updateHeaderBannerEndpoint,
  deleteHeaderBannerEndpoint,
  getMiniFeedDetailEndPoint,
  saveMiniFeedEndPoint,
  updateMiniFeedEndPoint,
  getCatListZoneWiseEndpoint,
  getSubCatListZoneWiseEndpoint,
  cancelCampaignEndPoint,
  getProdCsvDetailEndPoint,
  userSegmentListEndpoint,
  getBonusConstantEndPoint,
  uploadVideoEndPoint,
  getDcListEndPoint,
  createUpdateProductDetailEndpoint,
  getProductVideoDetailEndpoint,
  activeInactiveDetailEnpoint,
  fetchProductInfoEndpoint,
  fetchConstantListEnpoint,
  addNewCommunication,
  getCommunicationListingEndpoint,
  getCommunicationEditEndpoint,
  deleteSmsCampaignEndpoint,
  sendTestCommunicationEndpoint,
  uploadPdfFileEndpoint,
  getMembershipPlanListEndpoint,
  submitMembershipDataEndpoint,
  getMembershipCampListEndpoint,
  getMembershipCampDetailEndpoint,
  fetchCategoryListEndpoint,
  fetchSubCategoryListEndpoint,
  prodDetailKeyListEndpoint,
  listingSectionEndpoint,
  fetchWidgetDetailsEndpoint,
  getWidgetListingEndpoint,
  getNewFeedContentListEndPoint,
  searchNewBannerEndpoint,
  uploadNewBannerExcelEndpoint,
  getVersionsListingEndpoint,
  getNewMiniFeedListEndPoint,
  searchMiniFeedEndPoint,
  prodDetailCsvUploadEndpoint,
  prodDetailZipUploadEndpoint,
  bulkUploadWidgetsInMiniFeedEndPoint,
  addNewBulkCommunication,
  bannerTypeEndpoint,
  fetchSegmentListEndPoint,
  fetchSegmentUserListEndpoint,
  createUserSegmentEndpoint,
  searchUserSegmentEndpoint,
  updateUserSegmentEndpoint,
  deleteSegmentEndpoint,
  getExcelFileInMailEndpoint,
  fetchOndcCityListEndpoint,
  fetchCitywiseProviderEndpoint,
  fetchOndcCategoryListEndpoint,
  fetchOndcStoreProductsEndpoint,
  getOndcZoneListEndPoint,
  getOndcWidgetListingEndpoint,
  fetchOndcWidgetDetailsEndpoint,
  fetchOndcFeedDetailsEndpoint,
  fetchOndcFeedSearchEndpoint,
  ondcFeedNewVersionEndpoint,
  ondcHistoryToDraftFeedEndpoint,
  ondcPublishFeedEndpoint,
  getOndcWidgetTypeEndpoint,
  getOndcCatListEndpoint,
  getOndcStoreProdListEndpoint,
  getOfferListingEndPoint,
  SearchOfferEndPoint,
  getOfferDetailsEndPoint,
  ChangeOfferStatusEndPoint,
  activateUserSegmentEndpoint,
  uploadProdDetails,
  downloadUserListEnpoint,
  getTagListsEndPoint,
  historyToDraftNewAppFeedEndpoint,

} from "./apiEndPoints";

/* _____________________ UTIL FUNCTIONS STARTS _____________________ */

// DROPDOWN TOGGLE
export function toggle(index, class_name = "hide") {
  let child = document.getElementById(`child_${index}`);
  child.classList.toggle(class_name);
}

// formatdate
export function unformatDate(date) {
  if (date.length === 6) return date;
  date = date.split("-");
  date = date[2] + date[1] + date[0];
  return date;
}

// get today's n slot id
export function getTodayNslot() {
  let today = new Date();
  let year =
    String(today.getFullYear()).split("")[2] +
    "" +
    String(today.getFullYear()).split("")[3];
  let dat_value =
    today.getDate() >= 10 ? today.getDate() : "0" + String(today.getDate());
  let month =
    today.getMonth() + 1 > 9
      ? today.getMonth() + 1
      : "0" + (today.getMonth() + 1);
  let slot_id = year + "" + month + "" + dat_value;
  return slot_id;
}

  // function to take input only 0-9 and coma
  export function extractNumbers(inputString) {
    let cleanString = inputString.replace(/[^\d,]/g,'');   
    let numbersArray = cleanString.split(',');
    numbersArray = numbersArray.map(numStr => (numStr.trim()));
    return numbersArray;
  }

// rank change with go feature
// Handle go click to sort the rank accordingly
export const handleRankSortOnGo = (prodDetailList, index, uniqueKey) => {
  debugger;
  let rankList = prodDetailList.map(({ rank }) => parseInt(rank))
  let selProd = prodDetailList[index]
  let rankFlag = selProd.rank

  if (prodDetailList[index].rank == 0) {
    ErrorHandling(`Rank cannot be zero!`)
    return;
  }

  prodDetailList.map((prod, idx) => {
    prodDetailList[idx].show_rank_change = false;
  })


  let currentFlagProd = selProd;
  while (rankList.includes(parseInt(rankFlag))) {
    debugger;
    // finding the index of rankFlag
    let rankflagIdx = prodDetailList.findIndex(prod => prod.rank == rankFlag && prod[uniqueKey] !== currentFlagProd[uniqueKey])

    if (rankflagIdx >= 0) {
      prodDetailList[rankflagIdx].show_rank_change = true

      /* when rank no. is deceased ex: from 10 to 5
        add 1 to rank and setting the rankFlag to the same 
      */
      if (selProd.prev_rank - selProd.rank >= 0 || !selProd.prev_rank) {
        rankFlag = ++prodDetailList[rankflagIdx].rank;
        console.log("rank increased -- >", prodDetailList[rankflagIdx], prodDetailList[rankflagIdx].rank)
      }
      /* when rank no. is increased ex: from 3 to 5
        subtract 1 to rank and setting the rankFlag to the same 
      */
      else {
        rankFlag = --prodDetailList[rankflagIdx].rank;
        console.log("rank decreased -- >", prodDetailList[rankflagIdx], prodDetailList[rankflagIdx].rank)
      }
      currentFlagProd = prodDetailList[rankflagIdx];
    }
    else {
      break;
    }
  }

  return prodDetailList;
};

// deep copy of obj / array
export function deepCopyObject(obj) {
  let deepCopiedObj = JSON.parse(JSON.stringify(obj))
  return deepCopiedObj
}

// upload image util funciton
export async function uploadSelImage(data) {
  let url = Product + uploadImageEndPoint;
  return await post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("image successfully upload!");
        return res.image_id;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// upload video/lottie util function
export async function uploadSelVideo(data) {
  let url = Product + uploadVideoEndPoint;
  return await post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Successfully Upload!");
        return res.id;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}

// download excel from given data
export async function downloadExcelFile(data, fileName, fileExtension) {
  import(('xlsx'))
    .then(({ utils, writeFile }) => {
      const worksheet = utils.json_to_sheet(data);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(workbook, `${fileName}.${fileExtension}`);
    })
    .catch((error) => {
      console.log(error)
    })
}

// DC List = get api
export async function dcList() {
  let url = Base + getDcListEndPoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else {
        return Promise.reject(res.error)
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
    })
}

// dc wise pricing zone 
export async function getDcWisePricingZone(dc_id) {
  let url = pcms + getZoneListEndPoint + `?dc_id=${dc_id}`
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return []
    })
}

// download data via api to maill 
export async function getExcelFileInMail(type) {

  // 1 == product_video
  // 2 == product_list
  // 3 == PDP media

  let url = prodCms + getExcelFileInMailEndpoint + `?type=${type}`;

  return await get_data(url)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.msg)
        return res;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return error;
    })
}

// template replacement string - used in OFFERS
export function generateTempStringValue(templateStr, varValueObj, templateVar ){
  if(!templateVar.length){
    return "";
  }
  let localTempStr = deepCopyObject(templateStr)

  templateVar.map((tempVar) => {
    localTempStr = localTempStr.replace(`{${tempVar}}`, varValueObj[tempVar] );
    // console.log(localTempStr)
  })

  return localTempStr;
}

export function getTemplateVarMaxLength(templateStr, varValueObj, templateVar, excludeVar ){
  let localTempStr = deepCopyObject(templateStr)

  templateVar.map((tempVar) => {
    if(tempVar == excludeVar){
      localTempStr = localTempStr.replace(`{${tempVar}}`, '' );
    }
    else{
      localTempStr = localTempStr.replace(`{${tempVar}}`, varValueObj[tempVar] );
    }
    console.log(localTempStr)
    console.log(localTempStr.length)
  })
  console.log(localTempStr.length)

  return localTempStr.length;
}

/* _____________________ UTIL FUNCTIONS ENDS _____________________ */


// BRANDING > APP FEED > HEADER - SELECTING ZONES - get api
export async function getZoneData() {
  let url = pcms + getZoneListEndPoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Zone list");
      return res;
    }
  });
  return res;
}

// BRANDING > APP FEED > BRANNER LISTING - ITEMS IN BANNER LISTING - get api
export async function getItemsList(zone, banner) {
  let url = `${pcms}${getAppItemListEndpoint}?pricing_zone_id=${zone}&status=${banner}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Banner items list");
      return res;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      ErrorHandling(error.message)
    });
  return res;
}

// BRANDING > APP FEED - CREATE NEW DRAFT - get api
export async function getNewAppFeedDraftVersion(zone, newFeedTag, topPadding) {
  let url = `${pcms}${getNewAppFeedDraftVersionEndpoint}?pricing_zone_id=${zone}&feed_tag=${newFeedTag}&top_padding=${topPadding}`;
  await get_data(url)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Draft Feed Successfully");
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// BRANDING > APP FEED - PUBLISH FEED - get api
export async function publishAppFeed(feedId) {
  let url = `${pcms}${publishAppFeedEndpoint}?feed_id=${feedId}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        SuccessHandling("New Version Published Successfully!");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// BRANDING > APP FEED > GET INACTIVE FEED - get api
export async function inactiveAppFeed(selZoneId) {
  let url = `${pcms}${inactiveAppFeedEndPoint}?pricing_zone_id=${selZoneId}`;
  return await get_data(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return {};
    });
}

// BRANDING > APP FEED > GET INACTIVE FEED - get api
export async function moveInactiveToDraft(data) {
  let url = `${pcms}${moveInactiveToDraftEndPoint}?pricing_zone_id=${data.pricing_zone_id}`;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        return true;
      } else return Promise.reject(res.error);
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return false;
    });
}

// BRANDING > APP FEED > GET INACITVE FEED DETAILS - get api
export async function inactiveFeedDetail(feedId) {
  let url = `${pcms}${inactiveFeedDetailEndPoint}?feed_id=${feedId}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {};
    });
}

// BRANDING > APP FEED - ADD / EDIT WIDGET - get api
export async function getWidgetList() {
  let url = pcms + getWidgetListEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// BRANDING > APP FEED > ADD / EDIT WIDGET - get api
export async function getSubCatList() {
  let url = pcms + getSubCatListEndpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return res.error;
      }
    })
    .catch((error) => {
      return [];
    });
}
// BRANDING > APP FEED > ADD / EDIT WIDGET - get api - sub cat list zone wise
export async function getSubCatListZoneWise(zoneId) {
  let url = pcms + getSubCatListZoneWiseEndpoint + `pricing_zone_id=${zoneId}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return res.error;
      }
    })
    .catch((error) => {
      return [];
    });
}

// BRANDING > APP FEED > ADD / EDIT WIDGET - get api
export async function getCatList() {
  let url = pcms + getCatListEndpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return res.error;
      }
    })
    .catch((error) => {
      return [];
    });
}

// BRANDING > APP FEED > ADD / EDIT WIDGET - get api - cat list zone wise
export async function getCatListZoneWise(zoneId) {
  let url = pcms + getCatListZoneWiseEndpoint + `?pricing_zone_id=${zoneId}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return res.error;
      }
    })
    .catch((error) => {
      return [];
    });
}

// BRANDING > APP FEED > ADD /EDIT WIDGET - post api
export async function uploadImageToServe(body) {
  let url = Product + uploadImageEndPoint;
  return post_data(url, body)
    .then((res) => {
      if (res.succes) {
        return res.image_id;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// BRANDING > APP FEED > ADD / EDIT WIDGET - util funx
export function validateData(obj, checkWidgetTag) {
  let isValid = true,
    message = "",
    data = {};
  let pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
  if (obj.title.trim() === "" && obj.title_type.name !== "No Heading") {
    isValid = false;
    message = "Please enter a valid Header Title";
  } else if (obj.rank === "" || obj.rank < 1) {
    isValid = false;
    message = "Please enter a valid rank";
  }
  else if (obj.no_of_children === "" || obj.no_of_children < 3 || obj.no_of_children > 14) {
    isValid = false;
    message = "Sku limit should be between 3 to 13";
  }
  else if (
    (obj.widget_type === 'virtual_cat_wd' ||
      obj.widget_type === "large_prod_wd" ||
      obj.widget_type === "medium_prod_wd" ||
      obj.widget_type === "square_prod_wd" ||
      obj.widget_type === 'image_list_wd' ||
      obj.widget_type === 'image_video_wd' ||
      obj.widget_type === 'subscription_wd'
    ) && (
      obj.content_id == 0 || obj.content_id[0] === 0
    )
  ) {
    isValid = false;
    message = "Please enter banner id";
  } else if (
    (obj.name === "cat_prod_wd" || obj.name === "subcat_prod_wd") &&
    !obj.selCat
  ) {
    isValid = false;
    message = "please select catagory/sub category";
  } else if (
    obj.widget_type === "subcat_prod_wd" &&
    obj.widget_type === "cat_prod_wd" &&
    obj.widget_type === "virtual_cat_wd" &&
    obj.widget_type === "subscription_wd" &&
    !obj.widget_bg_color
  ) {
    isValid = false;
    message = "Please enter widget icon color";
  } else if (
    obj.widget_type === "subcat_prod_wd" &&
    obj.widget_type === "cat_prod_wd" &&
    obj.widget_type === "virtual_cat_wd" &&
    obj.widget_type === "subscription_wd" &&
    !obj.background_color
  ) {
    isValid = false;
    message = "Please enter background color";
  } else if (
    (obj.widget_type === "image_carousal_wd" ||
      obj.widget_type === "video_carousal_wd" ||
      obj.widget_type === "dynamic_banner_wd" ||
      obj.widget_type === "dynamic_end_to_end_banner_wd") &&
    (!obj.content_height || !obj.content_width)) {
    isValid = false;
    message = "Please Enter Media Height and width";
  }
  // to check for widget tag
  else if (checkWidgetTag && !obj.widget_tag) {
    isValid = false;
    message = "Please enter Widget Tag";
  }
  // to check for bottom padding
  else if (checkWidgetTag && (!obj.bottom_padding || obj.bottom_padding < 0 || obj.bottom_padding >= 25)) {
    isValid = false;
    message = "Please enter Widget bottom padding between 0px to 24px";
    console.log(obj.bottom_padding);
  } else if (obj.widget_bg_color && !pattern.test(obj.widget_bg_color)) {
    isValid = false;
    message = "Please enter widget icon color in hex code";
  } else if (obj.background_color && !pattern.test(obj.background_color)) {
    isValid = false;
    message = "Please enter background color in hex code";
  }
  else if (obj.bg_color_1 && !pattern.test(obj.bg_color_1)) {
    isValid = false;
    message = "Please enter background color - start in hex code";
  } else if (obj.bg_color_2 && !pattern.test(obj.bg_color_2)) {
    isValid = false;
    message = "Please enter background color - End in hex code";
  } else if (obj.text_color && !pattern.test(obj.text_color)) {
    isValid = false;
    message = "Please enter Text Color in hex code";
  } else if (obj.title_text_color && !pattern.test(obj.title_text_color)) {
    isValid = false;
    message = "Please enter Header Text Color in hex code";
  } else if (
    obj.title_background_color &&
    !pattern.test(obj.title_background_color)
  ) {
    isValid = false;
    message = "Please enter Header Background Color in hex code";
  } else {
    data = {
      id: obj.id,
      background_color: obj.background_color,
      background_image: obj.background_image,
      category_id:
        obj.widget_type === "subcat_prod_wd" ? obj.selSubCat.cat_id : 0,
      content_id:
        typeof obj.content_id === "object" ? obj.content_id : [obj.content_id],
      rank: obj.rank,
      sub_title: obj.sub_title,
      title: obj.title,
      widget_bg_color: obj.widget_bg_color,
      widget_icon: obj.widget_icon,
      widget_type: obj.widget_type,
    };
    console.log(typeof (obj.content_id) === "object", data.content_id);
  }
  if (isValid) {
    return data;
  } else {
    ErrorHandling(message);
    return false;
  }
}

// BRANDING > APP FEED / WEB FEED > ADD / EDIT WIDGET - post api
export async function saveAppFeed(data) {
  let url = pcms + saveAppFeedEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Feed saved successfully");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// BRANDING > APP FEED / EDIT WIDGET - post api 4001 handle
export async function saveAppFeedWidget(data) {
  let url = pcms + saveAppFeedEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Feed saved successfully");
        return res;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code == 4001) {
        return error;
      }
      else {
        ErrorHandling(error.message);
        return error;
      }
    });
}

// BRANDING > APP FEED / EDIT WIDGET -get api for constants
export async function fetchConstantList() {
  let url = pcms + fetchConstantListEnpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
    })
}

// BRANDING > WEB FEED > BRANNER LISTING - ITEMS IN BANNER LISTING - get api
export async function getWebItemsList(zone, banner) {
  let url = `${pcms}${getAppItemListEndpoint}?pricing_zone_id=${zone}&status=${banner}&feed_type=web`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Banner items list");
      return res;
    }
  });
  return res;
}

// BRANDING > WEB FEED - CREATE NEW DRAFT - get api
export async function getNewWebFeedDraftVersion(zone) {
  let url = `${pcms}${getNewAppFeedDraftVersionEndpoint}?pricing_zone_id=${zone}&feed_type=web`;
  await get_data(url)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Draft Feed Successfully");
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// BRANDING > Banner > Feed Content List - get api
export async function getFeedContentListing(zoneId, contentType, nSlot) {
  let url = `${pcms}${getFeedContentListEndPoint}?pricing_zone_id=${zoneId}&banner_type=${contentType}&n_slot_id=${nSlot}`;
  let res = await get_data(url);
  return res;
}

// FEED CONTETNT >
export async function addNewBanner(data) {
  let url = `${pcms}${addBannerEndPoint}`;
  let res = await post_data(url, data);
  return res;
}

// FEED CONTETNT >
export async function updateBanner(data) {
  let url = `${pcms}${addBannerEndPoint}`;
  let res = await post_data(url, data);
  return res;
}

// FEED CONTETNT >
export async function searchBannerApi(banner_text) {
  let url = `${pcms}${searchBannerEndpoint}?search_txt=${banner_text}`;
  let res = await get_data(url);
  return res;
}

// FEED CONTETNT >
export async function uploadBannerExcel(bannerList) {
  let url = `${pcms}${uploadBannerExcelEndpoint}`;
  let res = await post_data(url, bannerList);
  return res;
}

// FEED CONTETNT >
export async function downloadBannerExcel(nslot) {
  let url = `${pcms}${downloadBannerExcelEndpoin}?n_slot_id=${nslot}`;
  let res = await get_data(url);
  return res;
}

// FEED CONTETNT >
export async function uploadBannerImage(data) {
  let url = `${Base}${uploadBannerImageEndPoint}`;
  let res = await post_image(url, data);
  return res;
}

// BONUS MANAGEMENT > - get api
export async function getBonusConstantList() {
  // let url = `${Base}${getZoneListEndPoint}`;
  let url = `${Base}${getBonusConstantEndPoint}`;
  let res = await get_data(url);
  return res;
}

//Bonus Management >> communication Listing >> getdata of communication listing

export async function getCommunicationListing(status, page, slot) {
  let url = `${Base}${getCommunicationListingEndpoint}?status=${status}&page=${page}&n_slot_id=${slot}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Banner items list");
      return res;
    }
  });
  return res;
}

//Bonus Management >> campaign >> getdata of communication listing

export async function deleteSmsCampaign(id) {
  let url = `${Base}${deleteSmsCampaignEndpoint}?campaign_id=${id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Banner items list");
      return res;
    }
  });
  return res;
}

//Bonus Management >> campaign >> getdata of communication listing

export async function sendTestCommunication(data) {
  let url = `${Base}${sendTestCommunicationEndpoint}`;
  let res = await post_data(url, data)
    .then((res) => {
      if (res.success) {
        // console.log("Banner items list");
        SuccessHandling(res.message)
        return true;
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return false;
    })
  return res
}

//Bonus Management >> campaign >> get user data for which communication has been sent
export async function downloadUserList(id){
  let url = Base + downloadUserListEnpoint + '?campaign_id=' + id;
  return await get_data(url)
  .then((res) => {
    if (res.success) {
      return res.consumer_list;
    }
    else Promise.reject(res.error);
  })
  .catch((error) => {
    ErrorHandling(error.message);
    return [];
  })

}

export async function updateBonusCommunication(communicationList) {
  let url = `${Base}${addNewCommunication}`;
  let res = await post_data(url, communicationList);
  return res;
}

export async function updateBukBonusCommunication(communicationList) {
  let url = `${Base}${addNewBulkCommunication}`;
  let res = await post_data(url, communicationList);
  return res;
}

export async function getBonusDetail(id) {
  let url = `${Base}${getCommunicationEditEndpoint}?campaign_id=${id}`;
  let res = await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return new Promise.reject(res.error);
    })
    .catch((error) => {
      return { success: false }
    })
  return res;
}

// BRANDING > ORDER SUCCESS BANNER - ZONE LIST - get api
export async function getOrderBannerZonewiseList() {
  let url = pcms + getZoneListEndPoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Zone list");
      return res;
    }
  });
  return res;
}

// BRANDING > ORDER SUCCESS BANNER - ORDER BANNER LIST - get api
export async function getOrderBannerListing(zoneId, isActive) {
  let url = `${Product}${bannerListingEndpoint}?pricing_zone_id=${zoneId}&is_active=${isActive}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Banner items list");
      return res;
    }
  });
  return res;
}

// BRANDING > ORDER SUCCESS BANNER - UPDATE BANNER - put api
export async function updateOrderBanner(data) {
  let url = Product + bannerListingEndpoint;
  await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// BRANDING > ORDER SUCCESS BANNER - ADD BANNER - post api
export async function addNewOrderBanner(data) {
  let url = Product + bannerListingEndpoint;
  await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

// BRANDING > ORDER SUCCESS BANNER - BANNER TYPE LIST - get api
export async function getOrderBannerTypeList() {
  let url = Product + bannerTypeEndpoint;
  let res = await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return [];
    })
  return res;
}

// CUT OFF TIME > CUT OFF ZONE LIST - get api
export async function getCutOffZoneListing() {
  let url = Product + cutOffZoneListEndpoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Zone list");
      return res;
    }
  });
  return res;
}
// CUT OFF TIME -> UPDATE - post api
export async function updateCutOffTime(data) {
  let url = Product + cutOffZoneListEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("CutOff Time updated successfully");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// COUPONS > COUPON DETAILS - COUPON LIST - get coupon list - get api
export async function getCouponList() {
  let url = Base + couponDetailEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// COUPONS > COUPON DETAILS - COUPON LIST - enable disable coupon - put api
export async function enableDisableCoupon(data) {
  let url = Base + couponDetailEndpoint;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        let message = "The Coupon Activated Successfully";

        if (!data.is_active) message = "The Coupon Deactivated Successfully";

        SuccessHandling(message);
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// COUPONS > COUPON DETAILS > COUPON LIST - create new coupon - post api
export async function createNewCoupon(data) {
  let url = Base + couponDetailEndpoint;
  return post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Coupon added Successfully");
        return true;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// COUPONS > COUPON LIST - get api
export async function getCouponDetailList(zone_id, status_id) {
  let url = `${Base}${couponListEndpoint}?pricing_zone_id=${zone_id}&is_active=${status_id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Coupon list");
      return res;
    }
  });
  return res;
}
// MARKETING > BURN MAPPING LISTING - get api
export async function getBurnMappingList() {
  let url = pcms + burnMappingEndpoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

// MARKETING > USER SEGMENT LISTING - get api
export async function getUserSegmentList() {
  let url = pcms + userSegmentEndpoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

// MARKETING > UPDATE BURN MAPPING LIST - post api
export async function updateBurnMappingList(data) {
  let url = pcms + burnMappingEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Burn mapping list updated successfully");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// MARKETING > UPDATE USER SEGMENT LIST - post api
export async function updateUserSegmentList(data) {
  let url = pcms + userSegmentEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("User Segment list updated successfully");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

// MARKETING > GET CAMPAIGN LISTING - get api
export async function getCampaignList(slotId) {
  let url = `${pcms}${campaignListingEndpoint}?slot_id=${slotId}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Campaign list");
      return res;
    }
  });
  return res;
}

// MARKETING > CREATE CAMPAIGN - seacrh sku in popup - get api
export async function searchSku(zoneId, searchText) {
  let url = `${Base}${searchSkuEndPoint}?pricing_zone_id=${zoneId}&search_text=${searchText}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.products;
      } else {
        return Product.reject(res.error);
      }
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}

// MARKETING > CREATE CAMPAIGN - get max min sku price - post api
export async function getMaxMinSkuPrice(skuId, zoneList) {
  let selZone = [];
  zoneList.map((item) => {
    if (item.is_selected) {
      selZone.push(item.id);
    }
  });
  let data = {
    prod_id: skuId,
    zone_id: selZone,
  };
  let url = pcms + getMaxMinSkuPriceEndPoint;
  return post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return {};
    });
}

// MARKETING > CREATE CAMPAIGN - get sku stock info - post api
export async function getInventryInfoCampaignSku(skuId, zoneList) {
  let selZone = [];
  zoneList.map((item) => {
    if (item.is_selected) {
      selZone.push(item.id);
    }
  });
  let nSlot = getTodayNslot();
  let data = {
    n_slot_id: nSlot,
    prod_ids: [skuId],
    zone_ids: selZone,
  };
  let url = pcms + getInventryInfoCampaignSkuEndPoint;
  return post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}

// MARKETING >  CREATE CAMPAIGN > REFRESH SKU DATA - get sku min max data after updating selected zone list - post api
export async function refreshminmaxSkuData(data) {
  let url = pcms + refreshminmaxSkuDataEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}

// MARKETING >  CREATE CAMPAIGN > get uset segent data - get api
export async function getUserSegmentInfo() {
  let url = pcms + getUserSegmentInfoEndPoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}

// MARKETING > CREATE CAMPAIGN > submit data for new campaign - post api
export async function createNewCampaign(data) {
  let url = pcms + createNewCampaignEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data.message);
        return res;
      } else return Promise.reject(res);
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
}

// MARKETING || WIDGET > CREATE CAMPAIGN > get user segment data - post api
export async function userSegmentList() {
  let url = pcms + userSegmentListEndpoint;
  return await post_data(url, {})
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}

// MARKETING > CAMPAIGN > get Product details after csv upload
export async function getProdCsvDetail(body) {
  let url = `${pcms}${getProdCsvDetailEndPoint}`;
  return await post_data(url, body)
    .then((res) => {
      if (res.success) return res;
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error.message);
      return res;
    });
}

// MARKETING > CLONE CAMPAIGN > get data for cloning campaign
export async function cloneCampaign(id) {
  let url = `${pcms}${cloneCampaignEndPoint}?campaign_id=${id}&is_clone=true`;
  return await get_data(url)
    .then((res) => {
      if (res.success) return res.data;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    });
}

//Marketing >> Campaign Listing >> getdata of campaign list
export async function getCampaignListing(n_slot, status, zoneList) {
  let selZone = [];
  zoneList.map((item) => {
    if (item.is_selected) {
      selZone.push(item.id);
    }
  });
  let url = `${pcms}${getcampaignListEndpoint}`;
  let data = {
    n_slot_id: n_slot,
    zone_ids: selZone,
    status: status,
  };
  let res = await post_data(url, data).then((res) => {
    console.log(res);
    return res;
  });

  return res;
}

// Marketing >> Campaign Details >> get details of purticular campaign

export async function getCampaignDetails(id) {
  let url = `${pcms}${getcampaignDetailEndpoint}`;
  let data = {
    campaign_id: id,
    zones_list: [],
  };
  let res = await post_data(url, data);
  return res;
}

//Marketing >> Activate/deactivate campaign >> to activate and deactivate the campaign

export async function changeCampaignStatus(campain_id, is_active, deactivateRunnningCampain) {
  let url = `${pcms}${activateCampaingEndpoint}?campaign_id=${campain_id}&is_active=${is_active}&deactivate_runnning_campain=${deactivateRunnningCampain}`;
  let res = await post_data(url, {});
  return res;
}

//Marketing >> Activate/deactivate campaign >> to activate and deactivate the campaign

export async function cancelCampaign(campaign_id) {
  let url = `${pcms}${cancelCampaignEndPoint}?campaign_id=${campaign_id}`;
  let res = await get_data(url);
  return res;
}

// Marketing >> Remove Sku >> Remove sku from purticular/all zones

export async function removeSkusFromZones(campaign_id, prod_id, zone_id) {
  let url = `${pcms}${removeSkuFromZoneEndpoint}`;
  let data = {
    campaign_id,
    prod_id,
    zone_id,
  };
  let res = await post_data(url, data);
  return res;
}

//Marketing >> Bonus Management >> get segment data
export async function getWallteRechargeList(wallet_type) {
  let url = `${Base}${getWalletListEndPoint}?is_reward=${wallet_type.id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

export async function resetSegement(wallet_type) {
  let url = `${Base}${resetSegmentListEndPoint}?is_reward=${wallet_type.id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

export async function getScheduleItem(obj_id) {
  let url = `${Base}${getWalletListEndPoint}?object_id=${obj_id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

export async function addSegmentItem(data) {
  let url = `${Base}${addSegemntEndPoint}`;
  let res = await post_data(url, data).then((res) => {
    if (res.success) {
      return res;
    }
  });
  return res;
}

// MARKETING >  CREATE/EDIT MEMBERSHIP CAMPAIGN > get membership plan list - get api
export async function getMembershipPlanList() {
  let url = `${Base}${getMembershipPlanListEndpoint}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) return res.data;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        plans: [],
      };
    });
}

// MARKETING >  CREATE/EDIT MEMBERSHIP CAMPAIGN > submit data - post api
export async function submitMembershipData(data) {
  let url = `${pcms}${submitMembershipDataEndpoint}`;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) return res;
      else return Promise.reject(res);
    })
    .catch((res) => {
      return res;
    });
}

// MARKETING >   MEMBERSHIP CAMPAIGN LISTING > campaign Detai - post api
export async function getMembershipCampDetail(body) {
  let url = `${pcms}${getMembershipCampDetailEndpoint}`;
  return await post_data(url, body)
    .then((res) => {
      if (res.success) return res;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        data: {},
      };
    });
}

// MARKETING >   MEMBERSHIP CAMPAIGN LISTING > campaign listing - get api
export async function getMembershipCampList() {
  let url = `${pcms}${getMembershipCampListEndpoint}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) return res;
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        data: [],
      };
    });
}

// COUPON LIST > GET PRODUCT ITEMS
export async function getProductItems(item) {
  let url = `${Product}${productListEndpoint}?text=${item}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Products list");
      return res;
    }
  });
  return res;
}
export async function getCategoryItems() {
  let url = `${pcms}${categoryListEndpoint}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Category list");
      return res;
    }
  });
  return res;
}
export async function getSubCategoryItems() {
  let url = `${pcms}${subCategoryListEndpoint}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Sub Category list");
      return res;
    }
  });
  return res;
}

export async function submitCoupon(data) {
  let url = `${Base}${submitCouponEndpoint}`;
  return post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((err) => {
      ErrorHandling(err.message);
      return [];
    });
}
// SMS DASHBOARD - get list
export async function getSmsDashboardList(nSlotId) {
  let url = `${Base}${SmsDashboardListEndpoint}?n_slot_id=${nSlotId}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("SMS Dashboard list");
      return res;
    }
  });
  return res;
}
// KAFKA SWITCHER - get list
export async function getKafkaSwitcherList() {
  let url = `${Base}${kafkaSwitcherListEndpoint}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("Kafka switcher list");
      return res;
    }
  });
  return res;
}

// KAFKA SWITCHER - post api
export async function updateKafkaSwitcherList(data) {
  let url = `${Base}${kafkaSwitcherListEndpoint}`;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Kafka Switcher updated successfully");
        return true;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

export async function getPNTextDetails(id) {
  let url = `${pcms}${pushNotificationListEndpoint}?campaign_id=${id}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      // console.log("PN Text list");
      return res;
    }
  });
  return res;
}

// Subscription >> subscription product >> get subscritpion product list

export async function getSubscriptionProductList(zone_id) {
  let url = `${pcms}${subscriptionProductEndpoint}?pricing_zone_id=${zone_id}`;
  let res = await get_data(url);
  return res;
}

/***** product widget function */
export async function uploadProductImage(data) {
  let url = `${Base}${uploadProductImageEndPoint}`;
  let res = await post_image(url, data);
  return res;
}
export async function getProductWidgetList(pricing_zone_id, image_type, searchText, page) {
  let url = `${pcms}${getProductWidgetEndpoint}?pricing_zone_id=${pricing_zone_id}&image_type=${image_type}&search_text=${searchText}&page=${page}`;
  let res = await get_data(url);
  return res;
}

export async function createProductWidget(data) {
  let url = `${pcms}${createProductWidgetEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

export async function getProductWidgetDetail(feed_prod_id) {
  let url = `${pcms}${getProductDetailEndpoint}?feed_prod_id=${feed_prod_id}`;
  let res = await get_data(url);
  return res;
}

export async function editProductWidget(data) {
  let url = `${pcms}${updateProductWidgetEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

export async function deleteProductWidget(data) {
  let url = `${pcms}${deleteProductWidgetEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

// upload pdf file - post api
export async function uploadPdfFile(data) {
  let url = Base + uploadPdfFileEndpoint;
  return post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Successfully Uploaded!!")
        return res.id
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return "";
    })
}

/*********END PRODUCT WIDGET FUNCTION */

export async function uploadHeaderBannerImage(data) {
  let url = `${Base}${uploadProductImageEndPoint}`;
  let res = await post_image(url, data);
  return res;
}
export async function getHeaderBannerList(pricing_zone_id, searchText) {
  let url = `${pcms}${getHeaderBannerListEndpoint}?pricing_zone_id=${pricing_zone_id}&search_text=${searchText}`;
  let res = await get_data(url);
  return res;
}

export async function createHeaderBanner(data) {
  let url = `${pcms}${createHeaderBannerEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

export async function getHeaderBannerDetail(pricing_zone_id, id) {
  let url = `${pcms}${getHeaderBannerEndpoint}?pricing_zone_id=${pricing_zone_id}&search_text=${id}`;
  let res = await get_data(url);
  return res;
}

export async function editHeaderBanner(data) {
  // debugger;
  let url = `${pcms}${updateHeaderBannerEndpoint}?pricing_zone_id=${data.pricing_zone_ids[0]}&search_text=${data.id}`;
  let res = await post_data(url, data);
  return res;
}

export async function deleteHeaderBanner(data) {
  let url = `${pcms}${deleteHeaderBannerEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

/*********END HEADER BANNER IMAGE ******* */

export async function createSubscriptionProduct(data) {
  let url = `${pcms}${subscriptionProductEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

export async function editSubscriptionProduct(data) {
  let url = `${pcms}${subscriptionProductEndpoint}`;
  let res = await put_data(url, data);
  return res;
}

export async function getProductDetailsZoneWise(prod_id) {
  let url = `${pcms}${getProdDetailsZoneWise}?prod_id=${prod_id}`
  let res = get_data(url);
  return res;
}

//subscription >> subscritption product >> get all type of offers

export async function getAllOffertype() {
  let url = `${pcms}${getOfferTypeEndpoint}`
  let res = await get_data(url);
  return res;
}

//subscription >> offer type >> Edit offer type details
export async function updateOfferType(data) {
  let url = `${pcms}${updateOffertypeEndpoint}`
  let res = await put_data(url, data);
  return res;
}

//subscription >> subscription top banner >> get all banner
export async function getSubscriptionTopBanner() {
  let url = `${pcms}${getSubscriptionBannerEndPoint}`
  let res = await get_data(url);
  return res;
}

export async function createSubscriptionBanner(data) {
  let url = `${pcms}${createSubscriptionBannerEndpoint}`;
  let res = await post_data(url, data);
  return res;
}

export async function updateSubscriptionBanner(data) {
  let url = `${pcms}${updateSubscriptionBannerEndpoint}`;
  let res = await put_data(url, data);
  return res;
}

export async function getSubscriptionProduct(prod_id, zone_id) {
  let url = `${pcms}${getProdDetailsEndpoint}?prod_id=${prod_id}&pricing_zone_id=${zone_id}`;
  let res = await get_data(url);
  return res;
}

// BRANDING > MINI FEED > MINI FEED DETAIL - WIDGET etc IN FEED LISTING - get api
// 645a217aeabc7bd829013eea
// BRANDING > MINI FEED Listing - get api
export async function getMiniFeedListing(zone, status) {
  let url = `${pcms}${getMiniFeedDetailEndPoint}?pricing_zone_id=${zone}&status=${status}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        // console.log("Banner items list");
        return res.data.feeds;
      }
      else Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

export async function getMiniFeedDetail(feedId) {
  let url = `${pcms}${getMiniFeedDetailEndPoint}?feed_id=${feedId}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        // console.log("Banner items list");
        return res.data.feeds;
      }
      else Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

// BRANDING > MINI FEED > SAVE MINI FEED - WIDGET etc IN FEED LISTING - post api
export async function saveMiniFeed(data) {
  let url = pcms + saveMiniFeedEndPoint;
  return await post_data(url, data)
    .then((res) => {
      console.log(res);
      if (res.success) {
        SuccessHandling("Feed saved successfully");
        // return res.data.feed_id;
        return res;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      console.log(error)
      if (error.code !== 4001) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

// BRANDING > MINI FEED LISTING > UPDATE MINI FEED - GET api
export async function updateMiniFeedDetail(feedId) {
  let url = `${pcms}${updateMiniFeedEndPoint}?feed_id=${feedId}`;
  //return await get_data(url)
  let res = await get_data(url);
  return res;
}

// --------------------------------------------------------------

// BRANDING > NEW APP FEED MANAGEMENT - New app feed details based on status - get api
export async function fetchNewAppFeedDetails(status, pricing_zone_id) {

  let url = `${prodCms}${getAppItemListEndpoint}?status=${status}&pricing_zone_id=${pricing_zone_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        draft_exist: false,
        feed_list: [],
      }
    })
}

//  BRANDING > NEW APP FEED MANAGEMENT - ondc feed search - get api
export async function fetchAppFeedSearch(id) {
  let url = `${prodCms}${fetchOndcFeedSearchEndpoint}?feed_id=${id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {}
    })
}

// BRANDING > NEW APP FEED MANAGEMENT - New app feed draft creation - post api
export async function newAppFeedNewVersion( feedId, pricingZoneId, feedTag, topPadding ) {

  let url = `${prodCms}${getNewAppFeedDraftVersionEndpoint}`;
  let body = {
    pricing_zone_id: pricingZoneId,
    feed_type: 0,
    feed_tag: feedTag,
    top_padding: topPadding,
    // feed_id: str
  }
  
  return await post_data(url, body)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data.message);
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })

}

// BRANDING > NEW APP FEED MANAGEMENT - publish feed - put api
export async function newAppFeedPublishFeed(feedId, pricingZoneId) {

  let url = `${prodCms}${publishAppFeedEndpoint}`;
  let body = {
    feed_id: feedId,
    pricing_zone_id: pricingZoneId
  }
  return await put_data(url, body)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Successfully App Feed Published!!");
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}

// BRANDING > NEW APP FEED MANAGEMENT - edit draft feed - put api
export async function createUpdateOndcDraftFeed(data) {
  let url = prodCms + saveAppFeedEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Successfully Updated App Feed !!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

// BRANDING > NEW APP FEED MANAGEMENT - history to draft creation - put api
export async function historyToDraftNewAppFeed(feedId, pricingZoneId) {
  let url = prodCms + historyToDraftNewAppFeedEndpoint;
  let data = {
    feed_id: feedId,
    pricing_zone_id: pricingZoneId,
    feed_type: 0,
  };
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.message);
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}


// --------------------------------------------------------------

// PRODUCT DETAIL VIDEO - CREATE AND UPDATE PRODUCT WISE VIDEO - post api
export async function createUpdateProductDetail(data) {
  let url = pcms + createUpdateProductDetailEndpoint;
  return post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(`Successfully ${data.prod_video_id ? 'Updated' : 'Added'} !!`)
        return true;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return false;
    })
}

// PRODUCT DETAIL VIDEO - GET PRODUCT LSITING - get api 
export async function fetchProductListing(distributor_id, searchText) {
  let url = pcms + getProductVideoDetailEndpoint + `?distributor_id=${distributor_id}&search_text=${searchText}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}


// PRODUCT DETAIL VIDEO - ACTIVE INATVIE PRODUCT LSITING - post api 
export async function activeInactiveDetail(data) {
  let url = pcms + activeInactiveDetailEnpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(`Successfully ${data.is_active ? "Activated" : "Deactivated"}!!`)
        return true;
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return false;
    })
}


// PRODUCT DETAIL VIDEO - GET PRODUCT LSITING - get api 
export async function fetchProductInfo(id) {
  let url = pcms + fetchProductInfoEndpoint + `?prod_video_id=${id}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {};
    })
}

/* ----------  PDP  ---------- */

// PDP - CATEGORY - GET CATEGORY LISTING - get api 
export async function fetchCategoryList() {
  let url = pcms + fetchCategoryListEndpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}

// PDP - SUB CATEGORY - GET CATEGORY LISTING - get api 
export async function fetchSubCategoryList(catId, page) {
  let url = pcms + fetchSubCategoryListEndpoint + `?category_list=${catId}&page=${page}`;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}

// PDP - PRODUCT DETAIL KEYS - GET LISTING - get api
export async function fetchProdDetailKeyList() {
  let url = prodCms + prodDetailKeyListEndpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}

// PDP - PRODUCT DETAIL KEYS - ADD EDIT KEYS - post api
export async function updateAddProdDetailKey(body) {
  let url = prodCms + prodDetailKeyListEndpoint;
  return post_data(url, body)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data)
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}

/* --------------- WIDGET LISTING ---------------*/

/* --------------- WIDGET LISTING ---------------*/

//BRANDING > WIDGET LISTING - ITEMS IN WIDGET LISTING - get api
export async function getWidgetListing(show_inactive, android, ios, zone) {
  let url = `${prodCms}${getWidgetListingEndpoint}?show_inactive=${show_inactive}&android=${android}&ios=${ios}&zone=${zone}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res.data;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      console.log(error.message);
      ErrorHandling(error.message);
      return []
    });
  return res;
}

//BRANDING > WIDGET LISTING > ITEMS IN WIDGET LISTING - put api
export async function enableDisableWidget(data) {
  let url = prodCms + getWidgetListingEndpoint;
  console.log(url);
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        let msg = "Widget is Activated Successfully";
        if (!data.is_active)
          msg = "Widget is Deactivated Successfully";
        SuccessHandling(msg);
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    });
}

//BRANDING > WIDGET LISITNG > SEARCH IN WIDGET LISTING / get widget detail  - get api
export async function searchWidget(search_text, flag) {
  let url = `${prodCms}${fetchWidgetDetailsEndpoint}?search_text=${search_text}&get_all=${flag ? 1 : 0}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res.data;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      console.log(error.message);
      ErrorHandling(error.message);
      return []
    });
  return res;
}

/* --------------- ADD EDIT WIDGET  ---------------*/

//BRANDING > ADD EDIT WIDGET - validate the fields - util funx
export function validateWidgetData(obj, zoneList) {
  let isValid = true,
    message = "";
  let pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
  let selzone = zoneList.filter((zone) => zone.is_selected);

  if (!selzone.length) {
    isValid = false;
    message = "Please Selected Zones!";
  }
  else if (!obj.widget_tag) {
    isValid = false;
    message = "Please enter Widget Tag";
  }
  else if (obj.no_of_children === "" || obj.no_of_children < 3 || obj.no_of_children > 14) {
    isValid = false;
    message = "Sku limit should be between 3 to 13";
  }
  else if (
    (obj.widget_type === 'virtual_cat_wd' ||
      obj.widget_type === "large_prod_wd" ||
      obj.widget_type === "medium_prod_wd" ||
      obj.widget_type === "square_prod_wd" ||
      obj.widget_type === 'image_list_wd' ||
      obj.widget_type === 'image_video_wd' ||
      obj.widget_type === 'subscription_wd'
    ) && (
      obj.content_id == 0 || obj.content_id[0] === 0
    )
  ) {
    isValid = false;
    message = "Please enter banner id";
  } else if (
    (obj.name === "cat_prod_wd" || obj.name === "subcat_prod_wd") &&
    !obj.selCat
  ) {
    isValid = false;
    message = "please select catagory/sub category";
  } else if (
    (obj.widget_type === "image_carousal_wd" ||
      obj.widget_type === "video_carousal_wd" ||
      obj.widget_type === "dynamic_banner_wd" ||
      obj.widget_type === "dynamic_end_to_end_banner_wd") &&
    (!obj.content_height || !obj.content_width)) {
    isValid = false;
    message = "Please Enter Media Height and width";
  }
  else if ((obj.bottom_padding === '' || obj.bottom_padding < 0 || obj.bottom_padding >= 25)) {
    isValid = false;
    message = "Please enter Widget bottom padding between 0px to 24px";
    console.log(obj.bottom_padding);
  } else if (obj.bg_color_1 && !pattern.test(obj.bg_color_1)) {
    isValid = false;
    message = "Please enter background color - start in hex code";
  } else if (obj.bg_color_2 && !pattern.test(obj.bg_color_2)) {
    isValid = false;
    message = "Please enter background color - End in hex code";
  } else if (obj.text_color && !pattern.test(obj.text_color)) {
    isValid = false;
    message = "Please enter Text Color in hex code";
  } else if (obj.title.trim() === "" && obj.title_type.name !== "No Heading") {
    isValid = false;
    message = "Please enter a valid Header Title";
  } else if (obj.title_text_color && !pattern.test(obj.title_text_color)) {
    isValid = false;
    message = "Please enter Header Text Color in hex code";
  } else if (
    obj.title_background_color &&
    !pattern.test(obj.title_background_color)
  ) {
    isValid = false;
    message = "Please enter Header Background Color in hex code";
  }
  else if ((obj.widget_st_time && !obj.widget_end_time) || (!obj.widget_st_time && obj.widget_end_time)) {
    isValid = false;
    message = "Please enter start and end date times together, or none at all.";

  } else if (obj.widget_st_time && obj.widget_end_time && obj.widget_st_time > obj.widget_end_time) {
    isValid = false;
    message = "Please correct the Scheduling Date and Time ";

  }

  if (isValid) {
    return true;
  } else {
    ErrorHandling(message);
    return false;
  }
}

//BRANDING > ADD EDIT WIDGET - updated widget details - put api
export async function updateWidgetDetails(data) {
  let url = prodCms + getWidgetListingEndpoint;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data ? res.data : 'Successfully Updated Widget!!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code == 4001) {
        return error;
      }
      else {
        ErrorHandling(error.message);
        return error;
      }
    })
}

//BRANDING > ADD EDIT WIDGET - updated widget details - post api
export async function addWidgetDetails(data) {
  let url = prodCms + getWidgetListingEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data ? res.data : 'Successfully Created Widget!!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code == 4001) {
        return error;
      }
      else {
        ErrorHandling(error.message);
        return error;
      }
    })
}

//BRANDING > WIDGET LISTING - version dropdown - get api
export async function getVersions() {
  let url = prodCms + getVersionsListingEndpoint;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res.data;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      console.log(error.message);
      ErrorHandling(error.message);
      return []
    });
  return res;
}

/* -------------NEW FEED CONTENT LISING AND ADD EDIT -------------------- */

// BRANDING > NEW FEED CONTENT > FEED CONTENT LIST - get api
export async function getNewFeedContentListing(zoneId, contentType, nSlot) {
  let url = `${prodCms}${getNewFeedContentListEndPoint}?pricing_zone_id=${zoneId}&banner_type=${contentType}&n_slot_id=${nSlot}`
  let res = await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return []
    })
  return res;
}

// PDP - PRODUCT DETAIL KEYS - ADD EDIT KEYS - post api
export async function deleteProdDetailKey(id) {
  let url = `${prodCms}${prodDetailKeyListEndpoint}?id=${id}`;
  return delete_data(url, {})
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data)
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    })
}

// PDP - LISTING ORDER - GET WIDGET LIST - get api
export async function fetchSectionWidgetListing() {
  let url = prodCms + listingSectionEndpoint;
  return get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}
/* ----------  PDP  ---------- */

// PDP - CATEGORY - GET CATEGORY LISTING - get api 
// export async function fetchCategoryList(){
//   let url = pcms + fetchCategoryListEndpoint;
//   return get_data(url)
//   .then((res) => {
//     if(res.success){
//       return res.data;
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message);
//     return [];
//   })
// }

// PDP - SUB CATEGORY - GET CATEGORY LISTING - get api 
// export async function fetchSubCategoryList(catId, page){
//   let url = pcms + fetchSubCategoryListEndpoint + `?category_list=${catId}&page=${page}`;
//   return get_data(url)
//   .then((res) => {
//     if(res.success){
//       return res.data;
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message);
//     return [];
//   })
// }

// PDP - PRODUCT DETAIL KEYS - GET LISTING - get api
// export async function fetchProdDetailKeyList(){
//   let url = prodCms + prodDetailKeyListEndpoint;
//   return get_data(url)
//   .then((res) => {
//     if(res.success){
//       return res.data;
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message);
//     return [];
//   })
// }

// PDP - PRODUCT DETAIL KEYS - ADD EDIT KEYS - post api
// export async function updateAddProdDetailKey(body){
//   let url = prodCms + prodDetailKeyListEndpoint;
//   return post_data(url, body)
//   .then((res) => {
//     if(res.success){
//       SuccessHandling(res.data)
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message);
//   })
// }

// PDP - PRODUCT DETAIL KEYS - ADD EDIT KEYS - post api
// export async function deleteProdDetailKey(id){
//   let url = `${prodCms}${prodDetailKeyListEndpoint}?id=${id}`;
//   return delete_data(url, {})
//   .then((res) => {
//     if(res.success){
//       SuccessHandling(res.data)
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message);
//   })
// }

// PDP - LISTING ORDER - GET WIDGET LIST - get api
// export async function fetchSectionWidgetListing(){
//   let url = prodCms + listingSectionEndpoint;
//   return get_data(url)
//   .then((res) => {
//     if(res.success){
//       return res.data;
//     }
//     else return Promise.reject(res.error);
//   })
//   .catch((error) => {
//     ErrorHandling(error.message)
//     return [];
//   })
// }

// PDP - BULK UPLOAD - CSV UPLOAD - post api
export async function uploadProdDetailCsv(data) {
  let url = prodCms + prodDetailCsvUploadEndpoint;
  return post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data)
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    })
}

// Bulk Upload
export async function bulkUploadCSVFile(data) {
  let url = prodCms + uploadProdDetails;
  return post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Data uploaded successfulluy")
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    })
}


// PDP - BULK UPLOAD - ZIP UPLOAD - post api
export async function uploadProdDetailZip(data, is_pdp) {
  let url = `${prodCms}${prodDetailZipUploadEndpoint}?is_pdp=${is_pdp}`;
  return post_image(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data);
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
    })
}

// BRANDING > NEW FEED CONTENT - post api
export async function addNewFeedBanner(data) {
  let url = `${prodCms}${getNewFeedContentListEndPoint}`
  let res = await post_data(url, data);
  console.log(url);
  console.log(res);
  return res;
}

// BRANDING > NEW FEED CONTENT - put api
export async function updateNewFeedBanner(data) {
  let url = `${prodCms}${getNewFeedContentListEndPoint}`
  let res = await put_data(url, data);
  console.log(url);
  return res;
}

// BRANDING > NEW FEED CONTENT > get api
export async function searchNewBannerApi(banner_text) {
  let url = `${prodCms}${searchNewBannerEndpoint}?search_text=${banner_text}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return error;
    })
}

//BRANDING > NEW FEED CONTENT > post api excel upload
export async function uploadNewBannerExcel(data) {
  let url = `${prodCms}${uploadNewBannerExcelEndpoint}`
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return error;
    })
}

//BRANDING > NEW MINI FEED > mini feed listing > get api
export async function getNewMiniFeedListing(is_active, pricing_zone_id) {
  let url = `${prodCms}${getNewMiniFeedListEndPoint}?is_active=${is_active}&pricing_zone_id=${pricing_zone_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

//BRANDING > NEW MINI FEED > mini feed search > get api
export async function searchMiniFeedApi(data) {
  let url = `${prodCms}${searchMiniFeedEndPoint}?search_text=${data}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data.length > 0 ? res.data : [];
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return []
    })
}

//BRANDING > NEW MINI FEED > mini feed active inactive > put api
export async function updateMiniFeedApi(data) {
  let url = prodCms + getNewMiniFeedListEndPoint;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data ? res.data : 'Successfully Updated MiniFeed !!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

// BRANDING > NEW MINI FEED > Mini feed add > post api
export async function saveNewMiniFeed(data) {
  let url = prodCms + getNewMiniFeedListEndPoint;
  return await post_data(url, data)
    .then((res) => {
      console.log(res);
      if (res.success) {
        SuccessHandling("Feed saved successfully");
        return res;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

//BRANDING > NEW MINI FEED  > Mini Feed widget bulk upload > post api
export async function uploadWidgetInMiniFeedApi(data) {
  let url = prodCms + bulkUploadWidgetsInMiniFeedEndPoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Widgets Uploaded Successfully");
        return res;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return { success: false, error: error };
    })
}



// USER SEGMENT > SEGMENT LISTING > listing api > get api 
export async function fetchSegmentList(page, tab) {
  let url = Base + fetchSegmentListEndPoint + `?page_no=${page}`;
  if (tab !== -1) {
    url += `&status=${tab}`
  }
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

// USER SEGMENT > SEGMENT LISTING > segement delete > get api 
export async function deleteSegment(segment_id) {
  let url = Base + deleteSegmentEndpoint + `?segment_id=${segment_id}`
  return await delete_data(url, {})
    .then((res) => {
      if (res.success) {
        SuccessHandling("Successfully Segment Deleted!")
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })

}

// USER SEGMENT > SEGMENT LISTING | EDIT > segement wise comsumer id list > get api 
export async function fetchSegmentUserList(segment_id) {
  let url = Base + fetchSegmentUserListEndpoint + `?segment_id=${segment_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

// USER SEGMENT > SEGMENT LISTING | EDIT > segement detail api > get api 
export async function searchUserSegment(segment_id) {
  let url = Base + searchUserSegmentEndpoint + `?search_text=${segment_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

// USER SEGMENT > ADD | EDIT > submit creation data > get api 
export async function createUserSegment(data) {
  let url = Base + createUserSegmentEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

// USER SEGMENT > ADD | EDIT > submit creation data > get api 
export async function activateUserSegment(data) {
  let url = Base + activateUserSegmentEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

// USER SEGMENT > ADD | EDIT > submit creation data > get api 
export async function updateUserSegment(data) {
  let url = Base + updateUserSegmentEndpoint;
  return await patch_data(url, data)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res);
    })
    .catch((res) => {
      ErrorHandling(res.error ? res.error.message : res.message);
      return res;
    })
}

/* --------------- ONDC   ---------------*/

//ONDC > ONDC PROVIDER > servicable city list > get api
export async function fetchOndcCityList() {
  let url = Ondc + fetchOndcCityListEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    })
}

//ONDC > ONDC PROVIDER > servicable city store list + search > get api
export async function fetchCitywiseProvider(cityId, page, searchQuery) {
  let url = Ondc + fetchCitywiseProviderEndpoint + `?std_code=${cityId}&query_param=${searchQuery}&page=${page}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return { rsuccess: false };
    })
}

//ONDC > ONDC PROVIDER CATALOGUE > category list > get api
export async function fetchOndcCategoryList(store_id) {
  let url = Ondc + fetchOndcCategoryListEndpoint + `?store_id=${store_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return { store: {}, categories_serving: [] };
    })
}

//ONDC > ONDC PROVIDER CATALOGUE > product list + search> get api
export async function fetchOndcStoreProducts(catName, page, searchQuery, storeId) {
  let url = Ondc + fetchOndcStoreProductsEndpoint;
  let data = {
    store_id: storeId,
    query_param: searchQuery,
    page: page,
    category: catName,
  }
  if (catName === 'All') {
    delete data.category;
  }

  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        return res;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return { rsuccess: false };
    })
}

/* --------------- ONDC FEED | WIDGET MANAGEMENT  ---------------*/

// ONDC > WIDGET LISTING | popup widget - zone names string - util function
export function getZoneNames(zoneIds, zoneList) {
  let zoneNames = ""
  zoneList.map((zone) => {
    if (zoneIds.includes(zone.id)) {
      zoneNames += `${zone.name}, `;
    }
  })
  return zoneNames;
}

// ONDC > WIDGET | FEED > HEADER - ZONES LISTING - get api
export async function getOndcZoneList() {
  let url = OndcFeed + getOndcZoneListEndPoint;
  let res = await get_data(url)
    .then((res) => {
      if (res.success) {
        // console.log("Zone list");
        return res;
      }
      else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        data: [],
        success: false,
      }
    })
  return res;
}

// ONDC > WIDGET LISTING - ITEMS IN WIDGET LISTING - get api
export async function getOndcWidgetListing(show_inactive, zone) {
  let showActive = show_inactive ? 0 : 1;
  let url = `${OndcFeed}${getOndcWidgetListingEndpoint}?is_active=${showActive}&zone=${zone}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res.data;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      console.log(error.message);
      ErrorHandling(error.message);
      return []
    });
  return res;
}

// ONDC > WIDGET LISTING - WIDGET SEARCH - get api
export async function searchOndcWidget(search_text, flag) {
  let url = `${OndcFeed}${fetchOndcWidgetDetailsEndpoint}?search_text=${search_text}&get_all=${flag ? 1 : 0}`;
  let res = await get_data(url).then((res) => {
    if (res.success) {
      return res.data;
    }
    else
      return Promise.reject(res.error)
  })
    .catch((error) => {
      console.log(error.message);
      ErrorHandling(error.message);
      return []
    });
  return res;
}

// ONDC > WIDGET LISTING | ADD EDIT - updated widget details - put api
export async function updateOndcWidgetDetails(data, isEnableDisable) {
  let url = OndcFeed + getOndcWidgetListingEndpoint;
  return await put_data(url, data)
    .then((res) => {
      // response for active inactive widget
      if (res.success && isEnableDisable) {
        let msg = "Widget is Activated Successfully";
        if (!data.is_active)
          msg = "Widget is Deactivated Successfully";
        SuccessHandling(msg);
        return true;
      }
      // response for widget update
      else if (res.success) {
        SuccessHandling('Successfully Updated Widget!!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code == 4001) {
        return error;
      }
      // response for active inactive widget
      else if (isEnableDisable) {
        ErrorHandling(error.message);
        return false;
      }
      // response for widget update
      else {
        ErrorHandling(error.message);
        return error;
      }
    })
}

// ONDC > ADD | EDIT FEED - widget type listing - get api
export async function getOndcWidgetType() {
  let url = OndcFeed + getOndcWidgetTypeEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return [];
    });
}

// ONDC > ADD | EDIT FEED - widget catgeory listing - get api 
export async function getOndcCatList() {
  let url = OndcFeed + getOndcCatListEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else
        return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return [];
    })
}

// ONDC > ADD | EDIT FEED - widget catgeory listing - get api 
export async function getOndcStoreProdList() {
  let url = OndcFeed + getOndcStoreProdListEndpoint;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else
        return Promise.reject(res.error);
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return [];
    })
}

// ONDC > ADD | EDIT FEED - widget creation - post api 
export async function addOndcWidgetDetails(data) {
  let url = OndcFeed + getOndcWidgetListingEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Successfully Added Widget!!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code == 4001) {
        return error;
      }
      ErrorHandling(error.message);
      return error;
    })
}

// ONDC > FEED MANAGEMENT - ondc feed details based on status - get api
export async function fetchOndcFeedDetails(status, pricing_zone_id) {
  let url = `${OndcFeed}${fetchOndcFeedDetailsEndpoint}?status=${status}&pricing_zone_id=${pricing_zone_id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      } else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return {
        draft_exist: false,
        feed_list: [],
      }
    })
}

// ONDC > FEED MANAGEMENT - ondc feed search - get api
export async function fetchOndcFeedSearch(id) {
  let url = `${OndcFeed}${fetchOndcFeedSearchEndpoint}?search_text=${id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return []
    })
}

// ONDC > FEED MANAGEMENT - ondc draft creation - post api
export async function ondcFeedNewVersion(feedId, pricingZoneId) {
  let url = OndcFeed + ondcFeedNewVersionEndpoint;
  let data = {
    id: feedId,
    pricing_zone_id: pricingZoneId,
  };
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data.message);
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })

}

// ONDC > FEED MANAGEMENT - ondc history to draft creation - post api
export async function ondcHistoryToDraftFeed(feedId, pricingZoneId) {
  let url = OndcFeed + ondcHistoryToDraftFeedEndpoint;
  let data = {
    id: feedId,
    pricing_zone_id: pricingZoneId,
  };
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling(res.data.message);
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}

// ONDC > FEED MANAGEMENT - publish feed - post api
export async function ondcPublishFeed(feedId, pricingZoneId) {
  debugger;
  let url = OndcFeed + ondcPublishFeedEndpoint;
  let data = {
    feed_id: feedId,
    pricing_zone_id: pricingZoneId,
  };
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling("Successfully Feed Published!!");
        return true;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      ErrorHandling(error.message);
      return false;
    })
}

// ONDC > FEED MANAGEMENT - edit draft feed - put api
export async function updateOndcDraftFeed(data) {
  let url = OndcFeed + fetchOndcFeedDetailsEndpoint;
  return await put_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Successfully Updated ONDC Feed !!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

// ONDC > FEED MANAGEMENT - create draft feed - post api
export async function createOndcDraftFeed(data) {
  let url = OndcFeed + fetchOndcFeedDetailsEndpoint;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Successfully Added ONDC Feed !!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}


/* --------------- OFFER MANAGEMENT ---------------*/


//OFFERS > LISTING > offer listting listing > get api
export async function getOfferListing(status, n_slot_id, type) {
  let url = `${otipyCoupons}${getOfferListingEndPoint}?status=${status}&n_slot_id=${n_slot_id}&benefit_type=${type}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

//OFFERS > LISTING > offer seacrh listing > get api
export async function getSearchedOffer(search_text) {
  let url = `${otipyCoupons}${SearchOfferEndPoint}?search_text=${search_text}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

//OFFERS > LISTING > offer details > get api
export async function getOfferDetails(id) {
  let url = `${otipyCoupons}${getOfferDetailsEndPoint}?id=${id}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}

//OFFERS > LISTING > offer chnage status > post api
export async function changeOfferStatus(data) {
  let url = `${otipyCoupons}${ChangeOfferStatusEndPoint}`;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Coupon Status Successfully Changed!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}


// OFFER CREATION > create new offer > post api
export async function createOffer(data) {
  let url = `${otipyCoupons}${getOfferListingEndPoint}`;
  return await post_data(url, data)
    .then((res) => {
      if (res.success) {
        SuccessHandling('Coupon Successfully Created!');
        return res;
      }
      else {
        return Promise.reject(res.error);
      }
    })
    .catch((error) => {
      if (error.code != 4000) {
        ErrorHandling(error.message);
      }
      return { success: false, error: error };
    })
}

// OFFER CREATION > TAG > get tag list > get api
export async function getTagList(search) {
  let url = `${otipyCoupons}${getTagListsEndPoint}?search_text=${search}`;
  return await get_data(url)
    .then((res) => {
      if (res.success) {
        return res.data;
      }
      else return Promise.reject(res.error)
    })
    .catch((error) => {
      ErrorHandling(error.message)
      return []
    })
}