import React from "react";
import closeImg from '../../icons/close-black.png'
import style from './Popup.module.css';

function PopUp(props) {
  return (
    <div className={`${style.backDiv} ${props.cName}`}>
      <div className={`${style.popup} ${props.width}`}>
        <h4 className={` ${style.flexSpaceBw} ${style.heading}`}>
          <span className={style.capital}> {props.title} </span>
          <span onClick={props.closeBtn}>
            <img src={closeImg} className={`pointer`}/>
          </span>
        </h4>
        {props.children}
      </div>
    </div>
  );
}

export default PopUp;
